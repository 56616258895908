import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import {
    IHardware, IHardwareType, IHardwareUpdate, IHardwareUpdateList, ISetting
} from '../backend/TyrionAPI';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { ModalsHardwareBootloaderUpdateModel } from '../modals/hardware-bootloader-update';
import { FlashMessageSuccess } from '../services/NotificationService';
import { ModalsDeviceEditDescriptionModel } from '../modals/device-edit-description';
import { ModalsRemovalModel } from '../modals/removal';
import { ModalsDeviceEditDeveloperParameterValueModel } from '../modals/device-edit-developer-parameter-value';
import { ModalsPictureUploadModel } from '../modals/picture-upload';
import { ModalsHardwareRestartMQTTPassModel } from '../modals/hardware-restart-mqtt-pass';
import { ModalsHardwareChangeServerModel } from '../modals/hardware-change-server';
import { _BaseMainComponent } from './_BaseMainComponent';
import { ModalsSelectCodeModel } from '../modals/code-select';
import { FormGroup } from '@angular/forms';
import { IError } from '../services/_backend_class/Responses';
import { handleStatus } from '../backend/BeckiBackend';
import { ModalsBootloaderSelectComponent, ModalsSelectBootloaderModel } from '../modals/bootloader-select';

export class FilterStatesValues {
    public PENDING: boolean = true;
    public RUNNING: boolean = true;
    public COMPLETE: boolean = true;
    public CANCELED: boolean = true;
    public OBSOLETE: boolean = true;
    public FAILED: boolean = true;
}

export class FilterTypesValues {
    public MANUALLY_BY_USER_INDIVIDUAL:  boolean = true;
    public MANUALLY_RELEASE_MANAGER: boolean = true;
    public MANUALLY_BY_INSTANCE:  boolean = true;
    public AUTOMATICALLY_BY_INSTANCE: boolean = true;
    public AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE:  boolean = true;
    public AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE: boolean = true;
}


@Component({
    selector: 'bk-view-projects-project-hardware-hardware',
    template: require('./projects-project-hardware-hardware.html')
})
export class ProjectsProjectHardwareHardwareComponent extends _BaseMainComponent implements OnInit, OnDestroy {

    init: boolean = false;  // Only for title and sutitle menu (for slow internet there was sometimes
    // issue with no project for admin view or for project view but with slow
    // ngOnInit method
    hardware: IHardware = null;
    hardwareType: IHardwareType = null;
    projectId: string;
    hardwareId: string;
    routeParamsSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    actualizationTaskFilter: IHardwareUpdateList = null;
    currentParamsService: CurrentParamsService; // exposed for template - filled by _BaseMainComponent
    tab: string = 'overview';

    terminalOpened: boolean = false;

    filterStatesValues = new FilterStatesValues();
    filterTypesValues = new FilterTypesValues();
    formUpdateHardwareGroup: FormGroup;

    // Config Parameters
    configParameters: Array<ISetting> = [];
    page: number = 0;

    constructor(injector: Injector) {
        super(injector);

        this.formUpdateHardwareGroup = this.formBuilder.group({
            'orderBy': ['HARDWARE_NAME', []],
            'order_schema': ['ASC', []],
        });
    };

    /**
     * If there is not project id in the url
     */
    getCurrentProjectId(): string {
        return super.getCurrentProjectId() || ((this.hardware && this.hardware.project) ? this.hardware.project.id : '');
    }

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.hardwareId = params['hardware'];
            this.projectId = params['project'];
            this.init = true;

            this.refresh();
        });

        this.tyrionBackendService.objectUpdateTyrionEcho.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status.model === 'Hardware' && this.hardwareId === status.model_id) {
                this.refresh();
                this.onFilterHardwareUpdates();
            }
        });
    }

    onBlinkDeviceClick(): void {
        this.tyrionBackendService.boardCommandExecution({
            hardware_id: this.hardwareId,
            command: 'BLINK'
        })
            .then(() => {
                this.fmSuccess(this.translate('blink_device_success'));
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onPortletClick(action: string): void {
        if (action === 'update_hardware') {
            this.onEditClick(this.hardware);
        }

        if (action === 'deactivate_hardware') {
            this.onFreezeDeviceClick(this.hardware);
        }

        if (action === 'active_hardware') {
            this.onActiveDeviceClick(this.hardware);
        }

        if (action === 'hardware_restart') {
            this.onRestartDeviceClick();
        }

        if (action === 'hardware_restart_bootloader') {
            this.onSwitchToBootloaderDeviceClick();
        }

        if (action === 'hardware_restart_pass') {
            this.onGenerateNewPassword();
        }

        if (action === 'hardware_change_server') {
            this.onChangeServer();
        }

        if (action === 'hardware_manual_update') {
            this.onManualIndividualUpdate();
        }

        if (action === 'blink_hardware') {
            this.onBlinkDeviceClick();
        }

        if (action === 'remove_hardware') {
            this.onRemoveClick(this.hardware);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onToggleTab(tab: string) {

        this.tab = tab;

        if (tab === 'updates') {
            this.onFilterHardwareUpdates();
        }

        if (tab === 'command_center') {
            this.terminalOpened = true;
            // Nothing
        }
    }

    refresh(): void {
        this.blockUI();
        this.tyrionBackendService.boardGet(this.hardwareId) // TODO [permission]: Project.read_permission
            .then((hardware) => {
                this.hardware = hardware;
                this.configParameters = hardware.bootloader_core_configuration.settings;

                if (this.hardware.server) {
                    this.tyrionBackendService.observeNetworkStatus(this.hardware.server.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(handleStatus(this.hardware.server));
                }
                this.tyrionBackendService.observeNetworkStatus(this.hardware.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(handleStatus(this.hardware));

                return this.tyrionBackendService.hardwareTypeGet(this.hardware.hardware_type.id);
            })
            .then((hardwareType) => {
                this.hardwareType = hardwareType;
                this.unblockUI();

            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onEditClick(device: IHardware): void {
        let model = new ModalsDeviceEditDescriptionModel(this.hardware.project.id, device);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.boardEditPersonalDescription(device.id, {
                    name: model.hardware.name,
                    description: model.hardware.description,
                    tags: model.hardware.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_edit_device_success')));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRemoveClick(device: IHardware): void {
        this.modalService.showModal(new ModalsRemovalModel(device.id)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.projectRemoveHW(device.id) // TODO [permission]: Project.update_permission (probably implemented as device.delete_permission)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_edit_device_success')));
                        this.router.navigate(['/projects/' + this.hardware.project.id + '/hardware']);
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                    });
            }
        });
    }

    onFreezeDeviceClick(device: IHardware): void {
        this.blockUI();
        this.tyrionBackendService.projectDeactiveHW(device.id)
            .then(() => {
                this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_edit_device_success')));
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.refresh();
            });
    }

    onActiveDeviceClick(device: IHardware): void {
        this.tyrionBackendService.projectActiveHW(device.id)
            .then(() => {
                this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_active_device_success')));
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.refresh();
            });
    }

    updatePictureClick(): void {
        let model = new ModalsPictureUploadModel(null, this.hardware.picture_link, false);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.hardwareUploadPicture(this.hardware.id, {
                    file: model.file
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_picture_updated')));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    /**
     * Edit onDeveloperSettingsClick
     * @param hardware
     * @param key
     * @param value type and Boolean
     */
    onDeveloperSettingsClick(key: string, value: boolean): void {
        this.blockUI();
        this.tyrionBackendService.boardEditDeveloperSettings(this.hardware.id, {
            key,
            value
        })
            .then(() => {
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    /**
     * Edit onEditParameterValue_Number_Click
     * @param parameter_user_description
     * @param key
     * @param value
     */
    onEditParameterValueClick(parameter_user_description: string, key: string, value: any): void {
        let model = new ModalsDeviceEditDeveloperParameterValueModel(this.hardware.id, parameter_user_description, value);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.onEditParameterClick(key, model.value)
            }
        });
    }

    onEditParameterClick(key: any, value: any) {
        this.blockUI();
        this.tyrionBackendService.boardEditParameters({
            hardware_ids: [this.hardware.id],
            parameters: [{ key: key.toUpperCase(), value: value.toString()}]
        })
            .then(() => {
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onRestartDeviceClick(): void {
        this.tyrionBackendService.boardCommandExecution({
            hardware_id: this.hardware.id,
            command: 'RESTART'
        })
            .then(() => {
                this.fmSuccess(this.translate('flash_device_restart_success'));
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onGenerateNewPassword(): void {
        let model = new ModalsHardwareRestartMQTTPassModel(this.hardware);
        this.modalService.showModal(model).then((success) => {
            if (success) { }
        });
    }

    onChangeServer(): void {
        let model = new ModalsHardwareChangeServerModel(this.hardware);
        this.modalService.showModal(model).then((success) => {
            if (success) { }
        });
    }

    onSwitchToBootloaderDeviceClick(): void {
        this.tyrionBackendService.boardCommandExecution({
            hardware_id: this.hardware.id,
            command: 'SWITCH_TO_BOOTLOADER'
        })
            .then(() => {
                this.fmSuccess(this.translate('flash_device_restart_success'));
                this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onManualUpdateBootloaderClick(): void {
        if (!this.hardware) {
            return;
        }

        let model = new ModalsSelectBootloaderModel(this.hardwareType.id, true);
        this.modalService.showModal(model)
            .then((success) => {
                if (success) {
                    this.blockUI();
                    this.tyrionBackendService.hardwareUpdate({
                        hardware_id: this.hardware.id,
                        bootloader_id: model.selected_bootloader != null ? model.selected_bootloader.id : null,
                        file: model.file,
                        firmware_type: 'BOOTLOADER'
                    })
                        .then(() => {
                            this.refresh();
                            this.tab = 'updates';
                            this.onFilterHardwareUpdates();

                        })
                        .catch((reason: IError) => {
                            this.fmError(reason);
                            this.unblockUI();
                        });
                }
            })
    }

    onUpdateBootloaderClick(): void {
        if (!this.hardware) {
            return;
        }

        let mConfirm = new ModalsHardwareBootloaderUpdateModel(this.hardware.name ? this.hardware.name : this.hardware.id);
        this.modalService.showModal(mConfirm)
            .then((success) => {
                if (success) {
                    this.blockUI();
                    this.tyrionBackendService.hardwareUpdateBootloader({
                        device_ids: [this.hardware.id],
                        bootloader_id: this.hardware.available_latest_bootloader.id
                    })
                        .then(() => {
                            this.refresh();
                        })
                        .catch((reason: IError) => {
                            this.fmError(reason);
                            this.unblockUI();
                        });
                }
            });
    }

    onManualIndividualUpdate(): void {

        let model = new ModalsSelectCodeModel(this.hardware.project.id, [this.hardwareType.id], true);
        this.modalService.showModal(model)
            .then((success) => {
                if (success) {
                    this.blockUI();
                    this.tyrionBackendService.hardwareUpdate({
                        hardware_id: this.hardware.id,
                        c_program_version_id: model.selected_c_program_version != null ? model.selected_c_program_version.id : null,
                        file: model.file,
                        firmware_type: 'FIRMWARE'
                    })
                        .then(() => {
                            this.refresh();
                            this.tab = 'updates';
                            this.onFilterHardwareUpdates();

                        })
                        .catch((reason: IError) => {
                            this.fmError(reason);
                            this.unblockUI();
                        });
                }
            })
    }


    onFilterHardwareUpdateChange = (filter: {key: string, value: any}) => {
        console.info('onFilterChange: Key', filter.key, 'value', filter.value);

        if (this.filterStatesValues.hasOwnProperty(filter.key)) {
            this.filterStatesValues[filter.key] = filter.value;
        }

        if (this.filterTypesValues.hasOwnProperty(filter.key)) {
            this.filterTypesValues[filter.key] = filter.value;
        }

        this.onFilterHardwareUpdates();
    }


    onFilterHardwareUpdates = (page?: number): void => {

        if (page) {
            this.page = page;
        }

        if (!this.formUpdateHardwareGroup.valid && this.formUpdateHardwareGroup.dirty) {
            return;
        }

        this.blockUI();

        let state_list: string[] = [];
        for (let k in this.filterStatesValues) {
            if (this.filterStatesValues.hasOwnProperty(k)) {
                if (this.filterStatesValues[k] === true) {
                    state_list.push(k);
                }
            }
        }

        let type_list: string[] = [];
        for (let k in this.filterTypesValues) {
            if (this.filterTypesValues.hasOwnProperty(k)) {
                if (this.filterTypesValues[k] === true) {
                    type_list.push(k);
                }
            }
        }

        this.tyrionBackendService.hardwareUpdateGetByFilter({
            hardware_ids: [this.hardwareId],
            update_states:  <any>state_list,
            type_of_updates: <any>type_list,
            order_by: this.formUpdateHardwareGroup.controls['orderBy'].value,
            order_schema: this.formUpdateHardwareGroup.controls['order_schema'].value,
            page_number: this.page
        })
            .then((values) => {
                this.actualizationTaskFilter = values;
                values.content.forEach(x => x['isOpen'] = false);

                // Set page to 0 if number of filtered items now less then 25
                if (this.actualizationTaskFilter.content.length < 25) {
                    this.page = 0;
                }
                this.actualizationTaskFilter.content.forEach((task) => {
                    this.tyrionBackendService.objectUpdateTyrionEcho.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
                        if (status.model === 'CProgramUpdatePlan' && task.id === status.model_id) {
                            this.tyrionBackendService.hardwareUpdateGet(task.id)
                                .then((value) => {
                                    task.state = value.state;
                                    task.finished = value.finished;
                                })
                                .catch((reason: IError) => {
                                    this.fmError(reason);
                                });
                        }
                    });
                });

                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.unblockUI();
                this.fmError(reason);
            });
    }

    onAutoBackupSwitchClick(backup_mode: string): void {

        if (!this.hardware) {
            return;
        }

        if (backup_mode === 'STATIC_BACKUP') {
            let model = new ModalsSelectCodeModel(this.hardware.project.id, [this.hardware.hardware_type.id], true);
            this.modalService.showModal(model)
                .then((success) => {
                    if (success) {
                        this.blockUI();
                        this.tyrionBackendService.hardwareUpdate({
                            hardware_id: this.hardware.id,
                            c_program_version_id: model.selected_c_program_version != null ? model.selected_c_program_version.id : null,
                            file: model.file != null ? model.file : null,
                            firmware_type: 'FIRMWARE'
                        })
                            .then(() => {
                                this.refresh();
                                this.onFilterHardwareUpdates();
                            })
                            .catch((reason: IError) => {
                                this.fmError(reason);
                                this.unblockUI();
                            });
                    }
                });

        } else {

            /*
            this.blockUI();

            this.tyrionBackendService.boardUpdateBackup({
                hardware_backup_pairs: [
                    {
                        hardware_id: this.hardware.id,
                        backup_mode: true
                    }
                ]
            })
                .then(() => {
                    this.refresh();
                })
                .catch((reason: IError) => {
                    this.fmError(reason);
                    this.unblockUI();
                });
                */
        }
    }

    cancelUpdates(update: IHardwareUpdate) {
        let model = new ModalsRemovalModel(this.translate('cancel_updates_for'), this.translate('dialog_message_on_cancel'));
        this.modalService.showModal(model)
            .then((success) => {
                if (success) {
                    this.tyrionBackendService.hardwareUpdateCancel(update.id)
                        .then(() => {
                            this.fmSuccess(this.translate('flash_updates_canceled'));
                            this.onFilterHardwareUpdates();
                        })
                        .catch((reason: IError) => {
                            this.fmError(reason);
                        });
                }
            });
    }

    onDropDownEmitter (action: string, object: any): void {
        if (action === 'cancel_updates') {
            this.cancelUpdates(object);
        }
        if (action === 'open_hardware_update') {
            this.openHardwareUpdateTab(object);
        }
    }

    openHardwareUpdateTab(update: IHardwareUpdate) {
        update['isOpen'] = !update['isOpen'];
    }

    getSettingValue(name: string) {
        if (this.hardware && this.hardware.bootloader_core_configuration) {
            let setting = this.hardware.bootloader_core_configuration.settings.find(s => s.key === name);
            return setting ? setting.value : undefined;
        }
    }
}
