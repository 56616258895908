import { Component, Input, OnChanges, SimpleChanges, ViewChildren, AfterViewInit } from '@angular/core';
import { IBlockVersion, ICProgramVersion, ICProgramVersionRefresh } from '../backend/TyrionAPI';
import { TranslationService } from '../services/TranslationService';
import { CodeFile } from './CodeIDEComponent';
import { FormSelectComponentOption, FormSelectComponent } from './FormSelectComponent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileDownloaderService } from '../services/FileDownloaderService';

@Component({
    selector: 'bk-program-version-diff',
    /* tslint:disable:max-line-length */
    template: `
        <div>
            <div style="max-width:100%; display: flex; flex-direction: row; justify-content: space-between;">
                <bk-form-select style="flex-grow: 1; margin: 10px;"
                                [options]="versionsListAsOptions"
                                [control]="form.controls['versionSource']"
                                [label]="(isBlocko ?'label_source_file' : 'label_source_version')|bkTranslate:this"
                                (valueChanged)="onSourceSelectionChanges($event)"
                                #sourceVersion > </bk-form-select>
                <bk-form-select style="flex-grow: 1; margin: 10px;"
                                [options]="versionsListAsOptions"
                                [control]="form.controls['versionTarget']"
                                (valueChanged)="onTargetSelectionChanges($event)"
                                [label]="(isBlocko ?'label_target_file' :'label_target_version')|bkTranslate:this"
                                #targetVersion > </bk-form-select>
             </div>
            <div style="max-width:100%; display: flex; flex-direction: row;">
                <bk-file-tree-root style="min-width: 250px;"
                                   *ngIf="codeFilesTarget && !isBlocko"
                                   [files]="codeFilesTarget"
                                   (newPathSelected)="onFileSelected($event)" ></bk-file-tree-root>
                <bk-monaco-diff style="flex-grow: 1"
                                *ngIf="selectedFilePath || isBlocko"
                                [originalCode] = "sourceFileContent"
                                [code] = "targetFileContent"> </bk-monaco-diff>
            </div>
        </div>
    `
    /* tslint:enable */
})
export class ProgramVersionDiffComponent implements  OnChanges, AfterViewInit {

    @Input()
    versionList: any;

    @Input()
    isBlocko: boolean = false;

    @ViewChildren('sourceVersion')
    sourceVersion: FormSelectComponent;

    @ViewChildren('targetVersion')
    targetVersion: FormSelectComponent;


    codeFilesSource: CodeFile[] = [];
    codeFilesTarget: CodeFile[] = [];

    versionsListAsOptions: FormSelectComponentOption[] = [];

    form: FormGroup;

    sourceFileContent: string;
    targetFileContent: string;

    selectedFilePath: string = null;

    constructor(private formBuilder: FormBuilder, private translationService: TranslationService, private fileDownloaderService: FileDownloaderService) {
        this.form = this.formBuilder.group({
            'versionTarget': ['', [Validators.required]],
            'versionSource': ['', [Validators.required]]
        });
    }

    ngAfterViewInit(): void {
        if (this.versionList) {
            this.versionsListAsOptions = this.versionList.map((version) => {
                const s = {
                    label: version.name,
                    value: version.name,
                    data: version
                } as FormSelectComponentOption;
                return s;
            });
            this.onSourceSelectionChanges(this.form.controls['versionSource'].value);
            this.onTargetSelectionChanges(this.form.controls['versionTarget'].value);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        let versions = changes['versionList'];

        if (this.isBlocko === false) {
            if (versions && versions.currentValue) {
                this.versionsListAsOptions = versions.currentValue.map((version: ICProgramVersion) => {
                    const s = {
                        label: version.name,
                        value: version.name,
                        data: version
                    } as FormSelectComponentOption;
                    return s;
                });
            }
        } else if (this.isBlocko === true) {
            if (versions && versions.currentValue) {
                this.versionsListAsOptions = versions.currentValue.map((version: IBlockVersion) => {
                    const s = {
                        label: version.name,
                        value: version.name,
                        data: version
                    } as FormSelectComponentOption;
                    return s;
                });
            }
        }

        this.onSourceSelectionChanges(this.form.controls['versionSource'].value);
        this.onTargetSelectionChanges(this.form.controls['versionTarget'].value);
    }

    onSourceSelectionChanges(value: String) {
        if (this.versionList) {
            const selectedVersion = this.versionList.find((version) => {
                return version.name === value;
            });
            if (typeof selectedVersion !== 'undefined') {

                if (this.isBlocko === false) {
                    this.fileDownloaderService.download(selectedVersion.link_to_download)
                        .then(data => {
                            const program: ICProgramVersionRefresh = JSON.parse(data);

                            let files = program.files.map((file) => {
                                return new CodeFile(file.file_name, file.content)
                            });
                            files.push(new CodeFile('main.cpp', program.main));
                            this.codeFilesSource = files;

                            if (this.selectedFilePath) {
                                this.onFileSelected(this.selectedFilePath);
                            } else {
                                this.onFileSelected('main.cpp');
                            }
                        })
                        .catch(reason => console.error(reason));

                } else if (this.isBlocko === true) {

                    this.fileDownloaderService.download(selectedVersion.link_to_download)
                        .then((data) => {
                            this.sourceFileContent = JSON.parse(data)['code'];
                        }).catch(reason => console.error(reason));

                }
            }
        }
    }

    onTargetSelectionChanges(value: String) {
        if (this.versionList) {
            const selectedVersion =  this.versionList.find((version) => {
                return version.name === value;
            });
            if (typeof selectedVersion !== 'undefined') {
                if (this.isBlocko === false ) {

                    this.fileDownloaderService.download(selectedVersion.link_to_download)
                        .then(data => {
                            const program: ICProgramVersionRefresh = JSON.parse(data);

                            let files = program.files.map((file) => {
                                return new CodeFile(file.file_name, file.content)
                            });
                            files.push(new CodeFile('main.cpp', program.main));
                            this.codeFilesTarget = files;
                            if (this.selectedFilePath) {
                                this.onFileSelected(this.selectedFilePath);
                            }
                        })
                        .catch(reason => console.error(reason));

                } else if (this.isBlocko === true) {

                    this.fileDownloaderService.download(selectedVersion.link_to_download)
                        .then((data) => {
                            this.targetFileContent = JSON.parse(data)['code'];
                        }).catch(reason => console.error(reason));

                }
            }
        }
    }

    onFileSelected(path: string) {
        const originalFile = this.codeFilesSource.find((f: CodeFile) => {
            return f.objectFullPath === path;
        });
        if (originalFile) {
            this.sourceFileContent = originalFile.content;
        } else {
            this.sourceFileContent = '';
        }


        const changedFile = this.codeFilesTarget.find((f: CodeFile) => {
            return f.objectFullPath === path;
        });
        if (changedFile) {
            this.targetFileContent = changedFile.content;
        } else {
            this.targetFileContent = '';
        }

        this.selectedFilePath = path;
    }
}
