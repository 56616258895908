import { IMyDpOptions, IMyDateModel, MyDatePicker } from 'mydatepicker';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { TimeHourMinute } from './TimePickerComponent';
// other imports here...

@Component({
    selector: 'bk-date-picker',
    /* tslint:disable:max-line-length */
    template: /*[formControl]="timeControl"*/`
        <label *ngIf="labelEnabled">{{label}}</label>
        <my-date-picker #datepicker [options]="dateOption"
                        [formControl]="dateControl"
                        [placeholder]="placeholder"
                        (dateChanged)="onDateChanged($event)">
        </my-date-picker>
        <div *ngIf="supportMinutes && hourMinutesControl">
            <bk-time-picker [control]="hourMinutesControl"
                            [readonly]="readonly"
                            [minuteStep]="15"
                            (dateTimeChanged)="onTimeChanged($event)"
                            (onEnterEvent)="onEnterEvent.emit($event)">
            </bk-time-picker>

        </div>
    `
    /* tslint:enable */
})
export class DatePickerComponent implements AfterViewInit {

    dateNow = new Date();

    @Input()
    set control(control: AbstractControl) {
        if (control) {
            this.dateControl = control;
            let myDateModel: IMyDateModel = control.value;
            let date;
            myDateModel.jsdate && typeof myDateModel.jsdate !== 'undefined' ? date = myDateModel.jsdate : date = new Date();
            let hours = ('0' + date.getHours()).slice(-2);
            let minutes = ('0' + date.getMinutes()).slice(-2);

            this.hourMinutesControl = this.formBuilder.control(hours + ':' + minutes, [Validators.required])

        }
    }

    @Input()
    label: string = '';

    @Input()
    labelEnabled: boolean = true;

    @Input()
    readonly: boolean = false;

    @Input()
    placeholder: string = this.dateNow.toDateString();

    @Input()
    dateOption: IMyDpOptions = { // can be found here: https://github.com/kekeh/mydatepicker/blob/master/README.md#options-attribute
        dateFormat: 'dd.mm.yyyy',
        showTodayBtn: true,
        showClearDateBtn: false,
        editableDateField: false,
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        inline: false
    };

    @Input()
    supportMinutes: boolean = false;

    @Output()
    onChange: EventEmitter<Date> = new EventEmitter();

    @Output()
    onEnterEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('datepicker')
    datePicker: MyDatePicker;

    hourMinutesControl: AbstractControl;
    dateControl: AbstractControl;

    ngAfterViewInit(): void {
        this.datePicker.inputBoxEl.nativeElement.addEventListener('keydown', this.onKeyDownEvent.bind(this))
    }

    onTimeChanged(event: TimeHourMinute) {

        let date: IMyDateModel = this.dateControl.value;
        date.jsdate.setHours(event.hour);
        date.jsdate.setMinutes(event.minute);

        this.onChange.emit(date.jsdate);
    }

    onDateChanged(event: IMyDateModel) {
        if (this.hourMinutesControl) {
            const hourMinute = this.hourMinutesControl.value.toString().split(':');
            let date: IMyDateModel = event;
            date.jsdate.setHours(Number(hourMinute[0]));
            date.jsdate.setMinutes(Number(hourMinute[1]));
        }
        this.dateControl.setValue(event);
        this.onChange.emit(event.jsdate);

    }

    constructor(private formBuilder: FormBuilder) {
    }

    onKeyDownEvent(event) {
        if (event.keyCode === 13) {
            this.onEnterEvent.emit(event);
        }
    }


}
