/**
 * Created by Alexandr Tylš on 03.01.18.
 */
/*
 * © 2015-2016 Becki Authors. See the AUTHORS file found in the top-level
 * directory of this distribution.
 */

import { Input, Output, EventEmitter, Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { ModalModel } from '../services/ModalService';
import { TranslationService } from '../services/TranslationService';
import { ICProgram, ICProgramList, ICProgramVersion, IHardwareType } from '../backend/TyrionAPI';
import { ProgramVersionSelectorComponent } from '../components/VersionSelectorComponent';
import { NotificationService } from '../services/NotificationService';
import { IError } from '../services/_backend_class/Responses';

export class ModalsSelectCodeModel extends ModalModel {
    public selected_c_program_version: ICProgramVersion = null;
    public selected_c_program: ICProgram = null;
    public file: any = null;
    public file_name: string = null;

    constructor(
        public project_id: string = null,
        public hardware_type_ids: string[] = null,
        public allow_select_file: boolean,
        public already_selected_code_for_version_change: {
            c_program_id: string,
            c_program_version_id: string
        } = null) {
        super();
        this.modalLarge = true;
    }
}

@Component({
    selector: 'bk-modals-code-select',
    template: require('./code-select.html')
})
export class ModalsCodeSelectComponent implements OnInit {

    @Input()
    modalModel: ModalsSelectCodeModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    @ViewChild(ProgramVersionSelectorComponent)
    versionSelector: ProgramVersionSelectorComponent;

    programs: ICProgramList = null;
    errorMessage: string = null;


    // For Filter parameters
    hardware_type_option: {
        label: string,
        value: string
    }[];

    // Filter parameters
    page: number = 0;
    public_programs: boolean = false;
    private_programs: boolean = true;
    hardware_type_id: string = null;
    formFilterGroup: FormGroup;

    // File Support
    data: any = null;

    constructor(private tyrionBackendService: TyrionBackendService, private formBuilder: FormBuilder, private translationService: TranslationService, protected notificationService: NotificationService) {
        this.formFilterGroup = this.formBuilder.group({
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
            'count_on_page': [10, []],
            'name': [null, []],
        });
    }

    ngOnInit(): void {

        // Expression has changed after it was checked -  setTimeout is protection
        if (!this.modalModel.already_selected_code_for_version_change) {

            // Get All Prerequisites
            if (this.modalModel.hardware_type_ids && this.modalModel.hardware_type_ids.length === 1)  {
                this.hardware_type_id = this.modalModel.hardware_type_ids[0];
                this.onFilterPrograms();
            }

            Promise.all<any>([
                this.tyrionBackendService.hardwareTypesGetAll()
            ])
                .then((values: [IHardwareType[]]) => {

                    let types = [];

                    values[0].forEach((pv) => {


                        if (this.modalModel.hardware_type_ids && this.modalModel.hardware_type_ids.indexOf(pv.id) > -1 ) {

                            types.push({
                                label: pv.name,
                                value: pv.id
                            });

                        } else if (!this.modalModel.hardware_type_ids) {
                            types.push({
                                label: pv.name,
                                value: pv.id
                            });
                        }
                    });

                    this.hardware_type_option = types;

                    this.onFilterPrograms();
                });

        } else {
            this.tyrionBackendService.cProgramGet(this.modalModel.already_selected_code_for_version_change.c_program_id)
                .then((program) => {
                    this.onSelectProgramClick(program);
                })
                .catch((reason: IError) => {
                    this.notificationService.fmError(reason);
                    this.errorMessage = reason.message;
                });

            this.tyrionBackendService.cProgramVersionGet(this.modalModel.already_selected_code_for_version_change.c_program_version_id)
                .then((programVersion) => {
                    this.onSelectProgramVersionClick(programVersion);
                })
                .catch((reason: IError) => {
                    this.notificationService.fmError(reason);
                    this.errorMessage = reason.message;
                });
        }
    }

    onSubmitClick(): void {
        if (!this.modalModel.selected_c_program_version && !this.modalModel.file) {
            this.errorMessage = this.translationService.translate('label_no_file_or_version_selected', this) ; // There is no version selected. ;
        } else {
            this.modalClose.emit(true);
        }
    }

    onSelectProgramClick(cprogram: ICProgram): void {
        this.modalModel.selected_c_program = cprogram;
    }

    onSelectProgramVersionClick(version: ICProgramVersion): void {
        this.modalModel.selected_c_program_version = version;
    }

    onBack(): void {
        this.modalModel.selected_c_program = null;
    }

    onDropDownEmitter(action: string, object: any): void {
        if (action === 'label_select_code') {
            this.onSelectProgramClick(object);
        }
    }

    onFilterChange = (filter: {key: string, value: any}) => {

        if (filter.key === 'public_programs') {
            this.public_programs = filter.value;
        }

        if (filter.key === 'private_programs') {
            this.private_programs = filter.value;
        }

        if (filter.key === 'hardware_type_id') {
            this.hardware_type_id = filter.value;
        }

        this.onFilterPrograms();
    }

    fileChangeListener($event: any) {
        let file: File = $event.target.files[0];
        if (file) {
            let myReader: FileReader = new FileReader();
            myReader.addEventListener('load', () => {
                this.data = myReader.result;
                this.modalModel.file_name = file.name;
                this.modalModel.file = this.data;
            }, false);

            myReader.readAsDataURL(file);
        }
    }

    onFilterPrograms = (page?: number): void => {

        if (page) {
            this.page = page;
        }

        this.tyrionBackendService.cProgramGetListByFilter({
            project_id: this.private_programs ? this.modalModel.project_id : null,
            public_programs: this.public_programs,
            hardware_type_ids: [this.hardware_type_id],
            count_on_page: 10,
            name: this.formFilterGroup.controls['name'].value,
            order_by: this.formFilterGroup.controls['orderBy'].value,
            order_schema: this.formFilterGroup.controls['order_schema'].value,
            page_number: this.page
        })
            .then((iCProgramList) => {
                this.programs = iCProgramList;
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
            });
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
