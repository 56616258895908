import { Component, Output, Input, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { FormSelectComponentOption } from './FormSelectComponent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { IMyDpOptions } from 'mydatepicker';

/* tslint:disable: */
@Component({
    selector: 'bk-filter-component',
    template: `

        <!-- Find and Select Program -->
        <div class="portlet light" style="padding: 0 0 0 0; margin: 0 0 0 0; min-height: 0 !important">
            <div *ngIf="showFilterHead" class="portlet-title" style="min-height: 0 !important">
                <div class="caption" style="padding: 0 0 0 0; cursor: pointer;">
                    <i class="icon-equalizer hide"></i>
                    <span class="caption-subject bold uppercase" (click)="onHide()">Filter </span>
                    <span class="caption-helper"
                          (click)="onHide()">Choose or not to choose, that is the question...</span>
                </div>

                <div class="tools" style="padding-bottom: 5px; padding-top: 5px;">
                    <a [class.collapse]="closed" [class.expand]="!closed" data-original-title="" title=""
                       (click)="onHide()"> </a>
                    <button *ngIf="haveChanges && applyButton || isDatePicker"
                            type="button"
                            style="margin-left: 10px;"
                            class="btn green"
                            (click)="onApply()"
                    [innerHTML]="'button_apply'|bkTranslate:this" >
                    </button>
                    <!-- <a data-toggle="modal" class="config" data-original-title="" title=""> </a> -->
                    <!-- <a href="" class="reload" data-original-title="" title=""> </a> -->
                    <!-- <a href="" class="remove" data-original-title="" title=""> </a> -->
                </div>
            </div>
            <div class="portlet-body" [style.display]="closed ? 'none': 'block'"
                 style="margin-left: 10px; margin-right: 10px">
                <div class="m-grid m-grid-filter m-grid-responsive-sm">
                    <div class="m-grid-row" *ngIf="_filter_parameters">
                        <div class="m-grid-col m-grid-col-center align-filter" *ngFor="let component of _filter_parameters; let i = index">

                            <!-- CHECK BOXS LIST-->
                            <div *ngIf="component.type == 'CHECKBOX_LIST'"
                                 [class.md-checkbox-list]="!component.content.horizontal"
                                 [class.md-checkbox-inline]="component.content.horizontal">
                                <div class="md-checkbox" *ngFor="let option of component.content.options; let j = index">
                                    <input type="checkbox" id="{{option.key +'_' + j}}" class="md-check"
                                           [(ngModel)]="option.selected" (click)="onCheckBoxClick(i, j)">
                                    <label for="{{option.key + '_' + j}}">

                                        <span class="check"></span>
                                        <span class="box"></span>
                                    </label>
                                    <label [innerHTML]="option.label" style="padding-left: 1px !important;"></label>
                                </div>
                            </div>

                            <!-- Select One List -->
                            <div *ngIf="component.type === 'LIST_SELECT'">
                                <bk-form-select [control]="component.content.form.controls['list']"
                                                [label]="component.content.label"
                                                [options]="component.content.options"
                                                [regexFirstOption]="component.content.first_value"
                                                [waitForTouch]="false"
                                                [emptyValueNeeded]="true"
                                                (valueChanged)="onSelectListChangeClick($event, component.content.key)">
                                </bk-form-select>
                            </div>


                            <!-- Select Multiple list -->


                            <!-- Write Tags -->
                            <div *ngIf="component.type === 'FIND_BY_TAG'">
                                <bk-form-tag-input [control]="component.content.form.controls[component.content.key]"
                                                   [label]="component.content.label"
                                                   [showLabel]="!!component.content.label"
                                                   [waitForTouch]="false"
                                                   (valueChange)="onSelectTagsChangeClick($event, component.content.key)"
                                                   (onEnterEvent)="onEnter()">
                                </bk-form-tag-input>
                            </div>

                            <!-- Write Name -->

                            <div *ngIf="component.type === 'FIND_BY_TEXT'">
                                <bk-form-input
                                    [control]="component.content.form.controls[component.content.key]"
                                    [label]="component.content.label"
                                    [waitForTouch]="false"
                                    [showLabel]="component.content.showLabel"
                                    (valueChange)="onSelectListChangeClick($event, component.content.key)"
                                    (onEnterEvent)="onEnter()">
                                </bk-form-input>
                            </div>

                            <!-- Set Date From To -->

                            <div *ngIf="component.type === 'TIME_DATE_SELECT'">
                                <bk-date-picker [control]="component.content.form.controls[component.content.key]"
                                                [label]="component.content.label"
                                                [dateOption]="component.content.dateOption"
                                                [supportMinutes]="true"
                                                [readonly]="true"
                                                (onChange)="onDateTimeChange($event, component['key'])"
                                                (onEnterEvent)="onEnter()">
                                </bk-date-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
/* tslint:enable */
export class FilterTableComponent implements OnInit, AfterViewInit {
    @Input()
    set filter_parameters(parameters: Filter_parameter[])  {
        parameters.forEach((parameter) => {

            if (parameter.type === 'LIST_SELECT') {
                (parameter.content as LIST_SELECT).form = this.formBuilder.group({
                    'list': ['', [Validators.required]]
                });

                if ((parameter.content as LIST_SELECT).options ==  null) {
                    (parameter.content as LIST_SELECT).options = [];
                }
            }

        });
        this._filter_parameters = parameters;
    }

    @Output()
    onChange: EventEmitter<{key: string, value: any}> = new EventEmitter<{key: string, value: any}>();

    @Input()
    closed: boolean = false;

    @Input()
    applyButton: boolean = false;

    @Input()
    showFilterHead: boolean = true;

    @Output()
    onEnterHit: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onApplyHit: EventEmitter<any> = new EventEmitter();

    private haveChanges: boolean = false;

    _filter_parameters: Filter_parameter[];
    isDatePicker: boolean;
    constructor(private backendService: TyrionBackendService, private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.isDatePicker = this._filter_parameters.some(filter => filter.type === 'TIME_DATE_SELECT');
    }

    ngAfterViewInit(): void {
        this.haveChanges = false;
    }

    onHide() {
        this.closed = !this.closed;
    }


    onCheckBoxClick(filter_parameter_index: number, content_index: number) {
        this.haveChanges = true;

        setTimeout( () => {

            this.onChange.emit({
                key: (this._filter_parameters[filter_parameter_index].content as CHECKBOX_LIST).options[content_index].key,
                value: (this._filter_parameters[filter_parameter_index].content as CHECKBOX_LIST).options[content_index].selected
            });
            this.onEnter();
        }, 50);
    }

    onSelectListChangeClick(value: any, key: string) {
        this.haveChanges = true;

        setTimeout( () => {
            this.onChange.emit({
                key: key,
                value: value
            });
        }, 50);
    }

    onSelectTagsChangeClick(tags: string[], key: string) {
        this.haveChanges = true;

        setTimeout( () => {

            this.onChange.emit({
                key: key,
                value: tags
            });
        }, 50);
    }


    onDateTimeChange(date: Date, key: string) {
        this.haveChanges = true;

        setTimeout(() => {
            this.onChange.emit({
                key: key,
                value: date
            })
        }, 50)
    }

    onEnter() {
        this.onEnterHit.emit();
        this.haveChanges = false;
    }

    onApply() {
        this.haveChanges = false;
        this.onApplyHit.emit();
    }
}
/* tslint:disable */

export interface Filter_parameter {
    type: ('CHECKBOX_LIST' | 'LIST_SELECT' | 'FIND_BY_TEXT' | 'FIND_BY_TAG'| 'TIME_DATE_SELECT');
    content: (CHECKBOX_LIST | LIST_SELECT | FIND_BY_TEXT | FIND_BY_TAG | TIME_DATE_SELECT);
}

export class CHECKBOX_LIST {
    options: {
        key: string,
        label: string,
        selected: boolean,
        color?: ('info' | 'error')
    }[];

    horizontal?: boolean; // Default is FALSE
}

export class FIND_BY_TEXT {
    key: string;       // Type "HW_ID" "HW_NAME...."
    label: string;
    form: FormGroup;    // you have to set by yourself  <-- Key is used also for find form.component['key'] in form
    showLabel: boolean;
}

export class FIND_BY_TAG {
    label: string;
    key: string;       // Type "HW_ID" "HW_NAME...."
    form: FormGroup;   // you have to set by yourself  <-- Key is used also for find form.component['key'] in form
    showLabel: boolean;
}

export class LIST_SELECT {
    options: FormSelectComponentOption[];
    first_value: string;
    label: string;
    key: string;       // List Type "HW_LIST" "CODE_LIST"
    form: FormGroup;
}

export class TIME_DATE_SELECT {
    form: FormGroup;
    key: string;       // List Type "HW_LIST" "CODE_LIST"
    dateOption: IMyDpOptions;
    label: string
}


/* tslint:enable  */
