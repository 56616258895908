import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IProject, IHardware } from '../backend/TyrionAPI';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { FormSelectComponentOption } from '../components/FormSelectComponent';
import { IError } from '../services/_backend_class/Responses';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'bk-view-projects-project-hardware-scan',
    template: require('./projects-project-hardware-scan.html')
})
export class ProjectsProjectHardwareAddWithQrComponent extends _BaseMainComponent implements OnInit, OnDestroy {

    projectId: string = null;
    project: IProject = null;

    routeParamsSubscription: Subscription;
    projectSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    currentParamsService: CurrentParamsService; // exposed for template - filled by _BaseMainComponent

    scanned = new Set<string>();
    waiting = new Set<string>();
    added = new Map<string, IHardware>();
    failed = new Map<string, string>();

    groups: FormSelectComponentOption[] = [];

    form: FormGroup;

    constructor(injector: Injector) {
        super(injector);
        this.form = this.formBuilder.group({
            'selected_group': ['', []]
        });
    };

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.projectId = params['project'];
            this.projectSubscription = this.storageService.project(this.projectId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((project) => {
                this.project = project;
                this.refresh();
            });
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    refresh(): void {
        this.scanned = new Set<string>();
        this.added = new Map<string, IHardware>();
        this.failed = new Map<string, string>();
        this.tyrionBackendService.hardwareGroupGetListByFilter( {
            project_id: this.projectId,
            page_number: 0
        }).then((hardwareGroups) => {
            this.groups = hardwareGroups.content.map((group) => {
                let wrappedGroup: FormSelectComponentOption = {
                    value: group.name,
                    label: group.name,
                    data: group
                };
                return wrappedGroup
            })
        }).catch(() => {

        })
    }

    qrCodeSent(code: string) {
        this.add(code);
    }

    add(code: string) {
        code =  code.replace(/ /g, '');
        if (!this.scanned.has(code)) {
            this.scanned.add(code);
            this.waiting.add(code);

            this.tyrionBackendService.projectAddHW({
                registration_hash: code,
                project_id: this.projectId,
                group_id: this.form.controls['selected_group'].value
            }).then((iHardware) => {
                this.waiting.delete(code);
                this.added.set(code, iHardware);
            }).catch((reason: IError) => {
                this.waiting.delete(code);
                this.failed.set(code, reason.message);
            });
        }
    }

}
