import { AfterViewInit, Component, Input, OnDestroy, OnInit, Injector, Output, EventEmitter } from '@angular/core';
const uuidv1 = require('uuid/v1');
// @ts-ignore
import * as am4core from '@amcharts/amcharts4/core';
// @ts-ignore
import * as am4charts from '@amcharts/amcharts4/charts';
// @ts-ignore
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
import { _BaseMainComponent } from '../views/_BaseMainComponent';
import { IHardwareOnlineStates } from '../backend/TyrionAPI';


class TransformedData {
    fromDate: Date;
    toDate: Date;
    value: ('NOT_YET_FIRST_CONNECTED'|'FREEZED'|'SHUT_DOWN'|'SYNCHRONIZATION_IN_PROGRESS'|'OFFLINE'|'ONLINE'|'UNKNOWN_LOST_CONNECTION_WITH_SERVER');
    alias: string;
    color: string;
}


@Component({
    selector: 'bk-online-state-chart',
    template: `
        <div>
            <ng-container>

                <br>

                <div class="m-widget3">
                    <div id="{{chartId}}"
                         [style.height.px]="size <= 6 ? 600 : ( (size * 60 ))"
                         style="width: 100%">
                    </div>
                </div>

                size: {{size}}

            </ng-container>
        </div>
    `
})
export class OnlineStatesChartComponent extends _BaseMainComponent implements OnInit, AfterViewInit, OnDestroy {


    @Input() loading$: boolean;
    @Input() showInPortlet: boolean = true;
    @Input() title: string = 'AmChartComponent_Title';
    @Input() showTitle: boolean = true;
    @Input() showHeadTools: boolean = true;
    @Input() title_tooltip: string = null;

    @Input() parserValue?: (val: number) => string;

    @Input() startDate?: number;
    @Input() endDate?: number;
    @Input() interval?: number;
    @Input() toolTipInGraph?: boolean = true;
    @Input() highlightFrom?: number;
    @Input() highlightTo?: number;

    @Input() set dataToShow(dataToShow: IHardwareOnlineStates[]) {
        this.zone.runOutsideAngular(() => {
            this.size = dataToShow.length;

            const transformedData = dataToShow.flatMap((value) => value.data.map((x, idx) => {

                const tranformed = new TransformedData();
                if (idx === 0) {
                    tranformed.fromDate = new Date(x.time * 1000 - 1000);
                } else {
                    tranformed.fromDate = new Date(x.time * 1000);
                }
                if (idx === value.data.length - 1) {
                    tranformed.toDate = new Date();
                } else {
                    tranformed.toDate = new Date(value.data[idx + 1].time * 1000 - 1);
                }

                tranformed.alias = value.hardware.name;
                tranformed.value = x.value;
                tranformed.color = this.stateToColor(x.value);
                return tranformed
            }));
            if (this.chart) {
                this.setupSeries(transformedData);
            } else if (transformedData) {
                this.savedData = transformedData;
            }
        });
    }

    @Output()
    chartIsReady = new EventEmitter<any>();

    /* Service variables  */
    chartId: string = uuidv1();
    series: am4charts.Series[];
    scrollbarX: am4charts.XYChartScrollbar;
    name: string;
    logo: HTMLImageElement;
    /* Operation variables */
    savedData: TransformedData[];
    size: number = 0;


    private chart: am4charts.XYChart;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit () {
    }


    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            const chart = am4core.create(this.chartId, am4charts.XYChart);
            chart.paddingRight = 20;
            chart.exporting.timeoutDelay = 10000;

            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'alias';
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.inversed = true;

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm:ss';
            dateAxis.renderer.minGridDistance = 70;
            dateAxis.renderer.tooltipLocation = 0;

            dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH:mm:ss';


            chart.cursor = new am4charts.XYCursor();

            chart.cursor.xAxis = dateAxis;

            this.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX = this.scrollbarX;

            this.chart = chart;
            if (this.savedData) {
                this.setupSeries(this.savedData);
            }
        });
    }

    setupSeries(data: TransformedData[]) {

        this.savedData = data;
        if (!data) {
            this.chartIsReady.emit();
            return;
        }

        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.series.clear();

                this.chart.data = data;

                const serie = new am4charts.ColumnSeries();
                serie.dataFields.openDateX = 'fromDate';
                serie.dataFields.dateX = 'toDate';
                serie.dataFields.categoryY = 'alias';
                serie.columns.template.width = am4core.percent(80);
                serie.columns.template.tooltipText = '{alias}: {value}';
                serie.columns.template.propertyFields.fill = 'color'; // get color from data
                serie.columns.template.propertyFields.stroke = 'color';
                serie.columns.template.strokeOpacity = 1;
                serie.events.on('ready', () => {this.chartIsReady.emit(true)});

                this.chart.series.push(serie);
                this.chart.validateData();
            }
        });
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }

    stateToColor (state: 'NOT_YET_FIRST_CONNECTED'|'FREEZED'|'SHUT_DOWN'|'SYNCHRONIZATION_IN_PROGRESS'|'OFFLINE'|'ONLINE'|'UNKNOWN_LOST_CONNECTION_WITH_SERVER'): string {
        switch (state) {
            case 'NOT_YET_FIRST_CONNECTED': return '#ff0e1b';
            case 'FREEZED': return '#1fb2ff';
            case 'SHUT_DOWN': return '#ffbf09';
            case 'SYNCHRONIZATION_IN_PROGRESS': return '#57ff31';
            case 'OFFLINE': return '#27ebff';
            case 'ONLINE': return '#fff815';
            case 'UNKNOWN_LOST_CONNECTION_WITH_SERVER': return '#737111';
        }
    }

}

export interface AmChartDataInterface {
    dataToShow: {_id: string, data: [{time: number, value: number}] }[];
    startDate?: number;
    endDate?: number;
    interval?: number;
}
