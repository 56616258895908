import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'bkTerminalHtmlPipe'
})

export class TerminalStripHtmlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {
    }

    // Its used for Hawdware loger terminal!
    transform(v: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }

}
