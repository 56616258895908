/**
 * Created by zaruba on 14.03.18.
 */

/* tslint:disable */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ValidatorErrorsService } from '../services/ValidatorErrorsService';
import { TranslationService } from '../services/TranslationService';
import { ConsoleLogComponent} from './ConsoleLogComponent';
import { IHardware } from '../backend/TyrionAPI';
import { TyrionBackendService } from '../services/BackendService';
import { ModalService } from '../services/ModalService';
import { FormSelectComponentOption } from './FormSelectComponent';
import { ModalsSelectHardwareModel } from '../modals/select-hardware';
import { WebSocketClientHardware } from '../services/websocket/WebSocketClientHardware';
import { IWebSocketMessage } from '../services/websocket/WebSocketMessage';
import { NotificationService } from '../services/NotificationService';
import { IError } from '../services/_backend_class/Responses';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Core } from 'blocko';

export interface IHardwareLog {
    target_id: string;
    level: ('mqtt' | 'trace' | 'debug' | 'info' | 'warn' | 'error');
    type: ('externalOutput' | 'externalCommandValue'
        // MQTT in & OUT
        | 'MQTTpacketPublishedToHardware' | 'MQTTpublishedFromHardware'
        // From Hardware - Console Logs
        | 'consoleLog'
        // From Hardware - Data
        | 'digitalInputReceived' | 'analogInputReceived' | 'messageInputReceived');
    event_name: string;
    value_type: ('message' | 'analog' | 'digital' | 'json' | 'string');
    value: number | string | string[] | Core.MessageJson | boolean;
}

@Component({
    selector: 'bk-terminal-log-component',
    template: require('./TerminalLogSubscriberComponent.html')
})
export class TerminalLogSubscriberComponent implements OnInit, OnDestroy, AfterViewInit {

    // Project Id for Filter Parameters
    @Input()
    project_id: string = null;

    @Input()
    readonly: boolean = false;

    // Allow Settings
    show_settings_panel: boolean = true;

    // Subscribe Logs
    @Input()
    preselected_hardware: IHardware[] = null;

    selected_hw_for_subscribe: IHardware[] = [];

    // Array With Log Type Parameters
    logLevel: { [hardware_id: string]: {
        log: ('error' | 'warn' | 'info' | 'debug' | 'trace'),
        subscribed: boolean,
        socket: WebSocketClientHardware
    }} = {};

    // First Preselect Tab
    tab: string = 'terminal';

    // Form For Color Selector
    colorForm: FormGroup;
    availableColors = ['#0082c8', '#e6194b', '#3cb44b', '#ffe119', '#f58231', '#911eb4', '#46f0f0', '#008080', '#aa6e28', '#ffd8b1']; // předdefinované barvy pro terminál

    @ViewChild('console')
    consoleLog: ConsoleLogComponent;

    lastColorInstance: number = 0; // kvůli barvám sledujeme poslední přidanou instanci

    logLevelOptions: FormSelectComponentOption[] = [
        { value: 'mqtt', label: 'MQTT - Everything' },
        { value: 'trace', label: 'trace' },
        { value: 'debug', label: 'debug' },
        { value: 'info', label: 'info' },
        { value: 'warn', label: 'warn' },
        { value: 'error', label: 'error' }
    ];

    isSubscripedForSocked = false;

    ngUnsubscribe = new Subject<void>();

    parser = new MessageLogHardwareParser();

    constructor(public modalService: ModalService, public tyrionBackendService: TyrionBackendService, public validatorErrorsService: ValidatorErrorsService, public formBuilder: FormBuilder, public translationService: TranslationService, protected notificationService: NotificationService,) {
    }

    ngOnInit() {
        this.colorForm = this.formBuilder.group({
        }); // inicializace prázdného formu pro barvy
    }

    ngAfterViewInit() {
        if (this.preselected_hardware && this.preselected_hardware.length > 0) {
            this.preselected_hardware.forEach((hw) => {
                setImmediate(() => this.addNewHardwareToSubscribeList(hw));
            });
        }
    }

    ngOnDestroy() {
        this.selected_hw_for_subscribe.forEach(hardware => {
            this.onHardwareUnSubscribeClick(hardware);
        }); // odhlásíme každej HW co byl připojen
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onToggleTab(tab: string) {
        this.tab = tab;
    }

    onPortletClick(action: string): void {
        if (action === 'add_hardware') {
            this.addNewHardwareToSubscribeModal();
        }
    }

    onMessage(msg: IWebSocketMessage) {

        if (msg.message_type === 'message_log') {



            let hardware = this.selected_hw_for_subscribe.find(device => device.id ===  msg.data['hardware_id']); // najdeme hardware, kterého se zpráva týká
            if (hardware) {

                // Parsování::
                if (this.consoleLog) {

                    let logMessage;

                    if (msg.data['log_event']['level']  == 'mqtt') {
                        logMessage =  this.parser.parseMQTT(<IHardwareLog>msg.data['log_event']);
                    } else if (msg.data['log_event']['type']  == 'consoleLog') {
                        logMessage =  this.parser.parseHardwareLogs(<IHardwareLog>msg.data['log_event']);
                    }

                    if(logMessage) {
                        this.consoleLog.add(msg.data['level'], logMessage, hardware.id, hardware.name); // přidání zprávy do consoleComponent
                    }


                }

            }

        }

        if (msg.message_type === 'online_state') {
            let hardware = this.selected_hw_for_subscribe.find(device => device.id ===  msg.data['hardware_id']); // najdeme hardware, kterého se zpráva týká
            if (hardware) {
                if (this.consoleLog) {

                    if(msg.data['state'] === 'online') {
                        this.consoleLog.add('info','connected', hardware.id, hardware.name); // přidání zprávy do consoleComponent
                    } else if(msg.data['state'] === 'offline') {

                        this.consoleLog.add('info', 'disconnected', hardware.id, hardware.name); // přidání zprávy do consoleComponent
                    }

                }
            }
        }
    }

    /**
     * Odlášení odběru, ale nikoliv odstranění ze seznamu
     * @param hardware
     */
    onHardwareUnSubscribeClick(hardware: IHardware): void {
        if (this.logLevel[hardware.id].socket) {
            this.logLevel[hardware.id].socket.requestDeviceTerminalUnsubscribe(hardware.id)
                .then((response: IWebSocketMessage) => {
                    if (response.isSuccessful()) {
                        this.logLevel[hardware.id].subscribed = false;
                        this.consoleLog.add('info', 'Un-Subscription successful.', hardware.id, hardware.name); // přidání zprávy do consoleComponent
                    } else {
                        this.consoleLog.add('error', 'Un-Subscription unsuccessful. Result' + response.error, hardware.id, hardware.name); // přidání zprávy do consoleComponent
                        throw new Error('Unable to unsubscribe the logger');
                    }
                })
                .catch((reason: IError) => {
                    this.notificationService.fmError(reason);
                    console.error('onHardwareUnSubscribeClick:', reason);
                });
        }
    }

    /**
     * Odlášení odběru, ale nikoliv odstranění ze seznamu
     * @param socket
     * @param hardware
     * @param logLevel
     */
    onHardwareSubscribeClick(hardware: IHardware, logLevel: ('error' | 'warn' | 'info' | 'debug' | 'trace') = 'info'): void {
        this.logLevel[hardware.id].socket.requestDeviceTerminalSubscribe(hardware.id, logLevel)
            .then((response: IWebSocketMessage) => {
                if (response.isSuccessful()) {
                    this.logLevel[hardware.id].subscribed = true;
                    this.consoleLog.add('info', 'Subscription successful'
                        + (hardware.online_state !== 'ONLINE' ? ', but the device is currently offline. Logs will appear here when device is connected' : '')
                        + '. Log level: ' + logLevel.toLocaleUpperCase() + '. You can change level or read MQTT Messages in settings tab.', hardware.id, hardware.name); // přidání zprávy do consoleComponent
                } else {
                    this.consoleLog.add('error', 'Subscription unsuccessful. Result' + response.error, hardware.id, hardware.name); // přidání zprávy do consoleComponent
                    throw new Error('Unable to subscribe new logger');
                }
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
                console.error('onHardwareSubscribeClick:', reason);
            });
    }

    /**
     * Změna Log Levelu
     * @param logLevel
     * @param hardware
     */
    onUserChangeLogLevelClick(logLevel: ('error' | 'warn' | 'info' | 'debug' | 'trace'), hardware: IHardware): void { // změna log levelu

        // console.log('onUserChangeLogLevelClick:: Hardware', hardware.id, 'LogLevel', logLevel);
        // console.log('onUserChangeLogLevelClick:: Server URL: ', this.logLevel[hardware.id].socket.getUrl());
        // console.log('onUserChangeLogLevelClick:: Log on : ', this.logLevel[hardware.id].log);

        if (this.logLevel[hardware.id].log === logLevel) {
            // console.log('onUserChangeLogLevelClick:: jsou stejné - vracím');
            // this.consoleLog.add('info', 'Nothing to change on Log level. Actual level is a same as required' + logLevel, hardware.id, hardware.name); // přidání zprávy do consoleComponent
            return;
        } else {

        }

        this.logLevel[hardware.id].socket.requestDeviceTerminalLevelChange(hardware.id, logLevel)
            .then((response: IWebSocketMessage) => {
                if (response.isSuccessful()) {
                    this.logLevel[hardware.id].log = logLevel;
                    this.consoleLog.add('info', 'Log level changed to <span class="bold">' + logLevel.toLocaleUpperCase() + '</span>', hardware.id, hardware.name); // přidání zprávy do consoleComponent
                } else {
                    throw new Error('Unable to change log level');
                }
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
                console.error('onUserChangeLogLevelClick:', reason);
            });

    }

    /**
     * Změna Log Levelu
     * @param color
     * @param hardware
     */
    onUserChangeColorClick(color: string, hardware: IHardware): void { // změna log levelu

        // console.log('onUserChangeColorClick:: Hardware', hardware.id, 'Color', color);

        this.colorForm.controls['color_' + hardware.id].setValue(color);
        this.consoleLog.set_color(hardware.id, this.colorForm.controls['color_' + hardware.id].value);

    }

    /**
     * Odstraníme Hardware úplně ze seznamu
     * @param hardware
     */
    removeHardwareFromSubscribeList(hardware: IHardware): void {
        // console.log('removeHardwareFromSubscribeList: Hardware::', hardware.id);
        this.onHardwareUnSubscribeClick(hardware);
        for(let i = this.selected_hw_for_subscribe.length - 1; i >= 0; i--) {
            if(this.selected_hw_for_subscribe[i].id === hardware.id) {
                this.selected_hw_for_subscribe.splice(i, 1);
            }
        }
    }


    addNewHardwareToSubscribeModal(): void {
        // console.log('addNewHardwareToSubscribeModal: ');
        let m = new ModalsSelectHardwareModel(this.project_id, null, true, false, true);
        this.modalService.showModal(m)
            .then(() => {
                m.selected_hardware.forEach((hw) => {

                    if (!this.selected_hw_for_subscribe.find(filter_hw => {
                            if (filter_hw.id === hw.id) {
                                return true;
                            }
                    })) {
                        if (this.lastColorInstance < 7) {
                            this.addNewHardwareToSubscribeList(hw);
                        } else {
                            this.addNewHardwareToSubscribeList(hw);
                        }
                    }
                });
            });
    }

    /**
     * Přidáme Hardware do seznamu a přihlásíme k odběru na default log level
     */
    public addNewHardwareToSubscribeList(hardware: IHardware): void {

        // console.log('addNewHardwareToSubscribeList: hardware:: ', hardware.id, 'color: ', color);
        let color = this.availableColors[this.lastColorInstance++];

        this.colorForm.addControl('color_' + hardware.id, new FormControl('color_' + hardware.id));
        this.colorForm.addControl('log_' + hardware.id, new FormControl('log_' + hardware.id));

        this.colorForm.controls['color_' + hardware.id].setValue(color);
        this.colorForm.controls['log_' + hardware.id].setValue('info');

        // console.log('addNewHardwareToSubscribeList: this.selected_hw_for_subscribe size: ', this.selected_hw_for_subscribe.length);
        // console.log('addNewHardwareToSubscribeList: this.selected_hw_for_subscribe: ', this.selected_hw_for_subscribe);

        if (hardware.server == null) {
            this.consoleLog.set_color(hardware.id, color);
            this.consoleLog.add('error', 'Device has not connected to any server before. Logs cannot be subscribed.', hardware.id, hardware.name);
            return
        }

        // Nejdříve je nutné připojit se k serveru
        this.tyrionBackendService.getWebsocketService().connectDeviceTerminalWebSocket(
            hardware.server.server_version == 'v1.6.3' ? hardware.server.server_url : hardware.server.server_url + "/ws",
            hardware.server.server_version == 'v1.6.3' ? hardware.server.hardware_logger_port.toString() : null,
            (socket: WebSocketClientHardware, error: any) => {


            if (socket) {
                console.error('addNewHardwareToSubscribeList: Connection connnected. Socket is open?: ', socket.isOpen());

                // Set Default Values
                this.logLevel[hardware.id] = {
                    log: 'info',
                    subscribed: false,
                    socket: socket
                };

                this.selected_hw_for_subscribe.push(hardware);

                if (socket.isOpen()) {
                    this.onHardwareSubscribeClick(hardware, this.logLevel[hardware.id].log)
                } else {
                    socket.onOpened = () => this.onHardwareSubscribeClick(hardware, this.logLevel[hardware.id].log);
                }

                if (!this.isSubscripedForSocked) {
                    socket.messages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(message => this.onMessage(message));
                    this.isSubscripedForSocked = true;
                }

                this.consoleLog.set_color(hardware.id, this.colorForm.controls['color_' + hardware.id].value);

            } else {
                console.error('addNewHardwareToSubscribeList: Connection Error ', error);
            }
        });

    }

}
/* tslint:enable */

/*
message_type: "message_log", level: "error",…}
hardware_id: "1e1ffa0e-18ed-41dd-a250-6534be3f5978"
level: "error"
log_event: {target_id: "1e1ffa0e-18ed-41dd-a250-6534be3f5978", level: "error", event_name: "message",…}
event_name: "message"
level: "error"
target_id: "1e1ffa0e-18ed-41dd-a250-6534be3f5978"
type: "consoleLog"
value: "BLINK LOG ERROR"
value_type: "string"
message_channel: "hardware-logs-and-states"
message_id: "1b8297ce-f50d-478c-bedb-966eacfdd732"
message_type: "message_log"

 */


export class MessageLogHardwareParser {

    public static readonly colorList: string[] = ['#0082FF', '#ff771f', '#beff5b', '#ff081f', '#28f7ff', '#fffd0c', '#737111', '#ff149e', '#00edff'];

    public parseHardwareLogs(event: IHardwareLog): string {

        let message_to_send: string;

        message_to_send =
            '[From Hardware]:: ' +
            '<span style="color:#ff04fe"> [Log]: </span>' + event.value;


        return message_to_send;
    }

    public parseMQTT(event: IHardwareLog): string {

        // console.log('Event', event);

        let message_to_send: string = '';

        // ITs MQTT message and its required - Make Cosmetic Fixes
        if (event.type === 'messageInputReceived') {

            message_to_send =
                '[From Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ' +
                '<span style="color:#ff06c6"> [Message]: </span> [';

            for (let i = 0; i < (<string[]> event.value).length; i++) {
                if (i > 0) {
                    message_to_send = message_to_send + ', ';
                }
                message_to_send = message_to_send +
                    '<span style="color:' + MessageLogHardwareParser.colorList[i] + '">' +
                    event.value[i] +
                    '</span> ';
            }

            message_to_send = message_to_send + ']';

        } else if (event.type === 'MQTTpacketPublishedToHardware' && event.value_type === 'json') {

            message_to_send =
                '[To Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' +
                event.event_name.toString().replace(/,/g, '/') +
                ']' +
                '</span> ' +
                '<strong style="color:#0082FF"> Payload::</strong>' +
                '<br>' +
                JSON.stringify(event.value, null, 6).replace(/\n( *)/g, function (match, p1) {
                    return '<br>' + '&nbsp;'.repeat(p1.length);
                })
        } else if (event.type === 'MQTTpublishedFromHardware' && event.value_type === 'json') {

            message_to_send =
                '[From Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' +
                event.event_name.toString().replace(/,/g, '/') +
                ']' +
                '</span> ' +
                '<strong style="color:#0082FF"> Payload::</strong>' +
                '<br>' +
                JSON.stringify(event.value, null, 6).replace(/\n( *)/g, function (match, p1) {
                    return '<br>' + '&nbsp;'.repeat(p1.length);
                })

        } else if (event.type === 'digitalInputReceived') {

            message_to_send =
                '[From Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ' +
                '<strong>Type::</strong> ' +
                '<span style="color:#ff04fe"> [Digital]: </span>' + ( event.value ? '1' : '0');

        } else if (event.type === 'analogInputReceived') {
            message_to_send =
                '[From Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ' +
                '<strong>Type::</strong> ' +
                '<span style="color:#ff06c6"> [Analog]: </span>' + event.value;

        } else if (event.type === 'externalCommandValue') {
            message_to_send =
                '[Command to Hardware]:: ' +
                '<strong>Topic::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ' +
                '<strong style="color:#0082FF"> Content::</strong>' +
                '[';

            for (let i = 0; i < (<string[]> event.value).length; i++) {
                if (i > 0) {
                    message_to_send = message_to_send + ', ';
                }
                message_to_send = message_to_send +
                    '<span style="color:' + MessageLogHardwareParser.colorList[i] + '">' +
                    event.value[i] +
                    '</span> ';
            }

            message_to_send = message_to_send + ']';

        } else if (event.type === 'externalOutput'  && event.value_type === 'digital') {

            message_to_send =
                '[To Hardware]:: ' +
                '<strong>Connector::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ::' +
                '<strong>Type::</strong> ' +
                '<span style="color:#ff04fe"> [Digital]: </span>' + event.value ? '1' : '0';

        }  else if (event.type === 'externalOutput'  && event.value_type === 'analog') {

            message_to_send =
                '[To Hardware]:: ' +
                '<strong>Connector::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ::' +
                '<strong>Type::</strong> ' +
                '<span style="color:#ff06c6"> [Analog]: </span>' + event.value;

        }  else if (event.type === 'externalOutput'  && event.value_type === 'message') {

           // console.info('Event externalOutput Message To Send: ', event);

            message_to_send =
                '[To Hardware]:: ' +
                '<strong>Connector::</strong> ' +
                '<span style="color:#0082FF">' +
                '[' + event.event_name + ']' +
                '</span> ::' +
                '<strong>Type::</strong> ' +
                '<span class="font-green-haze" style="color:#ff06c6"> [Message]: </span> [';

            for (let i = 0; i < (<string[]> event.value).length; i++) {
                if (i > 0) {
                    message_to_send = message_to_send + ', ';
                }
                message_to_send = message_to_send +
                    '<span style="color:' + MessageLogHardwareParser.colorList[i] + '">' +
                    event.value[i] +
                    '</span> ';
            }

            message_to_send = message_to_send + ']';

        }

        // console.info('Message Out:', message_to_send);
        return message_to_send;
    }
}
