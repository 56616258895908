import { ModalsBootloaderSelectComponent } from '../modals/bootloader-select';

export class StaticTranslation {
    /* tslint:disable:max-line-length */

    public static translate: { [lang: string]: { [keyOrEnv: string]: (string | { [key: string]: string }) } } = {
        'en': {
            'ModalsTariffComponent': {
                'title': 'Financial \"First Kick\" Tariff',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_identifier': 'Unique Identifier',
                'label_color': 'Color in Template. Please use color from http://keenthemes.com/preview/metronic/theme/admin_1/ui_colors.html',
                'label_owner_details_required': 'Customer (owner) details required',
                'label_payment_details_required': 'Payment details required (Full registration)',
                'label_payment_method_required': 'Payment mode required (User have to select a mode.)',
                'label_credit_for_beginning': 'Pre-Paid Credit for user at the beginning 1USD == 1000 !!!!!',
                'label_icon': 'FA-ICON (http://fontawesome.io/)',

            }, 'ModalsAddGSMComponent': {
                'title': '<strong class="font-color-hardware">CELLULAR</strong> registration',
                'label_successfully_registered': '2. Successfully registered',
                'label_failed_to_register': '3. Failed to register',
                'label_hash_token': 'Hash Token',
                'label_hash_tokens': '1. Hash Tokens',
                'label_multiple_registration': 'Multiple Registration <br> (From B2B Invoices, Files etc.)',
                'label_single_registration': 'Single Registration (QR - code)',
                'label_hash_token_label': 'Hash Token from Label',

            }, 'ModalsSnapShotInstanceComponent': {
                'title_create': 'Create <strong class="font-color-instance">SnapShot</strong>',
                'title_edit': 'Edit <strong class="font-color-instance">SnapShot</strong>',
                'label_snapshot_name': 'Instance Snap shot name',
                'label_snapshot_description': 'Description'

            }, 'ModalsGsmPropertiesComponent': {
                'title': '<strong class="font-color-hardware">CELLULAR</strong> basic & Threshold settings',
                'label_personal_server_name': 'Alias',
                'label_personal_server_description': 'Description',
                'table_maximum_threshold': 'Maximum data traffic threshold',
                'table_notify_me': 'Send Email',
                'table_block_sim': 'Block Sim',
                'table_daily_threshold': 'Daily (MB)',
                'table_monthly_threshold': 'Monthly (MB)',
                'table_total_threshold': 'Total (MB)',

            }, 'ModalsAddHardwareComponent': {
                'title': '<strong class="font-color-hardware">Hardware</strong> registration',
                'label_successfully_registered': '2. Successfully registered',
                'label_failed_to_register': '3. Failed to register',
                'label_hash_token': 'Hash Token',
                'label_hash_tokens': '1. Hash Tokens',
                'label_hardware_groups': 'Available <strong class="font-color-hardware">Hardware Groups</strong>',
                'label_no_hardware_groups': 'Not Available any <strong class="font-color-hardware">Hardware Groups</strong>',
                'label_hash_token_label': 'Hash Token from Label',
                'label_multiple_registration': 'Multiple Registration <br> (From B2B Invoices, Files etc.)',
                'label_single_registration': 'Single Registration (QR - code)',
                'label_little_advice': 'Little Advice',
                'label_advice_single_registration': '<p><strong>You will find HashToken on the sticker on Hardware</strong></p> <br> <p> If you own a smart-phone with camera <strong>(That\'s the thing that girls do with selfie. True gourmets can also use tablet.)</strong>, you can sign in to the portal and quickly and easily capture the QR code sticker on the Hardware.</p> <br> <p> Trust us, it\'s much more comfortable...</p>',
                'label_advice_multiple_registration': '<p> For register new <strong class="font-color-hardware">Hardware</strong> to this project, upload a text file below or insert text. The file or text should contain a list of device registration hash, where each key is separated by a semicolon. The spaces will be ignored. </p> <br><p><strong>Example: </strong> XXXXXXX; YYYYYYY;BBBBBB;  CCCCCC;DDDDD;</p><br><p> Each hash is sequentially registered. If a problem occurs - we\'ll show you which key is not valid and why. Attention - Multiple <strong>unsuccessful</strong> registration is flagged as a security breach attempt and your account may be blocked.</p><p>HASH keys are remove from list number 1, and registered one by one. If Registration is <strong>successful</strong> - HASH key will be entered in list number 2. If an <span class="font-red-flamingo bold">error has occurred</span> - the HASH key is not removed from list 1 and a reason "why" is entered in list number 3.</p>',
                'label_advice_no_group': '<p>We strongly recommend to register <strong class="font-color-hardware">Hardware</strong> into <strong class="font-color-hardware">Hardware Groups</strong>. We\'ve found that you do not have any yet. Groups are available in Release Manager to update more than one hardware. You can see progress and etc.</p> <br><p>You can use the <strong class="font-color-hardware">Hardware Groups</strong> to develop. For example, <strong>Beta</strong>, <strong>Dev HW</strong> or <strong>My Little Pony</strong>, and for production deployments where you can use an industrial registration API,for example <strong>Smart Fridge - Europe</strong> or <strong>Tracking equipment used in North Korea</strong>.</p>',
                'flash_add_device_success': 'Device add success',
                'flash_cant_add_hardware': 'Cant add device, ',
            }, 'ModalsContactComponent': {
                'title': 'Contact details',
            }, 'ModalsFinancialProductComponent': {
                'title': 'Change Basic Product information',
                'label_name': 'Product name',
            }, 'ModalsWidgetsWidgetCopyComponent': {
                'title': 'Make a Private Copy',
                'label_widget_name': 'Widget name',
                'label_widget_description': 'Widget description',
            }, 'ModalsArticleComponent': {
                'title': 'News Editor',
                'label_name': 'Name',
                'label_description': 'Description',
                'mark_down_text': 'Text In Markdown',
                'label_select': 'Select Article Group',
            }, 'ModalsPublicShareResponseComponent': {
                'title': 'Publishing decisions',
                'label_program_additional_comment': 'If the user shares a new version of the same program as before, the program (not version) will be named the same as the one which has already been used since the previous approval.',
                'label_program_name': 'Program name in the public list',
                'label_program_description': 'Program description in the public list',
                'label_version_name': 'Version name in the public list',
                'label_version_description': 'Version description in the public list',
                'label_group_select': 'Group / Type where it will be registered',
                'label_approve': 'The program will be published',
                'label_reason': 'If not - there is a place for comment for the author.',
                'btn_decide': 'Make decision',
            }, 'ModalsExtensionComponent': {
                'title': 'Add a Extension',
                'label_color': 'Color in Template. Please use color from http://keenthemes.com/preview/metronic/theme/admin_1/ui_colors.html',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_price': 'Price  1USD == 1000 !!!!!',
                'label_config': 'Config in JSON',
                'label_optional_included': 'Optional in Tariff',
                'label_extension_type': 'Extension type',
            }, 'ModalsAdminCreateHardwareComponent': {
                'title': 'Manual Hardware registration',
                'label_processor_id': 'Processor Id',
                'label_type_of_board': 'Board Type',
                'label_batch': 'Production Batch'
            }, 'ModalsCreateCompilationServerComponent': {
                'title': 'Create new Server',
                'label_personal_server_name': 'Server name',
                'label_server_url': 'Server url (without http..)',
                'label_hash_certificate': 'Hash',
                'label_connection_identifier': 'Connection Token',
            }, 'ModalsCreateProducerComponent': {
                'title': 'Producer',
                'label_name': 'Name',
                'label_description': 'Description'
            }, 'ModalsPaymentDetailsComponent': {
                'title': 'Payment Details'

            }, 'ModalsBlockoBlockCopyComponent': {
                'title': 'Make a Private Copy',
                'label_blocko_block_name': 'Widget name',
                'label_blocko_block_description': 'Widget description',
            }, 'ModalsPermissionGroupComponent': {
                'title': 'Create new Permission Group',
                'label_name': 'Group Name',
                'label_description': 'Description'
            }, 'ModalsBootloaderPropertyComponent': {
                'title': 'Create new Bootloader',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_version_identifier': 'Version Hardware Identification',
                'label_changing_note': 'Changing Note (Markdown)'
            }, 'ModalsCreateHardwareTypeBatchComponent': {
                'title': 'Create new Production Batch or Revision',
                'label_product_revision': 'Revision Code',
                'label_product_batch': 'Production Batch',
                'label_pcb_product_manufacture': 'PCB Producer Name',
                'label_pcb_product_manufacture_id': 'PCB Producer ID (VAT, IČO, etc)',
                'label_assembly_product_manufacture': 'Assemble Manufacture Name',
                'label_assembly_product_manufacture_id': 'Assemble Manufacture ID (VAT, IČO, etc)',
                'label_mac_address_start': 'Mac Address start position (decimal numbers - 187723572641792)',
                'label_mac_address_end': 'Mac Address End position (decimal numbers - 187723572641800)',
                'label_ean_number': 'EAN Number (13 digits)',
                'label_customer_product_name': 'Product name - For Label Print',
                'label_customer_company_name': 'Producer Company name - For Label Print',
                'label_customer_company_made_description': 'Made in Description - For Label Print',
                'label_date_of_assembly': 'Date of Assembly'
            }, 'ModalsCreateHardwareTypeComponent': {
                'title': 'Create new Board Type',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_processor': 'Processor',
                'label_producer': 'Producer',
                'label_connectible_to_internet': 'Connectable to Internet (Wifi, Ethernet)',
                'label_compiler_target_name': 'Target name for Compiler server'
            }, 'ModalsCreateProcessorComponent': {
                'title': 'Create new Processor',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_code': 'Processor Code',
                'label_speed': 'Hz'
            }, 'ModalsPermissionPermissionPropertyComponent': {
                'title': 'Edit Permission property',
                'label_description': 'Description'
            }, 'ModalsRolePermissionAddComponent': {
                'title': 'Add Permissions',
                'label_name': 'Permission Name',
                'label_description': 'Description',
                'label_action': 'Processor Code',
                'label_key_remove': 'Remove key',
                'btn_add_more': 'Add Selected Permission',
                'label_permission': 'Permission Key',
            }, 'ModalsCreateHomerServerComponent': {
                'title': 'Create new Server',
                'label_personal_server_name': 'Server name',
                'label_personal_server_description': 'Server Description',
                'label_server_url_comment': 'URL Address without port & schema (ftp, ws, http etc ..) When entering an IP address, it is necessary to specify the correct format according to ISO standard.',
                'label_server_url': 'Server url (without http..)',
                'label_mqtt_username': 'Mqtt user name',
                'label_mqtt_port': 'Mqtt port',
                'label_mqtt_password': 'Mqtt password',
                'label_grid_port': 'Grid port',
                'label_web_view_port': 'Web View port',
                'label_hardware_log_port': 'Hardware Logger Stream port',
                'label_hardware_rest_api_port': 'Rest Api',
                'label_hash_certificate': 'Hash',
                'label_connection_identifier': 'Connection Token',
            }, 'ModalsUpdateHomerServerComponent': {
                'title': 'Update Server',
                'label_personal_server_name': 'Server name',
            }, 'FormInputTagsComponent': {
                'label_place_holder': 'Type Tags and hit enter..',
            }, 'ModalsBlockoAddGridEmptyComponent': {
                'title': 'Unable to add <strong class="font-color-grid">GRID</strong> project.',
                'body_text': 'No existing <strong class="font-color-grid">GRID</strong> program found, let\'s create one!',
                'btn_redirect': 'Create GRID',

            }, 'ModalsBlockoAddGridComponent': {
                'title': 'New <strong class="font-color-grid">GRID</strong> Project',
                'label_grid_project': '<strong class="font-color-grid">GRID</strong> Project',
                'placeholder_grid_project': 'Select a GRID Project',

            }, 'ModalsBlockoAddHardwareComponent': {
                'title': 'Add <strong class="font-color-hardware">HARDWARE</strong>',
                'label_hardware': 'Hardware',
                'placeholder_select_hardware': 'Select Hardware',

            }, 'ModalsBlockoBlockCodeEditorComponent': {
                'title': 'JS Code Editor - block',
                'label_block_color': 'Colour',
                'label_block_icon': 'Icon',
                'label_block_description': 'Description',
                'label_block_code': 'Code',

            }, 'ModalsBlockoConfigPropertieComponent': {
                'title': 'Config Editor - block',

            }, 'ModalsBlockoPropertiesComponent': {
                'title_edit': '<strong class="font-color-blocko">BLOCKO</strong> Program Properties',
                'title_add': 'New <strong class="font-color-blocko">BLOCKO</strong> Program',
                'label_blocko_program_name': 'Name',
                'label_blocko_program_description': 'Description',

            }, 'ModalsInstanceApiPropertiesComponent': {
                'title_edit': '<strong class="font-color-instance">INSTANCE</strong> Token Properties',
                'title_add': 'New <strong class="font-color-instance">INSTANCE</strong> Token',
                'label_token_description': 'Token Description',
                'label_mesh_network_prefix': 'Prefix Name for better overview  "<strong>myHome</strong>_eafeef141..." or "<strong>factoryS1</strong>_eafeef1...."',

            }, 'ModalsBlockoVersionSelectComponent': {
                'title': 'Change Version',
                'label_program_version': 'Program Version',

            }, 'ModalsBlocksBlockPropertiesComponent': {
                'title_edit': '<strong class="font-color-blocko">BLOCKO</strong> Properties',
                'title_add': 'Add <strong class="font-color-blocko">BLOCKO</strong>',
                'label_block_name': 'Name',
                'label_block_description': 'Description',

            }, 'ModalsBlockoConfigPropertiesComponent': {
                'title': 'blocko Confurigation',
                'label_block_code_version': 'Block code version',
                'label_detached_version': 'detached version'

            }, 'ModalsInstanceCreateComponent': {
                'title_create': 'Create <strong class="font-color-cloud">INSTANCE</strong>',
                'title_edit': '<strong class="font-color-cloud">INSTANCE</strong> Properties',
                'label_instance_name': 'Instance Name',
                'label_instance_description': 'Description',
                'label_select_server': 'Select destination <strong class="font-color-cloud">MAIN SERVER</strong>',
                'label_select_backup_server': 'Select destination <strong class="font-color-cloud">BACKUP SERVER</strong> (optional)',
                'label_select_b_program': 'Select <strong class="font-color-blocko">BLOCKO</strong> program'

            }, 'ModalsCodeAddLibraryComponent': {
                'title': 'New <strong class="font-color-code">CODE</strong> Library',
                'body_text': 'Displaying <b>{}0</b> out of <b>{1}</b> Libraries',
                'label_load_more': 'Load More Libraries',
                'label_no_more': 'All libraries displayed.',
                'btn_select_library': 'Add',

            }, 'ModalsLogLevelComponent': {

                'title': 'Change terminal log level',
                'label_logLevel': 'Log Level',
                'label_choosen': '(chosen)',
            },
            'ModalsCodeFileDialogComponent': {
                'label_into_directory': 'Add to Directory',

                'text_add_file': 'New File',
                'btn_add_directory': 'New Directory',
                'btn_move': 'Move',

                'text_add_file_name': 'Name',
                'text_directory_name': 'Name',
                'text_move_file': 'Move File <b> {0}</b>',
                'text_move_directory': 'Move Directory <b> {0} </b>',
                'text_rename_file': 'Rename File <b> {0}</b>',
                'text_rename_directory': 'Rename Directory <b> {0}</b>',
                'text_remove_file': 'Are you sure to remove File <b> {0} </b>?',
                'text_remove_library': 'Are you sure to remove Library <b> {0} </b>?',
                'text_remove_directory': 'Are you sure to remove Directory <b> {0} </b> with all its Subdirectories?',
                'text_file_name': 'Name',

            },
            'ModalPickHardwareTerminalComponent': {
                'description_logLevel': 'Choose a maximal logLevel. The higer ones automaticly subscribe all logs under itself',
                'title': 'Subscribe a new terminal data',
                'label_logLevel': 'Maximal logLevel',
                'label_block_color': 'Terminal color',
                'label_hardware': 'Hardware',

            }, 'ModalsCodeLibraryVersionComponent': {
                'title': 'Select Library Version',
                'label_no_library': 'No version found in Library',
                'btn_select_library': 'Select',

            }, 'ModalsCodePropertiesComponent': {
                'title_edit': '<strong class="font-color-code">CODE</strong> Program Properties',
                'title_add': '<strong class="font-color-code">CODE</strong>New Program',
                'title_copy': '<strong class="font-color-code">CODE</strong> Copy Program',
                'label_program_name': 'Name',
                'label_program_description': 'Description',
                'label_device_type': 'Hardware Device Type',

            }, 'ModalsDeactivateComponent': {
                'title': 'Confirm',
                'body_text': 'Are you sure to',
                'label_deactivate': 'deactivate',
                'label_activate': 'activate',
                'label_attention': 'Warning',
            }, 'ModalsHardwareFindHashComponent': {
                'title': 'Find <strong class="font-color-hardware">HARDWARE</strong> Hash for Registration ',
                'label_full_id': 'Full ID!',
                'btn_find': 'Find',

            }, 'ModalsHardwareRestartMQTTPassComponent': {
                'title': 'Generate new MQTT Connection Secrets',
                'label_attention': 'Warning!',
                'body_text': '<p>MQTT <strong>Password</strong> and <strong>Name</strong> is stored in device memory as a <a href="https://en.wikipedia.org/wiki/Universally_unique_identifier" target="_blank">UUID</a> . And it is not distributed online or inside the firmware update. </p> <p><strong>Password</strong> and <strong>Name</strong> were created and uploaded during production in our factory. But in some case, for example, when you changing server hierarchy (Developer, Stage or Production Servers), you have to change security tokens.</p><p>Changing your password on Hardware within maximum security can <strong>only be done physically in Bootloader mode via USB Cable!</strong></p><p>If you are generating new access data, the previous ones will automatically be irreversibly removed. Also, it is not possible to show the original or new generated access data again because <strong>we have only HASH, not the original value.</strong></p><p>So you can only see the password in the original form once!</p>',
                'btn_generate': 'Generate New Password',
                'body_password_message': '<p> <strong>Warning!</strong> It is not possible to show access data again because <strong>we store all information only in HASH, not the original value. </p> <p>You can see the password and name in the original form once! </p>',


            }, 'ModalsHardwareChangeServerComponent': {
                'title': 'Change <strong class="font-color-cloud">CLOUD</strong> IoT Server',
                'label_attention': 'Warning!',
                'label_select_description_attention': '<p>You can redirect the hardware to another server if there is a geolocation in the preference. For public servers, the system can automatically make adjustments to increase stability and performance. If the server is overloaded, it can redirect the hardware itself to less busy servers.</p> <p>Additionally, the server measures latency and approximate hardware location at daily intervals. When it finds a better server with better latency, it automatically redirects the hardware. These features are also used when we updating our servers. Before update and restart of server, the hardware is redirected to another temporary server. We update at weekly intervals. This guarantees 100% reliability.</p>',
                'label_manual_description_attention': '<p> Manual redirection of hardware to another server can have fatal consequences. Use this feature only if you know what you are doing.</p><p><strong> There is a backup server where the hardware redirects if an unsuccessful connection to a new server, we cover your back</strong>, but we want to inform you that there may be unexpected complications. Hardware may "lose" on the Internet. </p> <p> Hardware can always be repaired by connecting a USB cable and by using manual settings in Bootloader. </p>',
                'label_select': 'Select Server',
                'label_manually': 'Manual Redirection',
                'label_url_description': '<p>The address can not contain http, https, ws, and others. The address may look like <strong>8.8.8.8</strong> or <strong>my-server.my_domain.com</strong>. Please note that Cloud server does not have the url directory.</p> <p>For example <strong>my_server.com/and_my_stupid_idea/yeah</strong>.</p>',
                'label_url': 'Url',
                'label_port': 'Port',
                'btn_redirect': 'Redirect',

            }, 'ModalsFileUploadComponent': {
                'title': 'Upload File',
                'label_file_type': 'File Type',
                'label_file_description': 'Description',
                'label_accept_file_type': 'Required File type',

            }, 'ModalsPictureUploadComponent': {
                'title': 'Upload Picture',
                'label_picture': 'Picture',
                'label_unsaved': '(not saved)',
                'label_select_avatar': 'Select file',
                'image_is_too_big': 'Soubor je větší než 3MB.',


            }, 'ModalsDeviceEditDescriptionComponent': {
                'title': '<strong class="font-color-hardware">Hardware</strong> basic Properties',
                'label_hardware_device': 'Name (private)',
                'label_device_description': 'Description (private)',

            }, 'ModalsDeviceEditDeveloperParameterValueComponent': {
                'title': 'Set Developer Properties',

            }, 'ModalsSendInvoiceComponent': {
                'label_email': 'Enter E-mail Address',

            }, 'ModalsGridConfigPropertiesComponent': {
                'title': 'Widget Config',
                'label_widget_code_version': 'Version',
                'label_select_widget_version': 'Select Version',
                'label_interface': 'Interface',
                'label_inputs': 'Inputs',
                'label_output': 'Output',

            }, 'ModalsGridProgramPropertiesComponent': {
                'title_edit': '<strong class="font-color-grid">GRID</strong> Program Properties',
                'title_add': 'New Program',
                'label_grid_program_name': 'Name',
                'label_grid_program_description': 'Description',

            }, 'ModalsGridProjectPropertiesComponent': {
                'title_edit': '<strong class="font-color-grid">GRID</strong> Project Properties',
                'title_add': 'New Project',
                'label_grid_name': 'Name',
                'label_grid_description': 'Description',

            }, 'ModalsHardwareBootloaderUpdateComponent': {
                'title': 'Hardware ID',
                'label_attention': 'Warning!',
                'body_text': 'We have to update a critical software component on your Hardware Device. The update transfer should last a couple of seconds. The update process itself takes around 200 ms. If the Device is shut down, restarted or disconnected during this procedure, the Device could be damaged! In case that happens, you will have to repair the firmware on the Device manually by connecting it to your computer. You can find more information about this topic in the documentation or in the “Ask” section of our website.',

            }, 'ModalsHardwareCodeProgramVersionSelectComponent': {
                'title': 'Select <strong class="font-color-code">CODE</strong> Program Version',
                'label_program': 'Program',
                'label_no_code': 'No <strong class="font-color-code">CODE</strong> programs found.',
                'label_select_program': 'Select Program',
                'label_no_version': 'No versions found.',
                'label_not_compiled': 'Compilation failed',
                'btn_select_version': 'Select',

            }, 'ModalsLibraryPropertiesComponent': {
                'title_edit': '<strong class="font-color-code">CODE</strong> Library Properties',
                'title_add': 'New Library',
                'label_library_name': 'Name',
                'label_library_description': 'Description',

            }, 'ModalsMembersAddComponent': {
                'title': 'Add Members',
                'label_member_email': 'New member E-Mail ',
                'btn_add_more': 'Add another member',

            }, 'ModalsProjectPropertiesComponent': {
                'title_edit': 'Project Properties',
                'title_add': 'New Project',
                'label_project_name': 'Name',
                'label_project_description': 'Description',
                'label_product_type': 'Select an existing Financial Product',

            }, 'ModalsRemovalComponent': {
                'title': 'Community publishing request',
                'body_text': 'Are you sure to irreversibly remove',

            }, 'ModalsPublicShareRequestComponent': {
                'title': 'Set as ',
                'body_text': '<p>Are you sure you want to publish the program? Is it properly named? Does it include quality documentation?</p><p> Your request will be immediately forwarded to the administrators for review. It may take a few days. <strong>Please be patient.</strong> The code may be modified or you will be asked to make revision.</p>',

            }, 'ModalsSetAsMainComponent': {
                'title': 'Set as default',
                'body_text': 'Some settings may have fatal consequences. Please reconsider what you are doing! Are you sure that you want to set the parameter as a default?',

            }, 'ArticleComponent': {
                'label_article_edit': 'Edit',
                'label_article_remove': 'Remove',

            }, 'ModalsHardwareParametersComponent': {
                'title': 'Bulk Settings Console',

            }, 'ModalsSelectHardwareComponent': {
                'title': 'Select <strong class="font-color-hardware">HARDWARE</strong>',
                'label_hardware': 'Hardware Device',
                'placeholder_hardware': 'Select',
                'label_no_hardware': 'No <strong class="font-color-hardware">HARDWARE</strong> in this project.',
                'label_no_hardware_comment': 'First, add the hardware to the project',
                'label_no_hardware_group': 'No <strong class="font-color-hardware">HARDWARE GROUP</strong> in this project.',
                'label_no_hardware_group_comment': 'Add Group on Hardware list page first.',
                'table_hardware_id': 'ID',
                'table_hardware_name': 'Name (alias)',
                'table_actions': 'Actions',
                'table_status': 'Status',
                'tab_hardware': 'Hardware',
                'tab_hardware_group': 'Hardware Groups',
                'label_add_multiple_hardware_supported': 'Multiple Select Hardware Supported',
                'label_select_hardware': 'Select',
                'label_remove_hardware': 'Remove',
                'table_hardware_group_name': '<strong class="font-color-hardware">HARDWARE</strong> Group',
                'table_hardware_group_description': 'Description',
                'table_hardware_group_size': 'Size []',
                'label_select_group': 'Select',
                'label_remove_group': 'Remove',

            }, 'ModalsGroupEditHardwareComponent': {
                'title': 'Select <strong class="font-color-hardware">HARDWARE</strong>',
                'label_hardware': 'Hardware Device',
                'placeholder_hardware': 'Select',
                'label_no_hardware': 'No <strong class="font-color-hardware">HARDWARE</strong> in this project.',
                'label_no_hardware_comment': 'First, add the hardware to the project',
                'label_no_hardware_group': 'No <strong class="font-color-hardware">HARDWARE GROUP</strong> in this project.',
                'label_no_hardware_group_comment': 'Add Group on Hardware list page first.',
                'table_hardware_id': 'ID',
                'table_hardware_name': 'Name (alias)',
                'table_actions': 'Actions',
                'table_status': 'Status',
                'tab_hardware': 'Hardware',
                'tab_hardware_group': 'Hardware Groups',
                'label_add_multiple_hardware_supported': 'Multiple Select Hardware Supported',
                'label_select_hardware': 'Select',
                'label_remove_hardware': 'Remove',
                'table_hardware_group_name': '<strong class="font-color-hardware">HARDWARE</strong> Group',
                'table_hardware_group_description': 'Description',
                'label_select_group': 'Select',
                'label_remove_group': 'Remove',
                'label_select_all' : 'Select all on page'

            }, 'ModalsVersionDialogComponent': {
                'title_edit': 'Edit Version',
                'title_save': 'Save Version',
                'label_version_name': 'Name',
                'label_version_description': 'Description',

            }, 'ModalsVersionSelectComponent': {
                'title': 'Select <strong class="font-color-blocko">BLOCKO</strong> program version',

            }, 'ModalsWidgetsWidgetPropertiesComponent': {
                'title_edit': 'Widget Properties',
                'title_add': 'New Widget',
                'label_widget_name': 'Name',
                'label_widget_description': 'Description'

            }, 'ModalsHardwareGroupPropertiesComponent': {
                'title_add': 'New  <strong class="font-color-hardware">Hardware Group</strong>',
                'title_edit': 'Edit  <strong class="font-color-hardware">Hardware Group</strong>',
                'label_group_name': 'Name',
                'label_group_description': 'Description',

            }, 'RedirectOkComponent': {
                'title': 'Success!',
                'label_can_login': 'Now you can login!',
            },

            'ReaderQrComponent': {
                'not_valid_skunks_qr_code': 'not valid Skunks QRcode',
                'skunks_qr_code_found': 'Skunks QR code found',
                'btn_rescan': 'Scan again',
                'btn_confirm_scan': 'Confirm Scan',
            },

            'ModalsGridProgramSettingsComponent': {
                'title': '<strong class="font-color-grid">Grid</strong> App settings in <strong class="font-color-instance">Instance</strong> ',
                'label_select_app_access': 'Access Settings',
                'label_grid_app_name': '<strong class="font-color-grid">Grid</strong> Program',
                'label_grid_app_description': '<strong class="font-color-grid">Grid</strong> Program Description',
                'btn_confirm_scan': 'Confirm Scan',
            },

            'ModalsDatabaseEditComponent': {
                'title_add': 'Create <strong class="font-color-cloud">Database</strong>',
                'title_edit': 'Update <strong class="font-color-cloud">Database</strong>',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_first_collection_name': 'First collection name',
                'btn_confirm_scan': 'Confirm Scan',
            },
            'ModalsDatabaseCollectionNewComponent': {
                'title': 'Create <strong class="font-color-cloud">Database Collection</strong>',
                'label_name': 'Collection name',
            },
            'MobileAddHardwareComponent': {
                'main_title': 'QR Hardware registration',
                'btn_add_hardware_qrcode': 'Insert Hardware ID by QR',
                'label_add_hardware': 'Add hardware',
                'title': 'Add hardware',
                'label_project': 'Selected Project',
                'placeholder_project': 'select project',
                'flash_add_device_success': 'Device {0} has been added to your project',
                'label_hardware_groups': 'hardware groups',
            },

            'DashboardComponent': {
                'title_first_steps': 'FIRST STEPS',
                'table_name': 'Name',
                'btn_add_article': 'Add',
                'table_description': 'Description',
                'title_platform_news': 'News',
                'title_your_project': 'Your <strong class="font-color-skunks-blue">Projects</strong>',
                'title_your_instance': 'Your <strong class="font-color-cloud">Cloud</strong> Instances',
                'tab_general': 'General',
                'tab_hardware': '<strong class="font-color-hardware">Hardware</strong>',
                'tab_blocko': '<strong class="font-color-blocko">Blocko</strong>',
                'tab_code': '<strong class="font-color-code">Code</strong>',
                'tab_grid': '<strong class="font-color-grid">Grid</strong>',
                'tab_cloud': '<strong class="font-color-cloud">Cloud</strong>',
                'main_title': 'Dashboard',
                'btn_qrcode': 'Scan a QR code',
                'title': 'Welcome to Skunks <strong style="color: #36c6d3;"> PORTAL </strong> ',
                'dashboard_info_text': 'We are Skunks – a technological laboratory of advanced automatization developing a toolkit for design, development and managing the \'Internet of Things\' (IoT) for industrial uses. We do not create smart washing machines, nor smart city furniture. We develop everything for our customers to let them do it themselves, easily and without any problems.',
                'btn_create_project' : 'Create First Project',
                'flash_project_create' : 'Project created successfully',
                'table_actions': 'Actions',
                'flash_cant_add_project': 'Cannot add project now.',
                'btn_add_hardware': 'Add hardware',
                'instance_name': 'Instance name',
                'table_status': 'State',


            }, 'ProjectsComponent': {
                'main_title': 'Projects',
                'title': 'Projects',
                'btn_add_project': 'New project',
                'btn_new_subscription': 'New Financial Product',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'table_product': 'Product',
                'table_tags': 'Tags',
                'label_deactivated_under': 'Deactivated under',
                'label_project_properties': 'Project properties',
                'label_Remove_project': 'Remove project',
                'label_Project_Properties': 'Settings',
                'label_basic_settings': 'Basic Settings',
                'label_remove': 'Remove',
                'label_no_projects': '<strong class="font-color-skunks-blue">Nerds</strong> rule N.1',
                'label_no_projects_comment': 'Create project first, connect hardware and <strong class="font-color-skunks-blue">start slowly control the world</strong>... Step by step...',
                'label_no_product': '<strong class="font-color-hardware">Mr. Salieri</strong> likes things in order and leads an <strong class="font-color-hardware">accounting book...</strong>',
                'label_no_product_comment': 'If you want to create a project, please create <span class="bold"> Financial product</span> first.',
                'label_create_product_below': 'You can create it by click on button bellow.',


                'flash_project_create': 'The project {0} has been created.',
                'flash_cant_add_project': 'Cannot add project now.',
                'flash_project_update': 'The project has been updated.',
                'flash_project_remove': 'The project has been removed.',

            }, 'CreateUserComponent': {
                'title': 'Create an account',
                'info_text': 'Enter your E-mail address, nickname, full name and password.',
                'label_nick_name': 'Nickname',
                'label_first_name': 'First Name',
                'label_last_name': 'Last Name',
                'label_full_name': 'Full Name',
                'label_password_again': 'Re-enter Password',
                'flash_email_was_send': 'Confirmation E-mail with further instructions was sent to your address.',
                'flash_email_cant_be_sent': 'Confirmation E-mail could not be sent, {0}',

            }, 'Error404Component': {
                'title': 'Oops! You\'re lost.',
                'info_text': 'We cannot find the page you\'re looking for.',
                'btn_return': 'Return Home',
                'btn_back': 'Go Back',

            }, 'FinancialProductBillingComponent': {
                'title': 'Billings',
                'label_owner': 'Product Owner'

            }, 'FinancialProductEmployeesComponent': {
                'label_cannot_remove_yourself': 'You can`t remove yourself from your project.',
                'btn_add_members': 'Add members',
                'no_persons_in_this_product': 'No Employees in this financial product',
                'no_persons_in_this_product_comment': 'You can add employee to your financial product. Just click the button and do it',
                'title': 'Employees',
                'table_name': 'Name',
                'table_state': 'State',
                'table_actions': 'Actions',

            }, 'FinancialProductExtensionsComponent': {
                'title': 'Extensions',
                'table_extension_name': 'Name',
                'table_extension_description': 'Description',
                'table_extension_type': 'Type',
                'table_extension_created': 'Created',
                'table_actions': 'Actions',
                'label_extension_edit_properties': 'Edit product extension',
                'label_no_extensions': 'No Extensions found.',
                'label_extension_actions': 'Actions',
                'label_deactivate_extension': 'Deactivate',
                'label_activate_extension': 'Activate',
                'label_error': '?',
                'no_extension': 'No Extensions',
                'no_extension_comment': '<p>No extensions? No additional tuning? Let\'s just tell us that you\'re not making selfie to the instagram... </p>',

            }, 'FinancialProductExtensionsExtensionComponent': {
                'title': 'Extension {0}',
                'label_deactivate_extension': 'Deactivate',
                'label_activate_extension': 'Activate',

            }, 'RoleGroupComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Permission Roles',

                'title': 'List of Roles',
                'label_create_role': 'Create new Role',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_actions': 'Action',
                'table_actions': 'Action',
                'label_role_properties': 'Edit Role properties',
                'label_role_remove': 'Remove Role',
                'label_no_role': 'No Roles',
                'label_no_role_comment': 'We didn\'t find any roles. Create new one',

            }, 'AdminFinancialComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Financial Management - Tariffs and Extensions',
                'title': 'Financial Management',
                'label_tariff_create': 'Create New Tariff',
                'table_icon': 'Icon',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'label_tariff_properties': 'Settings',
                'label_tariff_edit_properties': 'Edit Tariff Properties',
                'label_change_order': 'Change Order',
                'label_active_tariff': 'Active Tariff for Platform',
                'label_deactive_tariff': 'Deactivate Tariff for Platform',
                'label_delete_tariff': 'Permanently Remove Tariff',
                'label_no_tariffs': 'No Tariffs',
                'label_no_tariffs_comment': 'We didn\'t find any tariffs. Create new one',

                'flash_tariff_create_success': 'Tariff successfully created',
                'flash_tariff_edit_success': 'Tariff successfully updated',
                'flash_tariff_delete_success': 'Tariff successfully deleted',

                'label_no_extensions': 'No Extensions',
                'label_no_extensions_comment': 'We didn\'t find any extensions. Create new one',
                'label_extension_create': 'Create New Extension',
                'table_extension_icon': 'Icon',
                'table_extension_name': 'Name',
                'table_extension_description': 'Description',
                'table_extension_color': 'Color',
                'table_extension_price': 'Price (Average per month)',
                'label_extension_edit_properties': 'Edit Extension Properties',
                'label_extension_properties' : 'Settings',
                'label_active_extension': 'Active Extension for Platform',
                'label_deactive_extension': 'Deactivate Extension for Platform',
                'label_delete_extension': 'Permanently Remove Extension',

                'flash_extension_create_success': 'Extension successfully created',
                'flash_extension_edit_success': 'Extension successfully updated',
                'flash_extension_delete_success': 'Extension successfully deleted',

                'tab_tariffs': 'All Tariffs',
                'tab_extensions': 'All Extensions',
                'label_Project_Properties': 'Settings',
                'label_tariff_active': '(Active)',
                'label_tariff_deactivated': '(Deactivated)',

            }, 'AdminFinancialTariffComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Tariff Management',
                'title': 'Tariff Settings',
                'tab_tariffs': 'Settings',
                'tab_extensions': 'Extensions',
                'label_no_expansions': 'No Extensions in Tariff',
                'label_average_monthly_cost': 'Average monthly cost',
                'label_tariff_name': 'Tariff Name',
                'label_tariff_description': 'Long Text description',
                'label_tariff_identifier': 'Api identifier',
                'label_credit_for_beginning': 'Free Credit for beginning',
                'label_tariff_color': 'Background Color',
                'label_edit_tariff': 'Edit Tariff Properties',
                'label_active_tariff': 'Active tariff for platform',
                'label_deactive_tariff': 'Deactivate tariff for platform',
                'label_tariff_no_owner_details_required': 'Owner Details are NOT required',
                'label_tariff_owner_details_required': 'Owner Details are required',
                'label_tariff_no_payment_details_required': 'Payment Details are NOT required',
                'label_tariff_payment_details_required': 'Payment Details are required',
                'ribbon_selected': 'Selected',

                'flash_tariff_active_success': 'Tariff successfully activated',
                'flash_tariff_deactive_success': 'Tariff successfully deactivated',
                'flash_tariff_edit_success': 'Tariff successfully updated',

                'table_extension_included': 'Included',
                'table_extension_recommended': 'Recommended',
                'table_extension_price': 'Price (Average per month)',
                'table_extension_color': 'Color',
                'table_extension_type': 'Tyrion Extension Type',
                'table_extension_description': 'Description',
                'table_extension_name': 'Name',
                'label_change_order': 'Change order',
                'btn_select': 'Select',

            }, 'ProjectsProjectComponent': {
                'title': 'Project dashboard',
                'label_project_properties': 'Project properties',
                'label_project_remove': 'Remove project',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_product': 'Financial Product',
                'label_no_project_stats': '<i class="fa fa-3x fa-spinner fa-spin"></i>',
                'label_no_project_stats_comment': 'We Collecting all <strong class="font-color-skunks-blue">Project</strong> data and stats.. And of course you do not have to wait.',
                'label_skunks_hardware': 'Skunks<strong class="font-color-hardware">HARDWARE</strong>',
                'label_skunks_hardware_update': 'Skunks<strong class="font-color-hardware">HARDWARE</strong> Updates',
                'label_devices_count': 'Devices count',
                'label_online_status': 'Online',
                'label_skunks_cloud': 'Skunks<strong class="font-color-cloud">CLOUD</strong>',
                'label_skunks_instance': 'Skunks<strong class="font-color-instance">INSTANCE</strong>',
                'label_instances_count': 'Instances count',
                'label_servers_count': 'Private Server count',
                'label_programs_count': 'Programs count',
                'label_libraries_count': 'Libraries count',
                'label_blocks_count': 'Blocks count',
                'label_projects_count': 'Projects',
                'label_skunks_grid': 'Skunks<strong class="font-color-grid">GRID</strong>',
                'label_widgets_count': 'Widgets count',
                'label_in_group': 'in <strong> {0} </strong>group(s)',

                'flash_project_update': 'The project has been updated.',
                'flash_project_remove': 'The project has been removed.',

                'table_hardware_name': 'Name (alias)',
                'table_status': 'Status',
                'table_main_server': 'Main server',
                'table_backup_server': 'Backup Server',

                'program_name': 'Programs',
                'library_name': 'Libraries',
                'instance_name': 'Instances',
                'blocks_name': 'Blocks',
                'widgets_name': 'Widgets',
                'table_name': 'Name',
                'table_description': 'Description',

                'label_no_hardware': 'No <strong class="font-color-hardware">HARDWARE</strong> in this project.',
                'label_no_hardware_comment': 'Go to <strong class="font-color-hardware">Hardware</strong> list page and create your first device.',
                'btn_add_hardware': 'Add a Device',

                'label_no_instances': 'No <strong class="font-color-cloud">Instances</strong> found in <strong class="font-color-cloud">CLOUD</strong>',
                'label_no_instances_comment': 'If You already create <strong class="font-color-blocko">BLOCKO</strong>, you are ready to deploy it!',

                'label_no_servers': 'Not any <strong class="font-color-cloud">CLOUD</strong> Servers',
                'label_no_servers_comment': 'Your own Server will allow you to run your <strong class="font-color-blocko">BLOCKO</strong> instances.<br>You can install the Server on your own Servers or leave everything to us',

                'label_no_code': 'No <strong class="font-color-code">CODE</strong> Program',
                'label_no_code_comment': 'Time to create another miracle.. or to find an alternative path to your nerd life',

                'label_no_blocko_program': 'No <strong class="font-color-blocko">BLOCKO</strong> programs available.',
                'label_create_blocko_text': 'Click on the button bellow to create your first a program or use public one.',

                'label_no_blocks': 'No <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_blocks_comment': 'You havn\'t yet created your own <strong class="font-color-blocko">BLOCK</strong>. It\'s time to grow up... It\'s time to start connecting the world.',

                'label_no_grid_projects': 'No <strong class="font-color-grid">GRID</strong> projects available',
                'label_no_grid_projects_comment': 'Click on the button bellow to create a project.',

                'label_libraries': 'No <strong class="font-color-code">CODE</strong> libraries',
                'label_libraries_comment': 'Make an impression on your <strong>Mom</strong> and create a dream Library. And do not forget to share it!',

                'label_no_public_widget': 'No public <strong class="font-color-grid">GRID</strong> widgets',
                'label_no_public_widget_comment': 'Change filter Parameters, or create and share own miracle',

                'show_more': 'Show more...'


            }, 'DashboardElementComponent': {
                'table_status': 'Status',
                'table_description': 'Description',

            },  'FinancialProductInvoicesComponent': {
                'title': 'Invoice',
                'label_invoice_actions': 'Actions',
                'label_confirm_invoice': 'Confirm invoice',
                'label_synchronize_invoice': 'Synchronize with Fakturoid',
                'label_download_pro_forma_pdf': 'Download Pro forma invoice as PDF',
                'label_download_pdf': 'Download invoice as PDF',
                'label_fakturoid_invoice': 'Open on Fakturoid',
                'label_send': 'Send',
                'btn_add_credits': 'Add credits',
                'table_invoice_id': 'Invoice ID',
                'table_issued': 'Issued',
                'table_total': 'Total price',
                'table_paid': 'Paid',
                'table_to_confirm': 'Invoice not yet confirmed',
                'table_to_issue': 'Invoice not yet issued',
                'label_no_invoice_number': 'not set',
                'table_actions': 'Actions',
                'no_invoice': 'No invoices',
                'payment_required': 'Payment required',
                'no_invoice_comment': ' <p>We did not find any financial records... which means you did not even start to spend money. </p> <p> Maybe Mr.Salieri should send his regards. </p>',
                'flash_invoice_been_resend': 'The invoice has been sent.',
                'flash_invoice_been_confirmed': 'Invoice was conformed and will be visible to the user.',
                'flash_invoice_been_synchronized': 'Invoice was synchronized with Fakturoid.',

            }, 'FinancialProductInvoicesInvoiceComponent': {
                'title': 'Invoice {0}',
                'label_id_subscription': 'Subscription ID',
                'label_payment_mode': 'Payment Type',
                'label_status': 'Status',
                'label_paid': 'Paid in',
                'label_id_period': 'Billing period',
                'label_id_issued': 'Issued',
                'label_total': 'Total',
                'label_total_with_vat': 'Total with VAT',
                'label_invoice_actions': 'Actions',
                'label_download_pro_forma_pdf': 'Download Pro forma invoice as PDF',
                'label_download_pdf': 'Download invoice as PDF',
                'label_fakturoid_invoice': 'Open on Fakturoid',
                'label_send': 'Send',
                'label_confirm_invoice': 'Confirm invoice',
                'label_synchronize_invoice': 'Synchronize with Fakturoid',
                'table_quantity': 'Quantity',
                'table_unit': 'Unit',
                'table_vat': 'VAT',
                'table_name': 'Name',
                'table_unit_price': 'Unit price',
                'table_cost_without_vat': 'Price (without VAT)',
                'table_total_cost': 'Total cost',
                'flash_invoice_been_resend': 'The invoice has been sent.',
                'flash_invoice_been_confirmed': 'Invoice was conformed and will be visible to the user.',
                'flash_invoice_been_synchronized': 'Invoice was synchronized with Fakturoid.',

            }, 'FinancialProductInvoicesInvoiceEventsComponent': {
                'title': 'Invoice {0}: financial events',
                'table_from': 'From',
                'table_to': 'To',
                'table_extension': 'Extension',
                'table_resource_consumption': 'Resource consumption'

            }, 'FinancialProductHistoryComponent': {
                'title': 'Product history',
                'table_date': 'Event date',
                'table_type': 'Type',
                'table_reference': 'Reference',
                'table_details': 'Details',
                'no_product_event': 'No product events',
                'no_product_event_comment': 'For some reason, we could not get any information about past events.'

            }, 'ProductRegistrationComponent': {
                'main_title': 'Product subscription',
                'nav_step_tariff_title': 'Tariff',
                'nav_step_tariff_text': 'Select the right tariff for you',
                'nav_step_extensions_title': 'Packages',
                'nav_step_extensions_text': 'Choose your desired expansion',
                'nav_step_customer_title': 'Customer Information',
                'nav_step_customer_text': 'Fill customer information',
                'nav_step_summary_title': 'Summary',
                'nav_step_summary_text': 'Check your order and confirm',
                'financial_monologue': '<p> The financial plan - or the package you can see here is only the starting state at the beginning. ' +
                'You can see estimated monthly costs and recommended customer type. ' +
                'Each tariff includes a group of extensions at the beginning, such as number of co-workers, storage size, technical support, etc., but you can remove or add, increase, or change extensions anytime later. ' +
                'All tariffs and extensions are based on SaaS (Software as a Service) and Haas (Hardware as a Service). ' +
                'You can pay by card or set up automatic email billing alerts to be notified to your account department, which will be sent by bank transfer. ' +
                '<strong> For each tariff, we give you free credit that should be enough for the first month for free. </strong></p>' +
                '<p>For <strong>large or individual customers</strong>, we have a special tariff by the business contract with technical support, dedicated developers in your office etc. </p>' +
                '<p> For more details please visit <a href="http://skunks.cz/financial" target="_blank">Financial Site</a> </p>',
                'step_extensions_title': 'Packages',
                'tab_extensions_recommended': 'Voluntary enlargement right from the start',
                'tab_extensions_include': 'Already in the package',
                'label_no_recommended_extensions': 'Nothing to recommend',
                'label_no_recommended_extensions_comment': 'It\'s weird, but we have nothing to force you to buy.',

                'label_average_monthly_cost': 'Average monthly cost',
                'label_product_owner': 'Product owner:',

                'label_want_tariff_not_found': 'Wanted tariff not found.',
                'label_cant_load_tariff': 'Cannot load tariffs. {0}',
                'label_finance_model': 'Billing model',
                'label_finance_model_integrator': 'I am an integrator and I will manage the finances for my customer',
                'label_finance_model_do_it_yourself': 'Finance will be charged to me',

                'step_summary_title': 'Summary',
                'label_product_name': 'Product name',
                'label_your_product': 'Your product',
                'label_included': 'Included',
                'table_tarrif': 'Tariff',
                'table_description': 'Description',
                'table_price': 'Price',
                'table_packages': 'Packages',
                'table_total': 'Monthly estimated cost',

                'text_unknown': 'Unknown',
                'average_monthly_cost': 'Average monthly cost',
                'btn_select': 'Select',
                'btn_create_new_company': 'Create new Financial Account',
                'btn_included': 'Included',
                'ribbon_selected': 'Selected',
                'flash_cant_buy_product': 'The product cannot be bought.',
                'flash_product_created_prepaid': 'Financial Product was created, you are using your pre-paid credit',
                'flash_product_created': 'Financial Product was created, now you can create a new project',

                'label_customer_information_title': 'Fill in customer information',
                'label_customer_information_subtitle': 'You will be automatically first owner with highers permissions',
                'label_basic_setup': 'Basic information',
                'label_payment_details': 'Payment details',
                'label_owner_details': 'Owner Details'

            }, 'FinancialProductComponent': {
                'main_title': 'Financial',
                'title': 'Dashboard',
                'btn_add_credit': 'Add credit',
                'label_instance_properties': 'instance properties',
                'label_core_details': 'Core details',
                'label_id_subscription': 'ID Subscription',
                'label_country': 'Country',
                'label_payment_method': 'Payment method',
                'label_status': 'Status',
                'option_active': 'active',
                'option_hibernation': 'hibernation',
                'label_financial_status': 'Financial status',
                'label_created': 'Created',
                'label_remaining_credit': 'Remaining credit',
                'label_other_details': 'Other details',
                'label_billing_mode': 'Billing Mode',
                'label_contacts_details': 'Contacts details',
                'label_financial_product_properties': 'Financial product properties',
                'label_deactivate_product': 'Deactivate product',
                'label_activate_product': 'Activate product',
                'label_modal_body_text': 'By deactivating, you disable instances in the cloud and disconnect hardware from our servers. Remote administration will stop working. But All data and settings will be saved and you can reactivate the product at any time.',
                'flash_cant_deactivate_product': 'The product cannot be deactivated.',
                'flash_product_activated': 'The product has been activated.',
                'flash_product_deactivated': 'The product has been deactivated.',
                'label_no_payment_method': 'not set',
                'label_client_billing': 'Client Billing',
                'label_private_billing': 'Private Billing'

            }, 'FinancialComponent': {
                'main_title': 'Financial',
                'title': 'Products',
                'btn_new_subscription': 'New Subscription',
                'label_product_list': 'Products list',
                'label_active': 'active',
                'label_actions': 'Actions',
                'label_subscription_id': 'ID',
                'table_actions' : 'Action',
                'label_hibernation': 'hibernation',
                'label_modal_body_text': 'By deactivating, you disable instances in the cloud and disconnect hardware from our servers. Remote administration will stop working. But All data and settings will be saved and you can reactivate the product at any time.',

                'label_financial_product_properties': 'Financial product properties',
                'label_deactivate_product': 'Deactivate product',
                'label_activate_product': 'Activate product',


                'table_name': 'Name',
                'table_type': 'Type',
                'label_client_billing': 'Client Billing',
                'label_client_private': 'Private',
                'table_payment_type': 'Payment',
                'table_subscription_id': 'Subscription id',
                'label_bank_transfer': 'Bank transfer',
                'label_credits': 'Credits',
                'label_credit_card': 'Credit Card',
                'label_not_set_yet': 'Not set yet',
                'label_no_product': 'No product',
                'label_no_product_comment': 'First create a financial <strong>Tenant</strong> under which all your content will be registered. You can add hardware, run cloud applications, deploy own servers, pay invoices and of course go safely from prototype to mass IoT production. <br><br> We wish you much luck with the ideas that dominate the world.',
                'flash_cant_deactivate_product': 'The product cannot be deactivated. ',
                'flash_product_activated': 'The product has been activated.',
                'flash_product_deactivated': 'The product has been deactivated.',
                'flash_products_cant_load': 'Products cannot be loaded, {0}.',
                'label_settings': 'Settings'

            }, 'HardwareHardwareTypeComponent': {
                'main_administration_title': 'Platform administration',
                'main_title': 'Hardware Type',
                'main_subtitle': 'Board Type Management',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_producer': 'Manufacturer',
                'label_processor': 'Processor',
                'label_file': 'File',
                'label_no_file': 'No Source File!',
                'label_file_exist': 'File Uploaded',
                'label_target_name': 'Target name',
                'label_abilities': 'Abilities',
                'label_ethernet': 'Ethernet',
                'label_wifi': 'Wi-Fi',
                'label_version_identifier': 'Identifier',
                'label_changing_note': 'Changelog',
                'btn_upload_file': 'Upload File',
                'btn_download_file': 'Download File',
                'btn_edit_defaults': 'Edit defaults',

                'flash_batch_defaults_success': 'Defaults were set',

                'text_ethernet_with_poe': 'Ethernet with PoE',

                'label_bootloader': 'Bootlader',
                'label_bootloader_name': 'Name',
                'label_bootloader_description': 'Description',
                'label_bootlaoder_date': 'Date',
                'label_bootloader_remove': 'Remove bootloader',
                'label_bootloader_set_as_main': 'Set bootloader as main',
                'label_bootloader_download_file': 'Download bootloader for manual restore',
                'label_type_of_board_properties': 'Change bootloader properties',
                'label_main_bootloader': 'Main bootloader',
                'label_bootloader_settings': 'Bootloader settings',
                'label_set_own_picture': 'Set own picture',
                'label_bootloader_comment': 'Bootloader file must be compatible with unique bootloader object identification',
                'label_bootloader_upload_file': 'Upload Bootloader file first',
                'label_bootloader_replace_file': 'Replace Bootloader file',
                'label_type_of_boards_properties': 'Edit basic description',
                'label_type_of_boards_remove': 'Remove this Board type',
                'label_no_bootloaders': 'No <strong class="font-color-code">BootLoaders</strong> ',
                'label_no_bootloaders_comment': 'We didn\'t find any Bootloaders, please set new one',
                'label_bootloader_create_description': 'Create Bootloader',
                'label_default_c_program_setting': 'Default C Program',
                'label_default_test_c_program_setting': 'Default Test C Program',
                'label_main_c_program_version_set_as_main': 'Set this version as Main',

                'label_no_library': 'No Compilation libraries',
                'label_no_library_comment': 'Add first Library for Compilation server, of find in administration already created, and change support for this Hardware type',

                'label_library': 'Compilation Library',
                'label_library_comment': 'Upload compilation library ZIP file',

                'label_revisions': 'Revisions & Production Batches',
                'label_revision_create_description': 'Create new Revision',
                'table_revision': 'Revision',
                'table_production_batch': 'Production Batch',
                'table_mac_range': 'MacAddress Range',
                'table_latest_used': 'Latest Used',
                'table_ean': 'Ean Code',
                'table_assembly_time': 'Assembly Date',
                'table_created': 'Created',

                'label_c_program_version_set_as_main': 'Set this version as a main',
                'label_no_c_program_versions': 'No <strong class="font-color-code">Version</strong> found',
                'label_no_c_program_versions_comment': 'We didn\'t find any versions, please create new one',
                'label_no_batch': 'No Revisions or Batches',
                'label_no_batch_comment': 'We didn\'t find any revisions or production batches, please create new one',

                'flash_successfully_set_as_default': 'Successfully set as default',
                'flash_file_uploaded': 'File successfully uploaded',
                'flash_successfully_set_main': 'Successfully set as main',

                'table_id': 'Id',
                'table_name': 'Name',
                'table_identifier': 'Version Identification',
                'table_description': 'Description',
                'table_change_notes': 'Change notes',
                'table_author': 'Author',
                'table_status': 'Status',
                'table_actions': 'Actions',

                'btn_create_c_program_version': 'Create new Version',
                'btn_create_bootloader': 'Create first Bootloader',
                'btn_add_c_program_version': 'Add Version',
                'btn_edit_c_program': 'Edit Program',

                'tab_overview': 'Overview',
                'tab_bootloaders': 'Bootloader versions',
                'tab_firmwares': 'Firmwares version',
                'tab_admin_code': 'Default Code Settings',
                'tab_batches': 'Manufacture Batches',

                'label_version_properties': 'Properties',
                'label_remove_version': 'Remove version',

                'label_created': 'Created',
                'hardware_picture': 'Hardware picture'

            }, 'HardwareComponent': {
                'main_title': 'Hardware Types',
                'flash_project_cant_load': 'Projects could not be loaded, {0}.'

            }, 'ModalsHardwareGroupDeviceSettingsComponent': {
                'main_title': '<strong class="font-color-hardware">HARDWARE</strong> Groups Device Settings',
                'label_available': 'Available Groups',
                'title_in_group': 'In Groups'

            }, 'PictureUploadComponent': {
                'label_unsaved': 'unsaved',
                'label_select_avatar': 'select avatar',
                'label_avatar': 'avatar',
                'flash_image_changed': 'image has been changed',
            },

            'ForgotPasswordComponent': {
                'title': 'Forgot your password?',
                'info_text': 'Enter your E-mail address below to reset your password.',
                'flash_email_sent': 'E-mail with instructions for password reset was sent.',
                'flash_email_not_sent': 'E-mail could not be sent, {0}.',

            }, 'PasswordRestartComponent': {
                'title': 'Reset password',
                'info_text': 'Enter your E-mail address and new password.',
                'label_confirm_password': 'Confirm password',

                'placeholder_password': 'Password',
                'placeholder_confirm_password': 'Confirm password',
                'flash_password_change_fail': 'Password could not be changed, {0}.',
                'flash_password_change_success': 'Password was successfully changed.',

            }, 'LoginComponent': {
                'label_log_in': 'Log In',
                'label_forget_password': 'Forgot password?',
                'label_login_social': 'Log in or Sign up with',
                'btn_create_account': 'Create an account',
                'btn_login': 'Login',
                'btn_resend': 'Resend',
                'msg_login_user_cant_login': 'The user could not be logged in. Reason {0}',
                'msg_login_resend_verification': 'Press the resend button to send verification E-mail again',
                'msg_login_error': 'Error has occurred, when trying to log in.',
                'msg_login_email_sent': 'Verification E-mail was sent',

            }, 'LogoutComponent': {
                'label_log_out': 'Log out',
                'label_log_in': 'Log in',
                'msg_logout_cant_log_out': 'Current user could not be logged out.\n {0}',

            }, 'ModalsSnapShotDeployComponent': {
                'title': 'Deploy <strong class="font-color-instance">INSTANCE</strong> Snapshot to <strong class="font-color-cloud">CLOUD</strong> ',
                'label_select': 'Select Snapshot',
                'label_when': 'Choose time to deploy instance:',
                'label_immediately': 'Immediately',
                'label_allow_hardware_updates': 'Allow hardware updates',
                'label_on_time': 'At a certain time',
                'btn_deploy' : 'Deploy',
                'label_select_time': 'Select Time',
                'label_select_date': 'Select Date'

            }, 'NotificationsComponent': {
                'main_title': 'Notifications',
                'title': 'Notifications',
                'label_load_older': 'Load older notifications',
                'label_displaying_notif': 'Displaying <b>{0}</b> notification(s). Total Unread notifications: <b>{1}</b>',
                'label_notif_undead': '(<b>{0}</b> unread notifications)',

            }, 'ProducersProducerComponent': {
                'main_title': 'Producers',
                'title': 'Producer',
                'label_available_devices': 'Available devices',
                'label_no_device': 'No devices available.',
                'flash_project_cant_load': 'Project could not be loaded, {0}.',

            }, 'ModalsUpdateReleaseFirmwareComponent': {
                'title': 'Firmware Release Settings',
                'label_hw_group_title': '<strong class="font-color-hardware">HARDWARE</strong> Groups',
                'label_select': 'Please, select one:',
                'label_name': 'Name',
                'label_description': 'Description',
                'label_hardware_group': 'Hardware Group',
                'label_firmware_title': 'FIRMWARE',
                'label_bootloader_title': 'BOOTLOADER',
                'label_no_firmware_warning': 'Sorry, but you haven\'t owned <strong class="font-color-code">FIRMWARE CODE</strong> (program) yet.',
                'label_no_groups_warning': 'Sorry, but you haven\'t owned <br><strong class="font-color-hardware">HARDWARE</strong> groups yet.',
                'label_no_hw_types_in_group': 'Sorry, but this <strong class="font-color-hardware">HARDWARE</strong> groups probably not contains any Hardware',
                'label_firmware_type': 'Firmware Type',
                'label_select_bootloader_version': 'Select Bootloader version',
                'label_select_time': 'Select Time',
                'label_select_date': 'Select Date',
                'label_user_file': 'User File',
                'label_when': 'When',
                'label_immediately': 'Immediately',
                'label_on_time': 'At a set time',
                'label_program_name': '<strong class="font-color-code">CODE</strong>Program',
                'label_program_version_name': '<strong class="font-color-code">CODE</strong>Version',
                'btn_select_version': 'Select',

            }, 'ProducersComponent': {
                'main_title': 'Manufacturers',
                'title': 'Manufacturers',
                'table_name': 'Name',
                'table_description': 'Description',
                'label_no_producer': 'No manufacturers available.',
                'flash_project_cant_load': 'Project could not be loaded, {0}.',

            }, 'ProfileComponent': {
                'main_title': 'Account Settings',
                'title': 'Your profile',
                'nav_personal_info': 'Personal Information',
                'nav_avatar': 'Avatar',
                'nav_password': 'Password',
                'nav_email': 'E-mail Settings',
                'nav_logins': 'Login Management',

                'label_first_name': 'First Name',
                'label_last_name': 'Last Name',
                'label_nick_name': 'Nickname',
                'label_gender': 'Gender',
                'label_state': 'Country',
                'label_avatar': 'Avatar',
                'label_unsaved': '(Unsaved)',
                'label_select_avatar': 'Select another avatar',
                'label_current_password': 'Current password',
                'label_new_password': 'New password',
                'label_new_password_again': 'Re-enter new password',
                'label_current_email': 'Current E-mail',
                'label_new_email': 'New E-mail',
                'label_new_email_again': 'Re-enter new E-mail',
                'label_forever': 'Unlimited',
                'label_login_tokens': 'Logins',
                'label_apikeys': 'ApiKeys',
                'label_token_remove': 'Remove token',

                'btn_change_password': 'Change password',
                'btn_change_email': 'Change E-mail',
                'btn_add_apikey': 'Add ApiKey',

                'label_set_own_picture': 'Set profile picture',
                'table_system_component': 'System Component',
                'table_user_agent': 'User Agent',
                'table_created': 'Created',
                'table_access_age': 'Expiration',
                'table_actions': 'Actions',
                'table_value': 'Value',

                'flash_cant_change_password': 'Could not change password, ',
                'flash_email_was_send': 'E-mail with instructions was sent.',
                'flash_user_cant_log_out': 'This user could not be logged out, ',
                'flash_cant_change_email': 'Could not change email, ',
                'flash_new_avatar_saved': 'New avatar saved successfully.',
                'flash_cant_save_avatar': 'Could not save new avatar, ',
                'flash_information_updated': 'Your information was updated.',
                'flash_cannot_add_token': 'Unable to add ApiKey',
                'flash_token_added': 'New ApiKey added',
                'flash_cannot_load_tokens': 'Unable to load ApiKeys',
            }, 'ProjectsProjectBlockoBlockoComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Blocko IDE',
                'title': 'Skunks<strong class="font-color-blocko">BLOCKO</strong> - PROGRAM <strong>{0}</strong>',

                'label_blocko_name': '<strong class="font-color-blocko" > BLOCKO </strong> Program Name: <strong>{0}</strong >',
                'label_blocko_description': '<strong class="font-color-blocko">BLOCKO </strong> Program Description: <strong>{0}</strong >',
                'label_connections': 'Connections',
                'label_blocko_program_properties': 'Program properties',
                'btn_label_option': 'Option',
                'label_blocko_save': 'Save',
                'label_remove_blocko_program': 'Remove program',
                'label_used_in_cloud': 'Latest Used in Cloud',
                'label_saved_versions': 'Saved Versions',
                'tab_hardware': 'Hardware',
                'tab_ide': 'IDE',
                'tab_versions': 'Versions',
                'tab_grid': 'Grid',
                'tab_blocko': 'Blocko',
                'tab_blocks': 'Blocks',
                'tab_interfaces': 'Interfaces',
                'tab_hw_groups': 'HW Groups',
                'tab_instance_in_cloud': 'Instance in cloud',
                'btn_add_slave': 'Add Slave Device',
                'btn_add_master': 'Add Master Device',
                'btn_add_grid': 'Add GRID project',
                'btn_clear_program': 'Clear Program',
                'btn_clear_console': 'Clear Console',
                'tab_terminal': 'Log Terminal',
                'btn_upload_on_cloud': 'Upload Latest Configuration to Cloud',
                'btn_turn_off_instance': 'Shutdown instance',
                'btn_change_cloud_version': 'Change Version',
                'btn_first_deploy_cloud_version': 'Deploy to cloud',
                'btn_select_cloud_version': 'Select & Upload Version to Cloud',
                'label_no_devices_added': 'No devices added in this <strong class="font-color-blocko">BLOCKO</strong> program.',
                'label_select_version': 'Select Version',
                'label_no_program_version': 'No program version.',
                'label_modal_change_instance_version': 'Change Instance Version',
                'label_modal_change_running_instance_version': 'Are you sure to change the running instance version?',
                'label_modal_ok': 'OK',
                'label_modal_error': 'ERROR',
                'label_modal_no_main_boards': 'No available Master Devices.',
                'label_modal_shutdown_instance': 'Shutdown instance',
                'label_modal_confirm_shutdown_instance': 'Are you sure to shutdown the running instance?',
                'label_program_versions': 'No <strong class="font-color-blocko" >BLOCKO</strong> versions',
                'label_program_versions_comment': 'Add some <strong class="font-color-blocko" >Blocks</strong>, <strong class="font-color-grid">Grid</strong> Apps and <strong class="font-color-hardware">Hardware</strong> Interface on <strong>Screen</strong> and save it',
                'label_no_grid_in_blocko': 'No <strong class="font-color-grid">GRID</strong> projects added in this <strong class="font-color-blocko">BLOCKO</strong> program',
                'label_modal_cant_save_blocko_hw_without_version': 'Unable to save <strong class="font-color-blocko">BLOCKO</strong>, you have <b>hardware devices</b> without program <b>versions selected</b>.',
                'label_modal_clear_program': 'Clear Program',
                'label_modal_confirm_clear_blocko_program': 'Are you sure to clear the <strong class="font-color-blocko">BLOCKO</strong> program?',
                'label_modal_cant_save_grid_hw_without_version': 'Unable to save <strong class="font-color-blocko">BLOCKO</strong>, you have <b>grid programs</b>, without program <b>versions selected</b>.',
                'checkbox_advanced_mode': 'Advanced Mode',
                'label_no_blocks_in_group': 'No BLOCKs in this group',

                'table_name': 'Name',
                'table_in_cloud': 'In Cloud',
                'table_description': 'Description',
                'table_last_updated': 'Last Updated',
                'table_author': 'Author',
                'table_actions': 'Actions',

                'label_cloud': 'CLOUD',
                'label_blocko': 'BLOCKO',
                'label_server': 'Server Name',
                'label_instance': 'Instance ID',
                'label_status': 'Status:',
                'label_version': 'Version:',
                'label_program_version': 'Program version',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove version',
                'label_remove_device': 'Remove device',
                'label_remove_grid': 'Remove grid project',

                'flash_version_saved': 'Version <b> {0} </b> saved successfully.',
                'flash_cant_load_blocko_block': 'Unable to load this BLOCK version, ',
                'flash_cant_add_blocko_block': 'Unable to add this BLOCK.',
                'flash_cant_load_blocko_version': 'Unable to load this BLOCKO version, ',
                'flash_blocko_updated': 'BLOCKO updated successfully.',
                'flash_blocko_removed': 'BLOCKO removed successfully.',
                'flash_cant_change_information': 'Unable to change this information, {0}.',
                'flash_version_removed': 'Version removed successfully.',
                'flash_cant_find_program_version': 'Program version is not found.',
                'flash_edit_version_been_changed': 'Version {0} has been changed.',
                'flash_cant_turn_instance_on': 'Could not turn on this instance, {0}.',
                'flash_cant_turn_instance_off': 'Could not turn off this instance, {0}.',
                'flash_broken_grid_missing_version': 'Grid is broken or version is missing.',
                'label_publish': 'Publish',
                'label_publish_decision': 'Publication Decision',
                'label_user_blocks': 'User Blocks',

            }, 'ModalsCodeSelectComponent': {

                'title': 'Select <strong class="font-color-code">CODE</strong>',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Action',
                'label_select_code': 'Select',
                'table_public_type': 'Status',
                'filter_name': 'Program name',
                'filter_public_programs': 'Public Programs',
                'filter_private_programs': 'My Own',
                'filter_hardware_type': '<strong class="font-color-hardware">HARDWARE</strong>Type',
                'label_no_code': 'No <strong class="font-color-code">CODE</strong> Programs',
                'label_no_code_comment': 'Unfortunately we did not find anything... We came to the same conclusion as in your live...',
                'label_no_file_or_version_selected': 'Select File Or Program with Version!',
                'label_select_file': 'Or select Bin file for manual <strong class="font-color-hardware">HARDWARE</strong> update',

            }, 'ModalsBootloaderSelectComponent': {

                'title': 'Select <strong class="font-color-code">BOOTLOADER</strong>',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Action',
                'table_identifier': 'Version',
                'table_change_notes': 'Changes notes',
                'label_select_bootloader': 'Select',
                'label_no_code': 'No <strong class="font-color-code">BOOTLOADERS</strong>',
                'label_no_code_comment': 'Unfortunately we did not find anything... We came to the same conclusion as in your live...',
                'label_no_file_or_version_selected': 'Select File Or Bootloader!',
                'label_select_file': 'Or select Bin file for manual <strong class="font-color-code">BOOTLOADER</strong> update',

            }, 'ModalsBlockSelectComponent': {

                'title': 'Select <strong class="font-color-blocko">BLOCK</strong>',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Action',
                'label_select_code': 'Select',
                'filter_public_block': 'Public',
                'filter_private_block': 'Own Blocks',
                'tab_filter_blocks': 'Filter',
                'tab_debug_blocks': 'Special Debugs Blocks',
                'tab_logic_blocks': 'Special Logic Blocks',
                'table_public_type': 'Type',
                'label_no_blocks': 'No <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_blocks_comment': 'We found nothing... same result as the search for your friends...',


            }, 'ModalsGridProjectSelectComponent': {

                'title': 'Select <strong class="font-color-grid">GRID</strong> Collection',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_program': 'Program',
                'table_version': 'Version',
                'table_actions': 'Action',
                'label_select_project': 'Select',
                'label_no_grid_project': 'No <strong class="font-color-grid">GRID</strong> Collection',
                'label_no_grid_project_comment': 'First create a Grid collection, at least with one application.',

            }, 'ProjectsProjectHardwareComponent': {

                'title': 'Project <strong class="font-color-hardware">HARDWARE</strong> Overview',
                'label_loading': 'Loading',
                'label_id': 'Full ID',
                'label_description': 'Description',
                'label_type': '<strong class="font-color-hardware">Hardware</strong> Type',
                'label_status': 'Status',
                'label_actions': 'Actions',
                'btn_add_hardware': 'Add a Device',
                'btn_add_hardware_group': 'Create a Group',
                'btn_switch_mode': 'Switch Mode',
                'btn_bulk_parameters': 'Bulk set parameter',
                'btn_add_new_update': 'Release Firmware',
                'label_group_properties': 'Edit Group',
                'label_remove_group': 'Permanently Remove',
                'label_cancel_update': 'Stop Procedure',
                'label_change_update_parameters': 'Change Configuration (TODO)',
                'label_no_hardware': 'No <strong class="font-color-hardware">HARDWARE</strong> in this project.',
                'label_no_hardware_comment': 'Click on the button bellow to add a new device.',
                'label_no_hardware_group': 'No <strong class="font-color-hardware">HARDWARE GROUP</strong> in this project.',
                'label_no_hardware_group_comment': 'Click on the button bellow to add a new group for hardware.',
                'label_no_updates': 'No <strong class="font-color-code">FIRMWARE </strong> release',
                'label_no_updates_comment': 'Click on the button bellow to update <strong class="font-color-hardware">HARDWARE</strong>, or <strong class="font-color-hardware">Hardware Groups</strong>.',
                'label_device_properties': 'Device properties',
                'label_settings': 'Settings',
                'label_set_hardware_group': 'Set Hardware Groups',
                'label_blink_hardware': 'Blink',
                'label_remove_device': 'Remove from project permanently',
                'label_time_missing_in_json': 'Not Yet',
                'label_project_active': 'This one',
                'label_deactivate_hardware': 'Deactivate for this project',
                'label_restart_hardware': 'Restart',
                'label_activate_hardware': 'Activate for this project',
                'label_foreign_project': 'Active in Foreign project <br> without Access',
                'label_project_not_active': 'Freeze',
                'tab_hardware_list': 'Hardware List',
                'tab_hardware_extended_list': 'Hardware extended list',
                'tab_online_states': 'Online states',
                'tab_hardware_groups': 'Hardware Groups',
                'tab_updates': 'Updates',
                'label_hardware_group_documentation_description': '<p>Hardware can be registered to any number of groups. Using groups, you can more easily filter hardware.</p><br><p class="text-center"> <span class="text-center">Import it. Export. Create statistics. And other miracles.<br> But only if we have already implemented it.</p><br><p class="text-center">Trololo...</p>',
                'blink_device_success' : 'Blink Success',
                'table_description': 'Description',
                'table_id': 'Updates',
                'table_update_id': 'Update HASH',
                'table_project_status': 'Project Assign',
                'table_finished': 'Finished',
                'table_created': 'Created',
                'table_planed': 'Planed',
                'table_program': 'Program',
                'table_version': 'Version',
                'table_status': 'Status',
                'table_details': 'Additional Details',
                'table_update_state': 'Update State',
                'table_firmware_type': 'Firmware Type',
                'table_update_progress': 'Progress',
                'table_update_type': 'Who initiated the update',
                'table_hardware_id': 'ID',
                'table_hardware_full_id': 'Processor ID',
                'table_hardware_name': 'Name (alias)',
                'table_imsi': 'IMSI',
                'table_iccid': 'ICCID',
                'table_firmware': 'Main Firmware',
                'table_firmware_version': 'Firmware Version',
                'table_backup': 'Backup Firmware',
                'table_backup_version': 'Backup Version',
                'table_bootloader': 'Bootloader',
                'table_hardware_group_name': 'Group Name',
                'table_groups': 'Group',
                'table_actions': 'Actions',
                'table_size': 'Size []',
                'table_allow_updates': 'Allow Updates',
                'table_developer_notifications': 'Developer Notifications',

                'flash_add_device_success': 'Device {0} has been added to your project.',
                'flash_edit_device_success': 'Device information was updated.',
                'flash_remove_device_success': 'Device has been removed.',
                'state_pending': 'Pending'

            }, 'TerminalLogSubscriberComponent': {
                'title': '<strong class="font-color-hardware">HARDWARE</strong> Terminal',
                'label_terminal': 'Terminal',
                'label_settings_terminal': 'Settings',
                'label_no_hardware_to_show': 'No hardware to show',
                'label_no_hardware_to_show_comment': 'Nothing now...',
                'label_change_log_level': 'Change log level',
                'btn_add_hardware': 'Add hardware',
                'btn_clear_console': 'clear console',
                'table_actions': 'Actions',
                'table_color': 'Terminal color',
                'table_logLevel': 'Log Level',
                'table_state': 'Subscribe State',
                'table_id': 'Hardware',
                'table_connection': 'Online status',
                'table_connection_confirmed': 'Connected',
                'label_select_logLevel': 'Select Log Level',
                'label_select_color': 'Select Log Color',
            }, 'ProjectsProjectActualizationProceduresComponent' : {

                'title': 'Project <strong class="font-color-hardware">UPDATES</strong> Overview',
                'label_loading': 'Loading',
                'label_name': 'Name',
                'label_id': 'Full ID',
                'label_description': 'Description',
                'label_type': '<strong class="font-color-hardware">Hardware</strong> Type',
                'label_status': 'Status',
                'label_actions': 'Actions',
                'btn_add_new_update': 'Release Firmware',
                'label_cancel_update': 'Stop Procedure',
                'label_change_update_parameters': 'Change Configuration (TODO)',
                'label_no_updates': 'No <strong class="font-color-code">FIRMWARE </strong> release',
                'label_no_updates_comment': 'Click on the button bellow to update <strong class="font-color-hardware">HARDWARE</strong>, or <strong class="font-color-hardware">Hardware Groups</strong>.',
                'label_device_properties': 'Device properties',
                'label_settings': 'Settings',
                'label_set_hardware_group': 'Set Hardware Groups',
                'label_blink_hardware': 'Blink',
                'label_remove_device': 'Remove permanently',
                'label_time_missing_in_json': 'Not Yet',

                'label_project_not_active': 'Freeze',
                'label_release_documentation_description': '<p>Here you can create group orders. For example, update hardware in <strong>"North Korea"</strong> or execute <strong><a target="_blank" href="https://www.youtube.com/watch?v=xSN6BOgrSSU">order 66</a></strong>.<p/><p class="text-center"> Heavy times for the republic..</p>',
                'tab_hardware_list': 'Hardware List',
                'tab_hardware_groups': 'Hardware Groups',
                'tab_updates': 'Updates',

                'table_description': 'Description',
                'table_id': 'Updates',
                'table_update_id': 'Update HASH',
                'table_project_status': 'Project Assign',
                'table_finished': 'Finished',
                'table_created': 'Created',
                'table_planed': 'Planed',
                'table_program': 'Program',
                'table_version': 'Version',
                'table_status': 'Status',
                'table_name': 'Name',
                'table_details': 'Additional Details',
                'table_update_state': 'Update State',
                'table_firmware_type': 'Firmware Type',
                'table_update_progress': 'Progress',
                'table_update_type': 'Who initiated the update',
                'table_hardware_group_name': 'Group Name',
                'table_groups': 'Group',
                'table_actions': 'Actions',

            }, 'ProjectsProjectHardwareHardwareComponent': {

                'title': '<strong class="font-color-hardware">HARDWARE</strong> DashBoard',
                'main_subtitle_hardware': '<strong class="font-red-flamingo">Please keep in mind as administrator that you are editing HW of our customers and some functionality may not work! Access Hardware through the Projects!</strong>',
                'main_administration_title': 'Platform administration',
                'main_subtitle': 'Embedded Hardware Settings',
                'tab_overview': 'Overview',
                'tab_update': 'Update progress',
                'tab_developer': 'Developer Settings',
                'tab_advance_command_center': 'Online Terminal',
                'tab_online_state': 'Network status',
                'label_name': 'Name',
                'label_version': 'Version',
                'label_description': 'Description',
                'label_type': 'Type',
                'label_producer': 'Manufacturer',
                'label_revision': 'Revision',
                'label_id': 'ID',
                'label_web_link': 'Hardware Web view',
                'label_ipv6_address': 'Ipv6 Address',
                'label_ethernet_address': 'Ethernet MAC address',
                'label_device_is_not_dominant': 'Hardware is not actually assigned to this project. You can create new commands, schedule updates, but nothing will be done until Hardware be signed under this project again.',
                'label_device_project_dominant': 'Currently, Hardware is actively used in the Project:',
                'label_wifi_address': 'Wi-Fi MAC address',
                'label_device_status': 'Device status',
                'label_server': 'Server Name',
                'label_device_last_seen': 'Last seen',
                'label_bootloader_version': 'Version',
                'label_last_seen_un_know': 'Unknown',
                'label_update_to': 'Update to',
                'label_manual_select': 'Manual Select',
                'label_full_id': 'Processor ID',
                'label_update_in_que': 'Update in Que',
                'label_developer_settings': 'Developer settings',
                'label_update_prohibited_by_settings': 'Prohibited by developer settings. <strong>"Allow Updates from Server"</strong>',
                'label_port': 'Port',
                'label_alerts': 'Alerts',
                'label_overview': 'Overview',
                'label_settings': 'Settings & Developers',
                'label_core_configuration': 'Core Bootloader Configuration',
                'label_core_configuration_comment': '<p>Please keep in mind that you have God\'s power. You can remotely change anything out of common flow. Please change key configuration parameters only if you know what you are doing.</p>',
                'label_core_registers': 'Registers',
                'label_restart_hardware': 'Restart Device',
                'label_restart_to_bootloader': 'Restart to Bootloader',
                'label_change_mqtt_pass': 'Restart MQTT Password',
                'label_change_server': 'Change Server',
                'label_info': 'Basic System Info',
                'label_image': 'Image',
                'label_set_own_picture': 'Set your own photo',
                'label_no_running_update_on_hardware': 'No <strong class="font-color-code">FIRMWARE </strong> release',
                'label_no_running_update_on_hardware_comment': 'There is no update for this device yet. Create new <strong class="font-color-code">Code</strong> program or set new release',
                'label_main_server': '<strong class="font-color-cloud">CLOUD</strong> Server',
                'label_instance': '<strong class="font-color-instance">INSTANCE</strong> Name',
                'label_cellular': '<strong class="font-color-hardware">CELLULAR</strong> integration',
                'label_instance_description': 'Description',
                'label_main_server_not_found': 'Device has never connected to server yet.',
                'label_not_in_instance': 'Device is not running in any instance.',
                'label_server_online_state': 'State',
                'label_instance_online_state': 'State',
                'label_unknown_value': 'none',
                'label_no_hardware_to_show_comment': 'There are no devices to show, but you can add some',
                'label_unsubscribe_device': 'Unsubscribe device',
                'label_manual_individual_update': 'Manual Change',

                'blink_device_success' : 'Blink Success',


                'label_actual_program_name': 'Current <strong class="font-color-code">FIRMWARE </strong> name',
                'label_actual_program_version': 'Current <strong class="font-color-code">FIRMWARE </strong> version name',
                'label_actual_backup_program_name': 'Current <strong class="font-color-code">BACKUP </strong> program name',
                'label_actual_backup_program_version': 'Current <strong class="font-color-code">BACKUP </strong> version name',
                'label_backup_mode': 'Backup mode',
                'label_backup_static': 'Manual',
                'label_backup_automatic': 'Automatic',
                'label_switch_to_automatic': 'switch to <strong>Automatic</strong>',
                'label_switch_to_static': 'switch to <strong>Manual</strong>',
                'label_time_missing_in_json': 'Not yet (Unknown)',
                'label_you_can_set': '(You can set that)',
                'label_bootloader_not_supported': 'Backend has not set Bootloader for this type od Board',
                'label_bootloader_database_not_recognize': 'No available record about actual Bootloader version on Hardware ',
                'label_bootloader_up_to_date': 'Hardware has latest version',
                'label_firmware': 'Firmware',
                'label_backup': 'Backup',
                'label_bootloader': 'Bootloader',
                'label_code': '<strong class="font-color-code"> CODE </strong>',
                'label_blocko': 'BLOCKO',
                'label_cloud': 'CLOUD',
                'label_remove_device': 'Irreversibly Remove device',
                'label_device_properties': 'Device properties',
                'label_deactivate_device': 'Deactive for this project',
                'label_active_device': 'Active for this project',
                'label_blink_hardware': 'Blink',
                'label_cancel_updates': 'Cancel Updates',
                'label_open_updates': 'Show details',
                'label_close_updates': 'Hide details',
                'label_action_updates': 'Actions',
                'label_no_update_data': 'No data avaliable',
                'table_id': 'Update ID',
                'table_program': 'Program',
                'table_started': 'Created',
                'table_planned': 'Planned',
                'table_finished': 'Finished',
                'table_type': 'Update type',
                'table_progress': 'Progress',
                'table_state': 'Status',
                'table_version': 'Version',
                'table_status': 'Status',
                'table_actions': 'Actions',


                'flash_cant_add_hardware': 'Unable to add hardware ',
                'flash_no_more_device': 'No more Device to add',
                'flash_edit_device_success': 'Device information was updated.',
                'flash_device_restart_success': 'Restart command was successful',
                'flash_remove_device_success': 'Device has been removed.',
                'flash_remove_device_fail': 'Device could not be removed, ',
                'flash_cant_update_bootloader': 'Unable to update Bootloader',
                'flash_cant_update_firmware': 'Unable to update Bootloader.',
                'flash_cant_edit_backup_mode': 'Device backup mode could not be changed, {0}.',
                'flash_cant_connect': 'Something is wrong with server parameters, its not possible to subscribe logs form Device',
                'flash_conection_failed': 'device connection failed',
                'modal_unsubscribe_device': 'Are you sure you want to unsubribe {0}?\n (It can be subscribed again)',

                'flash_updates_canceled': 'Updates were canceled successfully',
                'dialog_message_on_cancel': 'Are you sure you want to cancel updates?',

                'cancel_updates_for': 'Cancel updates for ',


            }, 'ProjectsProjectServersComponent': {

                'title': 'Skunks<strong class="font-color-cloud">CLOUD</strong> SERVERS',
                'label_no_servers': 'Not any <strong class="font-color-cloud">CLOUD</strong> Servers',
                'label_no_servers_comment': 'Your own Server will allow you to run your <strong class="font-color-blocko">BLOCKO</strong> instances.<br>You can install the Server on your own Servers or leave everything to us',
                'table_id': 'Server ID',
                'table_server_name': 'Name',
                'table_server_version': 'Version',
                'table_status': 'Online Status',
                'label_server_update': 'Update',
                'label_server_properties': 'Server settings',
                'label_delete_server': 'Remove server',
                'label_deactivate_server': 'Activate',
                'label_shut_down': 'Shut Down',
                'label_upgrade': 'Upgrade',
                'label_activate_server': 'Deactivate',
                'label_restart': 'Restart',
                'table_actions': 'Actions',
                'table_rest_url': 'Rest URL',
                'table_mqtt_url': 'MQTT URL',
                'label_personal_server_name': 'Server name',
                'label_personal_server_description': 'Description',
                'label_no_server_size_found': 'No Server Components...',
                'label_no_server_size_found_comment': 'This message should never show you .... very interesting ... obviously something went wrong ... now is the time to turn to technical support.,',
                'label_deployment': 'Deploying',
                'label_select_size': 'Select Server Size',
                'label_select_destination': 'Choose a datacenter region',
                'btn_create_manually_server': 'Register Server Entity',
                'btn_create_server': 'Create Server',
                'btn_create_automatically_server': ' Create SaaS Server',

                'flash_upgrade_requested': 'Upgrade was requested'

            }, 'ProjectsProjectGSMSComponent': {

                'title': 'Skunks<strong class="font-color-hardware">CELLULAR</strong> Modules',
                'label_no_gsm': 'No <strong class="font-color-hardware">CELLULAR</strong> Modules',
                'label_no_gsm_comment': 'You have not registered any CELLULAR modules yet. It\'s time to pull out a credit card and buy some.',
                'btn_add_gsm': 'Register New Module',
                'table_msi_number': 'MSI Number',
                'table_msi_iccid': 'ICCID',
                'table_id': 'ID',
                'table_name': 'Name',
                'table_msi_status': 'Status',
                'table_msi_country': 'Country',
                'table_hardware' : 'Connected Hardware',
                'table_available_data_traffic': 'Available data traffic',
                'table_total_data_traffic': 'Total traffic',
                'table_daily_traffic': 'Daily data traffic',
                'table_monthly_traffic': 'Monthly data traffic',
                'table_description': 'Description',
                'table_status': 'Status',
                'table_actions': 'Actions',
                'label_gsm_properties': 'Properties',
                'label_gsm_edit': 'Edit',
                'label_gsm_remove': 'Remove',
                'label_gsm_un_register': 'Un Register',
                'label_deactive': 'Deactivate',
                'label_active': 'Activate',
                'label_gsm_print_sticker': 'Print Sticker',
                'tab_overview': 'Cellular Modules',
                'tab_traffic_details': 'Data Traffic Details',
                'label_gsm_traffic': 'Cellular Modules Aggregated Traffic',

            }, 'ProjectsProjectGSMSGSMComponent': {

                'title': 'Skunks<strong class="font-color-hardware">CELLULAR</strong> Module',
                'label_msi_number': 'MSI Number',
                'label_id': 'ID',
                'label_name': 'Name',
                'label_msi_status': 'CELLULAR status',
                'label_description': 'Description',
                'label_status': 'Status',
                'label_actions': 'Actions',
                'label_gsm_properties': 'Properties',
                'label_gsm_edit': 'Edit',
                'label_gsm_remove': 'Remove',
                'label_gsm_un_register': 'Un Register',
                'label_gsm_print_sticker': 'Print Sticker',
                'label_gsm_traffic': 'Cellular Module Traffic',
                'label_tags': 'tags',
                'tab_overview': 'Cellular Module Traffic',
                'tab_traffic_details': 'Data Traffic Details',
                'tab_cdr_details': 'Charging data records (CDR)',
                'tab_country_details': 'Country Settings',
                'table_maximum_threshold': 'Maximum data traffic threshold',
                'table_notify_me': 'Send Email',
                'table_block_sim': 'Block Sim',
                'table_daily_threshold': 'Daily Limit (MB)',
                'table_monthly_threshold': 'Monthly Limit (MB)',
                'table_total_threshold': 'Total Limit (MB)',
                'table_daily_report': 'Daily Report',
                'table_month_report': 'Monthly Report',
                'label_weekly_report': 'Weekly Report',
                'table_report_type': 'Report Period',
                'table_report_sate': 'State',
                'table_start_date': 'Start Date',
                'table_end_date': 'End Date',
                'table_msi_country': 'Country',
                'table_operator': 'Operator',
                'table_data_traffic': 'Traffic',
                'table_cost': 'Cost',
                'gsm_hardware': 'Hardware',
                'gsm_iccid': 'SIM Card identifier (ICCID)',
                'gsm_monthly_report_included': 'Included in the monthly report',
                'gsm_weekly_report_included': 'Included in the weekly report',
                'gsm_daily_report_included': 'Included in the daily report',
                'gsm_sms': 'Outgoing SMS service',
                'gsm_disabled': 'Disabled',
                'gsm_enabled': 'Enabled',
                'gsm_plmn': 'Operator Code (PLMN)',
                'gsm_mcc': 'Mobile Country Code (MCC)',
                'gsm_mnc': 'Mobile Network Code (MNC)',
                'gsm_days_activation': 'Days from activation',
                'gsm_balance': 'Residual data traffic',
                'gsm_date_activation': 'Activation date',
                'gsm_apn': 'APN',
                'gsm_support': 'Support Call N'

            }, 'ProjectsProjectInstancesComponent': {

                'title': 'Skunks<strong class="font-color-cloud">CLOUD</strong> - ALL INSTANCES',

                'table_name': 'Name',
                'table_id': 'ID',
                'table_description': 'Description',
                'table_blocko_program': '<strong class="font-color-blocko">BLOCKO</strong> Program',
                'table_status': 'Status',
                'table_actions': 'Actions',
                'table_main_server': 'Main Server',
                'table_backup_server': 'Backup Server',

                'flash_instance_edit_success': 'Instance information has been updated.',
                'flash_instance_create_success': 'Instance was created successfully.',
                'flash_no_snapshot': 'No snapshot is available for this instance.',

                'label_upload_instance_modal': 'Deploy to Cloud',
                'label_upload_instance_modal_comment': 'The last used <strong class="font-color-blocko">BLOCKO</strong> configuration will be uploaded to the server.',
                'label_no_instances': 'No <strong class="font-color-cloud">Instances</strong> found in <strong class="font-color-cloud">CLOUD</strong>',
                'label_no_instances_comment': 'If You already create <strong class="font-color-blocko">BLOCKO</strong>, you are ready to deploy it!',
                'label_instance_documentation_description': '<p><strong class="font-color-cloud">INSTANCE</strong>  is a <strong>container</strong> for a running application created in the <strong class="font-color-blocko">BLOCKO</strong> editor.</p><p><strong class="font-color-cloud">CLOUD</strong> Server is a <strong>container manager</strong>.</p><p>The server handles Hardware via MQTT and applications connected via the websocket and passes data safely to the container and from the container to the end devices. Each container (<strong class="font-color-cloud">INSTANCE</strong>) is totally separate from others. The more the instance is computationally more demanding, the fewer the Server can handle.<br>You can have an <strong class="font-color-cloud">INSTANCE</strong> in restricted mode on a public server or <strong>on your own servers.</strong></p>',
                'label_deactivate_instance': 'Shutdown instance',
                'label_upload_latest_used_version': 'Upload Latest Configuration to Cloud',
                'label_start': 'Start instance',
                'label_instance_properties': 'Instance properties',
                'label_own_name_and_description': 'You can set your own name and description',
                'btn_create_instance': 'Create Instance',
                'btn_deploy_snapshot': 'Deploy Some Snapshot',
                'btn_deploy_latest_snapshot': 'Deploy Latest Snapshot',
                'label_shut_down_instance_modal': 'Permanently shut down <strong class="font-color-instance">INSTANCE</strong> ',
                'label_shut_down_instance_modal_comment': ' <p> This <strong class="font-color-instance">INSTANCE</strong> will be turned off permanently. The <strong class="font-color-blocko">BLOCKO</strong> program stops working and all hardware will be unregistered from the instance. But All <strong class="font-color-code">CODE</strong> programs on the <strong class="font-color-hardware">HARDWARE</strong> will remain in the current / latest state.</p><p>The incomplete Actualization procedures for this instance will be immediately terminated. It is possible that another update will run, because is not possible to interrupt the ongoing <strong class="font-color-hardware">HARDWARE</strong> update. The <strong class="font-color-hardware">HARDWARE</strong> will be able to connect to another instance or update individually in a few seconds later.</p><p>The <strong class="font-color-instance">INSTANCE</strong> can be <strong>turned on at any time</strong> and everything will be restored.<br>A new Synchronization procedure will started to update / synchronize the <strong class="font-color-hardware">HARDWARE</strong> to the required state.</p>',
                'label_instance_remove': 'Remove instance',

            }, 'ProjectsProjectInstancesInstanceComponent': {

                'title': 'Skunks<strong class="font-color-cloud">INSTANCE</strong>',

                'tab_name_overview': 'Overview',
                'tab_name_editor': 'Editor',
                'tab_name_hardware': '<strong class="font-color-hardware">HARDWARE</strong>',
                'tab_name_grid': '<strong class="font-color-grid">GRID</strong> Apps',
                'tab_name_history': 'History',
                'tab_name_versions': 'Versions',
                'tab_name_api': 'Rest-API',
                'tab_name_mesh': 'Mesh Network',
                'tab_name_update_progress': 'Update Progress',
                'tab_name_update_view': 'Realtime Overview',
                'tab_name_logs': 'Logs',
                'tab_name_debugs': 'Debugs',
                'tab_hardware': 'Hardware',
                'tab_hardware_group': 'Hardware Groups',

                'table_name': 'Name',
                'table_id': 'ID',
                'table_hardware_type': 'Device Type',
                'table_author': 'Author',
                'table_date': 'Date',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'table_update_id': 'Procedure ID',
                'table_project': 'Project',
                'table_program': 'Program',
                'table_created': 'Created',
                'table_planed': 'Planed',
                'table_finished': 'Finished',
                'table_type': 'Update type',
                'table_progress': 'Progress',
                'table_state': 'Status',
                'table_b_program_version_name': '<strong class="font-color-blocko">BLOCKO</strong>Version',
                'table_b_program_version_description': 'Description',
                'table_update_progress': 'Progress',
                'table_mesh_key': 'Mash Token',
                'table_api_url': 'API URL',

                'btn_change_version_in_cloud': 'Change version in Cloud',
                'btn_upload_on_cloud': 'Upload Latest Configuration to Cloud',
                'btn_select_cloud_version': 'Select & Upload Version to Cloud',
                'btn_save_deploy': 'Save & Deploy now',
                'btn_deploy_snapshot': 'Deploy Some Snapshot',
                'btn_add_key': 'Add new Api Key',
                'btn_edit_token': 'Edit',
                'btn_remove_token': 'Remove',
                'label_grid_project': 'Skunks<strong class="font-color-grid">Grid</strong> Project',
                'table_grid_program_name': 'Program',
                'table_grid_program_description': 'Description',
                'table_grid_program_version_name': 'Version',
                'table_grid_program_version_description': 'Description',
                'table_grid_program_settings': 'Access Type',
                'table_grid_link': 'Access Link',
                'table_api_key': 'API KEY',
                'btn_turn_off_instance': 'Shut Down',
                'btn_change_version': 'Change Blocko Version',
                'label_shut_down_instance_modal': 'Permanently shut down <strong class="font-color-instance">INSTANCE</strong> ',
                'label_shut_down_instance_modal_comment': ' <p> This <strong class="font-color-instance">INSTANCE</strong> will be turned off permanently. The <strong class="font-color-blocko">BLOCKO</strong> program stops working and all hardware will be unregistered from the instance. But All <strong class="font-color-code">CODE</strong> programs on the <strong class="font-color-hardware">HARDWARE</strong> will remain in the current / latest state.</p><p>The incomplete Actualization procedures for this instance will be immediately terminated. It is possible that another update will run, because is not possible to interrupt the ongoing <strong class="font-color-hardware">HARDWARE</strong> update. The <strong class="font-color-hardware">HARDWARE</strong> will be able to connect to another instance or update individually in a few seconds later.</p><p>The <strong class="font-color-instance">INSTANCE</strong> can be <strong>turned on at any time</strong> and everything will be restored.<br>A new Synchronization procedure will started to update / synchronize the <strong class="font-color-hardware">HARDWARE</strong> to the required state.</p>',
                'label_no_api_keys': 'No Api Keys',
                'label_no_api_keys_comment': 'Create New one, if you want use your <strong>Webhooks</strong> in this <strong class="font-color-instance">INSTANCE</strong>',
                'label_instance_properties': 'Properties',
                'label_instance_name': 'Name',
                'label_instance_description': 'Description',
                'label_skunks_backup_cloud': 'Backup Server',
                'label_skunks_main_cloud': 'Main Server',
                'label_no_instance_version': 'No <strong class="font-color-instance">INSTANCE</strong> Snap Shots',
                'label_no_instance_version_comment': 'Each <strong class="font-color-blocko">BLOCKO</strong> program and versions which are paired with <strong class="font-color-hardware">HARDWARE</strong> are stored as a separate Snap Shots.',

                'label_no_instance_history': 'No <strong class="font-color-instance">INSTANCE</strong> history',
                'label_no_instance_history_comment': 'Each <strong class="font-color-instance">INSTANCE</strong> has history of snapshots deployed.',
                'label_instance_id': 'ID',
                'btn_add_new_snapshot': 'Create SnapShot',
                'label_server_name': '<strong class="font-color-cloud">CLOUD</strong> Server',
                'label_server_description': 'Server Description',
                'label_instance_status': 'Online Status',
                'label_instance_version_name': 'Selected Snapshot',
                'label_instance_version_description': 'Snapshot description',
                'label_status': 'Status',
                'label_info': 'Info',
                'label_cancel_update': 'Stop Updates',
                'label_no_updates': 'No <strong class="font-color-code">FIRMWARE </strong> release updates',
                'label_no_updates_comment': 'For Actual <strong class="font-color-hardware">INSTANCE</strong> Snapshot is not required any update',
                'label_instance_info_is_offline': '<strong class="font-color-instance">INSTANCE</strong> is offline',
                'label_instance_info_is_offline_comments': 'Deploy <strong class="font-color-blocko">INSTANCE</strong> on server or start required <strong class="font-color-cloud">CLOUD</strong>Server',
                'label_grid_group_in_snapshot': 'Not any <strong class="font-color-grid">GRID</strong> Apps',
                'label_no_grid_group_in_snapshot_comment': 'In Actual <strong class="font-color-instance">INSTANCE</strong> Snap Shots you are not using any <strong class="font-color-grid">GRID</strong> Apps',
                'label_instance_type': 'Instance Type',
                'label_edit_grid_app': 'Change Parameters',
                'label_created': 'Created',
                'label_running_from': 'Running from',
                'label_deployed': 'Deployed',
                'label_running_to': 'Running until',
                'label_deactivate_instance': 'Deactivated',
                'label_activate_instance': 'Deploy snapshot',
                'label_b_program_name': '<strong class="font-color-blocko">BLOCKO</strong> Program',
                'label_b_program_description': 'Program description',
                'label_b_program_version_name': '<strong class="font-color-blocko">BLOCKO</strong> Version name',
                'label_b_program_version_description': 'Version Description',
                'label_running_status': '<strong class="font-color-cloud">INSTANCE</strong> Status',
                'label_planned': 'Planned',
                'label_progress': 'Progress',
                'label_update_state': 'Update state',
                'label_no_updates_title': 'No updates',
                'label_no_update_text': 'Set instance to cloud does not require any updates of firmware on hardware',
                'label_project': 'Project',
                'label_program': 'Program',
                'label_finished': 'Finished',
                'label_firmware_type': 'Firmware Type',
                'label_version': 'Version',
                'label_description': 'Description',
                'label_no_history_in_instance': 'There is no history for this instance',
                'label_no_history_in_instance_comments': 'After you upload Instance to Cloud, you will see a miracle. If it does not meet your expectations, try Pornhub',
                'label_hardware_actual_in_instance': 'Currently in Instance',
                'label_hardware_no_hardware_in_instance': 'No assigned Hardware in currently running instance',
                'label_hardware_no_hardware_in_instance_comments': 'Create a new <strong class="font-color-blocko">BLOCKO</strong> version and assign the hardware. Or shift the <strong class="font-color-hardware">Hardware groups</strong> that are assigned to this instance.',
                'label_grid_no_grid_in_instance': 'No apps in this instance.',
                'label_grid_no_grid_in_instance_comments': 'Create a new <strong class="font-color-blocko">BLOCKO</strong> version and assign the <strong class="font-color-grid">GRID</strong> Apps.',
                'label_remove_device': 'Remove device',
                'label_device_type': 'Device type',
                'label_no_running_update_on_instance': 'There is no running updates in this instance',
                'label_no_running_update_on_instance_comments': 'There are no records of the required <strong class="font-color-hardware">Hardware</strong> update',
                'label_online_state': 'online status',
                'label_instance_not_running_in_cloud': 'Instance is not running in Cloud.',

                'label_cloud': 'CLOUD',
                'label_blocko': '<strong class="font-color-blocko">BLOCKO</strong> Program',
                'label_grid': 'GRID',
                'label_code': ' <strong class="font-color-code"> CODE </strong>>',
                'label_hardware': 'Hardware',
                'label_bootloader': 'Bootloader',
                'label_backup': 'Backup',
                'label_firmware': 'Firmware',
                'label_unknown_date': 'Not yet (Unknown)',
                'label_hardware_caps': 'HARDWARE',
                'label_select_version_for_details': 'Select version to see details',
                'label_snapshot_editor': 'Editor',
                'label_no_hardware': 'Nothing here',
                'label_version_name': 'Version used:',

                'label_no_running_update_on_hardware': 'No <strong class="font-color-code">FIRMWARE</strong> releases',
                'label_no_running_update_on_hardware_comment': 'Actual <strong class="font-color-instance">INSTANCE</strong> SpanShots not required any updates on <strong class="font-color-hardware">HARDWARE</strong>',
                'label_hardware_groups': '<strong class="font-color-hardware">HARDWARE</strong> Groups in Actual Snapshot',
                'label_hardware_in_instance': '<strong class="font-color-hardware">HARDWARE</strong> in Actual Snapshot',

                'label_no_hardware_groups_in_snapshot': 'No <strong class="font-color-hardware">HARDWARE</strong> Groups',
                'label_no_hardware_groups_in_snapshot_comment': 'In actual Snapshot of instance, you have not integrated any <strong class="font-color-hardware">HARDWARE</strong> groups',
                'label_no_hardware_in_snapshot': 'No <strong class="font-color-hardware">HARDWARE</strong> devices',
                'label_no_hardware_in_snapshot_comment': 'In actual Snapshot of instance, you have not integrated any <strong class="font-color-hardware">HARDWARE</strong> devices',

                'table_project_status': 'Project Assign',
                'table_hardware_group_name': 'Group Name',
                'table_groups': 'Group',
                'table_hardware_id': 'ID',
                'table_hardware_full_id': 'Processor ID',
                'table_update_state': 'State',
                'table_hardware_name': 'Name (alias)',
                'table_size': 'Size []',
                'label_type': '<strong class="font-color-hardware">HARDWARE</strong> Type',
                'label_project_active': 'This one',
                'label_project_not_active': 'Freeze',
                'table_status': '<strong class="font-color-hardware">HARDWARE</strong> Online status',

                'label_modal_shutdown_instance': 'Shutdown instance',
                'label_deploy_snapshot': 'Deploy',
                'label_export_snapshot': 'Export',
                'label_snapshot_settings': 'Edit',
                'label_snapshot_remove': 'Remove',
                'label_modal_confirm_shutdown_instance': 'Are you sure to shutdown the running instance?',
                'label_modal_run_latest_version': 'Deploy to CLOUD in the latest version',
                'label_modal_confirm_run_latest_version': 'Are you sure to deploy <strong class="font-color-blocko">BLOCKO</strong> and instance to CLOUD?',
                'label_cannot_execute': 'Unable to execute command, {0}.',
                'flash_picture_updated': 'Picture updated successfully.',
                'label_cannot_change_program_publicity': 'Unable to change program privacy setting, ',
                'label_modal_change_instance_version': 'Change instance version',
                'label_modal_change_running_instance_version': 'Are you sure to change the running instance version?',
                'label_time_missing_in_json': 'Not yet',

                'flash_instance_edit_success': 'Instance information was changed.',
                'flash_hardware_load_fail': 'Unable to load hardware',
                'flash_hardware_group_load_fail': 'Unable to load hardware groups',

                'flash_bprogram_no_versions': 'No versions found.',
                'flash_not_deployable' : 'Instance is not deployable. Select single hardware or group in the hardware interface block. Then bind those hardware interfaces to make instance deployable',

                'update_id': 'Update ID',
                'table_started': 'Created',
                'table_planned': 'Planned',
                'table_version': 'Version',
                'table_progress_status': 'Status',
                'label_cancel_updates': 'Cancel Updates',

                'dialog_message_on_cancel': 'Are you sure you want to cancel updates?',
                'cancel_updates_for': 'Cancel updates for ',

                // Actions button in Hardware devices table
                'label_set_hardware_group': 'Set Hardware Groups',
                'label_blink_hardware': 'Blink',
                'label_device_properties': 'Device properties',
                'label_deactivate_hardware': 'Deactivate for this project',
                'label_activate_hardware': 'Activate for this project',

                // Flash Messages for devices
                'flash_edit_device_success': 'Device information was updated.',
                'flash_remove_device_success': 'Device has been removed.',
                'blink_device_success' : 'Blink Success',

                'btn_clear_console': 'Clear Console',

                'label_no_extrnal_api_keys': 'No External Api Keys to local instance',
                'label_no_extrnal_api_keys_comment': 'There is no any <strong>External Api Keys</strong> to local <strong class="font-color-instance">INSTANCE</strong>',



            }, 'ProjectsProjectLibrariesComponent': {

                'title': 'Skunks<strong class="font-color-code">CODE</strong> - ALL LIBRARIES',

                'label_program_properties': 'CODE program properties',
                'label_remove_program': 'Remove CODE program',
                'label_libraries': 'No <strong class="font-color-code">CODE</strong> libraries',
                'label_libraries_comment': 'Make an impression on your <strong>Mom</strong> and create a dream Library. And do not forget to share it!',
                'label_no_public_library': 'No public <strong class="font-color-code">CODE</strong> Libraries',
                'label_no_private_library': 'No private <strong class="font-color-code">CODE</strong> Libraries',

                'label_create_public_library_comment': 'If you do not see your dream library, create it and share it with others.',
                'label_create_private_library_comment': 'If you do not see your dream library, create it and share it with others.',

                'btn_add_library': 'New Library',

                'tab_my_libraries': 'Private Libraries',
                'tab_public_libraries': 'Public Libraries',

                'flash_library_add_success': 'Library has been created.',
                'flash_library_edit_success': 'Library has been updated.',
                'flash_library_removed_success': 'Library has been removed.',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_hardware_type': 'Only compatible with',
                'table_actions': 'Actions',
                'label_library_make_copy': 'Make a copy of Library',
                'label_library_settings': 'Settings',
                'label_library_properties': 'Library Properties',
                'label_remove_library': 'Remove',
                'label_library_edit': 'Edit'

            }, 'ProjectsProjectLibrariesLibraryComponent': {

                'title': 'Skunks<strong class="font-color-code">CODE</strong> - LIBRARY <strong>{0}</strong>',

                'label_library_properties': 'CODE library properties',
                'label_delete_library': 'Delete',

                'flash_version_saved': 'Version <b>{0}</b> saved successfully.',
                'flash_unsaved_changes_version_change': 'You have <b> unsaved changes</b> in version <b> {0} </b>, do you really want switch to version <b>{1}</b>?',
                'flash_unsaved_changes_version_reload': 'You have <b>unsaved changes</b> in version <b>{0}</b>, do you really want reload this version?',
                'flash_library_edit_success': 'Library has been updated.',
                'flash_library_removed_success': 'Library has been removed.',
                'flash_version_save_success': 'Version <b> {0} </b> saved successfully.',
                'flash_version_save_fail': 'Unable to save version <b> {0} </b>, {1}.',
                'flash_version_edit_success': 'Version <b> {0} </b> saved successfully.',
                'flash_version_edit_fail': 'Unable to save version <b> {0} </b>, {1}.',
                'flash_version_removed_success': 'Version has been removed.',
                'flash_version_removed_fail': 'Unable to remove library, ',
                'flash_cannot_load_library': 'Unable to load library.',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_author': 'Author',
                'table_actions': 'Actions',

                'label_ide': 'IDE',
                'label_code': 'CODE',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove version',
                'label_save': 'SAVED VERSIONS',
                'label_name': '<strong class="font-color-code">CODE</strong>Library Name',
                'label_description': '<strong class="font-color-code">CODE</strong>Library Description',
                'label_version': 'Version',
                'label_no_library_version': 'There are no libraries yet.',
                'label_library_make_copy': 'Make a copy of Library.',
                'label_public_comment': 'This is public Library, you can only test it or make a copy.'

            }, 'ProjectsProjectMembersComponent': {

                'title': 'Project members',

                'btn_add_members': 'Invite collaborators',

                'table_name': 'Name',
                'table_invited': 'Date of invitation',
                'table_who_invite': 'Invited by',
                'table_state': 'State',
                'table_actions': 'Actions',


                'no_persons_in_this_projects': 'Forever <strong class="font-color-skunks-blue">Alone?</strong>',
                'no_persons_in_this_projects_comment': 'It can be easily changed. <strong>Invite your mom</strong>, colleague or anyone you want.',

                'modal_label_invitation': 'Invitation',
                'modal_label_invitation_send': 'Invitation E-mail was sent to {0}.',
                'modal_title_remove_member': 'Remove member',
                'modal_text_remove_member': 'Do you really want to remove this member?',

                'label_remove_member': 'Remove member',
                'label_send_invitation': 'Send invitation again',
                'label_remove_invitation': 'Cancel invitation',

                'label_cannot_remove_yourself': 'You can`t remove yourself from your project.',
                'label_cannot_add_member': 'Unable to add collaborators. {0}',
                'label_invitation_sent': 'Invitation(s) sent.',

            }, 'ProjectsProjectWidgetsComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Community Widget Administration',
                'title': 'Skunks<strong class="font-color-grid">GRID</strong> widgets',

                'btn_add_widget': 'Create Widget',

                'tab_my_widgets': 'My Widgets',
                'tab_public_widgets': 'Public Widgets',
                'tab_admin_widgets': 'Widgets for Publish Decisions',
                'table_active': 'Active for Users',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',

                'label_remove_group': 'Remove Group',
                'label_widget_properties': 'Widget properties',
                'label_widget_make_decision': 'Make Decision',
                'label_widget_clone': 'Clone',
                'label_remove_widget': 'Remove Widget',
                'label_activate': 'Active for user',
                'label_deactivate': 'Deactivate it for users',

                'flash_grid_group_edit_success': 'Version has been changed.',
                'flash_grid_group_edit_fail': 'Unable to change version, ',
                'flash_grid_group_remove_success': 'Version has been removed.',
                'flash_grid_group_remove_fail': 'Unable to remove version, ',
                'flash_widget_add_success': 'Version has been successfully created.',
                'flash_widget_add_fail': 'Unable to create version, ',
                'flash_widget_removed_success': 'Widget has been removed.',
                'flash_widget_removed_fail': 'Unable to remove widget, ',
                'flash_widget_edit_success': 'Widget has been edited.',
                'flash_widget_edit_fail': 'Unable to edit widget, ',

                'label_no_private_widget': 'No <strong class="font-color-grid">GRID</strong> widgets',
                'label_no_private_widget_comment': 'Click on the button bellow to create a widget.',
                'label_no_public_widget': 'No public <strong class="font-color-grid">GRID</strong> widgets',
                'label_no_public_widget_comment': 'Change filter Parameters, or create and share own miracle',
                'label_no_admin_widget': 'No <strong class="font-color-grid">GRID</strong> widgets',
                'label_no_admin_widget_comment': 'There is not any widgets to check and valid',

                'label_grid_widget_properties': 'Widget Properties',

            }, 'ProjectsProjectWidgetsWidgetComponent': {

                'title': 'Skunks<strong class="font-color-grid">GRID</strong> - WIDGET <strong>{0}</strong>',
                'main_title': 'Platform administration',
                'main_subtitle': 'Widget Editor',

                'flash_version_save_success': 'Version <b>{0}</b> saved successfully.',
                'flash_version_save_fail': 'Unable to save version <b>{0}</b>, {1}.',
                'flash_version_removed_success': 'Version has been removed.',
                'flash_version_removed_fail': 'Unable to remove version, ',
                'flash_version_changed_success': 'Version {0} has been changed.',
                'flash_version_changed_fail': 'Unable to change version <b>{0}</b>, {1}.',
                'flash_version_load_fail': 'Unable to load version <b>{0}</b>, {1}.',
                'flash_widget_removed_success': 'Widget has been removed.',
                'flash_widget_removed_fail': 'Unable to remove widget, ',
                'flash_widget_edit_success': 'Widget has been edited.',
                'flash_widget_edit_fail': 'Unable to edit widget, ',

                'tab_ide': 'IDE',
                'tab_versions': 'Versions',
                'label_console_cant_crate_widget': '<strong>Cannot create widget:</strong> Make sure, that you specified size profiles for widget',
                'label_widget_properties': 'GRID widget properties',
                'label_remove_widget': 'GRID widget properties',
                'label_widget_code': 'Widget Code',
                'label_name': 'Name',
                'label_properties': 'Edit',
                'label_remove': 'Remove',
                'label_description': 'Description',
                'label_grid_group_name': '<strong class="font-color-grid">GRID</strong> Widget Group',
                'label_version': 'Version',
                'label_ide': 'IDE',
                'label_build_errors': 'Build Errors',
                'label_save': 'SAVED VERSIONS',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove version',
                'label_console_output': 'Output <strong> {0} </strong> = {1}',
                'label_console_true': '<span class="bold font-red">true</span>',
                'label_console_false': '<span class="bold font-blue"> false </span>',
                'label_grid': 'GRID',

                'table_name': 'Name',
                'table_version_name': 'Name',
                'table_description': 'Description',
                'table_last_updated': 'Last Updated',
                'table_author': 'Author',
                'table_actions': 'Actions',

                'table_status' : 'Status',

                'label_publish': 'Publish',
                'label_publish_decision': 'Publication Decision',
                'label_version_set_as_main': 'Set as main',

                'label_program_versions': 'No <strong class="font-color-blocko">WIDGET</strong> version',
                'label_program_versions_comment': 'Save your code first, and you will never see this screen again',

            }, 'ProjectsProjectActualizationProceduresProcedureComponent': {
                'title': 'Actualization Procedure',
                'table_finished': 'Finished',
                'table_created': 'Created',
                'table_id': 'Task ID',
                'table_planed': 'Planed',
                'table_hardware': '<strong class="font-color-hardware">Hardware</strong>',
                'table_hardware_full_id': '<strong class="font-color-hardware">Full ID</strong>',
                'table_program': 'Program',
                'table_version': 'Version',
                'table_status': 'Status',
                'table_update_type': 'Update Type',
                'table_firmware_type': 'Type',
                'table_update_progress': 'Progress',
                'table_update_state': 'State',
                'table_hardware_id': 'Hardware ID',
                'label_code_program': '<strong class="font-color-code">Code</strong> Program',
                'label_code_version': '<strong class="font-color-code">Code</strong> Version',
                'label_bootloader_version': '<strong class="font-color-code">Bootloader</strong> Version',
                'table_actions': 'Actions',
                'table_project': 'Project',
                'table_started': 'Created',
                'table_type': 'Update type',
                'label_finished': 'Finished',
                'label_time_missing_in_json': 'Not Yet',
                'label_created': 'Created',
                'label_planed': 'Planed',
                'label_update_type': 'Update Type',
                'label_firmware': 'FIRMWARE',
                'label_bootloader': 'BOOTLOADER',
                'label_backup': 'BACKUP',
                'label_action_updates': 'Actions',
                'label_cancel_updates' : 'Cancel Update',
                'label_open_updates': 'Show details',
                'label_close_updates': 'Hide details',
                'label_no_running_update_on_hardware': 'Not any <strong class="font-color-blocko">HARDWARE</strong> updates',
                'label_no_running_update_on_hardware_comment': 'It\'s weird, but this update procedure does not contain any updates. <br> This is exactly the moment when you should think about yourself or contact technical support.',

            }, 'ProjectsProjectBlockoComponent': {
                'title': 'Skunks<strong class="font-color-blocko">BLOCKO</strong> - ALL PROGRAMS',

                'label_blocko_remove': 'Remove Program',
                'label_blocko_program_properties': 'Program properties',
                'label_blocko_make_copy': 'Make copy',
                'label_no_blocko_program': 'No <strong class="font-color-blocko">BLOCKO</strong> programs available.',
                'label_no_blocko_program_comment': 'There is no BLOCKO programs',
                'label_create_blocko_text': 'Click on the button bellow to create your first a program or use public one.',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'btn_add_blocko_program': 'New Program',
                'label_blocko_documentation_description': '<p><strong class="font-color-blocko">BLOCKO</strong> is a web-based editor developed for creating CLOUD decision server apps. By combining individual <strong>BLOCKS</strong> together, you can create a very complex logic. Actions and reactions. Storage of data. Performing commands when combining conditions. Work with a small Set of Hardware or create logic behavior for thousands of Hardware Devices and links to third-party services.<br><br><strong class="font-color-blocko">BLOCKO</strong> is stored at the end and compiled into a self-running container. We call it an <strong class="font-color-instance">INSTANCE</strong>, which you can upload to the Server.</p><p>The <strong class="font-color-blocko">BLOCKO</strong> program is created without parameters. That means, that you making logic for anonymous hardware. As you create your own Instance, you assign anonymous Blocks representing <strong class="font-color-hardware">HARDWARE</strong> to the specific <strong class="font-color-hardware">HARDWARE</strong> you own. So, you can use the same Blocko program for dozens of different Hardware groups across the world.</p>',
                'tab_my_b_programs': 'My Programs',
                'tab_public_b_programs': 'Public Programs',
                'flash_blocko_remove': 'Program has been removed.',
                'flash_blocko_cant_remove': 'Unable to remove program, ',
                'flash_blocko_add_to_project': 'BLOCKO program {0} has been added to your project.',
                'flash_blocko_cant_add_to_project': 'Unable to add BLOCKO program {0} to your project, {1}.',
                'flash_blocko_update': 'Program has been updated.',

            }, 'ProjectsProjectBlocksBlockComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Block IDE',
                'title': 'Skunks<strong class="font-color-blocko">BLOCKO</strong> - BLOCK',
                'label_name': '<strong class="font-color-blocko">BLOCKO</strong> Block Name:',
                'label_description': '<strong class="font-color-blocko">BLOCKO</strong> Block Description:',
                'label_group': '<strong class="font-color-blocko">BLOCKO</strong> Block Group Name',
                'label_console_output': 'Output <strong>{0}</strong> = {1}',

                'tab_ide': 'IDE',
                'tab_configuration': 'Configuration',
                'tab_diff': 'Diff',
                'tab_versions': 'Versions',
                'label_properties': 'Settings',
                'label_remove': 'Remove',
                'label_group_remove': 'Remove BLOCKO block',
                'label_code': 'IDE',
                'label_version': 'Version',
                'label_block_name': 'Display name',
                'label_block_color': 'Colour',
                'label_block_icon': 'Icon',
                'label_block_description': 'Description',
                'label_block_code': 'Code',
                'label_test': 'Test',
                'label_inputs_simulator': 'Inputs simulator',
                'label_value': 'Value',
                'label_datatype_boolean': 'boolean <strong>Arg #{0}</strong>',
                'label_datatype_float': 'float <strong>Arg #{0}</strong>',
                'label_datatype_integer': 'integer <strong>Arg #{0}</strong>',
                'label_datatype_string': 'string <strong>Arg #{0}</strong>',
                'label_block': 'block',
                'label_program_versions': 'No <strong class="font-color-blocko">BLOCK</strong> version',
                'label_program_versions_comment': 'Save your code first, and you will never see this screen again',
                'label_saved_versions': 'Saved versions',
                'label_device_properties': 'Device properties',
                'label_remove_device': 'Remove device',
                'label_block_code_change': 'Block code has been changed, you must test the program first, then save it.',
                'label_icon_svg': 'SVG icon',
                'label_block_make_copy': 'Make Copy',

                'btn_upload_svg': 'Upload SVG file',

                'table_name': 'Name',
                'table_version_name': 'Name',
                'table_description': 'Description',
                'table_last_updated': 'Last Updated',
                'table_author': 'Author',
                'table_actions': 'Actions',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove version',
                'table_status': 'Status',

                'ts_error_typescript_error': 'TypeScript Error',
                'ts_error_block_error': 'Block Error',
                'ts_error_block_error_code_empty': 'Block code cannot be empty',

                'bool_true': 'true',
                'bool_false': 'false',

                'flash_version_save': 'Version <b> {0} </b> saved successfully.',
                'flash_block_code_empty': 'Block code cannot be empty! Block error, {0}.',
                '!!!flash_blocko_removed': 'Blocko program has been successfully deleted',
                'flash_cant_load_block': 'Unable to load block, ',
                'flash_blocko_edit': 'Block has been edited.',
                'flash_cant_edit_block': 'Unable to edit block, ',
                'flash_block_remove': 'Block has been removed.',
                'flash_cant_remove_block': 'Unable to remove block, ',
                'flash_version_remove': 'Version has been removed.',
                'flash_version_change': 'Version {0} has been changed.',
                'flash_cant_load_block_version': 'Unable to load block version, ',
                'label_publish': 'Publish',
                'label_publish_decision': 'Publication Decision',

            }, 'ProjectsProjectBlocksComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Community Blocks Administration',
                'title': 'Skunks<strong class="font-color-blocko">BLOCKO</strong> Blocks',
                'label_block_remove': 'Remove Block',
                'label_block_make_copy': 'Make Copy',
                'label_activate': 'Active for user',
                'label_deactivate': 'Deactivate it for users',
                'label_block_properties': 'Update Block',
                'label_block_make_decision': 'Make Decision',
                'table_name': 'Name',
                'table_active': 'Active for Users',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'label_no_blocks': 'No <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_blocks_comment': 'You havn\'t yet created your own <strong class="font-color-blocko">BLOCK</strong>. It\'s time to grow up... It\'s time to start connecting the world.',
                'label_no_public_blocks': 'No <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_public_blocks_text': 'Based on specified criteria, there is no public or available <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_admin_blocks': 'No <strong class="font-color-blocko">BLOCKO</strong> blocks',
                'label_no_admin_blocks_comment': 'There is not any blocks to check and valid',
                'btn_add_block': 'Create Block',
                'tab_my_blocks': 'My Blocks',
                'tab_public_blocks': 'Public Blocks',
                'tab_admin_blocks': 'Blocks for Publish Decisions',

                'flash_block_add': 'Block has been created.',
                'flash_cant_add_block': 'Unable to create block, ',
                'flash_block_edit': 'Block has been edited.',
                'flash_cant_edit_block': 'Unable to edit block, ',
                'flash_block_remove': 'Block has been removed.',
                'flash_cant_remove_block': 'Unable to remove block, ',

            }, 'ProjectsProjectCodeCodeComponent': {

                'title': 'Skunks<strong class="font-color-code">CODE</strong> <strong>IDE</strong>',
                'main_title': 'Platform administration',
                'main_subtitle': 'Code Editor',
                'btn_label_option': 'Option',
                'label_library_version': 'Library Version',
                'label_program_properties': 'Settings',
                'label_remove_program': 'Remove',
                'label_make_copy': 'Make a Copy',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove CODE version',
                'label_code_name': '<strong class="font-color-code">CODE</strong> Program Name: <strong>{0}</strong>',
                'label_code_description': '<strong class="font-color-code">CODE</strong> Program Description: <strong>{0}</strong>',
                'label_hardware_type': '<strong class="font-color-hardware">Hardware</strong> Type',
                'tab_ide': 'IDE',
                'tab_diff': 'Diff',
                'tab_label_version': 'Saved Versions',
                'tab_error_list': 'Build Errors',
                'tab_online_terminal': 'Terminal',
                'tab_versions': 'Versions',
                'label_file_and_line': 'File: <b>{0}</b> Line: <b>{1}</b>',
                'label_saved_versions': 'SAVED VERSIONS',
                'label_public_comment': 'This is public Program, you can only test program or make a copy.',

                'label_no_c_program_versions': 'No <strong class="font-color-code">Version</strong> found',
                'label_no_c_program_versions_comment': 'Create a new one!',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_last_updated': 'Last Updated',
                'table_lib_version': 'Library Version',
                'table_gcc_version': 'GCC Version',
                'table_author': 'Author',
                'table_status': 'Status',
                'table_actions': 'Actions',

                'btn_build': 'Build',
                'btn_upload_to_hardware': 'Deploy to HARDWARE',

                'codefile_library_version_dont_have_readme': '{0} ({1}), # Library {2}\n\nVersion: {3}\n\nLooks like this library doesn\'t have README.md file.',
                'codefile_library_version_short_dont_have_readme': '# Library {0}\n\nVersion: {1}\n\nLooks like this library doesn\'t have README.md file.',
                'text_unsaved_change_reload': 'You have <b> unsaved changes</b> in this version <b> {0} </b>, are you sure to reload this version?',
                'text_unsaved_change_switch': 'You have <b> unsaved changes</b> in this version <b> {0} </b>, are you sure to switch to version <b> {1} </b>?',
                'text_changed_files': '<h5>Changed files:</h5>',

                'modal_label_save_same_code': 'Save identical code?',
                'modal_text_no_change': 'No changes have been made, are you sure to save this code?',
                'modal_label_error': 'Error',
                'modal_text_no_yoda': 'No available devices.',

                'label_main_c_program_version_set_as_main': 'Set this version as Main',
                'label_publish': 'Make it Public',
                'label_publish_decision': 'Publication Decision',

                'flash_update_success': 'Deployed successfully',
                'flash_cant_upload_code': 'Deployment failed.',
                'flash_code_was_publisher': 'Your Community publish request was successful!.',
                'flash_code_publish_error': 'Deployment failed.',
                'flash_code_version_build_success': 'Built successfully.',
                'flash_code_version_build_warnings': 'Built with warnings, see below.',
                'flash_code_version_build_failed': 'Build failed, see errors below',
                'flash_code_version_save': 'Version <b> {0} </b> saved successfully.',
                'flash_cant_save_code_version': 'Unable to save version <b>{0}</b>, {1}',
                'flash_code_remove': 'Program has been removed.',
                'flash_code_update': 'Program has been updated.',
                'flash_code_version_remove': 'Version has been removed.',
                'flash_file_uploaded': 'File successfully uploaded',
                'flash_cant_load_code_types': 'Unable to load code types, {0}.',

                'label_download_file': 'Download',
                'label_upload_file': 'Upload',

            }, 'ProjectsProjectCodeLibraryComponent': {

                'title': 'Skunks<strong class="font-color-code">CODE Library</strong> <strong>IDE</strong>',
                'main_title': 'Platform administration',
                'main_subtitle': 'Code Editor',
                'btn_label_option': 'Option',
                'label_library_version': 'Library Version',
                'label_program_properties': 'Settings',
                'label_remove_program': 'Remove',
                'label_make_copy': 'Make a Copy',
                'label_version_properties': 'Version properties',
                'label_remove_version': 'Remove CODE version',
                'label_code_name': '<strong class="font-color-code">CODE</strong> Program Name: <strong>{0}</strong>',
                'label_code_description': '<strong class="font-color-code">CODE</strong> Program Description: <strong>{0}</strong>',
                'label_hardware_type': '<strong class="font-color-hardware">Hardware</strong> Type',
                'tab_ide': 'IDE',
                'tab_diff': 'Diff',
                'tab_label_version': 'Saved Versions',
                'tab_error_list': 'Build Errors',
                'tab_online_terminal': 'Terminal',
                'tab_versions': 'Versions',
                'label_file_and_line': 'File: <b>{0}</b> Line: <b>{1}</b>',
                'label_saved_versions': 'SAVED VERSIONS',
                'label_public_comment': 'This is public Program, you can only test program or make a copy.',

                'label_no_c_program_versions': 'No <strong class="font-color-code">Version</strong> found',
                'label_no_c_program_versions_comment': 'Create a new one!',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_last_updated': 'Last Updated',
                'table_lib_version': 'Library Version',
                'table_gcc_version': 'GCC Version',
                'table_author': 'Author',
                'table_status': 'Status',
                'table_actions': 'Actions',

                'btn_build': 'Build',
                'btn_upload_to_hardware': 'Deploy to HARDWARE',

                'codefile_library_version_dont_have_readme': '{0} ({1}), # Library {2}\n\nVersion: {3}\n\nLooks like this library doesn\'t have README.md file.',
                'codefile_library_version_short_dont_have_readme': '# Library {0}\n\nVersion: {1}\n\nLooks like this library doesn\'t have README.md file.',
                'text_unsaved_change_reload': 'You have <b> unsaved changes</b> in this version <b> {0} </b>, are you sure to reload this version?',
                'text_unsaved_change_switch': 'You have <b> unsaved changes</b> in this version <b> {0} </b>, are you sure to switch to version <b> {1} </b>?',
                'text_changed_files': '<h5>Changed files:</h5>',

                'modal_label_save_same_code': 'Save identical code?',
                'modal_text_no_change': 'No changes have been made, are you sure to save this code?',
                'modal_label_error': 'Error',
                'modal_text_no_yoda': 'No available devices.',

                'label_main_c_program_version_set_as_main': 'Set this version as Main',
                'label_publish': 'Make it Public',
                'label_publish_decision': 'Publication Decision',

                'flash_update_success': 'Deployed successfully',
                'flash_cant_upload_code': 'Deployment failed.',
                'flash_code_was_publisher': 'Your Community publish request was successful!.',
                'flash_code_publish_error': 'Deployment failed.',
                'flash_code_version_build_success': 'Built successfully.',
                'flash_code_version_build_warnings': 'Built with warnings, see below.',
                'flash_code_version_build_failed': 'Build failed, see errors below',
                'flash_code_version_save': 'Version <b> {0} </b> saved successfully.',
                'flash_cant_save_code_version': 'Unable to save version <b>{0}</b>, {1}',
                'flash_code_remove': 'Program has been removed.',
                'flash_code_update': 'Program has been updated.',
                'flash_code_version_remove': 'Version has been removed.',
                'flash_file_uploaded': 'File successfully uploaded',
                'flash_cant_load_code_types': 'Unable to load code types, {0}.',

                'label_download_file': 'Download',

            } , 'ProjectsProjectCodeComponent': {
                'title': 'Skunks<strong class="font-color-code">CODE</strong> - ALL PROGRAMS',
                'label_no_code': 'No <strong class="font-color-code">CODE</strong> Program',
                'label_no_code_comment': 'Time to create another miracle.. or to find an alternative path to your nerd life',
                'label_remove_code_program': 'Remove CODE program',

                'label_create_code_text': 'Click on the button bellow to create a program.',
                'btn_add_code_program': 'Create new Program',

                'label_no_public_code': 'No <strong class="font-color-code">CODE</strong> programs available.',
                'label_create_public_code_text': 'If you do not see your dream program, create it and share it with others',

                'table_name': 'Name',
                'table_hardware_type': '<strong class="font-color-hardware">Hardware</strong> device type',
                'table_no_hardware_type': 'No hardware type',
                'table_description': 'Description',
                'table_actions': 'Actions',

                'tab_my_programs': 'My Program',
                'tab_public_c_programs': 'Public Programs',
                'label_code_copy': 'Make Copy',

                'flash_code_update': 'Program has been updated.',
                'flash_code_add_to_project': 'CODE program {0} has been added to your project.',
                'flash_cant_add_code_to_project': 'Unable to add CODE program to your project.',
                'flash_code_remove': 'Program has been removed.',
                'label_code_program_properties': 'Code Properties'

            }, 'ProjectsProjectGridGridsGridComponent': {
                'title': 'Skunks<strong class="font-color-grid">GRID</strong>',

                'label_program_properties': 'GRID Program properties',
                'label_program_delete': 'Remove GRID program',
                'label_version_properties': 'Version properties',
                'label_version_remove': 'Remove version',
                'label_version': 'Version',
                'label_grid_size_class': 'GRID size class',
                'label_saved_versions': 'Saved versions',
                'label_grid_program_name': '<strong class="font-color-grid" >GRID </strong> Program Name: <strong>{0}</strong>',
                'label_grid_description': '<strong class="font-color-grid" >GRID </strong> Program Description: <strong>{0}</strong>',
                'label_grid_grid_project': '<strong class="font-color-grid" >GRID </strong> Program Group Name',
                'label_grid': 'GRID',
                'label_widgets': 'Widgets',
                'label_no_widgets': 'No <strong class="font-color-grid" > GRID </strong> widgets in this group',
                'label_device_properties': 'Device properties',
                'label_device_remove': 'Remove Device',
                'label_version_name': 'Name',
                'table_actions': 'Actions',
                'table_last_updated': 'Last Updated',
                'label_description': 'Description',
                'label_author': 'Author',
                'label_actions': 'Actions',
                'label_program_versions': 'No <strong class="font-color-grid" >GRID</strong> versions',
                'label_program_versions_comment': 'Add some <strong class="font-color-grid" >Widgets</strong> on <strong>Screen</strong> and save it',

                'option_mobile': 'Mobile',
                'option_tablet': 'Tablet',
                'option_desktop': 'Desktop',

                'tab_ide': 'IDE',
                'tab_versions': 'Versions',

                'btn_add_page': 'New Page',
                'btn_clear_console': 'Clear Console',
                'btn_program_properties': 'Update App',
                'btn_program_delete': 'Delete App',
                'modal_label_grid_size_change': 'Change <strong class="font-color-grid">GRID</strong> size class',
                'modal_text_grid_size_change': 'Changing <strong class="font-color-grid">GRID</strong> size class <strong>will delete all your pages</strong>, are you sure?',

                'flash_version_save': 'Version <b>{0}</b> saved successfully.',
                'flash_grid_remove': 'Program has been removed.',
                'flash_grid_edit': 'Program has been edited.',
                'flash_version_change': 'Version {0} has been changed.',
                'flash_version_remove': 'Version has been removed.',

            }, 'ProjectsProjectGridGridsComponent': {
                'title': 'Skunks<strong class="font-color-grid">GRID</strong>',

                'btn_edit_project': 'Edit Apps Project',
                'btn_remove_project': 'Remove Group',
                'btn_add_grid_program': 'Create New App',
                'btn_label_option': 'Option',

                'label_no_grid_programs': 'No <strong class="font-color-grid">GRID</strong> programs available',
                'label_no_grid_programs_comment': 'Click on the button bellow to create a new one',

                'label_grid_remove': 'Remove App',
                'label_grid_properties': 'Properties',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'flash_grid_project_remove': 'Project has been removed.',
                'flash_grid_project_edit': 'Project has been edited.',
                'flash_grid_program_add': 'Program has been created.',
                'flash_grid_program_edit': 'Program has been edited.',
                'flash_grid_program_remove': 'Program has been removed.',

            }, 'ProjectsProjectGridComponent': {

                'title': 'Skunks<strong class="font-color-grid">GRID</strong> - ALL PROGRAM GROUPS',
                'btn_add_grid_project': 'Create GRID program group',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',

                'label_group_properties': 'Group properties',
                'label_group_remove': 'Remove Grid Group',
                'label_no_grid_projects': 'No <strong class="font-color-grid">GRID</strong> projects available',
                'label_no_grid_projects_comment': 'Click on the button bellow to create a project.',

                'flash_grid_project_add': 'Project has been created.',
                'flash_cant_add_grid_project': 'Unable to create project.',
                'flash_grid_project_edit': 'Project has been edited.',
                'flash_grid_project_remove': 'Project has been removed.',

            }, 'ProjectsProjectHardwareAddWithQrComponent': {
                'label_hw_scaned' : 'Loading',
                'label_hw_added' : 'Added',
                'label_info' : 'Status',
                'label_hw_id': 'Code',
                'label_hardware_groups': 'Hardware group',
                'main_title': 'Add Hardware',
                'title': 'Scan QR',

            }, 'SupportComponent': {

                'main_title': 'Support',
                'tab_knowledge_base': 'Knowledge Base',
                'tab_tickets': 'My Tickets',
                'label_faq': 'FAQ',
                'label_documentation': 'Documentation',
                'label_open_ticket': 'Open Ticket',
                'knowledge_base': 'Knowledge Base',
                'view_more': 'View More',
                'label_no_tickets': 'No TICKETS available',
                'label_no_tickets_comment': 'There is no any tickets. You can create, just press button and see.',
                'btn_create_ticket': 'Create Ticket',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_hardware_type': 'Hardware Type',
                'table_actions': 'Action',
            }, 'ValidatorErrorsService': {
                'label_field_required': 'This field is required.',
                'label_minimal_length': 'Minimal length of this field is {0} characters.',
                'label_maximal_length': 'Maximal length of this field is {0} characters.',
                'label_minimal_value': 'Minimal value of this field is {0}.',
                'label_maximal_value': 'Maximal value of this field is {0}.',
                'label_name_taken': 'This name is already in use.',
                'label_required_pattern': ' Invalid Patter - Required only Small letters (a-z), number (0-9) or symbols (-_)  (NO SPACE!)',
                'label_invalid_uuid': 'Invalid UUID format. Substring not supported.',

                'label_project_name_taken': 'This project name is already in use.',
                'label_homer_server_name_taken': 'This Server name is already in use.',
                'label_compilation_server_name_taken': 'This Server name is already in use.',
                'label_hardware_name_taken': 'This Hardware name is already in use.',
                'label_program_name_taken': 'This Name is already in use.',

                'label_bprogram_name_taken': 'Name of Blocko program in this Project is already in use.',
                'label_cprogram_name_taken': 'Name of Code program in this Project is already in use.',
                'label_grid_program_name_taken': 'Name of Grid program in this Project is already in use.',
                'label_gsm_name_taken': 'Name of GSM in this Project must be unique',
                'label_role_name_taken': 'Name of Role in this Project must be unique',
                'label_block_name_taken': 'Name of Block in this Project must be unique',
                'label_widget_name_taken': 'Name of Widget in this Project must be unique',
                'label_instance_name_taken': 'Name of Instance in this Project must be unique',
                'label_snapshot_name_taken': 'Name of Snap Shot in this Instance must be unique',
                'label_grid_project_name_taken': 'Name of Grid Collection in this Project must be unique',
                'label_grid_program_version_name_taken': 'Version name must be unique for this program',
                'label_cprogram_version_name_taken': 'Version name must be unique for this program',
                'label_bprogram_version_name_taken': 'Version name must be unique for this program',
                'label_database_collection_name_taken': 'Collection in Database must be unique',
                'label_database_name_taken': 'Name of Database must be unique',
                'label_hardware_group_name_taken': 'Name of Hardware group in this Project is already in use.',

                'label_invalid_email': 'Invalid E-mail address.',
                'label_invalid_time': 'Invalid Time, please write time in XY:YX format',
                'label_different_password': 'Passwords do not match.',
                'label_invalid_file_name': 'Invalid file/directory name.',
                'label_field_only_number': 'This field only accepts numbers.',
                'label_field_only_float_number': 'This field only accepts real numbers.',
                'label_field_invalid_url': 'Invalid URL.',
                'label_unknown_error': 'Unknown error.',
                'label_forbidden_symbol': 'This symbol is not allowed.',

            }, 'TabMenuService': {
                'label_tab_menu_not_found': 'Unable to find TabMenu named {0}.',

            }, 'NotificationService': {
                'flash_communication_failed': 'Connection to Server for Real Time synchronization and Notification subscription failed.',

            }, 'ModalService': {
                'error_missing_modal': 'Missing modal model.',
                'error_modal_already_open': 'This modal model instanace is already open.',

            }, 'ExitConfirmationService': {
                'dialog_discard_changes': 'Discard changes and leave?',
                'confirm_discard_changes': 'Are you sure to leave your unfinished work unsaved?',

            }, 'BackendService': {
                'error_content_not_supported': 'Content type not supported.',

            }, 'LayoutNotLoggedComponent': {
                'label_becki_version': 'Version: {0} ID: {1} Date: {2}',
                'label_skunks': 'Skunks',
                'made_by': 'Made by'

            }, 'RoleGroupGroupComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Role Settings',

                'title': 'Role settings',

                'tab_permissions': 'Permissions',
                'tab_persons': 'Users',

                'label_permission': 'Permission',
                'label_person': 'Person',
                'label_remove_role': 'Remove Role',
                'label_person_add': 'Add Person',
                'label_permission_add': 'Add Permission',

                'label_no_person': 'No Users',
                'label_no_person_comment': 'We didn\'t find any users, add new one (already remigrated)',
                'label_no_permission': 'No Permissions',
                'label_no_permission_comment': 'We didn\'t find any permision, add new one (already generated by system)',
                'label_cannot_remove_yourself': 'You can`t remove yourself from your project.',


                'label_name': 'Name',
                'label_email': 'Email',
                'label_description': 'Description',
                'label_actions': 'Actions',
                'label_person_remove': 'Remove Person from Role',
                'label_permission_remove': 'Remove permission',
                'label_action': 'Action',
                'label_target': 'Target',
                'btn_add_person': 'Add Person',
                'btn_add_permission': 'Add Permission',
                'table_actions': 'Actions',
            },

            'LayoutMainComponent': {
                'label_number_of_unread': '{0} unread',
                'label_notifications': 'notifications',
                'label_profile': 'Account Settings',
                'label_log_out': 'Log out',
                'label_view_all': 'view all',
                'label_made_by': 'Made by',
                'label_skunks': 'Skunks',

            }, 'MonacoEditorComponent': {
                'error_cant_change_editor_language': 'Unable to change editor language.',

            }, 'NotificationsListComponent': {
                'label_no_notifications': 'No notifications.',
            },
            'NothingToShowComponent' : {
                'loading' : 'Loading'
            },
            'ContactFormComponent': {
                'label_company_account': 'Company account',
                'label_full_name': 'Full name',
                'label_company_name': 'Company name',
                'label_street': 'Street name',
                'label_street_number': 'Street number',
                'label_city': 'City',
                'label_zip_code': 'Zip code',
                'label_country': 'Country',
                'label_registration_number': 'Registration number',
                'label_vat_number': 'VAT number (with country code)',
                'label_company_web': 'Company website',
                'label_company_email': 'Company authorised contact E-mail',
                'label_company_phone': 'Company authorised contact Phone number',
                'label_invoice_email': 'Invoice E-mail'

            }, 'ContactTableComponent': {
                'label_title': 'Contact',
                'label_edit': 'Edit Contact',
                'label_invoice_email': 'Invoice E-mail',
                'label_full_name': 'Full name',
                'label_company_name': 'Company name',
                'label_street': 'Street name',
                'label_street_number': 'Street number',
                'label_city': 'City',
                'label_zip_code': 'Zip code',
                'label_country': 'Country',
                'label_registration_number': 'Registration number',
                'label_vat_number': 'VAT number (with country code)',
                'label_company_web': 'Company website',
                'label_company_email': 'Company authorised contact E-mail',
                'label_company_phone': 'Company authorised contact Phone number',
                'label_not_created': 'Not yet created'

            }, 'PaymentDetailsFormComponent': {
                'label_payment_method': 'Payment method'

            }, 'PaymentDetailsTableComponent': {
                'label_title': 'Payment Details',
                'label_edit': 'Edit Payment Details',
                'label_payment_method': 'Payment method',
                'label_not_created': 'Not yet created'

            }, 'BlockoViewComponent': {
                'error_execution_cant_change': 'Unable to change execution status.',
                'error_cant_change_readability': 'Unable to change readability.',
                'error_configuration_cant_change': 'Unable to change configuration status.',
                'error_read_only': 'read only',
                'error_block_not_found': 'block {0} not found',
                'flash_no_config': 'No configuration available.'

            }, 'FormFAIconSelectComponent': {
                'label_unknown_label': 'Unknown label',
                'error_readonly_not_support': 'Read-only is not supported.',

            }, 'FormColorPickerComponent': {
                'error_readonly_not_support': 'Read-only is not supported.',

            }, 'ConsoleLogComponent': {
                'label_console_is_empty': 'Console is empty.',
                'label_position_and_line': '<strong>Position:</strong> line',
                'label_column': 'column',
                'label_line': 'line',
                'label_typescript_error': 'TypeScript Error',
            }, 'CodeIDEComponent': {
                'label_error_not_selected_library': 'No selected <b>library</b> in file tree.',
                'error_missing_folder': 'Missing folder {0} in path {1}',
                'modal_label_error': 'Error',
                'modal_label_cant_add_file_at_path': 'Unable to create file in <b>/{0}</b>. File with the same name already exists.',
                'modal_label_cant_move_directory_at_path': 'Unable to move directory to <b>/{0}</b>. Directory with the same name already exists.',
                'modal_label_cant_move_base_directory': 'Unable to move <b>/</b> directory.',
                'modal_label_cant_move_file_already_exist': 'Unable to move file to <b>/{0}</b>. File with the same name already exists.',
                'modal_label_cant_add_directory_at_path': 'Unable to create directory in <b>/{0}</b>. Directory with the same name already exists.',
                'modal_label_cant_move_directory_to_childern': 'Unable to move directory to its own <b>sub-directory</b>. ',
                'modal_label_cant_rename_directory': 'Unable to rename <b>/</b> directory.',
                'modal_label_cant_rename': 'Unable to rename <b>/{0} </b>.',
                'modal_label_cant_remove_base_directory': 'Unable to remove <b>/</b> directory.',
                'modal_label_cant_remove_file': 'Unable to remove <b>/{0} </b> file.',
                'modal_label_cant_rename_file_already_exist': 'Unable to rename file in <b>/{0}</b>. File with the same name already exists.',
                'modal_label_cant_rename_directory_already_exist': 'Unable to rename directory in <b>/{0}</b>. Directory with the same name already exists.',
                'modal_label_cant_move_file': 'Unable to move <b>/{0}</b>.',

                'label_library': ' library',
                'label_file': ' file',
                'label_panel_hardware': 'Assigned <strong class="font-color-hardware">HARDWARE</strong>',
                'label_panel_blocko_interface_preview': '<strong class="font-color-blocko">BLOCKO</strong> Interface Preview',
                'label_panel_code_settings': 'Settings',
                'label_panel_binary_details': 'Binary details',
                'label_panel_libraries': 'External Libraries',
                'label_panel_files': 'Files',
                'label_panel_macros': 'Build macros',
                'label_library_version': 'Core Library Version',

                'btn_add_file': 'New File',
                'btn_add_directory': 'New Directory',
                'btn_add_library': 'New Library',
                'btn_change_library_version': 'Change Library Version',
                'btn_move': 'Move',
                'btn_diff': 'Diff',
                'label_open_file_browser': 'Open file in file browser',
                'btn_build': 'Build',
                'not_latest_compilation': 'You are not using latest compilation library',
                'not_supported_compilation': 'You are using compilation library which is not supported',
                'flash_clean_success': 'Cleaned successfully.'
            }, 'TableListComponent': {
                'type_of_board_name': 'Type of board',
                'table_name': 'name',
                'table_description': 'description',
                'table_product': 'product',
                'table_actions': 'actions',
            }, 'VersionListComponent': {
                'table_name': 'Name',
                'table_description': 'Description',
                'table_product': 'Product',
                'table_actions': 'Actions',
            }, 'ServerComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Server Management',

                'title': 'List of Servers',
                'tab_homer_server': 'Homer Servers',
                'tab_compilation_server': 'Compilation Servers',
                'label_server_name': 'Server Name',
                'label_server_version': 'Version',
                'label_server_type': 'Server Type',
                'label_server_url': 'Server Link',
                'label_gcc_version': 'GCC Version',
                'label_id': 'Id',
                'table_actions': 'Actions',
                'label_status': 'Status',
                'label_actions': 'Actions',
                'label_no_servers': 'No servers',
                'label_no_servers_comment': 'We did not find any server that we could show you',
                'label_loading': 'Loading',
                'btn_create_server': 'Create server',

                'label_server_update': 'Update',
                'label_server_properties': 'Properties',
                'label_delete_server': 'Remove',


            }, 'UpdateStateComponent': {
                'error_code': 'Error Code:',
                'error': 'Error Message:',

            }, 'AdminDashboardComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Admin Dashboard',

                'title': 'Basic Overview',

                'label_homer_server': 'Homer servers',
                'label_compilation_server': 'Compilation Servers',
                'label_person_registered': 'Person registered',
                'label_project_registered': 'Project registered',
                'label_bug_registered': 'Bugs auto cached',
                'label_hardware_registered': 'Hardware registered',
                'btn_clear_cache': 'Clean Cache',

            }, 'AdminHardwareComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Hardware Management',

                'title': 'Hardware Overview of all components',
                'label_name': 'Name (alias)',
                'label_description': 'Description',
                'label_version_identifier': 'Identifier',
                'label_changing_note': 'Changelog',
                'label_download_file': 'Download file',
                'label_file': 'File',
                'label_no_file': 'No Source File!',
                'label_file_exist': 'File Uploaded',
                'label_target_name': 'Target',
                'label_bootloader': 'Bootloader',
                'label_default_firmware': 'Default Firmware',
                'label_test_firmware': 'Test firmware',

                'tab_hardware_list': 'Hardware List (Filter)',
                'tab_hardware_registration_hash': 'Registration Hash\'s',
                'tab_hardware_type': 'Hardware Types',
                'tab_processor': 'Processors',
                'tab_producers': 'Producers',
                'tab_libraries': 'Compilation Libraries',
                'label_producer': 'Producer',
                'label_processor': 'Processor',
                'label_actions': 'Actions',
                'label_project': 'Project',

                'label_table_id': 'UUID ID',
                'label_table_full_id': 'Processor ID <strong class="font-red-flamingo"> It can be repeated!</strong>',
                'label_table_type': '<strong class="font-color-hardware">Hardware</strong> Type',
                'label_hash': 'Hash Token',
                'label_table_status': 'Online status',

                'label_no_type_of_boards': 'No strong <strong class="font-color-hardware">HARDWARE</strong> Types',
                'label_no_type_of_boards_comment': 'No accessible <strong>Hardware</strong> Type, create new one. You also have to create Processor and Producer',
                'label_no_boards': 'There is no <strong class="font-color-hardware">HARDWARE</strong>',
                'label_no_boards_comment': 'Change filter parameters to find more items. Attention! The hardware in the list is only the one that was registered to the project by the user!',
                'label_no_producer': 'No Producers',
                'label_no_producer_comment': 'You can create new Producer, it can by your client or you!',
                'label_no_processor': 'No Processors',
                'label_no_processor_comment': 'You can create new Processor',
                'label_no_library': 'No Libraries',
                'label_no_library_comment': 'You can create new Compilation Library',
                'label_speed': 'Speed',
                'label_library': 'Compilation Library',
                'label_library_comment': 'Upload compilation library ZIP file',

                'btn_label_create_manually_hardware': 'Manual HW Registration',
                'btn_label_hardware_hash': 'Find HASH',
                'btn_label_synchronize_hardware': 'Synchronize with CA',
                'btn_label_create_type_of_board': 'Create',
                'btn_label_create_producer': 'Create',
                'btn_label_create_processor': 'Create',
                'btn_label_create_library': 'Create',
                'btn_upload_file': 'Upload file',
                'btn_download_file': 'Download file',


                'label_Project_Properties': 'Settings',
                'label_print_stickers': 'Print Stickers',
                'label_device_properties': 'Hardware Properties',
                'label_remove_device': 'Remove Hardware',

                'label_type_of_board_properties': 'Hardware Type Properties',
                'label_type_of_board_remove': 'Hardware Type Remove',

                'label_producer_properties': 'Producer Properties',
                'label_producer_remove': 'Producer Remove',

                'label_processor_properties': 'Processor Properties',
                'label_processor_remove': 'Processor Remove',
                'table_actions': 'Actions',

                'flash_file_uploaded': 'File was uploaded'

            }, 'CommunityBProgramBlocksComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Community BLOCKO management (decision tool)',
                'title': 'Community Content',
                'tab_public_b_programs': 'Public Blocko',
                'tab_admin_b_programs': 'Admin Blocko Requests',
                'tab_public_blocks': 'Public Blocks',
                'tab_admin_blocks': 'Admin Blocks Requests',
                'table_name': 'Name',
                'table_description': 'Description',
                'table_actions': 'Actions',
                'label_block_properties': 'Properties',
                'label_blocko_program_properties': 'Properties',
                'label_blocko_remove': 'Remove',
                'label_block_remove': 'Remove',

                'label_no_blocko_program': 'No <strong class="font-color-blocko">BLOCKO</strong> programs available.',
                'label_no_blocko_comment': 'There is no BLOCKO programs here',

                'label_no_admin_blocko_comment': 'There is no BLOCKO programs to check and validate',

                'label_no_admin_blocks' : 'No <strong class="font-color-blocko">BLOCKO BLOCKS</strong> available.',
                'label_no_admin_blocks_comment' : 'There is not any blocks to check and validate',

                'label_no_public_blocks': 'No <strong class="font-color-blocko">BLOCKS</strong>',
                'label_no_public_blocks_text': 'Based on specified criteria, there is no public or available <strong class="font-color-blocko">BLOCKS</strong>',


            }, 'CommunityCProgramComponent': {
                'main_title': 'Platform administration',
                'main_subtitle': 'Community C# management (decision tool)',
                'title': 'Community Content',

                'tab_public_c_programs': 'Public Code Program',
                'tab_admin_c_programs': 'Code Program for Publish decision',
                'tab_public_c_libraries': 'Public Code Libraries',
                'tab_c_programs_for_decisions': 'Admin Code Requests',
                'tab_c_libraries_for_decisions': 'Admin Libraries Requests',

                'table_name': 'Name',
                'table_description': 'Description',
                'table_hardware_type': 'HardwareType',
                'table_actions': 'Actions',

                'flash_code_add': 'Community Code Add. Don\'t forget to set first Version and publish program',
                'flash_cant_add_code_to_project': 'Unable to add CODE program to your project.',

                'label_no_c_programs': 'No <strong class="font-color-code">CODE</strong> Programs',
                'label_no_c_programs_comment': 'No <strong>Code</strong> for decision or change Filter property',

                'label_no_c_library': 'No <strong class="font-color-code">CODE</strong> libraries ',
                'label_no_c_library_comment': 'Create new <strong>Library</strong>, or change Filter property',

                'label_code_program_properties': 'Program properties',
                'label_remove_code_program': 'Remove CODE program',
                'label_properties': 'Settings',

            }, 'BugsBugComponent': {
                'main_title': 'Platform administration',
                'title': 'Bug',

                'label_report_bug': 'Report to YouTrack',
                'label_view_bug': 'View on YouTrack',
                'label_remove_bug': 'Remove bug',
                'label_repetition': 'Occurrences',
                'label_stack_trace': 'Stack Trace',
                'label_cause': 'Cause',
                'label_type': 'Exception type',
                'label_server': 'Server version',
                'label_cause_type': 'Cause type',
                'label_cause_message': 'Cause message',
                'label_request': 'Request'
            }, 'ProjectsProjectRolesComponent': {
                'title': 'Roles',
                'label_name': 'Name',
                'btn_add_role': 'Add role',
                'role_create_fail': 'Role creation failed',
            }, 'ProjectsProjectDatabasesComponent': {
                'btn_create_database': 'Create Database',
                'label_copy_connection_string_database': 'Copy Conection',
                'label_remove_database': 'Remove Database',
                'label_edit_database': 'Edit Database',
                'table_database_name': 'Name',
                'table_database_description': 'Description',
                'table_action': 'Action',
                'table_id': 'Database id',
                'label_delete_database': 'Drop',
                'table_database_collections': 'Collections',
                'title': 'Databases',
                'label_connection_string': 'Connection String',
                'label_no_databases': 'You have no any Database',
                'label_no_databases_comment': 'Create your first <strong class="font-color-cloud">DATABASE</strong> and save data from your <strong class="font-color-hardware">HARDWARE</strong>. Database in Cluster with worldwide distribution and double backup.',
                'label_create_collection_database': 'Create collection',
            }, 'ProgramVersionDiffComponent': {
                'label_source_version': 'Original Library',
                'label_target_version': 'Modified Library',
                'label_source_file': 'Source File',
                'label_target_file': 'Target File',
            },
            'ModalsCreateCompilationLibraryComponent': {
                'title_create': 'New Firmware Compilation library',
                'title_update': 'Edit Compilation library',
                'label_version_identifier': 'Version Identification',
                'label_changing_note': 'Changing Note',
                'label_supported_types': 'Supported Hardware Types'
            },
            'ModalsInstanceStartComponent': {
                'label_upload_instance_modal': 'Deploy to Cloud',
                'label_upload_selected_snapshot': 'Deploy {0} to server',
                'label_upload_instance_modal_comment': 'The last used <strong class="font-color-blocko">BLOCKO</strong> configuration will be uploaded to the server.',
                'label_allow_hardware_updates': 'Allow hardware updates',
            },
            'ModalsHardwareBatchDefaultsComponent': {
                'title': 'Batch default settings',
                'label_default_value': 'Default value',
                'label_allowed_values': 'Allowed values',
                'label_max': 'Max value/length',
                'label_min': 'Min value/length'
            },
            'DataUsageChartComponent': {
                'label_value_axis': 'Data Consumption',
                'label_date_axis': 'Date',
            }, 'HardwareNetworkStatusComponent': {
                'limit': 'Only showing 6000 data points. Change interval or granularity'
            }, 'FilterTableComponent': {
                'button_apply': 'Apply'
            }, 'HardwareUpdateChartComponent': {
                'label_value_axis': 'Part number',
                'label_date_axis': 'Time from start, ms',
                'label_refresh_chart': 'Refresh chart',
            },


            'label_skunks_hardware': 'Skunks<strong class="font-color-hardware">HARDWARE</strong>',
            'label_skunks_blocko': 'Skunks<strong class="font-color-blocko">BLOCKO</strong>',
            'label_skunks_instance': 'Skunks<strong class="font-color-instance">INSTANCE</strong>',
            'label_skunks_cloud': 'Skunks<strong class="font-color-cloud">CLOUD</strong>',
            'label_skunks_code': 'Skunks<strong class="font-color-code">CODE</strong>',
            'label_skunks_backup': 'Skunks<strong class="font-color-code">CODE - BACKUP</strong>',
            'label_project_owner': 'Project owner',
            'label_project_admin': 'Project admin',
            'label_project_member': 'Project member',
            'label_email': 'E-mail',
            'label_password': 'Password',
            'label_console': 'Console',
            'label_configuration': 'Configuration',
            'label_offline': 'OFFLINE',
            'label_online': 'ONLINE',
            'label_date': 'Date',
            'label_time': 'Time',
            'label_version': 'Version',
            'label_message': 'Message',
            'label_description': 'Description',
            'label_user': 'User',
            'label_name': 'Name',
            'label_none': 'None',
            'label_type': 'Type',
            'btn_save': 'Save',
            'btn_save_as': 'Save as',
            'table_tags': 'Tags',
            'btn_test': 'Test',
            'btn_close': 'Close',
            'btn_back': 'Back',
            'btn_send': 'Send',
            'btn_submit': 'Submit',
            'btn_add': 'Add',
            'btn_edit': 'Edit',
            'btn_remove': 'Remove',
            'btn_continue': 'Continue',
            'btn_cancel': 'Cancel',
            'btn_change': 'Change',
            'btn_confirm': 'Confirm',
            'btn_clear': 'Clear',
            'label_loading': 'Loading...',
            'loading': 'Loading...',
            'btn_done': 'Done',
            'btn_rename': 'Rename',
            'btn_clean': 'Clean',
            'label_settings': 'Settings',
            'btn_yes': 'Yes',
            'btn_no': 'No',
            'btn_update': 'Update',
            'btn_ok': 'OK',
            'btn_create': 'Create',
            'btn_copy': 'Copy',
            'btn_options': 'Options',
            'flash_successfully_edit': 'Successfully edit',
            'flash_successfully_remove': 'Successfully removed',
            'label_free': 'Free',
            'hello_world': 'Hello {0}! {1}?',
        },
        'cz': {
            // TODO
        }
    };

    public static translateTables: {
        [lang: string]: {
            [tableOrEnv: string]: {
                [keyOrTable: string]: (
                    string
                    | { [key: string]: string })
            }
        }
    } = {
        'en': {
            'board_state': {
                'UNKNOWN': 'unknown',
                'NO_COLLISION': 'No collisions found.',
                'ALREADY_IN_INSTANCE': 'Warning! Device is already running in this instance.',
                'PLANNED_UPDATE': 'Update is scheduled in the future.',
            },
            'online_state': {
                'ONLINE': 'Online',
                'OFFLINE': 'Offline',
                'FREEZED': 'Freeze',
                'SYNCHRONIZATION_IN_PROGRESS': 'Synchronizing',
                'NOT_YET_FIRST_CONNECTED': 'We have no idea',
                'UNKNOWN_LOST_CONNECTION_WITH_SERVER': 'Lost connection with external server',
                'SHUT_DOWN': 'Shut Down',
            },
            'public_state': {
                'PRIVATE' : 'Private' ,
                'PUBLIC' : 'Public' ,
                'COMMERCIAL' : 'Commercial' ,
            },
            'server_type': {
                'PUBLIC': 'Public Skunks Server',
                'PRIVATE': 'Private Server',
                'BACKUP': 'Public Skunks Backup Server',
                'MAIN': 'Public Skunks Main Server',
                'TEST': 'Test Server',
                'ALREADY_ON': 'Already on'
            },
            'device_alerts': {
                'BOOTLOADER_REQUIRED': 'Bootloader update is strongly recommended.',
                'RESTART_REQUIRED': 'Device restart is required.'
            },
            'instance_status': {
                'FUTURE': 'Scheduled',
                'NOW': 'Running now',
                'HISTORY': 'No longer running'
            },
            'snapshot_settings': {
                'only_for_project_members': 'Only for project members',
                'absolutely_public': 'Public',
                'private': 'Private',
                'not_in_instance': 'Not present instance.',
            },
            'payment_method': {
                'INVOICE_BASED': 'Choose later for every invoice',
                'CREDIT_CARD' : 'Saved credit card (auto-pay)',
                'NOT_SET' : 'Not yet set'
            },
            'type_of_update': {
                'MANUALLY_BY_USER_INDIVIDUAL': 'Individual Update',
                'MANUALLY_RELEASE_MANAGER': 'Release Manager',
                'MANUALLY_BY_INSTANCE': 'Manually through <strong class="font-color-blocko">BLOCKO</strong>',
                'AUTOMATICALLY_BY_INSTANCE': 'Scheduled through <strong class="font-color-blocko">BLOCKO</strong>',
                'AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE': 'System up-to-date',
                'AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE': 'Critical patch',
            },
            'firmware_type': {
                'FIRMWARE': 'Firmware',
                'BOOTLOADER': 'Boot-Loader',
                'BACKUP': 'Firmware - Backup',
                'WIFI': 'Wi-Fi '
            },
            'publish_status': {
                'PENDING': 'Your request has been submitted, <br> please wait for the administrator\'s decision.',
                'APPROVED': 'Your program has been approved. <br> You can find it with a slight modification in the list of public programs. <strong>Thank you!</strong>',
                'DISAPPROVED': 'We apologize, but the administrator did not approve your program. More information was sent to you by email and notification.',
                'EDITED': 'The program has been edited for another iteraction. ',
            },
            'version_status': {
                'IN_PROGRESS': 'Compilation is in progress.',
                'SUCCESS': 'Successfully compiled.',
                'SERVER_OFFLINE': 'Server error (offline). The server can fix bugs in a short while. We know about this issue and we\'re working on it. Please be patient.',
                'SUCCESS_DOWNLOAD_FAILED': 'Compilation server error. The server can fix bugs in a short while. We know about this issue and we\'re working on it. Please be patient.',
                'FAILED': 'Code compilation finished with errors.',
                'FILE_NOT_FOUND': 'Code file not found. The server can fix bugs in a short while. We know about this issue and we\'re working on it. Please be patient.',
                'SERVER_ERROR': 'Compilation server error. The server can fix bugs in a short while. We know about this issue and we\'re working on it. Please be patient.',
                'BROKEN_JSON': 'Json Code is Broken. Please try again later.',
                'UNSTABLE': 'Some of your devices with this version of the program had a critical error and had to be restored from a backup. This version is not recommended to use in production until you have solved the cause of the error.',
                'UNDEFINED': 'Version status is unknown.',
                'MAIN_VERSION': 'Version is set as Main or Default'
            },
            'error_code': {
                '1': 'Missing Label in update procedure - probably incompatible previous firmware'

            },
            'gsm_status': {
                'active': 'Active',
                'not active': 'Not Active'

            },
            'update_state': {
                'IN_PLAN': 'Planned',
                'PENDING': 'Pending',
                'IN_PROGRESS': 'In Progress',
                'COMPLETE': 'Completed',
                'CANCELED': 'Cancelled',
                'RUNNING': 'Update is in progress',
                'OBSOLETE': 'Obsolete',
                'FAILED': 'Failed',
                'SUCCESSFULLY_COMPLETE': 'Successfully complete',
                'COMPLETE_WITH_ERROR': 'Complete with errors',
                'NOT_START_YET': 'Not started yet',

                // This description is used for Additional comment after mouseover. Information specifies a brief enum description
                'complete_description': 'TODO', // TODO navázat na text po najetí myšky chci informační bublinu
                'canceled_description': 'TODO',
                'not_start_yet_description': 'TODO',
                'in_progress_description': 'TODO',
                'overwritten_description': 'TODO',

            }, 'hardware_device_id': {
                'ALREADY_REGISTERED_IN_YOUR_ACCOUNT': 'Device is already registered with your account.',
                'ALREADY_REGISTERED': 'Device is already registered.',
                'PERMANENTLY_DISABLED': 'This device is permanently disabled.',
                'BROKEN_DEVICE': 'This device is broken.',
                'NOT_EXIST': 'Device doesn\'t exist.',
                'default': 'There is a problem with this device.',

            }, 'entity_not_valid': {
                'email': 'E-mail address is already taken.',
                'nick_name': 'Nickname is already taken.',
                'vat_number': 'Wrong VAT number (type it without spaces, dashes etc.).',
                'default': 'Unknown error, {0}.',

            }, 'regexp_not_valid': {
                'street_number': 'Wrong street number format. "Number" or "number/number" is the correct format.',
                'version_validation_error': 'Use following format for version name: v1.20.30',
            }, 'productStatusTranslate': {
                'false': 'All activities such as running instances in cloud or hardware data services are temporarily suspended. During the inactivity, no fees are charged. This product can not be removed by user. Only by technical support. After six months of inactivity, it will be archived.',
                'true': 'The financial product is activated. '
            }, 'order_by': {
                'ascending': 'In alphabetical order',
                'descending': 'In reverse alphabetical order'
            }

        },
        'cz': {
            // TODO
        }
    };
};
