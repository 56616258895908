import { WebSocketClient } from './WebSocketClient';
import { IWebSocketMessage, WebSocketMessage } from './WebSocketMessage';

export class WebSocketClientHardware extends WebSocketClient {

    public static readonly CHANNEL: string = 'hardware-logs-and-states';

    constructor(url: string) {
        super(WebSocketClientHardware.CHANNEL, url);
        this.connect();
    }

    protected onMessage(message: WebSocketMessage) {
    }




    /**
     * Online State Subscribe
     * @param deviceId
     * @param logLevel
     */
    public requestDeviceOnlineStateSubscribe(deviceId: string): Promise<IWebSocketMessage> {
        return this.sendWithResponse(new WSMessageSubscribeHardwareState( [deviceId]));
    }

    /**
     *  Online State UnSubscribe
     * @param deviceId
     */
    public requestDeviceOnlineStateUnsubscribe(deviceId: string): Promise<IWebSocketMessage> {
        return this.sendWithResponse(new WSMessageUnsubscribeHardwareState([deviceId]));
    }

    // ------------------------------------------------------------------------------------------------------------------------------------------------------

    /**
     * Subscribe Logs from Hardware
     * @param deviceId
     * @param logLevel
     */
    public requestDeviceTerminalSubscribe(deviceId: string, logLevel: ('error' | 'warn' | 'info' | 'debug' | 'trace')): Promise<IWebSocketMessage> {
        return this.sendWithResponse(new WSMessageSubscribeHardwareLogs(logLevel, [deviceId]));
    }

    /**
     * UnSubscribe Logs from Hardware
     * @param deviceId
     */
    public requestDeviceTerminalUnsubscribe(deviceId: string): Promise<IWebSocketMessage> {
        return this.sendWithResponse(new WSMessageUnsubscribeHardwareLogs([deviceId]));
    }

    /**
     * Change Level subscription
     * @param deviceId
     * @param logLevel
     */
    public requestDeviceTerminalLevelChange(deviceId: string, logLevel: ('error' | 'warn' | 'info' | 'debug' | 'trace')): Promise<IWebSocketMessage> {
        return this.sendWithResponse(new WSMessageChangeLogLevelLogs(logLevel, [deviceId]));
    }
}


export class WSMessageSubscribeHardwareState extends WebSocketMessage {
    constructor(ids: Array<string>) {
        super('subscribe_state_hardware');
        this.setData('hardware_ids', ids);
    }
}

export class WSMessageUnsubscribeHardwareState extends WebSocketMessage {
    constructor(ids: Array<string>) {
        super('unsubscribe_state_hardware');
        this.setData('hardware_ids', ids);
    }
}

export class WSMessageSubscribeHardwareLogs extends WebSocketMessage {
    constructor(level: string, ids: Array<string>) {
        super('subscribe_logs_hardware');
        this.setData('log_level', level);
        this.setData('hardware_ids', ids);
    }
}

export class WSMessageUnsubscribeHardwareLogs extends WebSocketMessage {
    constructor(ids: Array<string>) {
        super('unsubscribe_logs_hardware');
        this.setData('hardware_ids', ids);
    }
}

export class WSMessageChangeLogLevelLogs extends WebSocketMessage {
    constructor(level: string, ids: Array<string>) {
        super('change_log_level');
        this.setData('log_level', level);
        this.setData('hardware_ids', ids);
    }
}
