import { ModalModel } from '../services/ModalService';
import { IHardware, IHardwareGroup, IHardwareList } from '../backend/TyrionAPI';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TyrionBackendService } from '../services/BackendService';
import { TranslationService } from '../services/TranslationService';
import { NotificationService } from '../services/NotificationService';
import { BeckiValidators } from '../helpers/BeckiValidators';
import { BeckiAsyncValidators } from '../helpers/BeckiAsyncValidators';
import { takeUntil } from 'rxjs/operators';
import { IError } from '../services/_backend_class/Responses';
import { handleStatus } from '../backend/BeckiBackend';

export class ModalsGroupEditHardwareModel extends ModalModel {
    // @ts-ignore
    public changedHardwareList: string[] = [];
    constructor(
        public project_id: string,
        public hw_group: IHardwareGroup = null               // for add HW to group and show what is in group
    ) {
        super();
        this.modalLarge = true;
    }
}


@Component({
    selector: 'bk-modals-group-edit-hardware',
    template: require('./hardware-group-edit-hardwares.html')
})
export class ModalsGroupEditHardwareComponent implements OnInit, OnDestroy {

    @Input()
    modalModel: ModalsGroupEditHardwareModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    errorMessage: string = null;

    devicesFilter: IHardwareList = null;

    selectedHardwareList: { [id: string]: IHardware } = {};

    page: number = 0;
    formHardwareFilter: FormGroup;

    ngUnsubscribe = new Subject<void>();

    constructor(private tyrionBackendService: TyrionBackendService, private formBuilder: FormBuilder, private translationService: TranslationService, private notificationService: NotificationService) {
        this.formHardwareFilter = this.formBuilder.group({
            'alias': ['', [Validators.maxLength(60), BeckiValidators.forbiddenSymbol(/[^\x20-\x7E]/g)]],
            'id': ['', [Validators.maxLength(60)], [BeckiAsyncValidators.validUUID()]],
            'full_id': ['', [Validators.maxLength(60)]],
            'description': ['', [Validators.maxLength(60)]],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.onFilterHardware();
        }, 100);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    onFilterHardware(page?: number): void {

        if (page) {
            this.page = page;
        }

        if (!this.formHardwareFilter.valid && this.formHardwareFilter.dirty) {
            return;
        }

        this.tyrionBackendService.boardsGetListByFilter( {
            projects: [this.modalModel.project_id],
            hardware_type_ids: [null],
            count_on_page: 10,
            order_by: this.formHardwareFilter.controls['orderBy'].value,
            order_schema: this.formHardwareFilter.controls['order_schema'].value,
            full_id: this.formHardwareFilter.controls['full_id'].value,
            id: this.formHardwareFilter.controls['id'].value,
            name: this.formHardwareFilter.controls['alias'].value,
            description: this.formHardwareFilter.controls['description'].value,
            page_number: this.page
        })
            .then((values) => {
                this.devicesFilter = values;
                this.devicesFilter.content.forEach((device, index, obj) => {
                    this.tyrionBackendService.observeNetworkStatus(device.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(handleStatus(device));
                });

                if (this.modalModel.hw_group) {
                    this.devicesFilter.content.forEach((device, index, obj) => {
                        if (device.hardware_group && device.hardware_group.id === this.modalModel.hw_group.id) {
                            this.selectedHardwareList[device.id] = device;
                        }
                    });
                }
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
                this.errorMessage = reason.message;
            });
    }


    onHardwareAddToList(hardware: IHardware): void {
        this.selectedHardwareList[hardware.id] = hardware;
        this.onChangeHardwareClick(hardware.id);
    }

    onHardwareRemoveFromList(hardware: IHardware): void {
        this.selectedHardwareList[hardware.id] = null;
        this.onChangeHardwareClick(hardware.id);
    }


    onDropDownEmitter(action: string, object: any): void {
        if (action === 'label_select_hardware') {
            this.onHardwareAddToList(object);
        }
        if (action === 'label_remove_hardware') {
            this.onHardwareRemoveFromList(object);
        }

    }

    onChangeHardwareClick(id: string) {
        const idx = this.modalModel.changedHardwareList.indexOf(id);
        // const
        if (idx >= 0) {
            this.modalModel.changedHardwareList.splice(idx, 1);
        } else {
            this.modalModel.changedHardwareList.push(id);
        }
    }

    onSubmitClick(): void {


        this.modalClose.emit(true);
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }

    selectAllDevices() {
        this.devicesFilter.content.forEach( device => {
            if (this.selectedHardwareList[device.id] == null) {
                this.onHardwareAddToList(device);
            }
        });
    }
}
