/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { ModalModel } from '../services/ModalService';
import { AbstractControl, FormBuilder } from '@angular/forms';

export class ModalsHardwareParametersModel extends ModalModel {

    public parameters: {[key: string]: string|number|boolean} = {};

    constructor() {
        super();
    }
}

@Component({
    selector: 'bk-modals-hardware-parameters',
    template: require('./hardware-parameters.html')
})
export class ModalsHardwareParametersComponent implements OnInit {

    @Input()
    modalModel: ModalsHardwareParametersModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    parameters: Array<{paramControl: AbstractControl, valueControl: AbstractControl}>;

    constructor(public formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        this.parameters = [{
            paramControl: this.formBuilder.control('netsource'),
            valueControl: this.formBuilder.control('ethernet')
        }];
    }

    onAddParamClick() {
        this.parameters.push({
            paramControl: this.formBuilder.control('netsource'),
            valueControl: this.formBuilder.control('ethernet')
        });
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onConfirmClick(): void {
        this.parameters.forEach(p => {
            this.modalModel.parameters[p.paramControl.value] = p.valueControl.value;
        });
        this.modalClose.emit(true);
    }
}
