import { Component, Input } from '@angular/core';
import { FormSelectComponentOption } from './FormSelectComponent';
import { AbstractControl } from '@angular/forms';
import { Filter_parameter } from './FilterTableComponent';



export class NothingToShowData {

    condition_loading?: boolean = true;

    condition_empty?: boolean = false;

    main_message_link: string = 'NOT SET!';

    main_message_comment_link: string = 'NOT SET!';

    image_link?: string = null;

    image_description?: string = null;

    btn_label?: string = 'NOT SET!';

    icon?: string = 'fa-plus-circle';

    show_button: boolean = false;

    locatedDashboard?: boolean = false;

    mainDashboard?: boolean = false;

    onButtonClick?: () => void = () => {};
}

export class FilterOptions {
    key: string;

}


export class TableHeader {
    name: string;
    visible?: boolean = true;
    align?: 'LEFT'|'CENTER'|'RIGHT' = 'LEFT';
    filter?: FilterOptions;
}

export class TableCommonFilterOptions {
    orderSchemaControl: AbstractControl;
    orderKeyControl: AbstractControl;
    countOnPage?: AbstractControl;
    onClick: () => void;
}

export interface TableFilterResult {
    from: number;
    to: number;
    total: number;
    pages: number;
}

export class FilterPagerValues {
    filterResults: TableFilterResult;
    showPagesSquares?: boolean = true;
    optionListForSelector?: FormSelectComponentOption[];
    defaultIndex?: number = 25;
    onDashboard?: boolean = false;
    visible?: boolean = true;
}

export class FilterParameters {
    visible: boolean = true;
    filterParameters: Filter_parameter[];
    closed: boolean = false;
    showFilterHead: boolean = true;
    onChange?: (filter: {key: string, value: any}) => void;
}


@Component({
    selector: 'bk-table',
    template:
    `
        <bk-filter-component *ngIf="filterParameters && filterParameters.visible"
            [closed]="filterParameters.closed"
            [showFilterHead]="filterParameters.showFilterHead"
            [filter_parameters]="filterParameters.filterParameters"
            (onChange)="filterParameters.onChange? filterParameters.onChange($event) : null"
            (onEnterHit)="changedPage = {newValue: 1}; this.refreshPage(1)"
            (onApplyHit)="changedPage = {newValue: 1}; this.refreshPage(1)"
        ></bk-filter-component>
        <ng-content class="hide-bk-drob-down-button" select="[mTableHeader]">
        </ng-content>
        <table *ngIf="visible"
            class="table table-hover table-light table-scrollable table-scrollable-borderless">
            <thead>
            <tr>
              <th *ngFor="let header of visibleHeaders(_headers)" [class.text-center]="header.align === 'CENTER'" >
                <ng-container *ngIf="commonFilterOptions && header.filter">
                  <a [innerHTML]="header.name"
                    (click)="commonFilterOptions.orderKeyControl.setValue(header.filter.key); commonFilterOptions.onClick()"
                    [class.bold]="commonFilterOptions.orderKeyControl.value === header.filter.key"></a>
                  <bk-icon-component *ngIf="commonFilterOptions.orderKeyControl.value === header.filter.key"
                    [icon]="commonFilterOptions.orderSchemaControl.value === 'ASC' ? 'fa-chevron-up' : 'fa-chevron-down'"
                    [color]="'font-silver'"
                    [color_on]="'font-color-skunks-blue'"
                    [condition]="true"
                    (onClickEvent)="commonFilterOptions.orderSchemaControl.value=== 'ASC' ? commonFilterOptions.orderSchemaControl.setValue('DESC') : commonFilterOptions.orderSchemaControl.setValue('ASC'); commonFilterOptions.onClick()">
                  </bk-icon-component>
                </ng-container>
                  <span *ngIf="!(commonFilterOptions && header.filter)" [innerHTML]="header.name">
                  </span>
              </th>
            </tr>
            </thead>
            <ng-content class="hide-bk-drob-down-button" select="[mTableBody]" *ngIf="visible"></ng-content>
        </table>
        <bk-filter-page-component *ngIf="filterPagesValues && filterPagesValues.visible && visible"
                                  [from]="filterPagesValues.filterResults && filterPagesValues.filterResults.from"
                                  [to]="filterPagesValues.filterResults && filterPagesValues.filterResults.to"
                                  [total]="filterPagesValues.filterResults && filterPagesValues.filterResults.total"
                                  [totalPages]="filterPagesValues.filterResults && filterPagesValues.filterResults.pages"
                                  [externalPageRefresh]="changedPage"
                                  [showPagesSquares]="filterPagesValues.showPagesSquares"
                                  [onDashboard]="filterPagesValues.onDashboard"
                                  [defaultIndex]="filterPagesValues.defaultIndex"
                                  [control]="commonFilterOptions? commonFilterOptions.countOnPage : null"
                                  [refreshPage]="refreshPage"
                                >
        </bk-filter-page-component>
        <bk-nothing-to-show *ngIf="_nothingToShowData"
            [condition_loading]="_nothingToShowData.condition_loading"
            [locatedDashboard]="_nothingToShowData.locatedDashboard"
            [condition_empty]="_nothingToShowData.condition_empty"
            [image_description]="_nothingToShowData.image_description"
            [mainDashboard]="_nothingToShowData.mainDashboard"
            [image_link]="_nothingToShowData.image_link"
            [main_message_link]="_nothingToShowData.main_message_link"
            [btn_label]="_nothingToShowData.btn_label"
            [main_message_comment_link]="_nothingToShowData.main_message_comment_link"
            [show_button]="_nothingToShowData.show_button"
            (onButtonClick)="_nothingToShowData.onButtonClick()">
        </bk-nothing-to-show>
    `
})
export class DataTableComponent {

    @Input()
    visible: boolean = true;

    @Input()
    set headers (headers: TableHeader[]) {
        this.addDefaultValuesToHeaders(headers);
    };

    @Input()
    set nothingToShowData (nothingToShowData: NothingToShowData) {
        this._nothingToShowData = this.addDefaultValuesToNothingToShowData(nothingToShowData);
    }

    @Input()
    commonFilterOptions: TableCommonFilterOptions;

    @Input()
    filterPagesValues: FilterPagerValues;

    @Input()
    showedValue: string;

    @Input()
    filterParameters: FilterParameters;

    @Input()
    refreshPage: (page: number) => void;


    changedPage: { newValue: number};


    _headers: TableHeader[] = [];
    _nothingToShowData: NothingToShowData = null;

    visibleHeaders = (headers: TableHeader[]) => {
        return headers.filter(h => h.visible);
    };

    addDefaultValuesToHeaders = ( headers: TableHeader[]) => {
        this._headers = headers.map(header => {
            const result = new TableHeader();
            result.name = header.name;
            if (header.align !== null && header.align !== undefined) {
                result.align = header.align;
            }
            if (header.visible !== null && header.visible !== undefined) {
                result.visible = header.visible;
            }
            if ( header.filter !== null && header.filter !== undefined) {
                result.filter = header.filter;
            }
            return result;
        })
    }

    addDefaultValuesToNothingToShowData(nothingToShowData: NothingToShowData) {
        const result = new NothingToShowData();
        Object.keys(result).forEach(key => {
            if (!(nothingToShowData[key] === null || nothingToShowData[key] === undefined)) {
                result[key] = nothingToShowData[key];
            }
        });

        return result;
    }

}
