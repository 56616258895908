import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormSelectComponentOption } from './FormSelectComponent';
import { StaticOptionLists } from '../helpers/StaticOptionLists';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
    selector: 'bk-hardware-parameter',
    template: `
            <div class="row">
                <bk-form-select [control]="paramControl" [options]="options" (valueChanged)="onSelectionChange($event)" [labelComment]="false" class="col-md-6"></bk-form-select>
                <bk-form-input
                    *ngIf="(selectedParam.data.type === 'string' && !selectedParam.data.options) || selectedParam.data.type === 'number'"
                    [type]="selectedParam.data.type === 'number' ? 'number' : 'text'"
                    [control]="valueControl"
                    [showLabel]="false"
                    class="col-md-6">
                </bk-form-input>
                <bk-form-select
                    *ngIf="selectedParam.data.type === 'string' && selectedParam.data.options"
                    [control]="valueControl"
                    [options]="optionsFromArray(selectedParam.data.options)"
                    [labelComment]="false"
                    class="col-md-6">
                </bk-form-select>
                <div *ngIf="selectedParam.data.type === 'boolean'" class="col-md-6">
                    <i class="fa cursor-hand"
                       style="vertical-align: middle; font-size: 2em; margin-top: 0.33em;"
                       [class.fa-toggle-on]="valueControl.value"
                       [class.fa-toggle-off]="!valueControl.value"
                       [class.font-green-jungle]="valueControl.value"
                       [class.font-grey-mint]="!valueControl.value"
                       (click)="valueControl.setValue(!valueControl.value)">
                    </i>
                </div>
            </div>
    `
})
export class HardwareParameterComponent implements OnInit {

    @Input()
    paramControl: AbstractControl;

    @Input()
    valueControl: AbstractControl;

    options: Array<FormSelectComponentOption> = [];

    selectedParam: FormSelectComponentOption;

    @Output()
    onValueChanged: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit(): void {
        this.options = StaticOptionLists.hwParamsList.filter(p => p.value !== 'ALIAS');
        this.selectedParam = this.options[0];
    }

    onSelectionChange(value: any) {
        if (value) {
            const option: FormSelectComponentOption = this.options.find(o => o.value === value);
            if (option) {
                this.selectedParam = option;

                this.valueControl.clearValidators();
                this.valueControl.markAsUntouched();

                this.valueControl.setValue(option.data.default);

                let validators: Array<any> = [Validators.required];

                switch (this.selectedParam.data.type) {
                    case 'string': {
                        if (typeof this.selectedParam.data.min === 'number') {
                            validators.push(Validators.minLength(this.selectedParam.data.min))
                        }
                        if (typeof this.selectedParam.data.max === 'number') {
                            validators.push(Validators.maxLength(this.selectedParam.data.max))
                        }
                        break;
                    }
                    case 'number': {

                        if (typeof this.selectedParam.data.min === 'number') {
                            validators.push(Validators.min(this.selectedParam.data.min))
                        }
                        if (typeof this.selectedParam.data.max === 'number') {
                            validators.push(Validators.max(this.selectedParam.data.max))
                        }
                    }
                }

                this.valueControl.setValidators(validators)
            }
        }
    }

    optionsFromArray(options: Array<string>): Array<FormSelectComponentOption> {
        return options.map(o => {
            return {
                label: o,
                value: o
            };
        })
    }
}

