/**************************************************************/
/*                                                            */
/*   Generated Homer API backend file                         */
/*   © 2015-2016 Becki Authors.                               */
/*                                                            */
/*   Build date: 2018-01-18 18:17:25                          */
/*   Homer  version: v1.2.5                                   */
/*                                                            */
/*   Generated by automated script from Swagger JSON.         */
/*   Script author: Skunks                                   */
/*                                                            */
/*   Base Path: /v1                                           */
/**************************************************************/

/* tslint:disable */


import { IHomerServer } from "./TyrionAPI";


/**
 * @name IAvailableVersion
 * @description: HomerServer Version Overview
 */
export interface IAvailableVersion {
    /**
     * @name description
     * @type string
     * @description Description
     * @required
     */
    description: string;
    /**
     * @name tag
     * @type string
     * @description Version (release) according GitHub for example v1.0.4 or v5.4.3-alpha.9
     * @required
     */
    tag: string;
}


/**
 * @name IResultBadRequest
 * @description: Default BadRequest Response
 */
export interface IResultBadRequest {
    /**
     * @name code
     * @type number
     * @format double
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @required
     */
    message: string;
    /**
     * @name state
     * @type string
     * @required
     */
    state: string;
}


/**
 * @name IResultOK
 * @description: Default BadRequest Response
 */
export interface IResultOK {
    /**
     * @name code
     * @type number
     * @description Value is 200
     * @format double
     * @required
     */
    code: number;
    /**
     * @name state
     * @type string
     * @description OK
     * @required
     */
    state: string;
}


/**
 * @name IUpdateServerToRequiredVersion
 * @description: HomerServer Version Overview
 */
export interface IUpdateServerToRequiredVersion {
    /**
     * @name tag
     * @type string
     * @description Version (release) according GitHub for example v1.0.4 or v5.4.3-alpha.9
     * @required
     */
    tag: string;
    /**
     * @name time
     * @type number
     * @description If Value is null - update will be executed immediately
     * @format double
     */
    time?: number;
}


/**
 * @name IVersionOverview
 * @description: HomerServer Version Overview
 */
export interface IVersionOverview {
    /**
     * @name description
     * @type string
     * @description Version Description
     * @required
     */
    description: string;
    /**
     * @name os
     * @type ("windows"|"mac-os"|"linux")
     * @description Operation System, where server is running
     * @required
     */
    os: ("windows"|"mac-os"|"linux");
    /**
     * @name running_from
     * @type number
     * @description Time in Unix in ms
     * @format double
     * @required
     */
    running_from: number;
    /**
     * @name tag
     * @type string
     * @description Version (release) according GitHub for example v1.0.4 or v5.4.3-alpha.9
     * @required
     */
    tag: string;
    /**
     * @name time_offset
     * @type number
     * @description Time zone Offset 0 or -60 or +120....
     * @format double
     * @required
     */
    time_offset: number;
}




export abstract class HomerAPI {

    protected abstract requestRestPath<T>(method:string, path:string, body:Object, success:number[], contentType: string):Promise<T>;

    /**
     * @name homerServerGetOverView
     * @summary Get Homer_Server OverView
     * @operationId Server_get_version
     * @tags Server Overview
     *
     * @description Get Homer Server OverView - Permission (TOKEN) Required
     *
     * @param serverURL
     *
     * @returns {IVersionOverview} [code 200] Ok
     *
     */
    public homerServerGetOverView(serverURL:IHomerServer):Promise<IVersionOverview> {
        return this.requestRestPath("GET", `http://` + serverURL.server_url + (serverURL.rest_api_port !== null  ? `:` + serverURL.rest_api_port : ``)  + `/v1` + `/versions/overview`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerUpdateToNewVersion
     * @summary Update Homer_Server to new Version
     * @operationId Server_update_by_tag
     * @tags Server Update
     *
     * @description Update Server to required Version
     *
     * @param serverURL
     * @param {IUpdateServerToRequiredVersion} request
     *
     * @returns {IResultOK} [code 200] OK
     *
     * @throws {IResultBadRequest} [code 400] Unexpected error
     */
    public homerServerUpdateToNewVersion(serverURL:IHomerServer, request:IUpdateServerToRequiredVersion):Promise<IResultOK> {
        return this.requestRestPath("PUT", `http://` + serverURL.server_url + (serverURL.rest_api_port !== null  ? `:` + serverURL.rest_api_port : ``)  + `/v1` + `/versions/update_server`, request, [200], 'application/json');
    }

    /**
     * @name serverServerGetListAvailableVersions
     * @summary Get Server_Server List Available_Versions
     * @operationId Server_get_available_versions
     * @tags Server Overview
     *
     * @description Get List of all Available Versions for server update - Permission (TOKEN) Required
     *
     * @param serverURL
     *
     * @returns {IAvailableVersion[]} [code 200] Ok
     *
     */
    public serverServerGetListAvailableVersions(serverURL:IHomerServer):Promise<IAvailableVersion[]> {
        return this.requestRestPath("GET", `http://` + serverURL.server_url + (serverURL.rest_api_port !== null  ? `:` + serverURL.rest_api_port : ``)  + `/v1` + `/versions/available_versions`, {}, [200], 'application/json');
    }

}
