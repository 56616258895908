/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Router, ActivatedRoute } from '@angular/router';
import { TyrionBackendService } from '../services/BackendService';
import { ModalService } from '../services/ModalService';
import { Injector, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { BlockUIService } from '../services/BlockUIService';
import {
    NotificationService, FlashMessage, FlashMessageInfo,
    FlashMessageSuccess, FlashMessageWarning, FlashMessageError, FlashMessageErrorFromString
} from '../services/NotificationService';
import { StorageService } from '../services/StorageService';
import { MonacoEditorLoaderService } from '../services/MonacoEditorLoaderService';
import { TranslationService } from '../services/TranslationService';
import { BeckiImageLinks } from '../helpers/BeckiImageLinks';
import { IError } from '../services/_backend_class/Responses';
import { FileDownloaderService } from '../services/FileDownloaderService';
import { IHomerServer, IProducer, ITariff } from '../backend/TyrionAPI';

/* tslint:disable:class-name  */
export abstract class _BaseMainComponent {
/* tslint:disable:class-name  */

    protected beckiImageLinks: BeckiImageLinks = null;
    protected tyrionBackendService: TyrionBackendService = null;
    protected storageService: StorageService = null;
    protected router: Router = null;
    protected activatedRoute: ActivatedRoute = null;
    protected modalService: ModalService = null;
    protected notificationService: NotificationService = null;
    protected formBuilder: FormBuilder = null;
    protected currentParamsService: CurrentParamsService = null;
    protected blockUIService: BlockUIService = null;
    protected translationService: TranslationService = null;
    protected fileDownloaderService: FileDownloaderService = null;
    protected zone: NgZone = null;

    constructor(protected injector: Injector) {
        if (injector) {
            this.tyrionBackendService = injector.get(TyrionBackendService);
            this.storageService = injector.get(StorageService);
            this.router = injector.get(Router);
            this.activatedRoute = injector.get(ActivatedRoute);
            this.modalService = injector.get(ModalService);
            this.notificationService = injector.get(NotificationService);
            this.formBuilder = injector.get(FormBuilder);
            this.currentParamsService = injector.get(CurrentParamsService);
            this.blockUIService = injector.get(BlockUIService);
            this.translationService = injector.get(TranslationService);
            this.fileDownloaderService = injector.get(FileDownloaderService);
            this.zone = injector.get(NgZone);
            this.beckiImageLinks = injector.get(BeckiImageLinks);
            injector.get(MonacoEditorLoaderService); // only for preload monaco scripts
        } else {
            throw new Error('Injector is not defined! ... Don\'t you forget to add \"constructor(injector:Injector) {super(injector)};\"" in inherited class?');
        }
    }

    /**
     * Supplies current project id from the current route. May be overridden to supply project id manually.
     */
    public getCurrentProjectId(): string {
        return this.currentParamsService.get('project');
    }

    public addFlashMessage(fm: FlashMessage): void {
        this.notificationService.addFlashMessage(fm);
    }

    protected updateQueryParams(params: object) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: params
        })
    }

    protected getNavigateToPath = (link: any[]) => {
        return () => this.navigate(link);
    }

    protected navigate = (link: any[]) => {
        this.router.navigate(link);
    }

    public blockUI(): void {
        this.blockUIService.blockUI();
    }

    public unblockUI(): void {
        this.blockUIService.unblockUI();
    }

    protected getBeckiFlag(imageName: string): string {
        return this.beckiImageLinks.getBeckiImage(imageName, 'flags');
    }

    protected getBeckiImage(imageName: string, folderName: string): string {
        return this.beckiImageLinks.getBeckiImage(imageName, folderName);
    }

    public translate(key: string, ...args: any[]): string {
        return this.translationService.translate(key, this, null, args);
    }

    public translateTable(key: string, table: string, ...args: any[]): string {
        return this.translationService.translateTable(key, this, table, null, args);
    }

    protected fmInfo(msg: string, reason?: Object): FlashMessage {
        let fm = new FlashMessageInfo(msg, reason);
        this.addFlashMessage(fm);
        return fm;
    }

    protected fmSuccess(msg: string, reason?: Object): FlashMessage {
        let fm = new FlashMessageSuccess(msg, reason);
        this.addFlashMessage(fm);
        return fm;
    }

    protected fmWarning(msg: string, reason?: IError): FlashMessage {
        let fm = new FlashMessageWarning(msg, reason);
        this.addFlashMessage(fm);
        return fm;
    }

    fmError(reason?: IError): FlashMessage {
        let fm = new FlashMessageError(reason);
        this.addFlashMessage(fm);
        return fm;
    }

    fmErrorFromString(body?: string): FlashMessage {
        let fm = new FlashMessageErrorFromString(body);
        this.addFlashMessage(fm);
        return fm;
    }

// -- ON CLICK ----------------------------------------------------------------------------------------------------------------



    public onLogOutClick(): string[] {
        return ['/logout'];
    }

    public onDashboardClick(): string[] {
        return ['/dashboard'];
    }

    public onFinanceClick(): string[] {
        return ['/financial'];
    }

    public onAddProductClick(): string[] {
        return ['/financial/product-registration'];
    }

    public onGSMListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'gsm'];
    }
    // -------------------------------------------------------------------------------------------
    public onHardwareListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'hardware'];
    }

    public onInstancesListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'instances'];
    }

    public onServersListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'servers'];
    }

    public onCodeProgramsListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'code'];
    }

    public onCodeLibrariesListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'libraries'];
    }

    public onGridProjectsListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'grid'];
    }

    public onGridWidgetsListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'widgets'];
    }

    public onBlockoProgramsListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'blocko'];
    }

    public onBlockoBlocksListClick(): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'blocks'];
    }
    // -------------------------------------------------------------------------------------------

    public onGSMClick(gsm_id: string): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'gsm', gsm_id];
    }

    public onHardwareClick(hardwareId: string): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'hardware', hardwareId];
    }

    public onProjectClick(project_id: string): string[] {
        return ['/projects' , project_id];
    }

    public onProductClick(product_id: string): string[] {
        return ['/financial', product_id];
    }

    public onHardwareTypeClick(hardware_id: string): string[] {
        return ['/hardware', hardware_id];
    }

    public onProducerClick(producer: IProducer): string[] {
        return ['/producers', producer.id];
    }

    public onDeviceClick(device_id: string): string[] {
        return  [ '/projects', this.currentParamsService.get('project') , 'hardware' , device_id];
    }

    public onActualizationProcedureClick(procedure_id?: string): string[] {
        return ['/projects', this.getCurrentProjectId(), 'release-update', procedure_id];
    }

    public onLibraryAdminClick(library_id: string): string[] {
        return ['/admin/hardware/libraries', library_id];
    }

    public onLibraryClick(library_id: string, version_id: string = null): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'libraries', library_id, {version: version_id}];
    }

    public onCProgramClick(cProgram_id: string, version_id: string = null): string[] {
        return ['/projects', this.getCurrentProjectId(), 'code', cProgram_id, {version: version_id}] as Array<string>;
    }

    public onCProgramAdminClick(c_program_id: string, version_id: string = null): any[] {
        return ['/admin/hardware/code', c_program_id, {version: version_id}];
    }

    public onCProgramAdminCommunityManagementClick(c_program_id: string, version_id: string = null): any[] {
        return ['/admin/c-program/code', c_program_id, {version: version_id}];
    }
    public onCProgramHardwareTypeClick(hardwareType_id: string, c_program_id: string, version_id: string = null): any[] {
        return ['/hardware', hardwareType_id, 'code', c_program_id, {version: version_id}];
    }

    public onBProgramClick(bProgram_id: string, version_id: string = null): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'blocko', bProgram_id, { version: version_id}];
    }

    public onBProgramAdminClick(bProgram_id: string, version_id: string = null): any[] {
        return ['/admin/blocko/blocko', bProgram_id, {version: version_id}];
    }

    public onBlockClick(block_id: string, version_id: string = null): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'blocks', block_id, { version: version_id}];
    }

    public onBlockAdminClick(block_id: string, version_id: string = null): any[] {
        return ['/admin/blocko/block', block_id, {version: version_id}];
    }

    public onDeviceClick_Admin(device_id: string): string[] {
        return ['/admin/hardware', device_id];
    }

    public onInstanceClick(instance_id: string): string[] {
        return ['/projects', this.getCurrentProjectId(), 'instances', instance_id];
    }
    public onInstanceClickWithProject(project_id: string, instance_id: string): string[] {
        return ['/projects', project_id, 'instances', instance_id];
    }

    public onCellularClick(cellular_id: string): string[] {
        return ['/projects', this.getCurrentProjectId(), 'gsm', cellular_id];
    }

    public onGridProjectClick(grid_project_id: string): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'grid', grid_project_id];
    }

    public onGridProgramClick(grid_project_id: string, grid_program_id: string): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'grid', grid_project_id, grid_program_id];
    }

    public onGridProgramVersionClick(grid_project_id: string, grid_program_id: string, grid_program_version_id: string) {
        return ['/projects', this.currentParamsService.get('project'), 'grid', grid_project_id, grid_program_id, {version: grid_program_version_id}];
    }

    public onWidgetClick(widget_id: string, version_id: string = null): string[] {
        return ['/projects', this.currentParamsService.get('project'), 'widgets', widget_id, {version: version_id}] as Array<string>;
    }

    public onWidgetAdminClick(widget_id: string, version_id: string = null): any[] {
        return ['/admin/widgets', widget_id, {version: version_id}];
    }

    public onScanHardwareClick(project_id: string): string[] {
        return ['/projects', project_id, 'scanHardware'];
    }

    public onTariffClick(tariff: ITariff): string[] {
        return ['/admin/financial', tariff.id];
    }

    public onHomerServerClick(serverShortDetail: IHomerServer): string[] {
        return ['TODO'];
    }

// -- SemVer ----------------------------------------------------------------------------------------------------------------

    // Find out next version for saving
    nextVersion(versions: any[]): string {
        let nextVersion: string = null;
        let matchedVersionNames = [];
        let regexp = /^v?[0-9]+\.[0-9]+\.?[0-9]*$/;

        versions.forEach(version => {
            if (version.name.match(regexp)) {
                matchedVersionNames.push(version.name);
            }
        });

        if (matchedVersionNames.length === 0) {
            nextVersion = 'v1.0.0';
        } else {
            nextVersion = this.incrementPatch(matchedVersionNames[0]);
        }

        return nextVersion;
    }

    // Increment the patch (e.g. 1.2.3 - 3 is patch)
    incrementPatch(lastVersion: string): string {
        let parts = lastVersion.split('.');
        let patch = parts[2];
        let patchInt = parseInt(patch, 10);
        patchInt++;
        patch = patchInt.toString();
        parts[2] = patch;
        if (parts[0].charAt(0) !== 'v') {
            return 'v' + parts.join('.');
        } else {
            return parts.join('.')
        }
    }

}
