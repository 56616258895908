/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import {
    Component, OnChanges, Input, Output, SimpleChanges, EventEmitter, OnInit,
    AfterViewInit, QueryList, ViewChildren, OnDestroy, ViewChild, ElementRef
} from '@angular/core';
import { FileTreeObjectInterface } from './FileTreeComponent';
import { ModalsCodeFileDialogModel, ModalsCodeFileDialogType } from '../modals/code-file-dialog';
import { ModalService } from '../services/ModalService';
import { ModalsConfirmModel } from '../modals/confirm';
import { TranslationService } from '../services/TranslationService';
import { FormSelectComponentOption } from './FormSelectComponent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBinaryDetails, IHardware, ILibrary, ILibraryVersion } from '../backend/TyrionAPI';
import { BlockoViewComponent } from './BlockoViewComponent';
import { Blocks } from 'blocko';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TyrionBackendService } from '../services/BackendService';
import { FileTreeRootComponent } from './FileTreeRootComponent';
import { FlashMessageSuccess, NotificationService } from '../services/NotificationService';

export abstract class CodeFileSystemObject implements FileTreeObjectInterface {

    originalObjectFullPath: string;
    objectFullPath: string;

    open: boolean = false;

    constructor(objectFullPath: string) {
        this.originalObjectFullPath = objectFullPath;
        this.objectFullPath = objectFullPath;
    }

    get color(): string {
        return this.open ? 'gray' : 'silver';
    }

    get bold(): boolean {
        return false;
    }

    get changes(): boolean {
        return (this.originalObjectFullPath !== this.objectFullPath);
    }

    get objectName(): string {
        let i = this.objectFullPath.lastIndexOf('/');
        if (i > -1) {
            return this.objectFullPath.substr(i + 1);
        }
        return this.objectFullPath;
    }

    get objectPath(): string {
        let i = this.objectFullPath.lastIndexOf('/');
        if (i > -1) {
            return this.objectFullPath.substr(0, i);
        }
        return '';
    }

}

export class CodeFile extends CodeFileSystemObject {

    contentOriginal: string;
    content: string;

    fixedPath: boolean = false;

    library: boolean = false;

    readonly: boolean = false;

    libraryVersionId: string = null;

    annotations: any[];

    constructor(objectFullPath: string, content: string = null) {
        super(objectFullPath);
        if (content === null) {
            this.originalObjectFullPath = '';
            this.contentOriginal = '';
            this.content = '';
        } else {
            this.contentOriginal = content;
            this.content = content;
        }
    }

    get extension(): string {
        let i = this.objectName.lastIndexOf('.');
        if (i > -1) {
            return this.objectName.substr(i + 1);
        }
        return '';
    }

    get color(): string {
        if (this.library) {
            return '#9A12B3';
        }
        let ext = this.extension.toLowerCase();
        if (ext === 'cpp') {
            return '#067084';
        } else if (ext === 'h') {
            return '#782c1f';
        } else if (ext === 'c') {
            return '#013284';
        }
        return 'silver';
    }

    get icon(): string {
        if (this.library) {
            return 'briefcase';
        }
        return 'file-text';
    }

    get bold(): boolean {
        return this.open;
    }

    get changes(): boolean {
        return ((this.originalObjectFullPath !== this.objectFullPath) || (this.contentOriginal !== this.content));
    }

}

export class CodeDirectory extends CodeFileSystemObject {

    get color(): string {
        return '#ffc50d';
    }

    // for directory is path = fullPath;
    get objectPath(): string {
        return this.objectFullPath;
    }
}

@Component({
    selector: 'bk-code-ide',
    template: require('./CodeIDEComponent.html')
})
export class CodeIDEComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

    @Input()
    files: CodeFile[] = [];

    @Input()
    defaultOpenFilename: string = null;

    @Input()
    enableLibraryButtons: boolean = false;

    @Input()
    enableSaveButton: boolean = false;

    @Input()
    enableBuildButton: boolean = false;

    @Input()
    buildInProgress: boolean = false;

    @Input()
    enableExternalLibraries: boolean = true;

    @Input()
    enableAssignedHardware: boolean = true;

    @Input()
    enableInterfacePreviw: boolean = true;

    @Input()
    selectedLibrary: string = null;

    @Input()
    selectedVersionFreshest: boolean = true;

    @Input()
    selectedVersionSuported: boolean = true;

    @Input()
    selectedHardware: IHardware[] = [];

    @Input()
    externalLibraries: {library: ILibrary, version: ILibraryVersion}[] = [];

    @Input()
    macroFiles: CodeFile = null;

    @Output()
    fileContentChange = new EventEmitter<any>();

    @Output()
    onAddLibraryClick = new EventEmitter<any>();

    @Output()
    onAddHardwareClick = new EventEmitter<boolean>();

    @Output()
    onRemoveHardwareClick = new EventEmitter<IHardware>();

    @Output()
    onRemoveLibrary = new EventEmitter<{library: ILibrary, version: ILibraryVersion}>();

    @Output()
    onChangeLibraryVersionClick = new EventEmitter<ILibrary>();

    // List of all Version for compilation (for this type_of_board)
    @Input()
    libraryCompilationVersionOptions: FormSelectComponentOption[] = null;

    @Input()
    binaryDetails: IBinaryDetails;

    @Output()
    onSaveClick = new EventEmitter<boolean>();

    @Output()
    onSaveAsClick = new EventEmitter<boolean>();

    @Output()
    onBuildClick = new EventEmitter<boolean>();

    @Output()
    onDownloadClick = new EventEmitter<boolean>();

    directories: CodeDirectory[] = [];

    openFilesTabIndex: number = 0;

    openFiles: CodeFile[] = [];

    formLibrarySelector: FormGroup = null;

    inEditMode: boolean = true;

    emptyDirs: string[] = [];

    // Array of objects' full paths for keeping them open after saving.
    keepOpenFiles: string[] = [];

    ngUnsubscribe = new Subject<void>();

    @ViewChildren(BlockoViewComponent)
    blockoViews: QueryList<BlockoViewComponent>;


    @ViewChild('FileTree')
    fileTree: FileTreeRootComponent;

    @ViewChild('folderInput')
    folderInput: ElementRef;

    public _show_files_portlet: boolean = true;
    public _show_libraries_portlet: boolean = false;
    public _show_integrated_hardware_portlet: boolean = false;
    public _show_blocko_interface_portlet: boolean = false;
    public _show_code_settings_portlet: boolean = false;
    public _show_binary_details_portlet: boolean = false;
    public _latest_blockoInterface: Blocks.BlockoTargetInterface = null;
    private _editorView: BlockoViewComponent = null;


    public upload_after_build: boolean = false;
    constructor(protected modalService: ModalService, private translationService: TranslationService, private formBuilder: FormBuilder, protected backendService: TyrionBackendService, protected notificationService: NotificationService) {
        this.formLibrarySelector = this.formBuilder.group({
            'compilation_version_library_tag': ['', [Validators.required]]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        let files = changes['files'];
        if (files && files.currentValue) {
            this.openFiles = [];
            this.files = files.currentValue;
            this.directories = [];
            this.files.concat(this.macroFiles).forEach(file => this.keepOpenFiles.forEach(tabName => {
                if (file.objectFullPath === tabName) {
                    if (this.openFiles.indexOf(file) === -1) {
                        this.openFiles.push(file);
                    }
                }
            }));
            this.openFilesTabIndex = 0;

            // TODO: maybe do it as call method by ViewChild
            if (this.defaultOpenFilename && this.files) {
                let file: CodeFile = null;
                this.files.forEach((f) => {
                    if (f.objectFullPath === this.defaultOpenFilename) {
                        file = f;
                    }
                });
                if (file) {
                    this.openFilesOpenFile(file);
                }
            }
        }
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.blockoViews.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe((views: QueryList<BlockoViewComponent>) => {

            this._editorView = views.find((view) => {
                return view.id === 'block_view';
            });

            if (this._editorView) {
                if (this._latest_blockoInterface !== null) {
                    this._editorView.setSingleInterface(this._latest_blockoInterface);
                }
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    translate(key: string, ...args: any[]): string {
        return this.translationService.translate(key, this, null, args);
    }

    showModalError(title: string, text: string) {
        this.modalService.showModal(new ModalsConfirmModel(title, text, true, null, null, [this.translate('btn_ok')]));
    }

    langFromFilename(fileObj: CodeFile) {
        if (fileObj.extension.toLowerCase() === 'md') {
            return 'markdown';
        }
        if (fileObj.library) {
            return 'markdown';
        }
        return 'cpp';
    }

    toolbarAddLibraryClick(e: any) {
        this.onAddLibraryClick.emit(e);
    }

    toolbarAddHardwareClick( e: boolean = true) {
        this.onAddHardwareClick.emit(e);
        this._show_integrated_hardware_portlet = true;
    }

    toolbarRemoveHardwareClick(hardware: IHardware) {
        this.onRemoveHardwareClick.emit(hardware);
    }

    onCodeChange(code: string, fileObj: CodeFile) {
        fileObj.content = code;
        this.fileContentChange.emit({
            fileFullPath: fileObj.objectFullPath,
            content: code
        });
    }

    refreshInterface(ios: any) {

        if (ios == null) {
            return;
        }

        this._latest_blockoInterface = {
            code: {
                programId: 'dummy_program_id',
                programName: '',
                versionId: 'dummy_version_id',
                versionName: '',
                versionDescription: ''
            },
            interface : ios
        };

        if (this._editorView) {
            this._editorView.setSingleInterface(this._latest_blockoInterface);
        }

    }

    openMacro() {
        if (this.openFiles.indexOf(this.macroFiles) === -1) {
            this.openFiles.push(this.macroFiles);
        }
    }

    openFilesOpenFile(file: CodeFile) {
        if (this.files.indexOf(file) === -1 && file.objectFullPath !== 'build_macro') {
            return;
        }
        if (this.openFiles.indexOf(file) === -1) {
            this.openFiles.push(file);

            // Add file object full path to keep tab open after saving.
            if (!(this.keepOpenFiles.indexOf(file.objectFullPath) > -1)) {
                this.keepOpenFiles.push(file.objectFullPath);
            }
            file.open = true;
        }
        this.openFilesTabIndex = this.openFiles.indexOf(file);
    }

    openFilesOpenTab(index: number) {
        this.openFilesTabIndex = index;
    }

    openFilesCloseTab(file: CodeFile) {
        let i = this.openFiles.indexOf(file);
        if (i > -1) {
            this.openFiles.splice(i, 1);
            file.open = false;
            if (this.openFilesTabIndex >= this.openFiles.length) {
                this.openFilesTabIndex = (this.openFiles.length - 1);
            }
        }

        // Remove file object full path.
        let j = this.keepOpenFiles.indexOf(file.objectFullPath);
        if ( j > -1) {
            this.keepOpenFiles.splice(j, 1);
        }
    }

    isFileExist(fileFullPath: string) {
        if (!Array.isArray(this.files)) {
            return;
        }

        let exist = false;
        this.files.forEach((f) => {
            if (f.objectFullPath === fileFullPath) {
                exist = true;
            }
        });
        return exist;
    }

    isDirectoryExist(dirFullPath: string) {
        let exist = false;
        this.files.forEach((f) => {
            if (f.objectFullPath.startsWith(dirFullPath)) {
                exist = true;
            }
        });
        this.emptyDirs.forEach(d => {
            if (d.startsWith(dirFullPath)) {
                exist = true;
            }
        });
        return exist;
    }


    externalRefresh() {
        this.fileTree.externalRefresh();
    }


    onFileEditClick(file: CodeFile) {
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.RenameFile, file.objectName, null, null, '/' + file.objectFullPath);
        this.modalService.showModal(model).then((success) => {
            if (success) {

                if (model.objectName === file.objectName) {
                    return;
                }

                let newFullPath = (file.objectPath !== '' ? file.objectPath + '/' : '') + model.objectName;

                if (this.isFileExist(newFullPath)) {
                    this.showModalError(this.translate('modal_label_error'), this.translate('modal_label_cant_rename_file_already_exist', newFullPath ));
                } else {
                    const idx = this.files.findIndex(x => x.objectFullPath === file.objectFullPath);
                    file.objectFullPath = newFullPath;
                    this.fileTree.externalRefresh();

                }
            }
        });
        this.backendService.changeDetectionEmitter.emit({});
    }


    onFileDeleteClick(file: CodeFile) {
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.RemoveFile, '', null, null, '/' + file.objectFullPath);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.fileContentChange.emit(file.objectFullPath);
                this.openFilesCloseTab(file);
                let i = this.files.indexOf(file);
                if (i > -1) {
                    this.files.splice(i, 1);
                }
                this.fileTree.externalRefresh();
            }
        });

    }

    onFileCreateClick(path: string) {
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.AddFile, '', null, null);
        this.modalService.showModal(model).then((success) => {
            if (success) {

                let newFullPath = path.length > 0 ? path + '/' + model.objectName : model.objectName;
                this.fileContentChange.emit(newFullPath);

                if (this.isFileExist(newFullPath)) {
                    this.showModalError(this.translate('modal_label_error'), this.translate('modal_label_cant_add_file_at_path', newFullPath ));
                } else {

                    let obj = new CodeFile(newFullPath);
                    this.files.push(obj);
                    this.fileTree.externalRefresh();
                }

            }
        });

    }

    onFolderCreateClick(path: string) {
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.AddDirectory, '', null, null);
        this.modalService.showModal(model).then((success) => {
            if (success) {

                const newFullPath = path + '/' + model.objectName;
                if (this.isDirectoryExist(path + '/' + model.objectName)) {
                    this.showModalError(this.translate('modal_label_error'), this.translate('modal_label_cant_move_directory_at_path' , newFullPath ));
                } else {
                    this.emptyDirs.push(path + '/' + model.objectName);
                    this.fileTree.externalRefresh();
                }
            }
        });

    }

    onFolderEditClick(path: string) {

        const pathSplited = path.split('/');

        const oldName = pathSplited.pop();
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.RenameDirectory, oldName);
        this.modalService.showModal(model).then((success) => {
            if (success) {

                const newName = model.objectName;
                const newPath = pathSplited.reduce((current, acc) => current + '/' + acc) + '/' + newName;
                this.fileContentChange.emit(newPath);
                if (this.isDirectoryExist(newPath)) {
                    this.showModalError(this.translate('modal_label_error'), this.translate('modal_label_cant_move_directory_at_path' , newPath));
                } else {

                    this.files.filter(x => x.objectFullPath.startsWith(path)).forEach(z => {
                        const filename = z.objectFullPath.split('/').pop();
                        z.objectFullPath = newPath + '/' + filename;
                    });

                    const emptyDirIndex = this.emptyDirs.indexOf(path);
                    if (emptyDirIndex > -1) {
                        this.emptyDirs.splice(emptyDirIndex, 1);
                        this.emptyDirs.push(newPath);
                    }

                    this.fileTree.externalRefresh();
                }
            }
        });
    }

    onFolderDeleteClick(path: string) {
        let model = new ModalsCodeFileDialogModel(ModalsCodeFileDialogType.RemoveDirectory, '', null, null,  path);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.fileContentChange.emit(path);
                const index = this.emptyDirs.indexOf(path);
                if (index !== -1) {
                    this.emptyDirs.splice(index, 1);
                }

                path = path.slice(1);
                let idx = this.files.findIndex(x => x.objectFullPath.startsWith(path));
                while (idx !== -1) {
                    this.files.splice(idx, 1);
                    idx = this.files.findIndex(x => x.objectFullPath.startsWith(path));

                }
                this.fileTree.externalRefresh();
            }
        });
    }

    folderUploadClick() {
        this.folderInput.nativeElement.click();
    }

    filesPicked(files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const pathSplited = file.webkitRelativePath.split('/');
            pathSplited.splice(0, 1); // remove the name of uploaded folder
            const path = pathSplited.reduce((acc, current) => acc + '/' + current);
            this.fileContentChange.emit(path);

            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                const existingFileIndex = this.files.findIndex(x => x.objectFullPath === path);
                if (existingFileIndex > -1) {
                    this.openFilesCloseTab(this.files[existingFileIndex]);
                    this.files.splice(existingFileIndex,  1);
                }

                const codeFile = new CodeFile(path, <string>fileReader.result);
                this.files.push(codeFile);
                this.fileTree.externalRefresh();

                this.folderInput.nativeElement.value = null;
            };
            fileReader.readAsText(file);
        }
    }



    onToolBarSaveClick() {
        this.onSaveClick.emit(true);
    }

    onToolBarSaveAsClick() {
        this.onSaveAsClick.emit(true);
    }

    onToolBarBuildClick() {
        this.onBuildClick.emit(true);
    }

    onToolBarChangeMode() {
        this.inEditMode = !this.inEditMode;
    }

    show_files_portlet(show: boolean) {
        this._show_files_portlet = show;
    }

    show_libraries_portlet(show: boolean) {
        this._show_libraries_portlet = show;
    }

    show_integrated_hardware_portlet(show: boolean) {
        this._show_integrated_hardware_portlet = show;
    }
    show_blocko_interface_portlet(show: boolean) {
        this._show_blocko_interface_portlet = show;
        if (show) {
            // this.refreshInterface();
        }
    }
    show_code_settings_portlet(show: boolean) {
        this._show_code_settings_portlet = show;
    }


    onSelectedCompilationChanged(compilation: string) {
        this.selectedLibrary = compilation;
    }

    isLastVersionUsed(selected: {library: ILibrary, version: ILibraryVersion}): boolean {
        let index = selected.library.versions.findIndex(x => x.id === selected.version.id);
        return index === ( selected.library.versions.length - 1 )
    }

    onLibraryCleanClick() {
        this.backendService.compilationLibraryClean({
            libraries: [this.formLibrarySelector.controls['compilation_version_library_tag'].value]
        }).then(() => {
            this.notificationService.addFlashMessage(new FlashMessageSuccess(this.translate('flash_clean_success')));
        }).catch(reason => {
            this.notificationService.fmError(reason);
        })
    }
}
