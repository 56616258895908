/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */


import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { ModalModel } from '../services/ModalService';
import { IBoardNewGarfield, IHardwareNewSettingsResult, IHardwareType } from '../backend/TyrionAPI';
import { FormSelectComponentOption } from '../components/FormSelectComponent';
import { IError } from '../services/_backend_class/Responses';
const uuidv1 = require('uuid/v1');

export class ModalsAdminCreateHardwareModel extends ModalModel {
    constructor(
        public hardwareTypes: IHardwareType[],
        public processorId: string = '',
        public hardwareTypeId: string = '',
        public batchId: string = '',
        public registration_hash: string = '',
    ) {
        super();
    }
}

@Component({
    selector: 'bk-modals-admin-create-hardware',
    template: require('./admin-create-hardware.html')
})
export class ModalsAdminCreateHardwareComponent implements OnInit {

    @Input()
    modalModel: ModalsAdminCreateHardwareModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    form: FormGroup;

    hardwareTypeOption: FormSelectComponentOption[] = null;
    hardwareBatchOption: FormSelectComponentOption[] = null;
    result: IHardwareNewSettingsResult = null;
    resultStringify: string = null;

    constructor(private tyrionBackendService: TyrionBackendService, private backendService: TyrionBackendService, private formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            'processorId': ['', [Validators.required, Validators.minLength(24), Validators.maxLength(24)]],
            'hardwareTypeId': ['', [Validators.required]],
            'batchId': ['', [Validators.required]],
            'registration_hash': ['', [Validators.minLength(34), Validators.maxLength(34)]]
        });

        let chartId: string = 'HW' + uuidv1();
        chartId = chartId.replace(/-/g, '');
        this.form.controls['registration_hash'].setValue(chartId);
    }

    ngOnInit() {

        this.hardwareTypeOption = this.modalModel.hardwareTypes.map((pv) => {
            return {
                label: pv.name,
                value: pv.id
            };
        });

        (<FormControl>(this.form.controls['processorId'])).setValue(this.modalModel.processorId);
        (<FormControl>(this.form.controls['hardwareTypeId'])).setValue(this.modalModel.hardwareTypeId);
    }

    hardwareTypeChange(hardwareType: string): void {
        console.trace('ModalsAdminCreateHardwareComponent: hardwareTypeChange: hardwareType:', hardwareType);
        console.trace('ModalsAdminCreateHardwareComponent: hardwareTypes: ', JSON.stringify( this.modalModel.hardwareTypes, null, 4))
        const type = this.modalModel.hardwareTypes.find( (t) => t.id === hardwareType );

        console.trace('ModalsAdminCreateHardwareComponent: hardwareTypeChange: type:', JSON.stringify( type, null, 4));

        if (type == null) {
            console.warn('ModalsAdminCreateHardwareComponent: hardwareTypeChange: type is null');
            return;
        }

        if (type.batches == null) {
            console.warn('ModalsAdminCreateHardwareComponent: hardwareTypeChange: type.batches is null');
            return;
        }

        console.trace('ModalsAdminCreateHardwareComponent: hardwareTypeChange: type.batches:', JSON.stringify( type.batches, null, 4));

        this.hardwareBatchOption = type.batches.map((pv) => {
            return {
                label: pv.production_batch + ' ' + pv.revision,
                value: pv.id
            };
        });

    }

    batchTypeChange(batchType: string): void {
        console.trace('ModalsAdminCreateHardwareComponent: batchTypeChange: batchType:', batchType);
    }

    onSubmitClick(): void {
        this.modalModel.processorId = this.form.controls['processorId'].value;
        this.modalModel.hardwareTypeId = this.form.controls['hardwareTypeId'].value;
        this.modalModel.batchId = this.form.controls['batchId'].value;
        this.modalModel.registration_hash = this.form.controls['registration_hash'].value;

        this.create();
    }

    create(): void {
        this.tyrionBackendService.boardCreateAutomaticGarfield(<IBoardNewGarfield>{
            full_id: this.modalModel.processorId,
            hardware_type_id: this.modalModel.hardwareTypeId,
            batch_id: this.modalModel.batchId,
            registration_hash: this.modalModel.registration_hash,
        })
            .then((result: IHardwareNewSettingsResult) => {

                this.result = result;
                this.resultStringify = JSON.stringify(result, null, 4);

            }).catch((reason: IError) => {
            });
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
