/**
 * Created by Tomáš Záruba on 03.01.18.
 */
/*
 * © 2015-2016 Becki Authors. See the AUTHORS file found in the top-level
 * directory of this distribution.
 */

import { Input, Output, EventEmitter, Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { ModalModel } from '../services/ModalService';
import { TranslationService } from '../services/TranslationService';
import { IBootLoader, ICProgram, ICProgramList, ICProgramVersion, IHardwareType } from '../backend/TyrionAPI';
import { ProgramVersionSelectorComponent } from '../components/VersionSelectorComponent';
import { NotificationService } from '../services/NotificationService';
import { IError } from '../services/_backend_class/Responses';

export class ModalsSelectBootloaderModel extends ModalModel {

    public selected_bootloader: IBootLoader = null;
    public file: any = null;
    public file_name: string = null;

    constructor(public hardware_type_id: string = null, public allow_select_file: boolean) {
        super();
        this.modalWide = true;
    }
}

@Component({
    selector: 'bk-modals-bootloader-select',
    template: require('./bootloader-select.html')
})
export class ModalsBootloaderSelectComponent implements OnInit {

    @Input()
    modalModel: ModalsSelectBootloaderModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    hardwareType: IHardwareType = null;

    errorMessage: string = null;

    // Filter parameters
    page: number = 0;
    formFilterGroup: FormGroup;

    // File Support
    data: any = null;

    constructor(private tyrionBackendService: TyrionBackendService, private formBuilder: FormBuilder, private translationService: TranslationService, protected notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.tyrionBackendService.hardwareTypeGet(this.modalModel.hardware_type_id)
            .then((tresult) => {
                this.hardwareType = tresult;
            }).catch((reason: IError) => {
            });
    }

    onSubmitClick(): void {
        if (!this.modalModel.selected_bootloader && !this.modalModel.file) {
            this.errorMessage = this.translationService.translate('label_no_file_or_version_selected', this) ; // There is no version selected. ;
        } else {
            this.modalClose.emit(true);
        }
    }

    onSelectBootloaderClick(bootloader: IBootLoader): void {
        this.modalModel.selected_bootloader = bootloader;
        this.modalClose.emit(true);
    }

    onDropDownEmitter(action: string, object: any): void {
        if (action === 'label_select_bootloader') {
            this.onSelectBootloaderClick(object);
        }
    }

    fileChangeListener($event: any) {
        let file: File = $event.target.files[0];
        if (file) {
            let myReader: FileReader = new FileReader();
            myReader.addEventListener('load', () => {
                this.data = myReader.result;
                this.modalModel.file_name = file.name;
                this.modalModel.file = this.data;
            }, false);

            myReader.readAsDataURL(file);
        }
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
