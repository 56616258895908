/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Component, OnInit, Injector, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { FlashMessageSuccess } from '../services/NotificationService';
import { Subject, Subscription } from 'rxjs';
import { CodeFile, CodeIDEComponent } from '../components/CodeIDEComponent';
import { ModalsConfirmModel } from '../modals/confirm';
import { ModalsVersionDialogModel } from '../modals/version-dialog';
import { ILibraryRecord, ILibrary, ILibraryVersion, ILibraryFileLoad, ICompilationBuildError } from '../backend/TyrionAPI';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { NullSafe } from '../helpers/NullSafe';
import { ModalsCodeAddLibraryModel } from '../modals/code-add-library';
import { ModalsCodeLibraryVersionModel } from '../modals/code-library-version';
import { ModalsRemovalModel } from '../modals/removal';
import { ModalsPublicShareRequestModel } from '../modals/public-share-request';
import { ModalsPublicShareResponseModel } from '../modals/public-share-response';
import { ExitConfirmationService } from '../services/ExitConfirmationService';
import { FormSelectComponentOption } from '../components/FormSelectComponent';
import { CodeCompileError, IError } from '../services/_backend_class/Responses';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bk-view-projects-project-code-library',
    template: require('./projects-project-code-library.html')
})
export class ProjectsProjectCodeLibraryComponent extends _BaseMainComponent implements OnInit, OnDestroy, AfterViewInit {

    project_Id: string;
    libraryId: string;

    routeParamsSubscription: Subscription;

    library: ILibrary = null;
    libraryVersions: ILibraryVersion[] = null;

    selectedLibraryVersion: ILibraryVersion = null;
    selectedCodeFiles: CodeFile[] = [];

    buildErrors: ICompilationBuildError[] = null;
    buildInProgress: boolean = false;

    importedLibraries: {version: ILibraryVersion, library: ILibrary}[] = [];

    currentParamsService: CurrentParamsService; // exposed for template - filled by BaseMainComponent
    projectSubscription: Subscription;

    @ViewChild('CodeIDEComponent')
    codeIDE: CodeIDEComponent;

    tab: string = 'ide';
    tab_under_ide: string = 'error_list';

    // List of all Version for compilation (for this type_of_board)
    libraryCompilationVersionOptions: FormSelectComponentOption[] = null;


    protected afterLoadSelectedVersionId: string = null;

    fileChangeTimeout: any = null;

    ngUnsubscribe = new Subject<void>();

    protected exitConfirmationService: ExitConfirmationService = null;

    constructor(injector: Injector) {
        super(injector);

        this.exitConfirmationService = injector.get(ExitConfirmationService);
        this.exitConfirmationService.setConfirmationEnabled(false);

    };

    ngOnInit(): void {

        this.refreshInterface();

        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.libraryId = params['library'];
            this.project_Id = params['project'];
            this.refresh();

            if (this.project_Id != null && params['version']) {
                this.router.navigate(['/projects', this.project_Id, 'libraries', this.libraryId], { replaceUrl: true });
                this.selectVersionByVersionId(params['version']);
            }
        });

        this.tyrionBackendService.objectUpdateTyrionEcho.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
            if (status.model === 'Library' && this.libraryId === status.model_id) {
                this.refresh();
            } else if (status.model === 'LibraryVersion' && this.libraryVersions.find(v => v.id === status.model_id)) {
                this.reloadVersions();
            }
        });

    }

    onPortletClick(action: string): void {
        if (action === 'edit_program') {
            this.onEditClick();
        }

        if (action === 'remove_program') {
            this.onRemoveClick();
        }

        if (action === 'make_clone') {
            this.onMakeClone();
        }
    }



    onToggleTab(tab: string) {
        this.tab = tab;
    }

    onToggleIDETab(tab: string) {
        if (this.tab_under_ide === tab) {
            this.tab_under_ide = ''; // Hide tab
        } else {
            this.tab_under_ide = tab;
        }
    }


    ngAfterViewInit(): void {
        this.refreshInterface();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        if (this.project_Id != null && this.projectSubscription) {
            this.projectSubscription.unsubscribe();
        }
    }


    onLibraryPublishResult(version: ILibraryVersion): void {
        let model = new ModalsPublicShareResponseModel(
            version.name,
            version.description,
            this.library.name,
            this.library.description
        );
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.libraryVersionEditResponsePublication({
                    version_id: version.id,
                    version_name: model.version_name,
                    version_description: model.version_description,
                    decision: model.decision,
                    reason: model.reason,
                    program_description: model.program_description,
                    program_name: model.program_name
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));
                        this.refresh();
                        this.tab = 'ide';
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRemoveClick(): void {
        this.modalService.showModal(new ModalsRemovalModel(this.library.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.libraryDelete(this.library.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_remove')));
                        if (this.project_Id != null) {
                            this.refresh();
                        }
                        this.router.navigate(['/projects/' + this.project_Id + '/libraries']);
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onEditClick(): void {
        // let model = new ModalsCodePropertiesModel(null, this.project_Id, this.library);
        // this.modalService.showModal(model).then((success) => {
        //     if (success) {
        //         this.blockUI();
        //         this.tyrionBackendService.cProgramEdit(this.library.id, {
        //             name: model.program.name,
        //             description: model.program.description,
        //             tags: model.program.tags
        //         })
        //             .then(() => {
        //                 this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));
        //                 this.refresh();
        //             })
        //             .catch((reason: IError) => {
        //                 this.fmError(reason);
        //                 this.refresh();
        //             });
        //     }
        // });
    }

    onRemoveVersionClick(version: ILibraryVersion): void {
        this.modalService.showModal(new ModalsRemovalModel(version.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.libraryVersionDelete(version.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_version_remove')));
                        if (this.library.versions && this.library.versions.length === 1) {
                            // if last version was removed
                            this.router.navigate(['projects', this.project_Id, 'libraries']);
                        } else {
                            if (this.project_Id != null) {
                                this.refresh();
                            }
                            this.refresh();
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (this.project_Id != null) {
                            this.refresh();
                        }
                        this.refresh();
                    });
            }
        });
    }

    onEditVersionClick(version: ILibraryVersion): void {
        let model = new ModalsVersionDialogModel(this.library.id, 'LibraryVersion', version);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.libraryVersionEdit(version.id, {
                    name: model.object.name,
                    description: model.object.description,
                    tags: model.object.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_version_change', model.object.name)));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    selectVersionByVersionId(versionId: string) {
        if (this.libraryVersions && !(this.libraryVersions.length === 0)) {
            let version = null;
            if (versionId) {
                version = this.libraryVersions.find((bpv) => bpv.id === versionId);
            }

            if (version) {
                this.selectLibraryVersion(version);
            }
        } else {
            this.afterLoadSelectedVersionId = versionId;
        }
    }

    refresh(): void {

        this.blockUI();

        this.tyrionBackendService.libraryGet(this.libraryId).then((library) => {
            this.library = library;
            this.libraryVersions = this.library.versions || [];
            let version = null;
            if (this.afterLoadSelectedVersionId) {
                version = this.libraryVersions.find((bpv) => bpv.id === this.afterLoadSelectedVersionId);
            }
            if (version) {
                this.selectLibraryVersion(version);
            } else if (this.libraryVersions.length > 0) {
                this.selectLibraryVersion(this.libraryVersions[0]);
            }

            this.unblockUI();

        })
    }

    onFileContentChange(e: { fileFullPath: string, content: string }) {
        if (this.fileChangeTimeout) {
            clearTimeout(this.fileChangeTimeout);
        }
        this.fileChangeTimeout = setTimeout(() => this.onFileContentChangeDebounced(), 500);
        this.exitConfirmationService.setConfirmationEnabled(true);

    }

    onFileContentChangeDebounced() {
        this.refreshInterface();
    }

    onMakeClone(): void {
        // let model = new ModalsCodePropertiesModel(null, this.project_Id, this.library, true);
        // this.modalService.showModal(model).then((success) => {
        //     if (success) {
        //         this.blockUI();
        //         this.tyrionBackendService.libraryMakeClone({
        //             library_id: this.library.id,
        //             project_id: this.project_Id,
        //             name: model.program.name,
        //             description: model.program.description,
        //             tags: model.program.tags
        //         })
        //             .then(() => {
        //                 this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));
        //
        //                 this.unblockUI();
        //                 if (this.project_Id != null) {
        //                     this.navigate(['/projects', this.project_Id, 'code']);
        //                 }
        //
        //             })
        //             .catch((reason: IError) => {
        //                 this.fmError(reason);
        //                 this.unblockUI();
        //             });
        //     }
        // });
    }

    onAddLibraryClick() {
        let m = new ModalsCodeAddLibraryModel(this.project_Id, this.importedLibraries.map(x => x.library.id));
        let mm: ModalsCodeLibraryVersionModel = null;
        this.modalService.showModal(m)
            .then((success) => {
                if (success) {
                    mm = new ModalsCodeLibraryVersionModel(m.library.id);
                    return this.modalService.showModal(mm);
                }
                return null;
            })
            .then((success) => {
                if (success && mm && mm.libraryVersion) {
                    this.tyrionBackendService.libraryVersionGet(mm.libraryVersion.id)
                        .then((lv) => {
                            this.importedLibraries.push({library: mm.library, version: lv});
                        });
                }
            });
    }

    refreshInterface() {

        if (!this.library) {
            return;
        }

        let main = '';

        let userFiles: { [file: string]: string } = {};

        this.selectedCodeFiles.forEach((file) => {
            if (file.objectFullPath === 'main.cpp') {
                main = file.content;
            } else if (!file.library) {
                userFiles[file.objectFullPath] = file.content;
            }
        });


    }

    reloadVersions(): void {
        if (this.libraryId) {
            this.tyrionBackendService.libraryGet(this.libraryId)
                .then((library) => {
                    this.library = library;
                    this.libraryVersions = this.library.versions || [];
                });
        }
    }

    onCommunityPublicVersionClick(libraryVersion: ILibraryVersion) {
        this.modalService.showModal(new ModalsPublicShareRequestModel(this.library.name, libraryVersion.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.libraryVersionMakePublic(libraryVersion.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_was_publisher')));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    selectLibraryVersion(libraryVersion: ILibraryVersion) {
        if (!this.libraryVersions) {
            return;
        }
        if (this.libraryVersions.indexOf(libraryVersion) === -1) {
            return;
        }
        if (libraryVersion.link_to_download == null || libraryVersion.link_to_download.length === 0 ) {
            return;
        }

        this.selectedLibraryVersion = libraryVersion;

        this.tyrionBackendService.hardwareTypesGetAll().then( (types) => {
            this.libraryCompilationVersionOptions = types.filter(x => x.name === 'IODAG3E').pop().supported_libraries.map((pv) => {
                return {
                    label: pv.version_identifier,
                    value: pv.version_identifier
                };
            });
        });
        if (libraryVersion.link_to_download) {
            this.fileDownloaderService.download(libraryVersion.link_to_download)
                .then((data) => {
                    const program: ILibraryFileLoad = JSON.parse(data);

                    let codeFiles: CodeFile[] = [];
                    if (Array.isArray(program.files)) {
                        codeFiles = (program.files).map((uf) => {
                            return new CodeFile(uf.file_name, uf.content);
                        });
                    }

                    this.tyrionBackendService.librariesGetById({library_id: program.imported_libraries.map((x) => x.library_id)}).then(
                        (libraryList) => {
                            this.importedLibraries = program.imported_libraries.map((importedLib) => {
                                const library = libraryList.find((x) => x.id === importedLib.library_id);
                                const version = library.versions.find((x) => x.id === importedLib.library_version_id );
                                return {
                                    library: library,
                                    version: version
                                }
                            })
                        }
                    );

                    this.selectedCodeFiles = codeFiles;

                    this.refreshInterface();
                })
                .catch((reason) => {
                    this.fmError(reason);
                });

            this.buildErrors = null;
        }
    }

    onLibraryVersionClick(libraryVersion: ILibraryVersion) {

        if (this.selectedLibraryVersion) {

            let changedFiles: string[] = this.changesInSelectedVersion();

            if (changedFiles.length) {

                let text;
                if (this.selectedLibraryVersion.id === libraryVersion.id) {
                    text = this.translate('text_unsaved_change_reload', this.selectedLibraryVersion.name);
                } else {
                    text = this.translate('text_unsaved_change_switch', this.selectedLibraryVersion.name, libraryVersion.name);
                }

                let confirm = new ModalsConfirmModel(
                    text,
                    this.translate('text_changed_files') + changedFiles.join('<br>')
                );

                this.modalService.showModal(confirm).then((yes) => {
                    if (yes) {
                        this.selectLibraryVersion(libraryVersion);
                    }
                });

            } else {
                if (this.selectedLibraryVersion.id !== libraryVersion.id) {
                    this.selectLibraryVersion(libraryVersion);
                }
            }

        } else {
            this.selectLibraryVersion(libraryVersion);
        }
    }

    changesInSelectedVersion(): string[] {
        let changedFiles: string[] = [];
        if (Array.isArray(this.selectedCodeFiles)) {
            this.selectedCodeFiles.forEach((file) => {
                if (file.changes) {
                    changedFiles.push('/' + file.objectFullPath);
                }
            });
        }
        return changedFiles;
    }

    isSelected(version: ILibraryVersion): boolean {
        return NullSafe(() => this.selectedLibraryVersion.id) === version.id;
    }

    saveAsCode() {
        let model = new ModalsVersionDialogModel(this.library.id, 'LibraryVersion', {name: this.nextVersion(this.libraryVersions)});
        this.modalService.showModal(model).then((success) => {
            if (success) {

                let userFiles: ILibraryRecord[] = [];

                this.selectedCodeFiles.forEach((file) => {
                    userFiles.push({
                        file_name: file.objectFullPath,
                        content: file.content
                    });
                });

                this.blockUI();
                this.tyrionBackendService.libraryVersionCreate(this.libraryId, {
                    name: model.object.name,
                    description: model.object.description,
                    tags: model.object.tags,
                    files: userFiles,
                    imported_libraries: this.importedLibraries.map((lv) => { return {library_id: lv.library.id, library_version_id: lv.version.id}} ),
                })
                    .then(() => {
                        this.fmSuccess(this.translate('flash_code_version_save', model.object.name));
                        this.exitConfirmationService.setConfirmationEnabled(false);

                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.unblockUI();
                    });
            }
        });
    }

    saveCode() {
        let userFiles: ILibraryRecord[] = [];

        this.selectedCodeFiles.forEach((file) => {
            userFiles.push({
                file_name: file.objectFullPath,
                content: file.content
            });
        });

        this.blockUI();
        this.tyrionBackendService.libraryVersionWorkingcopysave(this.libraryId, {
            imported_libraries: this.importedLibraries.map((lv) => { return {library_id: lv.library.id, library_version_id: lv.version.id}} ),
            files: userFiles,
        })
            .then((program: ILibraryVersion) => {
                this.exitConfirmationService.setConfirmationEnabled(false);
                this.library.versions.push(program);

                this.unblockUI();
                // this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });

    }

    onSaveClick() {
        if (this.changesInSelectedVersion().length === 0) {
            let con = new ModalsConfirmModel(this.translate('modal_label_save_same_code'), this.translate('modal_text_no_change'), false, this.translate('btn_yes'), this.translate('btn_no'), null);

            this.modalService.showModal(con).then((success) => {
                if (!success) {
                    return;
                } else {
                    this.saveCode();
                }
            });
        } else {
            this.saveCode();
        }
    }

    onBuildClick() {
        if (!this.selectedCodeFiles) {
            return;
        }

        let main = '';

        let userFiles: ILibraryRecord[] = [];

        let libs: string[] = [];

        this.buildErrors = null;
        this.selectedCodeFiles.forEach((file) => {
            file.annotations = [];

            if (file.objectFullPath === 'main.cpp') {
                main = file.content;
            } else if (file.library) {
                libs.push(file.libraryVersionId);
            } else {
                userFiles.push({
                    file_name: file.objectFullPath,
                    content: file.content
                });
            }
        });

        this.buildInProgress = true;
        this.tyrionBackendService.libraryCompile({
            files: userFiles,
            imported_libraries: this.importedLibraries.map((lv) => { return {library_id: lv.library.id, library_version_id: lv.version.id}} ),
            library_compilation_version: this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value,
        }).then((result) => {
            this.buildInProgress = false;
            if (result.build_errors && result.build_errors.length) {
                this.buildErrors = result.build_errors;
                this.handleErrors();
                this.fmWarning(this.translate('flash_code_version_build_warnings'));
            } else {
                this.fmSuccess(this.translate('flash_code_version_build_success'));
            }
        }).catch((reason: IError) => {
            this.buildInProgress = false;
            if (reason instanceof CodeCompileError) {
                this.buildErrors = reason.errors;
                this.handleErrors();
                this.fmWarning(this.translate('flash_code_version_build_failed'));
            } else {
                this.fmError(reason);
            }
        });
        // this.tyrionBackendService.libraryC({
        //     imported_libraries: libs,
        //     main: main,
        //     files: userFiles,
        //     hardware_type_id: this.codeProgram.hardware_type.id,
        //     library_compilation_version: this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value,
        //     immediately_hardware_update: this.codeIDE.upload_after_build,
        //     hardware_ids: this.codeIDE.selectedHardware.map(x => x.id)
        // })
        //     .then((success) => {
        //         this.buildInProgress = false;
        //         this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_version_build_success')));
        //     })
        //     .catch((reason: IError) => {
        //         this.buildInProgress = false;
        //         if (reason instanceof CodeCompileError) {
        //             this.buildErrors = reason.errors;
        //
        //             // TODO: move to method
        //             let filesAnnotations: { [filename: string]: any[] } = {};
        //             this.buildErrors.forEach((bError) => {
        //                 let filename = bError.filename.substr(1);
        //                 if (!filesAnnotations[filename]) {
        //                     filesAnnotations[filename] = [];
        //                 }
        //                 filesAnnotations[filename].push({
        //                     row: bError.line - 1,
        //                     column: 1,
        //                     text: bError.text,
        //                     type: bError.type
        //                 });
        //             });
        //
        //             this.selectedCodeFiles.forEach((f) => {
        //                 if (filesAnnotations[f.objectFullPath]) {
        //                     f.annotations = filesAnnotations[f.objectFullPath];
        //                 }
        //             });
        //
        //         } else {
        //             this.fmError(reason);
        //         }
        //     });
    }

    handleErrors() {
        let filesAnnotations: { [filename: string]: any[] } = {};
        this.buildErrors.forEach((bError) => {
            let filename = bError.file_name.substr(1);
            if (!filesAnnotations[filename]) {
                filesAnnotations[filename] = [];
            }
            filesAnnotations[filename].push({
                row: bError.line - 1,
                column: 1,
                text: bError.text,
                type: bError.type
            });
        });

        this.selectedCodeFiles.forEach((f) => {
            if (filesAnnotations[f.objectFullPath]) {
                f.annotations = filesAnnotations[f.objectFullPath];
            }
        });
    }

    onRemoveLibraryClicked(library: {version: ILibraryVersion, library: ILibrary}) {
        this.importedLibraries.splice(this.importedLibraries.indexOf(library), 1);
    }

    onDropDownEmitter(action: string, version: ILibraryVersion): void {

        if (action === 'version_properties') {
            this.onEditVersionClick(version);
        }

        if (action === 'version_properties_community') {
            this.onCommunityPublicVersionClick(version);
        }

        if (action === 'version_publish') {
            this.onLibraryPublishResult(version);
        }


        if (action === 'remove_version') {
            this.onRemoveVersionClick(version);
        }
    }
}
