/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */


import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { _BaseMainComponent } from './_BaseMainComponent';
import { FlashMessageSuccess } from '../services/NotificationService';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalsRemovalModel } from '../modals/removal';
import { IBlock, IBlockList } from '../backend/TyrionAPI';
import { ModalsBlocksBlockPropertiesModel } from '../modals/blocks-block-properties';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { ModalsBlockoBlockCopyModel } from '../modals/blocko-block-copy';
import { IError } from '../services/_backend_class/Responses';

@Component({
    selector: 'bk-view-projects-project-blocks',
    template: require('./projects-project-blocks.html'),
})
export class ProjectsProjectBlocksComponent extends _BaseMainComponent implements OnInit, OnDestroy {

    projectId: string;

    routeParamsSubscription: Subscription;
    projectSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    blockList: IBlockList = null;
    blockPublicList: IBlockList = null;

    currentParamsService: CurrentParamsService; // exposed for template - filled by BaseMainComponent

    tab: string = 'my_blocks';

    pagePrivateBlocks: number = 0;
    pagePublicBlocks: number = 0;

    formFilterPrivateBlocks: FormGroup;
    formFilterPublicBlocks: FormGroup;

    constructor(injector: Injector) {
        super(injector);

        this.formFilterPrivateBlocks = this.formBuilder.group({
            'name': ['', [Validators.maxLength(60)]],
            'description': ['', [Validators.maxLength(60)]],
            'tags': [[], []],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });

        this.formFilterPublicBlocks = this.formBuilder.group({
            'name': ['', [Validators.maxLength(60)]],
            'description': ['', [Validators.maxLength(60)]],
            'tags': [[], []],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });
    };

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.projectId = params['project'];
            if (this.projectId) {
                this.onShowProgramPrivateBlocksFilter();
            } else {
                this.tab = 'public_blocks';
                this.onShowProgramPublicBlocksFilter();
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onToggleTab(tab: string) {
        this.tab = tab;

        if (tab === 'my_blocks' && this.blockList == null) {
            this.onShowProgramPrivateBlocksFilter();
        }

        if (tab === 'public_blocks' && this.blockPublicList == null) {
            this.onShowProgramPublicBlocksFilter();
        }
    }

    onPortletClick(action: string): void {
        if (action === 'add_block') {
            this.onBlockAddClick();
        }
    }

    onMakeClone(block: IBlock): void {
        let model = new ModalsBlockoBlockCopyModel(this.projectId, block);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.blockClone({
                    block_id: block.id,
                    project_id: this.projectId,
                    name: model.block.name,
                    description: model.block.description,
                    tags: model.block.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_copy_success')));
                        this.unblockUI();
                        this.onShowProgramPrivateBlocksFilter(this.pagePrivateBlocks);
                        this.tab = 'my_blocks';

                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.unblockUI();
                    });
            }
        });
    }

    onBlockAddClick = () => {

        let model = new ModalsBlocksBlockPropertiesModel(this.projectId);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.blockCreate({
                    name: model.block.name,
                    description: model.block.description,
                    project_id: this.projectId,
                    tags: model.block.tags
                })
                    .then(block => {
                        this.unblockUI();
                        this.navigate( this.onBlockClick(block.id));
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.onShowProgramPrivateBlocksFilter(this.pagePrivateBlocks);
                    });
            }
        });
    }

    onBlockEditClick(block: IBlock): void {

        let model = new ModalsBlocksBlockPropertiesModel(this.projectId, block);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.blockEdit(block.id, {
                    name: model.block.name,
                    description: model.block.description,
                    tags: model.block.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_block_edit')));
                        if (block.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateBlocksFilter(this.pagePrivateBlocks);
                        } else {
                            this.onShowProgramPublicBlocksFilter(this.pagePublicBlocks);
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (block.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateBlocksFilter(this.pagePrivateBlocks);
                        } else {
                            this.onShowProgramPublicBlocksFilter(this.pagePublicBlocks);
                        }
                    });
            }
        });

    }

    onBlockDeleteClick(block: IBlock): void {

        this.modalService.showModal(new ModalsRemovalModel(block.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.blockDelete(block.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_block_remove')));
                        if (block.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateBlocksFilter((this.blockList.content.length === 1 && this.blockList.pages > 1) ? this.pagePrivateBlocks - 1 : this.pagePrivateBlocks);
                        } else {
                            this.onShowProgramPublicBlocksFilter((this.blockPublicList.content.length === 1 && this.blockPublicList.pages > 1) ? this.pagePublicBlocks - 1 : this.pagePublicBlocks);
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (block.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateBlocksFilter(this.pagePrivateBlocks);
                        } else {
                            this.onShowProgramPublicBlocksFilter(this.pagePublicBlocks);
                        }
                    });
            }
        });
    }

    onShowProgramPrivateBlocksFilter = (page?: number): void => {
        if (page) {
            this.pagePrivateBlocks = page;
            this.blockUI();
        }

        if (!this.formFilterPrivateBlocks.valid && this.formFilterPrivateBlocks.dirty) {
            return;
        }

        this.tyrionBackendService.blockGetListByFilter( {
            project_id: this.projectId,
            name: this.formFilterPrivateBlocks.controls['name'].value,
            description: this.formFilterPrivateBlocks.controls['description'].value,
            tags: this.formFilterPrivateBlocks.controls['tags'].value,
            order_by: this.formFilterPrivateBlocks.controls['orderBy'].value,
            order_schema: this.formFilterPrivateBlocks.controls['order_schema'].value,
            page_number: this.pagePrivateBlocks
        })
            .then((list) => {
                this.blockList = list;
                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onShowProgramPublicBlocksFilter = (page?: number): void => {
        if (page) {
            this.pagePublicBlocks = page;
            this.blockUI();
        }

        if (!this.formFilterPublicBlocks.valid && this.formFilterPublicBlocks.dirty) {
            return;
        }

        this.tyrionBackendService.blockGetListByFilter({
            public_programs: true,
            name: this.formFilterPublicBlocks.controls['name'].value,
            order_by: this.formFilterPublicBlocks.controls['orderBy'].value,
            description: this.formFilterPublicBlocks.controls['description'].value,
            tags: this.formFilterPublicBlocks.controls['tags'].value,
            order_schema: this.formFilterPublicBlocks.controls['order_schema'].value,
            page_number: this.pagePublicBlocks,
        })
            .then((list) => {
                this.blockPublicList = list;
                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onDropDownEmitter(action: string, object: any): void {

        if (action === 'block_copy') {
            this.onMakeClone(object);
        }

        if (action === 'block_properties') {
            this.onBlockEditClick(object);
        }

        if (action === 'block_remove') {
            this.onBlockDeleteClick(object);
        }

        if (action === 'make_decision') {
            this.navigate(this.onBlockAdminClick(object.id));
        }
    }

}
