import { CodeFile } from './CodeIDEComponent';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Style } from './FileTreeNodeComponent';

export class FileTreeNodeObject {
    path: string;
    files: CodeFile[] = [];
    directories: FileTreeNodeObject[] = [];
    root: boolean;

    name: string;

    constructor(path: string, root = false) {
        this.path = path;
        this.root = root;
        let slicedPath = this.path.split('/');
        this.name = slicedPath[slicedPath.length - 1];
    }
}

export interface FileTreeElement {
    style: Style;
    path: string;
}

@Component({
    selector: 'bk-file-tree-root',
    template: `
        <div>
            <bk-file-tree-node *ngIf="!displaySingleFile"
            [folder] = "rootNode"
            [showControls]="showControls"
            (fileSelected)="fileSelected.emit($event.file)"
            (elementSelected) = "onElementSelected($event)"
            (fileEditClick)="fileEdit.emit($event.file)"
            (folderEditClick)="folderEditAtPath.emit($event.folder.path)"
            (fileRemoveClicked)="fileRemove.emit($event.file)"
            (folderRemoveClick)="folderRemoveAtPath.emit($event.folder.path)"
            (folderCreateClicked)="folderAddAtPath.emit($event.folder.path)"
            (fileCreateClicked)="fileAddAtPath.emit($event.folder.path)">
            </bk-file-tree-node>
            <bk-file-tree-file *ngIf="displaySingleFile && files"
                [file]="files[0]"
                (fileSelected)="fileSelected.emit($event.file)">
            </bk-file-tree-file>
        </div>
    `
})

export class FileTreeRootComponent implements OnInit, OnChanges {

    @Input()
    files: CodeFile[] = [];

    @Input()
    emptyFolders: string[] = [];

    @Input()
    showControls = false;

    @Output()
    newPathSelected = new EventEmitter<string>();

    @Output()
    fileAddAtPath = new EventEmitter<string>();

    @Output()
    folderRemoveAtPath = new EventEmitter<string>();

    @Output()
    folderAddAtPath = new EventEmitter<string>();

    @Output()
    folderEditAtPath = new EventEmitter<string>();

    @Output()
    fileEdit = new EventEmitter<CodeFile>();

    @Output()
    fileRemove = new EventEmitter<CodeFile>();

    @Output()
    fileSelected = new EventEmitter<CodeFile>();

    @Input()
    displaySingleFile = false;

    selectedPath: string;

    rootNode: FileTreeNodeObject = new FileTreeNodeObject('');

    private selectedElement: FileTreeElement;

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['files'] || changes['emptyFolders']) {
            this.createNodeHierarchy();
        }
    }

    public externalRefresh() {
        this.createNodeHierarchy();
    }

    createNodeHierarchy() {
        this.rootNode = new FileTreeNodeObject('', true);
        this.emptyFolders.sort().forEach(folder => {
            this.putFolderIntoHierarchy(folder.split('/').filter(x => x.length > 0), this.rootNode);
        });
        if (this.files) {
            this.files.sort((a, b) => {
                if (a.objectFullPath.toLowerCase() < b.objectFullPath.toLowerCase()) {
                    return -1;
                } else if (a.objectFullPath.toLowerCase() > b.objectFullPath.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            }).forEach((file) => {
                this.putFileIntoHierarchy(file, file.objectFullPath.split('/'), this.rootNode);
            });
        }
    }

    putFileIntoHierarchy(file: CodeFile, remainingPath: string[], directory: FileTreeNodeObject) {
        if (remainingPath.length > 1) {
            let nextDirectory = remainingPath[0];
            let foundDirectory = directory.directories.find((node) => { return node.name === nextDirectory; } );
            if (foundDirectory) {
                this.putFileIntoHierarchy(file, remainingPath.slice(1), foundDirectory);
            } else {
                let newDirectory = new FileTreeNodeObject(directory.path.length === 0 ? nextDirectory : directory.path + '/' + nextDirectory);
                directory.directories.push(newDirectory);
                this.putFileIntoHierarchy(file, remainingPath.slice(1), newDirectory);
            }
        } else {
            directory.files.push(file);
        }
    }

    putFolderIntoHierarchy(remainingPath: string[], directory: FileTreeNodeObject) {
        if (remainingPath.length > 1) {
            let nextDirectory = remainingPath[0];
            let foundDirectory = directory.directories.find((node) => { return node.name === nextDirectory; } );
            if (foundDirectory) {
                this.putFolderIntoHierarchy(remainingPath.slice(1), foundDirectory);
            } else {
                let newDirectory = new FileTreeNodeObject(directory.path.length === 0 ? nextDirectory : directory.path + '/' + nextDirectory);
                directory.directories.push(newDirectory);
                this.putFolderIntoHierarchy(remainingPath.slice(1), newDirectory);
            }
        } else {
            let newDirectory = new FileTreeNodeObject(directory.path.length === 0 ? remainingPath[0] : directory.path + '/' + remainingPath[0]);
            directory.directories.push(newDirectory);
        }
    }

    onElementSelected(element: FileTreeElement) {
        if (this.selectedElement) {
            this.selectedElement.style.selected = false;
        }
        this.selectedElement = element;
        this.selectedPath = element.path;
        this.selectedElement.style.selected = true;
        this.newPathSelected.emit(element.path);
    }
}


