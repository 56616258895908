import { Component, Input } from '@angular/core';

@Component({
    selector: 'bk-type-of-update',
    template: require('./TypeOfUpdateComponent.html')
})
export class TypeOfUpdateComponent {

    @Input()
    state: string = '';

}
