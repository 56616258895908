/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import moment = require('moment/moment');
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SafeMachineError, TypescriptBuildError, MachineMessage, SafeMachineMessage } from 'script-engine';
import { TranslationService } from '../services/TranslationService';


export interface ConsoleLogItem {
    timestamp: string;
    type: string;
    message: string;
    alias?: string;
    source?: string;
    color?: string;
}

export type ConsoleLogType = 'log' | 'error' | 'output' | 'info' | 'warn' | 'debug' | 'trace' | 'mqtt';

@Component({
    selector: 'bk-console-log',
    /* tslint:disable:max-line-length */
    template: `
        <a  class="monaco-toggle-fullscreen" (click)="onReverseClick()"></a>
        <div #scrollable class="console-log-table-wrapper" [style.min-height]="(startOnMaxHeight?maxHeight:'20px')" [style.max-height]="maxHeight" >
            <a class="fa fa-fw fa-arrows-v"  style="top:10px; left:100%; position: sticky; max-width: 20px !important; margin-right: 40px;" (click)="onReverseClick()"></a>
            <a class="fa fa-fw fa-trash"  style="top:10px; left:100%; position: sticky; max-width: 20px !important; margin-right: 10px;" (click)="clear()"></a>

            <table  class="table table-fixed table-hover table-light" style="word-break: break-all;">
                <tbody>
                <ng-container>
                    <tr *ngFor="let log of (topToBottom ? items.slice().reverse() : items)" class="console-log-tr">
                        <td>
                            <div class="console-log-head">
                                <span class="console-log-timestamp">{{log.timestamp}}&nbsp;</span>
                                <span *ngIf="log.type == 'error'" class="font-red"               >[Error]</span>
                                <span *ngIf="log.type == 'warn'"  class="font-yellow"            >[Warn]&nbsp;</span>
                                <span *ngIf="log.type == 'debug'" class="font-purple"            >[Debug]</span>
                                <span *ngIf="log.type == 'trace'" class="font-grey-salsa"        >[Trace]</span>
                                <span *ngIf="log.type == 'info'"  class="font-color-skunks-blue">[Info]&nbsp;&nbsp;</span>
                                <span *ngIf="log.type == 'log'"   class="font-grey-salsa"        >[Log]&nbsp;&nbsp;&nbsp;</span>
                                <span *ngIf="log.type == 'mqtt'"  class="font-green-haze"        >[MQTT]</span>
                            </div>
                            <div class="console-log-message">
                                <ng-template [ngIf]="log.source || log.alias">
                                    <span [style.color]="sourceColor[log.source] ? sourceColor[log.source].color : ''" class="bold">{{log.alias?log.alias:log.source}}</span>
                                    <i class="fa fa-fw fa-angle-right"></i>
                                </ng-template>
                            </div>
                            <br>
                            <span class="margin-left-30"
                                [class.font-purple]="log.type === 'debug'"
                                [class.font-grey-salsa]="log.type === 'trace' || log.type === 'log'"
                                [class.font-color-skunks-blue]="log.type === 'info'"
                                [class.font-green-haze]="log.type === 'mqtt'"
                                [class.font-red]="log.type === 'error'"
                                [class.font-yellow]="log.type === 'warn'"
                                [innerHTML]="log.message | bkTerminalHtmlPipe">
                            </span>
                        </td>
                    </tr>
                </ng-container>

                <tr *ngIf="items.length == 0">
                    <td class="text-center">
                        <i>{{'label_console_is_empty'|bkTranslate:this}}</i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
`
    /* tslint:enable */
})
export class ConsoleLogComponent implements AfterViewChecked, AfterViewInit {

    @Input()
    maxHeight: string = null;

    @Input()
    startOnMaxHeight: boolean = true;

    @Input()
    topToBottom = true;

    @ViewChild('scrollable') private scrollableElement: ElementRef;

    items: ConsoleLogItem[] = [];

    sourceColor: {[source_id: string]: {
        color: string
    }} = {};

    shouldScroll = true;

    constructor(private translationService: TranslationService) {
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    ngAfterViewInit() {
        this.scrollableElement.nativeElement.scrollTop = this.scrollableElement.nativeElement.scrollHeight;
    }

    isCompleteScrolled() {
        return this.scrollableElement.nativeElement.scrollHeight - this.scrollableElement.nativeElement.scrollTop - this.scrollableElement.nativeElement.clientHeight < 5;
    }

    scrollToBottom() {
        if ( this.shouldScroll ) {
            this.scrollableElement.nativeElement.scrollTop = this.scrollableElement.nativeElement.scrollHeight - this.scrollableElement.nativeElement.clientHeight;
            this.shouldScroll = false;
        }
    }

    setShouldScroll() {
        this.shouldScroll = this.topToBottom && this.isCompleteScrolled();
    }

    onReverseClick() {
        this.topToBottom = !this.topToBottom;
    }

    clear() {
        this.items = [];
    }

    translate(key: string, ...args: any[]): string {
        return this.translationService.translate(key, this, null, args);
    }

    set_color(source: string, color: string) {
        // console.log('set_Color: source', source, 'color', color);
        if (!this.sourceColor[source]) {
            this.sourceColor[source] = {
                color: color
            };
        } else {
            this.sourceColor[source].color = color;
        }
    }

    /**
     *
     * @param {ConsoleLogType} type
     * @param {string} message
     * @param {string} source
     * @param {string} alias
     * @param {string} timestamp
     */
    add(type: ConsoleLogType, message: string, source?: string, alias?: string, timestamp?: string) {
        this.setShouldScroll();
        try {
            if (!timestamp) {
                timestamp = moment().format('HH:mm:ss.SSS');
            }
            this.items.unshift({
                timestamp: timestamp,
                type: type,
                message: message.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'),
                source: source,
                alias: alias
            });
        } catch (exc) {
            console.error('ConsoleLogComponent:: add', exc);
        }
    }

    addFromMessage(message: MachineMessage, source?: string, timestamp?: string) {
        this.setShouldScroll();
        if (!timestamp) {
            timestamp = moment().format('HH:mm:ss.SSS');
        }

        let msg = '<strong>' + message.message + '</strong>';
        let posInfo = '';
        if (message instanceof SafeMachineMessage) {
            if (message.position && message.position.lineA != null) {
                if (message.position.lineA === message.position.lineB) {
                    posInfo = '<br>' + this.translate('label_position_and_line') + ' <strong>'
                        + message.position.lineA
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + message.position.columnA
                        + '</strong> - <strong>'
                        + message.position.columnB
                        + '</strong>';
                } else {
                    posInfo = '<br>+ ' + this.translate('label_position_and_line') + ' + <strong>'
                        + message.position.lineA
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + message.position.columnA
                        + '</strong> - ' + this.translate('label_line') + ' <strong>'
                        + message.position.lineB
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + message.position.columnB
                        + '</strong>';
                }
            }
        }

        this.items.unshift({
            timestamp: timestamp,
            type: 'warn',
            message: msg + posInfo,
            source: source
        });
    }

    addFromError(error: Error, source?: string, timestamp?: string) {
        this.setShouldScroll();
        if (!timestamp) {
            timestamp = moment().format('HH:mm:ss.SSS');
        }
        if (error instanceof SafeMachineError) {
            let msg = '<strong>' + error.message + '</strong>';
            if (typeof error.original === 'object') {
                msg = '<strong>' + (<Error>error.original).name + '</strong>: ' + (<Error>error.original).message;
            }

            let posInfo = '';
            if (error.position) {
                if (error.position.lineA === error.position.lineB) {
                    posInfo = '<br>' + this.translate('label_position_and_line') + ' <strong>'
                        + error.position.lineA
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + error.position.columnA
                        + '</strong> - <strong>'
                        + error.position.columnB
                        + '</strong>';
                } else {
                    posInfo = '<br><strong>Position:</strong> line <strong>'
                        + error.position.lineA
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + error.position.columnA
                        + '</strong> - ' + this.translate('label_line') + ' <strong>'
                        + error.position.lineB
                        + '</strong> ' + this.translate('label_column') + ' <strong>'
                        + error.position.columnB
                        + '</strong>';
                }
            }

            /*
            let stackInfo = "";
            if (error.safeMachineStack) {
                console.log(error.safeMachineStack);
                stackInfo = "<br><strong>Stack:</strong> ";
                error.safeMachineStack.forEach((sms) => {
                    stackInfo += "<br>&nbsp;&nbsp;&nbsp;&nbsp;" + sms.deepDir + " " + sms.lineA + ":" + sms.columnA + " - " + sms.lineB + ":" + sms.columnB;
                });
            }
            */

            this.items.unshift({
                timestamp: timestamp,
                type: 'error',
                message: msg + posInfo,
                source: source
            });
        } else if (error instanceof TypescriptBuildError) {
            if (!error.diagnostics) {
                this.items.unshift({
                    timestamp: timestamp,
                    type: 'error',
                    message: '<strong>' + this.translate('label_typescript_error') + '</strong>: ' + (<Error>error).message,
                    source: source
                });
            } else {
                error.diagnostics.forEach((d) => {
                    this.items.unshift({
                        timestamp: timestamp,
                        type: 'error',
                        message: '<strong>' + this.translate('label_typescript_error') + ' #' + d.code + '</strong>: ' + d.messageText,
                        source: source
                    });
                });
            }
        } else if (error instanceof Error) {
            let msg = '<strong>' + (<Error>error).name + '</strong>: ' + (<Error>error).message;
            this.items.unshift({
                timestamp: timestamp,
                type: 'error',
                message: msg,
                source: source
            });
        } else {
            this.items.unshift({
                timestamp: timestamp,
                type: 'error',
                message: '' + error,
                source: source
            });
        }
    }

}
