/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { TranslationService } from '../services/TranslationService';
import { Input, Output, EventEmitter, Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { ModalModel } from '../services/ModalService';
import {
    IHardware, IHardwareGroup, IHardwareGroupList, IHardwareList, IHardwareType
} from '../backend/TyrionAPI';
import { NotificationService } from '../services/NotificationService';
import { BeckiAsyncValidators } from '../helpers/BeckiAsyncValidators';
import { IError } from '../services/_backend_class/Responses';
import { BeckiValidators } from '../helpers/BeckiValidators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { handleStatus } from '../backend/BeckiBackend';


export class ModalsSelectHardwareModel extends ModalModel {
    public selected_hardware: IHardware[] = [];
    public selected_hardware_group: IHardwareGroup;
    public selected_hardware_groups: IHardwareGroup[] = [];
    constructor(
        public project_id: string,
        public hardware_type: IHardwareType = null,
        public multiple_select_support: boolean = true,
        public support_select_hw_groups = false,
        public support_select_hw = false,
        public preselected_group: IHardwareGroup = null,   // already selected hw groups
        public hw_group: IHardwareGroup = null               // for add HW to group and show what is in group
    ) {
        super();
        this.modalLarge = true;
    }
}

@Component({
    selector: 'bk-modals-select-hardware',
    template: require('./select-hardware.html')
})
export class ModalsSelectHardwareComponent implements OnInit, OnDestroy {

    @Input()
    modalModel: ModalsSelectHardwareModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    errorMessage: string = null;

    tab: string = '';

    devicesFilter: IHardwareList = null;
    groupFilter: IHardwareGroupList = null;
    selectedHardwareList: { [id: string]: IHardware } = {};
    selectedHardwareGroupList: { [id: string]: IHardwareGroup } = {};
    selectedGroup: IHardwareGroup;

    page: number = 0;
    formHardwareFilter: FormGroup;

    ngUnsubscribe = new Subject<void>();

    constructor(private tyrionBackendService: TyrionBackendService, private formBuilder: FormBuilder, private translationService: TranslationService, private notificationService: NotificationService) {
        this.formHardwareFilter = this.formBuilder.group({
            'alias': ['', [Validators.maxLength(60), BeckiValidators.forbiddenSymbol(/[^\x20-\x7E]/g)]],
            'id': ['', [Validators.maxLength(60)], [BeckiAsyncValidators.validUUID()]],
            'full_id': ['', [Validators.maxLength(60)]],
            'description': ['', [Validators.maxLength(60)]],
            'tags': [[], []],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });
    }

    ngOnInit() {

        if (this.modalModel.support_select_hw) {
            this.tab = 'hardware';
            setTimeout(() => {
                this.onFilterHardware();
            }, 100);
        } else if (this.modalModel.support_select_hw_groups) {
            this.tab = 'group';
            setTimeout(() => {
                this.onFilterHardwareGroup();
            }, 100);
        }

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    // TOGGLE TAB & PORTLET BUTTONS
    onToggleTab(tab: string) {
        this.tab = tab;

        if (this.tab === 'group' && this.groupFilter === null ) {
            this.onFilterHardwareGroup();
        }
    }

    onFilterHardware(page?: number): void {

        if (page) {
            this.page = page;
        }

        if (!this.formHardwareFilter.valid && this.formHardwareFilter.dirty) {
            return;
        }

        this.tyrionBackendService.boardsGetListByFilter({
            projects: [this.modalModel.project_id],
            hardware_type_ids: [ (this.modalModel.hardware_type != null) ? this.modalModel.hardware_type.id : null],
            count_on_page: 10,
            order_by: this.formHardwareFilter.controls['orderBy'].value,
            order_schema: this.formHardwareFilter.controls['order_schema'].value,
            full_id: this.formHardwareFilter.controls['full_id'].value,
            id: this.formHardwareFilter.controls['id'].value,
            name: this.formHardwareFilter.controls['alias'].value,
            description: this.formHardwareFilter.controls['description'].value,
            tags: this.formHardwareFilter.controls['tags'].value,
            page_number: this.page,
        })
            .then((values) => {
                this.devicesFilter = values;
                this.devicesFilter.content.forEach((device) => {
                    this.tyrionBackendService.observeNetworkStatus(device.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(handleStatus(device));
                });

                if (this.modalModel.hw_group) {
                    this.devicesFilter.content.forEach((device) => {
                        if (device.hardware_group && device.hardware_group.id === this.modalModel.hw_group.id) {
                            this.selectedHardwareList[device.id] = device;
                        }
                    });
                }
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
                this.errorMessage = reason.message;
            });
    }

    onFilterHardwareGroup(page?: number): void {

        if (page) {
            this.page = page;
        }

        this.tyrionBackendService.hardwareGroupGetListByFilter( {
            project_id : this.modalModel.project_id,
            count_on_page: 10,
            page_number: this.page,
        })
            .then((values) => {
                this.groupFilter = values;

                if (this.modalModel.preselected_group) {
                    let group: IHardwareGroup = this.groupFilter.content.find((g) => {
                        return g.id === this.modalModel.preselected_group.id;
                    });

                    if (group) {
                        this.selectedGroup = group;
                    }
                }
            })
            .catch((reason: IError) => {
                this.notificationService.fmError(reason);
                this.errorMessage = reason.message;
            });
    }

    onHardwareAddToList(hardware: IHardware): void {
        this.selectedHardwareList[hardware.id] = hardware;
        if (!this.modalModel.multiple_select_support) {
            this.onSubmitClick();
        }
    }

    onGroupAddToList(group: IHardwareGroup): void {
        this.selectedHardwareGroupList[group.id] = group;
    }

    onGroupSelect(group: IHardwareGroup): void {
        this.selectedGroup = group;
        this.selectedHardwareGroupList[group.id] = group;
        if (!this.modalModel.multiple_select_support) {
            this.onSubmitClick();
        }
    }

    onHardwareRemoveFromList(hardware: IHardware): void {
        delete this.selectedHardwareList[hardware.id];
    }

    onGroupRemoveFromList(group: IHardwareGroup): void {
        delete this.selectedHardwareGroupList[group.id];
    }

    onDropDownEmitter(action: string, object: any): void {
        if (action === 'label_select_hardware') {
            this.onHardwareAddToList(object);
        }
        if (action === 'label_remove_hardware') {
            this.onHardwareRemoveFromList(object);
        }
        if (action === 'label_select_group') {
            this.onGroupSelect(object);
        }
    }

    onSubmitClick(): void {
        // this.modalModel.selected_hardware = []; TODO maybe clean the array?
        for (let i in this.selectedHardwareList) {
            if (this.selectedHardwareList.hasOwnProperty(i)) {
                this.modalModel.selected_hardware.push(this.selectedHardwareList[i]);
            }
        }

        // this.modalModel.selected_hardware_groups = []; TODO maybe clean the array?
        for (let i in this.selectedHardwareGroupList) {
            if (this.selectedHardwareGroupList.hasOwnProperty(i)) {
                this.modalModel.selected_hardware_groups.push(this.selectedHardwareGroupList[i]);
            }
        }

        this.modalModel.selected_hardware_group  = this.selectedGroup;

        this.modalClose.emit(true);
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
