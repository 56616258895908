/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { IProject, IRoleList, IRole } from '../backend/TyrionAPI';
import { FlashMessageSuccess } from '../services/NotificationService';
import { CurrentParamsService } from '../services/CurrentParamsService';

import { ModalsRemovalModel } from '../modals/removal';
import { ModalsPermissionGroupModel } from '../modals/permission-group';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IError } from '../services/_backend_class/Responses';

@Component({
    selector: 'bk-view-permission-group',
    template: require('./admin-permission-group.html')
})
export class RoleGroupComponent extends _BaseMainComponent implements OnInit, OnDestroy {
    project_id: string;

    routeParamsSubscription: Subscription;
    projectSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    project: IProject = null;

    selfId: string = '';

    securityRoleList: IRoleList = null;

    currentParamsService: CurrentParamsService; // exposed for template - filled by _BaseMainComponent

    constructor(injector: Injector) {
        super(injector);
    };

    onPortletClick(action: string): void {
        if (action === 'create_role') {
            this.onRoleCreateClick();
        }
    }

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (params['project']) {
                this.project_id = params['project'];
                this.projectSubscription = this.storageService.project(this.project_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((project) => {
                    this.project = project;
                });
            }
            this.refresh();
        });
        this.selfId = this.tyrionBackendService.personInfoSnapshot.id;
        this.refresh();
    }



    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    refresh(): void {
        this.onFilterRole();
    }

    onFilterRole = (pageNumber: number = 0) => {
        this.blockUI();
        this.tyrionBackendService.roleGetListByFilter( {
            project_id: this.project_id,
            page_number: pageNumber
        })
            .then((values) => {
                this.unblockUI();
                this.securityRoleList = values;
            })
            .catch((reason: IError) => {
                this.unblockUI();
                this.fmError(reason);
            });
    }

    onRoleClick(role_id: string): void {
        if (this.project_id) {
            this.navigate(['projects', this.project_id, 'roles', role_id]);
        } else {
            this.navigate(['admin/permission-group', role_id]);
        }
    }

    onRoleCreateClick = () => {
        let model = new ModalsPermissionGroupModel();
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.roleCreate({
                    description: model.description,
                    name: model.name
                })
                    .then(role => {
                        this.onRoleClick(role.id);
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRoleEditClick(role: IRole): void {
        let model = new ModalsPermissionGroupModel(role.name, role.description);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.roleEdit(role.id, {
                    description: model.description,
                    name: model.name
                })
                    .then(() => {
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRoleRemoveClick(role: IRole): void {
        this.modalService.showModal(new ModalsRemovalModel(role.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.roleDelete(role.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_successfully_remove')));
                        this.refresh(); // also unblockUI
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onDropDownEmitter (action: string, role: IRole): void {
        if (action === 'edit_role') {
            this.onRoleEditClick(role);
        }

        if (action === 'remove_role') {
            this.onRoleRemoveClick(role);
        }
    }

}




