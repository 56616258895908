import { AfterViewInit, Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IHardwareNetworkStatesList } from '../backend/TyrionAPI';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BeckiValidators } from '../helpers/BeckiValidators';
import { Filter_parameter, TIME_DATE_SELECT } from './FilterTableComponent';
import { _BaseMainComponent } from '../views/_BaseMainComponent';
import { IMyDpOptions } from 'mydatepicker';


@Component({
    selector: 'bk-hardware-online-state',
    template: require('./HardwareNetworkStatusComponent.html')
})
export class HardwareNetworkStatusComponent extends _BaseMainComponent implements AfterViewInit, OnChanges {

    @Input() projectId: string;

    @Input()
    set additional(additional: {filterParameters: Filter_parameter[], formFilterGroup: FormGroup}) {
        if (additional !== null) {
            this.formFilterGroup = additional.formFilterGroup;
            const date = new Date();
            const firstDay = new Date();
            firstDay.setDate(date.getDate() - 7);

            if (!this.formFilterGroup.contains('dateFrom')) {
                this.formFilterGroup.addControl('dateFrom', new FormControl({jsdate: firstDay}, [Validators.required]));
            }

            if (!this.formFilterGroup.contains('dateTo')) {
                this.formFilterGroup.addControl('dateTo', new FormControl({jsdate: date}, [Validators.required]));
            }

            this.filterParameters = additional.filterParameters.concat([
                {
                    type: 'TIME_DATE_SELECT',
                    content: <TIME_DATE_SELECT>{
                        label: 'Time from',
                        key: 'dateFrom',
                        form: this.formFilterGroup,
                        dateOption: this.dateOptions
                    }
                },
                {
                    type: 'TIME_DATE_SELECT',
                    content: <TIME_DATE_SELECT>{
                        label: 'Time To',
                        key: 'dateTo',
                        form: this.formFilterGroup,
                        dateOption: this.dateOptions
                    }
                }
            ]);
        }
    }

    @Input() preselectedHardwareId: String;

    formFilterGroup: FormGroup;

    page: number = 0;

    filterParameters: Filter_parameter[] = null;

    // result from tyrion
    states: IHardwareNetworkStatesList;

    dateOptions: IMyDpOptions = { // can be found here: https://github.com/kekeh/mydatepicker/blob/master/README.md#options-attribute
        dateFormat: 'dd.mm.yyyy',
        showTodayBtn: true,
        showClearDateBtn: false,
        editableDateField: false,
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        inline: false
    };

    constructor(injector: Injector) {
        super(injector);

        const date = new Date();
        const firstDay = new Date();
        firstDay.setDate(date.getDate() - 7);
        // Filter Helper
        this.formFilterGroup = this.formBuilder.group({
            'alias': ['', [Validators.maxLength(60), BeckiValidators.forbiddenSymbol(/[^\x20-\x7E]/g)]],
            'id': ['', [Validators.maxLength(60), BeckiValidators.uuid()]],
            'full_id': ['', [Validators.maxLength(60)]],
            'description': ['', [Validators.maxLength(60)]],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
            'tags': [[], []],
            'hardware_groups_id': ['', []],
            'dateFrom': [{jsdate: firstDay}, [Validators.required]],
            'dateTo': [{jsdate: date}, [Validators.required]],
            'count_on_page': [5, [Validators.required]],
        });


        this.filterParameters = [
            {
                type: 'TIME_DATE_SELECT',
                content: <TIME_DATE_SELECT>{
                    label: 'Time from',
                    key: 'dateFrom',
                    form: this.formFilterGroup,
                    dateOption: this.dateOptions
                }
            },
            {
                type: 'TIME_DATE_SELECT',
                content: <TIME_DATE_SELECT>{
                    label: 'Time To',
                    key: 'dateTo',
                    form: this.formFilterGroup,
                    dateOption: this.dateOptions
                }
            }
        ];
    };

    ngOnChanges(changes: SimpleChanges): void {
        this.onFilterHardware();
    }

    ngAfterViewInit(): void {
    }

    onFilterHardware = (page?: number): void => {

        if (page) {
            this.page = page;
        }

        this.blockUI();
        this.tyrionBackendService.hardwareGetStates({
            projects: [this.projectId],
            hardware_groups_id: this.formFilterGroup.controls['hardware_groups_id'].value ? this.formFilterGroup.controls['hardware_groups_id'].value : null,
            hardware_type_ids: [],
            order_by: this.formFilterGroup.controls['orderBy'].value,
            order_schema: this.formFilterGroup.controls['order_schema'].value,
            full_id: this.formFilterGroup.controls['full_id'].value,
            id: this.preselectedHardwareId ? this.preselectedHardwareId : this.formFilterGroup.controls['id'].value,
            name: this.formFilterGroup.controls['alias'].value,
            description: this.formFilterGroup.controls['description'].value,
            tags: this.formFilterGroup.controls['tags'].value,
            start_date: this.formFilterGroup.controls['dateFrom'].value.jsdate.getTime(),
            end_date: this.formFilterGroup.controls['dateTo'].value.jsdate.getTime(),
            count_on_page: this.formFilterGroup.controls['count_on_page'].value,
            page_number: this.page
        }).then(data => {
            // Not unblocking here because chart takes some time to be ready. When chart is ready onChartReady is called, which unblocks UI
            // this.unblockUI();
            this.states = data;
        }).catch(reason => {
            this.unblockUI();
            this.fmError(reason);
        })
    }

    onChartReady = () => {
        this.unblockUI();
    }


}
