/**************************************************************/
/*                                                            */
/*   Generated Tyrion API backend file                        */
/*   © 2015-2016 Becki Authors.                               */
/*                                                            */
/*   Build date: 2020-02-24 16:59:20                          */
/*   Tyrion version: 2.3.44                                   */
/*                                                            */
/*   Generated by automated script from Swagger JSON.         */
/*   Script author: Skunks                                   */
/*                                                            */
/**************************************************************/

/* tslint:disable */

import { HomerAPI } from './HomerAPI';
/**
 * @name IApplicableProduct
 * @description: Json Model for selecting Product for registration new project
 */
export interface IApplicableProduct {
    /**
     * @name id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     * @readonly
     * @required
     */
    name: string;
    /**
     * @name tariff
     * @type string
     * @readonly
     * @required
     */
    tariff: string;
}


/**
 * @name IArticle
 * @description: Model of Article
 */
export interface IArticle {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name mark_down_text
     * @type string
     * @required
     */
    mark_down_text: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IArticleCreateUpdate
 * @description: Json Model for create or update Article
 */
export interface IArticleCreateUpdate {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name mark_down_text
     * @type string
     * @required
     */
    mark_down_text: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IArticleFilter
 * @description: Json Model for getting Article Filter List
 */
export interface IArticleFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name tags
     * @type string[]
     * @description List of Tagst
     */
    tags?: string[];
}


/**
 * @name IArticleList
 * @description: Article List
 */
export interface IArticleList {
    /**
     * @name content
     * @type IArticle[]
     * @readonly
     * @required
     */
    content: IArticle[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IAuthorizationToken
 * @description: Model of AuthorizationToken
 */
export interface IAuthorizationToken {
    /**
     * @name access_age
     * @type number
     * @description UNIX time in ms
     * @example 1466163478925
     * @format int32
     * @readonly
     * @required
     */
    access_age: number;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name provider_key
     * @type string
     * @required
     */
    provider_key: string;
    /**
     * @name provider_user_id
     * @type string
     * @required
     */
    provider_user_id: string;
    /**
     * @name return_url
     * @type string
     * @required
     */
    return_url: string;
    /**
     * @name social_token_verified
     * @type boolean
     * @required
     */
    social_token_verified: boolean;
    /**
     * @name token
     * @type string
     * @format uuid
     * @required
     */
    token: string;
    /**
     * @name type_of_connection
     * @type string
     * @required
     */
    type_of_connection: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name user_agent
     * @type string
     * @required
     */
    user_agent: string;
    /**
     * @name where_logged
     * @type ("BECKI_WEBSITE"|"HOMER_SERVER"|"E_STORE")
     * @description Record, where user make login
     * @required
     */
    where_logged: ("BECKI_WEBSITE"|"HOMER_SERVER"|"E_STORE");
}


/**
 * @name IBProgram
 * @description: Model of BProgram
 */
export interface IBProgram {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type IBProgramVersion[]
     */
    versions?: IBProgramVersion[];
}


/**
 * @name IBProgramVersion
 * @description: Model of BProgram Version
 */
export interface IBProgramVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name grid_project_snapshots
     * @type IBProgramVersionSnapGridProject[]
     */
    grid_project_snapshots?: IBProgramVersionSnapGridProject[];
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name IBProgramVersionSnapGridProject
 * @description: Model of Snapshot of versions of M_Project Snapshots
 */
export interface IBProgramVersionSnapGridProject {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name grid_programs
     * @type IBProgramVersionSnapGridProjectProgram[]
     * @required
     */
    grid_programs: IBProgramVersionSnapGridProjectProgram[];
    /**
     * @name grid_project
     * @type IGridProject
     * @required
     */
    grid_project: IGridProject;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IBProgramVersionSnapGridProjectProgram
 * @description: Interface IBProgramVersionSnapGridProjectProgram definition
 */
export interface IBProgramVersionSnapGridProjectProgram {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name grid_program
     * @type IGridProgram
     * @readonly
     * @required
     */
    grid_program: IGridProgram;
    /**
     * @name grid_program_version
     * @type IGridProgramVersion
     * @required
     */
    grid_program_version: IGridProgramVersion;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IBProgramCopy
 * @description: Json Model for copy B_Program
 */
export interface IBProgramCopy {
    /**
     * @name b_program_id
     * @type string
     * @format uuid
     * @required
     */
    b_program_id: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IBProgramFilter
 * @description: Json Model for getting B_Program Filter List
 */
export interface IBProgramFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name pending_programs
     * @type boolean
     * @description Designed for Administrators for publishing decisions of Community Codes - Without permission, the value is ignored.
     */
    pending_programs?: boolean;
    /**
     * @name project_id
     * @type string
     * @description Include only if you want to get b_programs of given project
     * @format uuid
     */
    project_id?: string;
    /**
     * @name public_programs
     * @type boolean
     * @description Show - All Public Programs which are confirmed and approved.
     */
    public_programs?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IBProgramList
 * @description: Individual B_Program List
 */
export interface IBProgramList {
    /**
     * @name content
     * @type IBProgram[]
     * @readonly
     * @required
     */
    content: IBProgram[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IBProgramVersionNew
 * @description: Json Model for new Version of B_Program
 */
export interface IBProgramVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name m_project_snapshots
     * @type IMProjectSnapShot[]
     * @description Connected groups of grid_programs.versions under imported grid_programs
     */
    m_project_snapshots?: IMProjectSnapShot[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name program
     * @type string
     * @required
     */
    program: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IBase64File
 * @description: Json Model for upload File to server
 */
export interface IBase64File {
    /**
     * @name file
     * @type string
     * @description The file is encoded in base64. If it is null - it is a command to delete a file
     */
    file?: string;
}


/**
 * @name IBinaryDetails
 * @description: Interface IBinaryDetails definition
 */
export interface IBinaryDetails {
    /**
     * @name bss
     * @type number
     * @format int32
     * @required
     */
    bss: number;
    /**
     * @name data
     * @type number
     * @format int32
     * @required
     */
    data: number;
    /**
     * @name dec
     * @type number
     * @format int32
     * @required
     */
    dec: number;
    /**
     * @name text
     * @type number
     * @format int32
     * @required
     */
    text: number;
}


/**
 * @name IBlob
 * @description: Model of Blob
 */
export interface IBlob {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link
     * @type string
     * @required
     */
    link: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name path
     * @type string
     * @required
     */
    path: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IBlock
 * @description: Model of Block
 */
export interface IBlock {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name producer
     * @type IProducer
     * @description can be hidden, if BlockoBlock is created by User not by Company
     * @readonly
     */
    producer?: IProducer;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type IBlockVersion[]
     * @required
     */
    versions: IBlockVersion[];
}


/**
 * @name IBlockVersion
 * @description: Model of BlockVersion
 */
export interface IBlockVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name IBlockFilter
 * @description: Json Model for getting Block Filter List
 */
export interface IBlockFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Description
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Name
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name pending_blocks
     * @type boolean
     * @description Only for Admins with permissions
     */
    pending_blocks?: boolean;
    /**
     * @name project_id
     * @type string
     * @description Include only if you want to get Blocks of given project
     * @format uuid
     */
    project_id?: string;
    /**
     * @name public_programs
     * @type boolean
     * @description Show - All Public Programs which are confirmed and approved.
     */
    public_programs?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IBlockList
 * @description: Individual Block List
 */
export interface IBlockList {
    /**
     * @name content
     * @type IBlock[]
     * @readonly
     * @required
     */
    content: IBlock[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IBlockoBlockCopy
 * @description: Json Model for copy Block
 */
export interface IBlockoBlockCopy {
    /**
     * @name block_id
     * @type string
     * @format uuid
     * @required
     */
    block_id: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IBlockoTokenValidationRequest
 * @description: Json Model for validation of Token for HTTP requests
 */
export interface IBlockoTokenValidationRequest {
    /**
     * @name token
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    token: string;
    /**
     * @name type_of_token
     * @type string
     * @example PERSON_TOKEN, INSTANCE_TOKEN
     * @readonly
     * @required
     */
    type_of_token: string;
}


/**
 * @name IBlockoTokenValidationResult
 * @description: Json Model for validation of Token for HTTP requests
 */
export interface IBlockoTokenValidationResult {
    /**
     * @name available_requests
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    available_requests: number;
    /**
     * @name token
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    token: string;
}


/**
 * @name IBoardParameter
 * @description: Interface IBoardParameter definition
 */
export interface IBoardParameter {
    /**
     * @name key
     * @type ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME")
     * @required
     */
    key: ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME");
    /**
     * @name value
     * @type string
     * @required
     */
    value: string;
}


/**
 * @name IBoardBootloaderUpdate
 * @description: Interface IBoardBootloaderUpdate definition
 */
export interface IBoardBootloaderUpdate {
    /**
     * @name bootloader_id
     * @type string
     * @description If bootloader_is empty, system will used latest recommended version
     * @format uuid
     */
    bootloader_id?: string;
    /**
     * @name device_ids
     * @type string[]
     * @description Must be unique!!!, The hardware_id must have 20 hexadecimal characters!, It can combination of master devices and others
     * @required
     */
    device_ids: string[];
}


/**
 * @name IBoardCommand
 * @description: Json Model for developers commands to Hardware. For example restart, redirect etc. Please, use that, only if you know, what you are doing.
 */
export interface IBoardCommand {
    /**
     * @name command
     * @type ("RESTART"|"SWITCH_TO_BOOTLOADER"|"BLINK")
     * @description Command
     * @required
     */
    command: ("RESTART"|"SWITCH_TO_BOOTLOADER"|"BLINK");
    /**
     * @name hardware_id
     * @type string
     * @description Length must be between 0 and 255 characters.
     * @format uuid
     * @required
     */
    hardware_id: string;
}


/**
 * @name IBoardDeveloperSettings
 * @description: Json Model for update personal description of Board
 */
export interface IBoardDeveloperSettings {
    /**
     * @name key
     * @type string
     * @required
     */
    key: string;
    /**
     * @name value
     * @type boolean
     * @required
     */
    value: boolean;
}


/**
 * @name IBoardDeveloperParameters
 * @description: Json Model for update personal description of Board
 */
export interface IBoardDeveloperParameters {
    /**
     * @name hardware_group_ids
     * @type string[]
     * @description list of HW Group ids for which the parameter should be changed
     */
    hardware_group_ids?: string[];
    /**
     * @name hardware_ids
     * @type string[]
     * @description list of HW ids for which the parameter should be changed
     */
    hardware_ids?: string[];
    /**
     * @name parameters
     * @type IBoardParameter[]
     * @required
     */
    parameters: IBoardParameter[];
}


/**
 * @name IBoardGetByHash
 * @description: Json model for set Backup Mode on devices
 */
export interface IBoardGetByHash {
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name registration_hash
     * @type string
     * @required
     */
    registration_hash: string;
}


/**
 * @name IBoardNewGarfield
 * @description: Json Model for create new Board
 */
export interface IBoardNewGarfield {
    /**
     * @name batch_id
     * @type string
     * @description Required valid batch_id
     * @required
     */
    batch_id: string;
    /**
     * @name full_id
     * @type string
     * @description Must be unique!!!, The hardware_id must have 24 hexadecimal characters!
     * @required
     */
    full_id: string;
    /**
     * @name hardware_type_id
     * @type string
     * @description Required valid hardware_type_id
     * @format uuid
     * @required
     */
    hardware_type_id: string;
    /**
     * @name registration_hash
     * @type string
     * @description Must be unique!!!, The must have between 24 and 32 characters!
     */
    registration_hash?: string;
}


/**
 * @name IBoardNewManual
 * @description: Json Model for create new Board
 */
export interface IBoardNewManual {
    /**
     * @name full_id
     * @type string
     * @description Must be unique!!!, The hardware_id must have 24 hexadecimal characters!
     * @required
     */
    full_id: string;
    /**
     * @name hardware_type_id
     * @type string
     * @description Required valid hardware_type_id
     * @format uuid
     * @required
     */
    hardware_type_id: string;
}


/**
 * @name IBoardServerRedirect
 * @description: Interface IBoardServerRedirect definition
 */
export interface IBoardServerRedirect {
    /**
     * @name server_id
     * @type string
     * @description Required only if server_url & server_port is null
     * @format uuid
     */
    server_id?: string;
    /**
     * @name server_port
     * @type string
     * @description Required only if server_id is null && server_url is not null
     */
    server_port?: string;
    /**
     * @name server_url
     * @type string
     * @description Required only if server_id is null && server_port is not null
     */
    server_url?: string;
}


/**
 * @name IBoardFilter
 * @description: Json Model for Filtering Boards
 */
export interface IBoardFilter {
    /**
     * @name active
     * @type string
     * @description Boolean - required in String! "true" or "false "
     */
    active?: string;
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name end_time
     * @type number
     * @description Unix in second - created - end_time
     * @example 1536424319
     * @format int32
     */
    end_time?: number;
    /**
     * @name full_id
     * @type string
     * @description Hardware Full ID
     */
    full_id?: string;
    /**
     * @name hardware_groups_id
     * @type string[]
     * @description List of hardware_group.id
     */
    hardware_groups_id?: string[];
    /**
     * @name hardware_type_ids
     * @type string[]
     * @description List of hardware_type.id
     */
    hardware_type_ids?: string[];
    /**
     * @name id
     * @type string
     * @description Hardware ID or part of ID (not necessary to send UUID)
     * @format uuid
     */
    id?: string;
    /**
     * @name instance_snapshot
     * @type string
     * @description List of instance_snapshot.id
     * @format uuid
     */
    instance_snapshot?: string;
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"FULL_ID"|"GROUP_NAME"|"ID"|"DATE_OF_REGISTER"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"FULL_ID"|"GROUP_NAME"|"ID"|"DATE_OF_REGISTER"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name processors
     * @type string[]
     * @description List of processor.id
     */
    processors?: string[];
    /**
     * @name producers
     * @type string[]
     * @description List of producer.id
     */
    producers?: string[];
    /**
     * @name projects
     * @type string[]
     * @description List of project.id
     */
    projects?: string[];
    /**
     * @name start_time
     * @type number
     * @description Unix in second - created - start_time
     * @example 1536424319
     * @format int32
     */
    start_time?: number;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IBootLoader
 * @description: Model of BootLoader
 */
export interface IBootLoader {
    /**
     * @name changing_note
     * @type string
     * @required
     */
    changing_note: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name file_path
     * @type string
     * @readonly
     */
    file_path?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name main_bootloader
     * @type boolean
     * @readonly
     */
    main_bootloader?: boolean;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name version_identifier
     * @type string
     * @required
     */
    version_identifier: string;
}


/**
 * @name IBootLoaderCreate
 * @description: Json Model for create Bootloader
 */
export interface IBootLoaderCreate {
    /**
     * @name changing_note
     * @type string
     * @description Length must be between 8 and 2500 characters.
     * @required
     */
    changing_note: string;
    /**
     * @name description
     * @type string
     * @description Length must be between 8 and 2500 characters.
     * @required
     */
    description: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 8 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name version_identifier
     * @type string
     * @description Length must be between 8 and 60 characters.
     * @required
     */
    version_identifier: string;
}


/**
 * @name IBootloaderUpdateProgram
 * @description: Interface IBootloaderUpdateProgram definition
 */
export interface IBootloaderUpdateProgram {
    /**
     * @name bootloader_id
     * @type string
     * @description Can be empty
     * @format uuid
     * @readonly
     * @required
     */
    bootloader_id: string;
    /**
     * @name bootloader_name
     * @type string
     * @description Can be empty
     * @readonly
     * @required
     */
    bootloader_name: string;
    /**
     * @name hardware_type_id
     * @type string
     * @description Can be empty
     * @format uuid
     * @readonly
     * @required
     */
    hardware_type_id: string;
    /**
     * @name hardware_type_name
     * @type string
     * @description Can be empty
     * @readonly
     * @required
     */
    hardware_type_name: string;
    /**
     * @name version_identificator
     * @type string
     * @description Can be empty
     * @readonly
     * @required
     */
    version_identificator: string;
}


/**
 * @name ICProgramVersion
 * @description: Model of CProgramVersion
 */
export interface ICProgramVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name binary_details
     * @type IBinaryDetails
     * @readonly
     */
    binary_details?: IBinaryDetails;
    /**
     * @name build_errors
     * @type ICompilationFailure
     * @readonly
     */
    build_errors?: ICompilationFailure;
    /**
     * @name compilation_version
     * @type string
     * @readonly
     * @required
     */
    compilation_version: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name download_link_bin_file
     * @type string
     * @description Link for download file in Binary (Not in Base64). Its ready to manual Upload. Only if "status" == "SUCCESS"
     * @readonly
     */
    download_link_bin_file?: string;
    /**
     * @name gcc_version
     * @type string
     * @readonly
     * @required
     */
    gcc_version: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name main_mark
     * @type boolean
     * @description Visible only for Administrator with Special Permission
     * @readonly
     */
    main_mark?: boolean;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name status
     * @type ("IN_PROGRESS"|"FILE_NOT_FOUND"|"BROKEN_JSON"|"SERVER_OFFLINE"|"SERVER_ERROR"|"FAILED"|"SUCCESS_DOWNLOAD_FAILED"|"SUCCESS"|"UNSTABLE"|"UNDEFINED")
     * @readonly
     * @required
     */
    status: ("IN_PROGRESS"|"FILE_NOT_FOUND"|"BROKEN_JSON"|"SERVER_OFFLINE"|"SERVER_ERROR"|"FAILED"|"SUCCESS_DOWNLOAD_FAILED"|"SUCCESS"|"UNSTABLE"|"UNDEFINED");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name virtual_input_output
     * @type string
     * @description Value can be empty, Server cannot guarantee that.
     * @readonly
     * @required
     */
    virtual_input_output: string;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name ICProgram
 * @description: Object represented C_Program in database
 */
export interface ICProgram {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name default_version
     * @type ICProgramVersion
     * @description Visible only for Administrators with permission - its default version of Main Program of each hardware type
     * @readonly
     */
    default_version?: ICProgramVersion;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name hardware_type
     * @type IHardwareType
     * @required
     */
    hardware_type: IHardwareType;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type ICProgramVersion[]
     * @readonly
     */
    versions?: ICProgramVersion[];
}


/**
 * @name ICProgramCopy
 * @description: Json Model for copy C_Program
 */
export interface ICProgramCopy {
    /**
     * @name c_program_id
     * @type string
     * @format uuid
     * @required
     */
    c_program_id: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ICProgramFilter
 * @description: Json Model for getting C_Program Filter List
 */
export interface ICProgramFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Name - substring supported
     */
    description?: string;
    /**
     * @name hardware_type_ids
     * @type string[]
     * @description Return by Type Of Board - and only codes with permissions
     */
    hardware_type_ids?: string[];
    /**
     * @name name
     * @type string
     * @description Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name pending_programs
     * @type boolean
     * @description Designed for Administrators for publishing decisions of Community Codes - Without permission, the value is ignored.
     */
    pending_programs?: boolean;
    /**
     * @name project_id
     * @type string
     * @description Include only if you want to get C_Programs of given project
     * @format uuid
     */
    project_id?: string;
    /**
     * @name public_programs
     * @type boolean
     * @description Show - All Public Programs which are confirmed and approved.
     */
    public_programs?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name ICProgramList
 * @description: Individual C_Program List
 */
export interface ICProgramList {
    /**
     * @name content
     * @type ICProgram[]
     * @readonly
     * @required
     */
    content: ICProgram[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name ICProgramNew
 * @description: Json Model for new C_Program
 */
export interface ICProgramNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name hardware_type_id
     * @type string
     * @format uuid
     * @required
     */
    hardware_type_id: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     */
    project_id?: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ICProgramUpdateProgram
 * @description: Interface ICProgramUpdateProgram definition
 */
export interface ICProgramUpdateProgram {
    /**
     * @name c_program_id
     * @type string
     * @description Can be empty
     * @format uuid
     * @readonly
     * @required
     */
    c_program_id: string;
    /**
     * @name c_program_program_name
     * @type string
     * @description Can be empty
     * @readonly
     * @required
     */
    c_program_program_name: string;
    /**
     * @name c_program_version_id
     * @type string
     * @description Can be empty
     * @format uuid
     * @readonly
     * @required
     */
    c_program_version_id: string;
    /**
     * @name c_program_version_name
     * @type string
     * @description Can be empty
     * @readonly
     * @required
     */
    c_program_version_name: string;
    /**
     * @name firmware_type
     * @type ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI")
     * @description Can be empty
     * @readonly
     * @required
     */
    firmware_type: ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI");
}


/**
 * @name ICProgramVersionNew
 * @description: Json Model for new Version of C_Program
 */
export interface ICProgramVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name files
     * @type ILibraryRecord[]
     */
    files?: ILibraryRecord[];
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     * @description Contains IDs of imported Library versions
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
    /**
     * @name library_compilation_version
     * @type string
     * @description The Library Version tag_name from HardwareType.supported_libraries
     * @required
     */
    library_compilation_version: string;
    /**
     * @name macro
     * @type string
     */
    macro?: string;
    /**
     * @name main
     * @type string
     * @required
     */
    main: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ICProgramVersionRefresh
 * @description: Json Model for new Working Copy of C_Program
 */
export interface ICProgramVersionRefresh {
    /**
     * @name files
     * @type ILibraryRecord[]
     */
    files?: ILibraryRecord[];
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     * @description Contains IDs of imported Library versions
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
    /**
     * @name library_compilation_version
     * @type string
     * @description The Library Version tag_name from HardwareType.supported_libraries
     */
    library_compilation_version?: string;
    /**
     * @name macro
     * @type string
     */
    macro?: string;
    /**
     * @name main
     * @type string
     * @required
     */
    main: string;
}


/**
 * @name ICProgramVersionUpdate
 * @description: Json Model for new Version of C_Program
 */
export interface ICProgramVersionUpdate {
    /**
     * @name files
     * @type ILibraryRecord[]
     * @readonly
     */
    files?: ILibraryRecord[];
    /**
     * @name hardware_ids
     * @type string[]
     */
    hardware_ids?: string[];
    /**
     * @name hardware_type_id
     * @type string
     * @description Required only if user compile code not under C++ code version (where compilation can found hardware_type)
     * @format uuid
     */
    hardware_type_id?: string;
    /**
     * @name immediately_hardware_update
     * @type boolean
     * @description Only if user want update hardware with compilation immediately.
     */
    immediately_hardware_update?: boolean;
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     * @description List ID of libraries version ID
     * @readonly
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
    /**
     * @name library_compilation_version
     * @type string
     * @description The Library Version tag_name from HardwareType.supported_libraries
     */
    library_compilation_version?: string;
    /**
     * @name macro
     * @type string
     */
    macro?: string;
    /**
     * @name main
     * @type string
     * @required
     */
    main: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ICloudCompilationServerCompilationResult
 * @description: Interface ICloudCompilationServerCompilationResult definition
 */
export interface ICloudCompilationServerCompilationResult {
    /**
     * @name binary_details
     * @type IBinaryDetails
     * @required
     */
    binary_details: IBinaryDetails;
    /**
     * @name build_errors
     * @type ICompilationBuildError[]
     * @required
     */
    build_errors: ICompilationBuildError[];
    /**
     * @name interface_code
     * @type string
     * @readonly
     */
    interface_code?: string;
}


/**
 * @name ICloudHomerServerNewAuto
 * @description: Json Model for new Blocko Server
 */
export interface ICloudHomerServerNewAuto {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Optiona value - only for private server, for public server, user need permission
     * @format uuid
     */
    project_id?: string;
    /**
     * @name region_slug
     * @type string
     * @required
     */
    region_slug: string;
    /**
     * @name size_slug
     * @type string
     * @required
     */
    size_slug: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ICloudHomerServerNewManually
 * @description: Json Model for new Blocko Server
 */
export interface ICloudHomerServerNewManually {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;

    /**
     * @name mqtt_port
     * @type number
     * @format int32
     * @required
     */
    mqtt_port: number;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Optiona value - only for private server, for public server, user need permission
     * @format uuid
     */
    project_id?: string;

    /**
     * @name server_url
     * @type string
     * @required
     */
    server_url: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];

}


/**
 * @name ICommunityVersionPublishResponse
 * @description: Json Model for admin response for publishing C_Program
 */
export interface ICommunityVersionPublishResponse {
    /**
     * @name decision
     * @type boolean
     * @required
     */
    decision: boolean;
    /**
     * @name program_description
     * @type string
     * @required
     */
    program_description: string;
    /**
     * @name program_name
     * @type string
     * @required
     */
    program_name: string;
    /**
     * @name reason
     * @type string
     * @required
     */
    reason: string;
    /**
     * @name version_description
     * @type string
     * @required
     */
    version_description: string;
    /**
     * @name version_id
     * @type string
     * @format uuid
     * @required
     */
    version_id: string;
    /**
     * @name version_name
     * @type string
     * @required
     */
    version_name: string;
}


/**
 * @name ICompilationFailure
 * @description: Interface ICompilationFailure definition
 */
export interface ICompilationFailure {
    /**
     * @name build_errors
     * @type ICompilationBuildError[]
     */
    build_errors?: ICompilationBuildError[];
    /**
     * @name system_error
     * @type string
     */
    system_error?: string;
}


/**
 * @name ICompilationLibrary
 * @description: Model of CompilationLibrary
 */
export interface ICompilationLibrary {
    /**
     * @name changing_note
     * @type string
     * @required
     */
    changing_note: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name deprecated
     * @type boolean
     * @required
     */
    deprecated: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name hardware_types
     * @type IHardwareType[]
     */
    hardware_types?: IHardwareType[];
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name version_identifier
     * @type string
     * @required
     */
    version_identifier: string;
}


/**
 * @name ICompilationLibraryClean
 * @description: Interface ICompilationLibraryClean definition
 */
export interface ICompilationLibraryClean {
    /**
     * @name libraries
     * @type string[]
     * @required
     */
    libraries: string[];
}


/**
 * @name ICompilationLibraryCreate
 * @description: Json Model for create Bootloader
 */
export interface ICompilationLibraryCreate {
    /**
     * @name changing_note
     * @type string
     * @description Length must be between 8 and 2500 characters.
     * @required
     */
    changing_note: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name hardware_type_ids
     * @type string[]
     * @description Ids of supported hardware types.
     * @required
     */
    hardware_type_ids: string[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name version_identifier
     * @type string
     * @description Length must be between 8 and 60 characters.
     * @required
     */
    version_identifier: string;
}


/**
 * @name ICompilationLibraryFilter
 * @description: Json Model for Filtering Compilation Library
 */
export interface ICompilationLibraryFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name hardware_type_ids
     * @type string[]
     * @description List of hardware_type.id
     */
    hardware_type_ids?: string[];
    /**
     * @name order_by
     * @type ("VERSION_IDENTIFIER"|"DATE_OF_CREATE")
     * @description Order By Enum value
     */
    order_by?: ("VERSION_IDENTIFIER"|"DATE_OF_CREATE");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
}


/**
 * @name ICompilationLibraryList
 * @description: Individual Compilation Library List
 */
export interface ICompilationLibraryList {
    /**
     * @name content
     * @type ICompilationLibrary[]
     * @readonly
     * @required
     */
    content: ICompilationLibrary[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name ICompilationBuildError
 * @description: Json Model for Compilation Result
 */
export interface ICompilationBuildError {
    /**
     * @name adjusted_column
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    adjusted_column: number;
    /**
     * @name code
     * @type string
     * @readonly
     * @required
     */
    code: string;
    /**
     * @name code_white_space
     * @type string
     * @readonly
     * @required
     */
    code_white_space: string;
    /**
     * @name column
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    column: number;
    /**
     * @name end_index
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    end_index: number;
    /**
     * @name file_name
     * @type string
     * @readonly
     * @required
     */
    file_name: string;
    /**
     * @name line
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    line: number;
    /**
     * @name start_index
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    start_index: number;
    /**
     * @name state
     * @type string
     * @description Value is build_error
     * @readonly
     * @required
     */
    state: string;
    /**
     * @name text
     * @type string
     * @readonly
     * @required
     */
    text: string;
    /**
     * @name type
     * @type string
     * @readonly
     * @required
     */
    type: string;
}


/**
 * @name ICompilationServer
 * @description: Model of CompilationServer
 */
export interface ICompilationServer {
    /**
     * @name connection_identificator
     * @type string
     * @readonly
     */
    connection_identificator?: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name gcc_version
     * @type string
     * @required
     */
    gcc_version: string;
    /**
     * @name hash_certificate
     * @type string
     * @readonly
     */
    hash_certificate?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name online_state
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @required
     */
    online_state: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
    /**
     * @name personal_server_name
     * @type string
     * @required
     */
    personal_server_name: string;
    /**
     * @name server_url
     * @type string
     * @readonly
     * @required
     */
    server_url: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name ICompilationServerNew
 * @description: Json Model for new Compilation Server
 */
export interface ICompilationServerNew {
    /**
     * @name personal_server_name
     * @type string
     * @description Unique name For Compiler cloud_blocko_server, The name length must be between 6 and 60 characters
     * @required
     */
    personal_server_name: string;
    /**
     * @name server_url
     * @type string
     * @required
     */
    server_url: string;
}


/**
 * @name IContact
 * @description: Model of Contact
 */
export interface IContact {
    /**
     * @name bank_account
     * @type string
     * @description Used for billing and invoices
     */
    bank_account?: string;
    /**
     * @name city
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    city: string;
    /**
     * @name company_account
     * @type boolean
     * @required
     */
    company_account: boolean;
    /**
     * @name company_authorized_email
     * @type string
     * @description Only if Product is for business
     */
    company_authorized_email?: string;
    /**
     * @name company_authorized_phone
     * @type string
     * @description Only if Product is for business
     */
    company_authorized_phone?: string;
    /**
     * @name company_registration_no
     * @type string
     * @description Only if Product is for business
     */
    company_registration_no?: string;
    /**
     * @name company_vat_number
     * @type string
     * @description Only if Product is for business
     */
    company_vat_number?: string;
    /**
     * @name company_web
     * @type string
     * @description Only if Product is for business
     */
    company_web?: string;
    /**
     * @name country
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    country: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name invoice_email
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    invoice_email: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name street
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    street: string;
    /**
     * @name street_number
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    street_number: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name zip_code
     * @type string
     * @description Used for billing and invoices
     * @required
     */
    zip_code: string;
}


/**
 * @name IContactUpdate
 * @description: Json Model with List of Board ID
 */
export interface IContactUpdate {
    /**
     * @name city
     * @type string
     * @description Required: always
     * @required
     */
    city: string;
    /**
     * @name company_account
     * @type boolean
     * @description Required: only if user want have business account
     */
    company_account?: boolean;
    /**
     * @name company_authorized_email
     * @type string
     * @description Required: only if company_account = trueThe company_authorized_email must have at least 4 characters
     */
    company_authorized_email?: string;
    /**
     * @name company_authorized_phone
     * @type string
     * @description Required: only if company_account = trueThe company_authorized_phone must have at least 4 characters
     */
    company_authorized_phone?: string;
    /**
     * @name company_name
     * @type string
     * @description Required: only if company_account = trueThe name must have at least 4 characters
     */
    company_name?: string;
    /**
     * @name company_registration_no
     * @type string
     * @description Required: only if company_account = trueThe company_registration_no must have at least 4 characters
     */
    company_registration_no?: string;
    /**
     * @name company_vat_number
     * @type string
     * @description Required: only if company_account = true And VAT_number is required only for EU Customers.The VAT_number must have at least 4 characters
     * @example CZ12345678
     */
    company_vat_number?: string;
    /**
     * @name company_web
     * @type string
     * @description Required: only if company_account = trueThe company_web url must be valid
     */
    company_web?: string;
    /**
     * @name country
     * @type string
     * @description Required: alwaysThe country must have at least 4 characters
     * @required
     */
    country: string;
    /**
     * @name invoice_email
     * @type string
     * @description Required: always. Email must be valid
     * @required
     */
    invoice_email: string;
    /**
     * @name name
     * @type string
     * @description Required: alwaysThe name must have at least 4 characters
     * @required
     */
    name: string;
    /**
     * @name street
     * @type string
     * @description Required: always
     * @required
     */
    street: string;
    /**
     * @name street_number
     * @type string
     * @description Required: alwaysBut The street_number can be empty
     * @required
     */
    street_number: string;
    /**
     * @name zip_code
     * @type string
     * @description Required: alwaysThe zip_code must have at least 5 digits
     * @required
     */
    zip_code: string;
}


/**
 * @name ICustomer
 * @description: Model of Customer
 */
export interface ICustomer {
    /**
     * @name contact
     * @type IContact
     * @required
     */
    contact: IContact;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name emails
     * @type string[]
     * @required
     */
    emails: string[];
    /**
     * @name employees
     * @type IEmployee[]
     * @required
     */
    employees: IEmployee[];
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name ICustomerEmployee
 * @description: For adding employees to a company.
 */
export interface ICustomerEmployee {
    /**
     * @name customer_id
     * @type string
     * @format uuid
     * @required
     */
    customer_id: string;
    /**
     * @name mails
     * @type string[]
     * @required
     */
    mails: string[];
}


/**
 * @name IDataConsumptionFilter
 * @description: Json Model for getting Data From Filter List
 */
export interface IDataConsumptionFilter {
    /**
     * @name blocked
     * @type boolean
     * @required
     */
    blocked: boolean;
    /**
     * @name country_code
     * @type string[]
     * @description Default value null, Use ISO 3166-1 alpha-2/alpha-3/numeric Country code. For all set one value "ALL"  for get all countries
     */
    country_code?: string[];
    /**
     * @name from
     * @type number
     * @description Default Value Year 2018.01.01
     * @format int32
     */
    from?: number;
    /**
     * @name project_id
     * @type string
     * @description Required if you have not Admin permission
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name sim_id_list
     * @type string[]
     * @description Include only if you want to get Roles of given project
     */
    sim_id_list?: string[];
    /**
     * @name sim_msi_list
     * @type number[]
     * @description Include only if you want to get Roles of given project
     */
    sim_msi_list?: number[];
    /**
     * @name time_period
     * @type ("MONTH"|"WEEK"|"DAY"|"HOUR")
     * @description Default value MONTH
     */
    time_period?: ("MONTH"|"WEEK"|"DAY"|"HOUR");
    /**
     * @name to
     * @type number
     * @description Default Value Year 2018.30.12
     * @format int32
     */
    to?: number;
}


/**
 * @name IDataByMsisdn
 * @description: Json Model for gsm data by msisdn
 */
export interface IDataByMsisdn {
    /**
     * @name _id
     * @type number
     * @format int32
     * @required
     */
    _id: number;
    /**
     * @name data
     * @type ISingleValue[]
     * @required
     */
    data: ISingleValue[];
}


/**
 * @name IDatabase
 * @description: Json Model for Database
 */
export interface IDatabase {
    /**
     * @name collections
     * @type string[]
     * @readonly
     */
    collections?: string[];
    /**
     * @name connection_string
     * @type string
     * @readonly
     */
    connection_string?: string;
    /**
     * @name description
     * @type string
     * @readonly
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @readonly
     */
    id?: string;
    /**
     * @name name
     * @type string
     * @readonly
     */
    name?: string;
}


/**
 * @name IEmailAndPassword
 * @description: Json Model with email and password
 */
export interface IEmailAndPassword {
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
    /**
     * @name password
     * @type string
     * @description The password length must be between 8 and 60 characters
     * @required
     */
    password: string;
}


/**
 * @name IEmailRequired
 * @description: Json Model with email
 */
export interface IEmailRequired {
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
}


/**
 * @name IEmployee
 * @description: Model of Employee
 */
export interface IEmployee {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name person
     * @type IPerson
     */
    person?: IPerson;
    /**
     * @name state
     * @type ("OWNER"|"ADMIN"|"MEMBER"|"INVITED")
     * @required
     */
    state: ("OWNER"|"ADMIN"|"MEMBER"|"INVITED");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IEntityRegistrationStatus
 * @description: Json Model for Status and all information about embedded Hardware, GSM Modul, etx..
 */
export interface IEntityRegistrationStatus {
    /**
     * @name status
     * @type ("CAN_REGISTER"|"NOT_EXIST"|"ALREADY_REGISTERED_IN_YOUR_ACCOUNT"|"ALREADY_REGISTERED"|"PERMANENTLY_DISABLED"|"BROKEN_DEVICE")
     * @description CAN_REGISTER, ALREADY_REGISTERED_IN_YOUR_ACCOUNT, ALREADY_REGISTERED, PERMANENTLY_DISABLED, BROKEN_DEVICE
     * @example CAN_REGISTER
     * @readonly
     * @required
     */
    status: ("CAN_REGISTER"|"NOT_EXIST"|"ALREADY_REGISTERED_IN_YOUR_ACCOUNT"|"ALREADY_REGISTERED"|"PERMANENTLY_DISABLED"|"BROKEN_DEVICE");
}


/**
 * @name IEntityValidationIn
 * @description: Json Model for Validation of *-some Entity
 */
export interface IEntityValidationIn {
    /**
     * @name key
     * @type ("email"|"nick_name"|"vat_number")
     * @description This field is required
     * @required
     */
    key: ("email"|"nick_name"|"vat_number");
    /**
     * @name value
     * @type string
     * @description This field is required
     * @required
     */
    value: string;
}


/**
 * @name IEntityValidationOut
 * @description: Json Model for Validation of *-some Entity
 */
export interface IEntityValidationOut {
    /**
     * @name message
     * @type string
     * @description If valid = false, Json probably contains message for user
     * @example Email is used
     * @readonly
     */
    message?: string;
    /**
     * @name valid
     * @type boolean
     * @description Entity (Email, NickName.. etc) is valid if valid = true
     * @readonly
     * @required
     */
    valid: boolean;
}


/**
 * @name IExtensionFinancialEvent
 * @description: Interface IExtensionFinancialEvent definition
 */
export interface IExtensionFinancialEvent {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name event_end
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    event_end: number;
    /**
     * @name event_start
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    event_start: number;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name invoice
     * @type IInvoice
     * @required
     */
    invoice: IInvoice;
    /**
     * @name product_extension
     * @type IProductExtension
     * @required
     */
    product_extension: IProductExtension;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IExtensionFinancialEventSearch
 * @description: Filter data for searching in Extension Financial Events
 */
export interface IExtensionFinancialEventSearch {
    /**
     * @name extension_id
     * @type string
     * @format uuid
     */
    extension_id?: string;
    /**
     * @name from
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     */
    from?: number;
    /**
     * @name invoice_id
     * @type string
     * @format uuid
     */
    invoice_id?: string;
    /**
     * @name product_id
     * @type string
     * @format uuid
     */
    product_id?: string;
    /**
     * @name to
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     */
    to?: number;
}


/**
 * @name IFacebookLogin
 * @description: Json Model for Login on Social Networks
 */
export interface IFacebookLogin {
    /**
     * @name auth_token
     * @type string
     * @description Token for Cookies
     * @format uuid
     * @required
     */
    auth_token: string;
    /**
     * @name link
     * @type string
     * @description Valid URL fo redirect
     * @required
     */
    link: string;
}


/**
 * @name IFacebookLoginRedirect
 * @description: Json Model for Login with link for Redirection
 */
export interface IFacebookLoginRedirect {
    /**
     * @name redirect_link
     * @type string
     * @description Required log_level
     * @required
     */
    redirect_link: string;
}


/**
 * @name IGSM
 * @description: Model of GSM
 */
export interface IGSM {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name daily_data_traffic
     * @type number
     * @description If value is in -1 - its mean that we have no idea, probably missing traffic or error on partner server
     * @format int32
     * @readonly
     */
    daily_data_traffic?: number;
    /**
     * @name daily_statistic
     * @type boolean
     * @required
     */
    daily_statistic: boolean;
    /**
     * @name daily_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    daily_traffic_threshold_notify_type: boolean;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name hardware
     * @type IHardware[]
     * @readonly
     */
    hardware?: IHardware[];
    /**
     * @name iccid
     * @type string
     * @required
     */
    iccid: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name imsi
     * @type string
     * @required
     */
    imsi: string;
    /**
     * @name monthly_statistic
     * @type boolean
     * @required
     */
    monthly_statistic: boolean;
    /**
     * @name monthly_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    monthly_traffic_threshold_notify_type: boolean;
    /**
     * @name msi_number
     * @type number
     * @format int32
     * @required
     */
    msi_number: number;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name sim_tm_status
     * @type ITMSimStatus
     * @description If value is in -1 - its mean that we have no idea, probably missing traffic or error on partner server
     */
    sim_tm_status?: ITMSimStatus;
    /**
     * @name sim_type
     * @type ("CARD"|"CHIP")
     * @required
     */
    sim_type: ("CARD"|"CHIP");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name total_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    total_traffic_threshold_notify_type: boolean;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name weekly_statistic
     * @type boolean
     * @required
     */
    weekly_statistic: boolean;
    /**
     * @name weekly_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    weekly_traffic_threshold_notify_type: boolean;
}


/**
 * @name IGSMCDRList
 * @description: Interface IGSMCDRList definition
 */
export interface IGSMCDRList {
    /**
     * @name content
     * @type IModelMongoThingsMobileCDR[]
     * @readonly
     * @required
     */
    content: IModelMongoThingsMobileCDR[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IGSMCRDFilter
 * @description: Interface IGSMCRDFilter definition
 */
export interface IGSMCRDFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name gsm_ids
     * @type string[]
     * @required
     */
    sim_id_list: string[];
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
}


/**
 * @name IGSMFilter
 * @description: Interface IGSMFilter definition
 */
export interface IGSMFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name gsm_ids
     * @type string[]
     */
    gsm_ids?: string[];
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
}


/**
 * @name IGSMList
 * @description: Interface IGSMList definition
 */
export interface IGSMList {
    /**
     * @name content
     * @type IGSM[]
     * @readonly
     * @required
     */
    content: IGSM[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IGSMRegister
 * @description: Json Model for register SIMCard Modul to project
 */
export interface IGSMRegister {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name hardware_id
     * @type string
     * @format uuid
     * @required
     */
    hardware_id: string;
    /**
     * @name name
     * @type string
     * @description Length must be max 60 characters.
     */
    name?: string;
    /**
     * @name registration_hash
     * @type string
     * @format uuid
     * @required
     */
    registration_hash: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IGSMUpdate
 * @description: Interface IGSMUpdate definition
 */
export interface IGSMUpdate {
    /**
     * @name block_sim_daily
     * @type boolean
     * @required
     */
    block_sim_daily: boolean;
    /**
     * @name block_sim_monthly
     * @type boolean
     * @required
     */
    block_sim_monthly: boolean;
    /**
     * @name block_sim_total
     * @type boolean
     * @required
     */
    block_sim_total: boolean;
    /**
     * @name daily_statistic
     * @type boolean
     * @required
     */
    daily_statistic: boolean;
    /**
     * @name daily_traffic_threshold
     * @type number
     * @description Value in MB (Only round)
     * @format int32
     * @required
     */
    daily_traffic_threshold: number;
    /**
     * @name daily_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    daily_traffic_threshold_notify_type: boolean;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name monthly_statistic
     * @type boolean
     * @required
     */
    monthly_statistic: boolean;
    /**
     * @name monthly_traffic_threshold
     * @type number
     * @description Value in MB (Only round)
     * @format int32
     * @required
     */
    monthly_traffic_threshold: number;
    /**
     * @name monthly_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    monthly_traffic_threshold_notify_type: boolean;
    /**
     * @name name
     * @type string
     * @description name or alias can be null or maximum length of 255 characters.
     */
    name?: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name total_traffic_threshold
     * @type number
     * @description Value in MB (Only round)
     * @format int32
     * @required
     */
    total_traffic_threshold: number;
    /**
     * @name total_traffic_threshold_notify_type
     * @type boolean
     * @required
     */
    total_traffic_threshold_notify_type: boolean;
    /**
     * @name weekly_statistic
     * @type boolean
     * @required
     */
    weekly_statistic: boolean;
}


/**
 * @name IGridProgram
 * @description: Model of GridProgram
 */
export interface IGridProgram {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type IGridProgramVersion[]
     * @required
     */
    versions: IGridProgramVersion[];
}


/**
 * @name IGridProgramVersion
 * @description: Model of GridProgramVersion
 */
export interface IGridProgramVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name m_program_virtual_input_output
     * @type string
     */
    m_program_virtual_input_output?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name public_access
     * @type boolean
     * @required
     */
    public_access: boolean;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name IGridProgramCopy
 * @description: Json Model for copy GridProgram
 */
export interface IGridProgramCopy {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name grid_program_id
     * @type string
     * @format uuid
     * @required
     */
    grid_program_id: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IGridProject
 * @description: Model of GridProject
 */
export interface IGridProject {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name m_programs
     * @type IGridProgram[]
     * @readonly
     * @required
     */
    m_programs: IGridProgram[];
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IGridProjectFilter
 * @description: Json Model for Filtering Grids Projects
 */
export interface IGridProjectFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IGridProjectList
 * @description: Individual GridProject List
 */
export interface IGridProjectList {
    /**
     * @name content
     * @type IGridProject[]
     * @readonly
     * @required
     */
    content: IGridProject[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IGridTerminal
 * @description: Model of GridTerminal
 */
export interface IGridTerminal {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name device_name
     * @type string
     * @example Iphone 5S, Chrome 4
     */
    device_name?: string;
    /**
     * @name device_type
     * @type string
     * @example Mobile, WebBrowser
     */
    device_type?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name m_program_access
     * @type boolean
     * @required
     */
    m_program_access: boolean;
    /**
     * @name terminal_token
     * @type string
     * @example Mobile, WebBrowser
     * @readonly
     * @required
     */
    terminal_token: string;
    /**
     * @name up_to_date
     * @type boolean
     * @required
     */
    up_to_date: boolean;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name user_agent
     * @type string
     * @example Mozilla/5.0 (iPad; U; CPU OS 3_2_1 like Mac OS X; en-us) AppleWebKit/531.21.10 (KHTML, like Gecko) Mobile/7B405
     * @readonly
     */
    user_agent?: string;
    /**
     * @name ws_permission
     * @type boolean
     * @required
     */
    ws_permission: boolean;
}


/**
 * @name IGridWidgetVersionNew
 * @description: Json Model for crating new version and also content of GridWidget Model
 */
export interface IGridWidgetVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name logic_json
     * @type string
     * @required
     */
    logic_json: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IGridWidgetFilter
 * @description: Json Model for getting GridWidget Filter List
 */
export interface IGridWidgetFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Description - substring supported
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name pending_widgets
     * @type boolean
     * @description Only for Admins with permissions
     */
    pending_widgets?: boolean;
    /**
     * @name project_id
     * @type string
     * @description Include only if you want to get Widgets of given project
     * @format uuid
     */
    project_id?: string;
    /**
     * @name public_programs
     * @type boolean
     * @description Show - All Public Programs which are confirmed and approved.
     */
    public_programs?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IGridWidgetList
 * @description: Individual GridWidget List
 */
export interface IGridWidgetList {
    /**
     * @name content
     * @type IWidget[]
     * @readonly
     * @required
     */
    content: IWidget[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IGridTerminalIdentf
 * @description: Json Model for Identificator
 */
export interface IGridTerminalIdentf {
    /**
     * @name device_name
     * @type string
     * @example Iphone 5S, Chrome 4
     * @required
     */
    device_name: string;
    /**
     * @name device_type
     * @type string
     * @example Mobile, WebBrowser
     * @required
     */
    device_type: string;
}


/**
 * @name IHardware
 * @description: Model of Hardware
 */
export interface IHardware {
    /**
     * @name actual_bootloader
     * @type IBootLoader
     * @readonly
     */
    actual_bootloader?: IBootLoader;
    /**
     * @name actual_c_program
     * @type ICProgram
     * @readonly
     */
    actual_c_program?: ICProgram;
    /**
     * @name actual_c_program_backup
     * @type ICProgram
     * @readonly
     */
    actual_c_program_backup?: ICProgram;
    /**
     * @name actual_c_program_backup_version
     * @type ICProgramVersion
     * @readonly
     */
    actual_c_program_backup_version?: ICProgramVersion;
    /**
     * @name actual_c_program_version
     * @type ICProgramVersion
     */
    actual_c_program_version?: ICProgramVersion;
    /**
     * @name actual_instance
     * @type IInstance
     * @description Can be null, if device is not in Instance
     * @readonly
     */
    actual_instance?: IInstance;
    /**
     * @name alert_list
     * @type ("BOOTLOADER_REQUIRED"|"RESTART_REQUIRED")[]
     * @description Optional. Only if we have Alert parameters
     * @readonly
     */
    alert_list?: ("BOOTLOADER_REQUIRED"|"RESTART_REQUIRED")[];
    /**
     * @name available_latest_bootloader
     * @type IBootLoader
     * @readonly
     */
    available_latest_bootloader?: IBootLoader;
    /**
     * @name backup_mode
     * @type ("STATIC_BACKUP"|"AUTO_BACKUP"|"NO_BACKUP")
     */
    backup_mode?: ("STATIC_BACKUP"|"AUTO_BACKUP"|"NO_BACKUP");
    /**
     * @name bootloader_core_configuration
     * @type IHardwareConfig
     * @readonly
     */
    bootloader_core_configuration?: IHardwareConfig;
    /**
     * @name bootloader_update_in_progress
     * @type IBootLoader
     * @readonly
     */
    bootloader_update_in_progress?: IBootLoader;
    /**
     * @name cellular
     * @type IGSM
     * @readonly
     */
    cellular?: IGSM;
    /**
     * @name collision
     * @type ("NO_COLLISION"|"ALREADY_IN_INSTANCE"|"PLANNED_UPDATE")
     * @description Basic alerts for potential collisions when deploying or updating new programs
     * @readonly
     * @required
     */
    collision: ("NO_COLLISION"|"ALREADY_IN_INSTANCE"|"PLANNED_UPDATE");
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name database_synchronize
     * @type boolean
     * @required
     */
    database_synchronize: boolean;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name developer_kit
     * @type boolean
     * @required
     */
    developer_kit: boolean;
    /**
     * @name dominant_entity
     * @type boolean
     * @required
     */
    dominant_entity: boolean;
    /**
     * @name dominant_project_active
     * @type IProject
     * @readonly
     */
    dominant_project_active?: IProject;
    /**
     * @name full_id
     * @type string
     * @required
     */
    full_id: string;
    /**
     * @name hardware_group
     * @type IHardwareGroup
     * @required
     */
    hardware_group: IHardwareGroup;
    /**
     * @name hardware_type
     * @type IHardwareType
     * @required
     */
    hardware_type: IHardwareType;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name ip_address
     * @type string
     * @description Value is cached with asynchronous refresh
     * @required
     */
    ip_address: string;
    /**
     * @name latest_online
     * @type number
     * @description Value is cached with asynchronous refresh
     * @format int32
     * @required
     */
    latest_online: number;
    /**
     * @name mac_address
     * @type string
     * @required
     */
    mac_address: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name online_state
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @description Value is cached with asynchronous refresh
     * @required
     */
    online_state: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
    /**
     * @name picture_link
     * @type string
     * @readonly
     * @required
     */
    picture_link: string;
    /**
     * @name private_hardware_config_and_settings
     * @type IHardwareStaticIndividualConfigAndValues
     * @readonly
     */
    private_hardware_config_and_settings?: IHardwareStaticIndividualConfigAndValues;
    /**
     * @name producer
     * @type IProducer
     * @readonly
     */
    producer?: IProducer;
    /**
     * @name project
     * @type IProject
     */
    project?: IProject;
    /**
     * @name required_updates
     * @type IHardwareUpdate[]
     * @readonly
     */
    required_updates?: IHardwareUpdate[];
    /**
     * @name server
     * @type IHomerServer
     * @readonly
     */
    server?: IHomerServer;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name unknown_actual_c_program_backup
     * @type IWSMessageHardwareOverviewBoardBinariesInfo
     * @readonly
     */
    unknown_actual_c_program_backup?: IWSMessageHardwareOverviewBoardBinariesInfo;
    /**
     * @name unknown_actual_c_program_firmware
     * @type IWSMessageHardwareOverviewBoardBinariesInfo
     * @readonly
     */
    unknown_actual_c_program_firmware?: IWSMessageHardwareOverviewBoardBinariesInfo;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareBatch
 * @description: Interface IHardwareBatch definition
 */
export interface IHardwareBatch {
    /**
     * @name assembly_manufacture_id
     * @type string
     * @required
     */
    assembly_manufacture_id: string;
    /**
     * @name assembly_manufacture_name
     * @type string
     * @required
     */
    assembly_manufacture_name: string;
    /**
     * @name compiler_target_name
     * @type string
     * @required
     */
    compiler_target_name: string;
    /**
     * @name config
     * @type IHardwareBatchConfig
     * @required
     */
    config: IHardwareBatchConfig;
    /**
     * @name created
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name customer_company_made_description
     * @type string
     * @required
     */
    customer_company_made_description: string;
    /**
     * @name customer_company_name
     * @type string
     * @required
     */
    customer_company_name: string;
    /**
     * @name customer_product_name
     * @type string
     * @required
     */
    customer_product_name: string;
    /**
     * @name date_of_assembly
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    date_of_assembly: number;
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name ean_number
     * @type string
     * @required
     */
    ean_number: string;
    /**
     * @name id
     * @type string
     * @example 5e14a6cafbde1c0001ae483e
     * @required
     */
    id: string;
    /**
     * @name latest_used_mac_address
     * @type number
     * @format int32
     * @required
     */
    latest_used_mac_address: number;
    /**
     * @name mac_address_end
     * @type number
     * @format int32
     * @required
     */
    mac_address_end: number;
    /**
     * @name mac_address_start
     * @type number
     * @format int32
     * @required
     */
    mac_address_start: number;
    /**
     * @name pcb_manufacture_id
     * @type string
     * @required
     */
    pcb_manufacture_id: string;
    /**
     * @name pcb_manufacture_name
     * @type string
     * @required
     */
    pcb_manufacture_name: string;
    /**
     * @name production_batch
     * @type string
     * @required
     */
    production_batch: string;
    /**
     * @name revision
     * @type string
     * @required
     */
    revision: string;
    /**
     * @name updated
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareBatchConfig
 * @description: Interface IHardwareBatchConfig definition
 */
export interface IHardwareBatchConfig {
    /**
     * @name parameters
     * @type IParameterConfig[]
     * @required
     */
    parameters: IParameterConfig[];
}


/**
 * @name IHardwareBatchConfigParameter
 * @description: Interface IHardwareBatchConfigParameter definition
 */
export interface IHardwareBatchConfigParameter {
    /**
     * @name allowed_values
     * @type string[]
     * @required
     */
    allowed_values: string[];
    /**
     * @name default_value
     * @type any
     * @required
     */
    default_value: any;
    /**
     * @name disabled
     * @type boolean
     * @required
     */
    disabled: boolean;
    /**
     * @name key
     * @type ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME")
     * @required
     */
    key: ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME");
    /**
     * @name max
     * @type number
     * @format int32
     * @required
     */
    max: number;
    /**
     * @name min
     * @type number
     * @format int32
     * @required
     */
    min: number;
}


/**
 * @name IHardwareBatchConfigSet
 * @description: Interface IHardwareBatchConfigSet definition
 */
export interface IHardwareBatchConfigSet {
    /**
     * @name parameters
     * @type IHardwareBatchConfigParameter[]
     * @required
     */
    parameters: IHardwareBatchConfigParameter[];
}


/**
 * @name IHardwareBatchNew
 * @description: Json Model for new Hardware Batch
 */
export interface IHardwareBatchNew {
    /**
     * @name assembly_manufacture_id
     * @type string
     * @required
     */
    assembly_manufacture_id: string;
    /**
     * @name assembly_manufacture_name
     * @type string
     * @required
     */
    assembly_manufacture_name: string;
    /**
     * @name customer_company_made_description
     * @type string
     * @required
     */
    customer_company_made_description: string;
    /**
     * @name customer_company_name
     * @type string
     * @required
     */
    customer_company_name: string;
    /**
     * @name customer_product_name
     * @type string
     * @required
     */
    customer_product_name: string;
    /**
     * @name date_of_assembly
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    date_of_assembly: number;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name ean_number
     * @type string
     * @required
     */
    ean_number: string;
    /**
     * @name mac_address_end
     * @type number
     * @format int32
     * @required
     */
    mac_address_end: number;
    /**
     * @name mac_address_start
     * @type number
     * @format int32
     * @required
     */
    mac_address_start: number;
    /**
     * @name pcb_manufacture_id
     * @type string
     * @required
     */
    pcb_manufacture_id: string;
    /**
     * @name pcb_manufacture_name
     * @type string
     * @required
     */
    pcb_manufacture_name: string;
    /**
     * @name production_batch
     * @type string
     * @required
     */
    production_batch: string;
    /**
     * @name revision
     * @type string
     * @required
     */
    revision: string;
}


/**
 * @name IHardwareConfig
 * @description: Interface IHardwareConfig definition
 */
export interface IHardwareConfig {
    /**
     * @name settings
     * @type ISetting[]
     * @required
     */
    settings: ISetting[];
    /**
     * @name version
     * @type number
     * @format int32
     * @required
     */
    version: number;
}


/**
 * @name IHardwareConfiguration
 * @description: Interface IHardwareConfiguration definition
 */
export interface IHardwareConfiguration {
    /**
     * @name autobackup
     * @type boolean
     * @readonly
     * @required
     */
    autobackup: boolean;
    /**
     * @name autojump
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    autojump: number;
    /**
     * @name backup_mqtt_hostname
     * @type string
     * @readonly
     * @required
     */
    backup_mqtt_hostname: string;
    /**
     * @name backup_mqtt_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    backup_mqtt_port: number;
    /**
     * @name blreport
     * @type boolean
     * @readonly
     * @required
     */
    blreport: boolean;
    /**
     * @name keepalive
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    keepalive: number;
    /**
     * @name lowpanbr
     * @type boolean
     * @readonly
     * @required
     */
    lowpanbr: boolean;
    /**
     * @name mac
     * @type string
     * @readonly
     * @required
     */
    mac: string;
    /**
     * @name mqtt_password
     * @type string
     * @readonly
     * @required
     */
    mqtt_password: string;
    /**
     * @name mqtt_username
     * @type string
     * @readonly
     * @required
     */
    mqtt_username: string;
    /**
     * @name netsource
     * @type string
     * @readonly
     * @required
     */
    netsource: string;
    /**
     * @name normal_mqtt_hostname
     * @type string
     * @readonly
     * @required
     */
    normal_mqtt_hostname: string;
    /**
     * @name normal_mqtt_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    normal_mqtt_port: number;
    /**
     * @name reconnect
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    reconnect: number;
    /**
     * @name revision
     * @type string
     * @readonly
     * @required
     */
    revision: string;
    /**
     * @name timeoffset
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    timeoffset: number;
    /**
     * @name timesync
     * @type boolean
     * @readonly
     * @required
     */
    timesync: boolean;
    /**
     * @name wdenable
     * @type boolean
     * @readonly
     * @required
     */
    wdenable: boolean;
    /**
     * @name wdtime
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    wdtime: number;
    /**
     * @name webport
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    webport: number;
    /**
     * @name webview
     * @type boolean
     * @readonly
     * @required
     */
    webview: boolean;
}


/**
 * @name IHardwareGroup
 * @description: Model of Hardware Group
 */
export interface IHardwareGroup {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name hardware_types
     * @type IHardwareType[]
     * @readonly
     * @required
     */
    hardware_types: IHardwareType[];
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name size
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    size: number;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareGroupFilter
 * @description: Json Model for Hardware Group Filter List
 */
export interface IHardwareGroupFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Name - substring supported
     */
    description?: string;
    /**
     * @name instance_snapshots
     * @type string[]
     * @description List of instance_snapshot.id
     */
    instance_snapshots?: string[];
    /**
     * @name name
     * @type string
     * @description Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IHardwareGroupList
 * @description: Individual Hardware Group List
 */
export interface IHardwareGroupList {
    /**
     * @name content
     * @type IHardwareGroup[]
     * @readonly
     * @required
     */
    content: IHardwareGroup[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHardwareReleaseUpdate
 * @description: Model of Hardware Release Updates
 */
export interface IHardwareReleaseUpdate {
    /**
     * @name bootloader
     * @type IBootloaderUpdateProgram
     * @description Only of type constant is MANUALLY_RELEASE_MANAGER && firmware_type is BOOTLOADER
     * @readonly
     * @required
     */
    bootloader: IBootloaderUpdateProgram;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name finished
     * @type number
     * @description UNIX time in s
     * @example 1466163471
     * @format int32
     * @readonly
     * @required
     */
    finished: number;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name planned
     * @type number
     * @description UNIX time in s
     * @example 1466163471
     * @format int32
     * @readonly
     * @required
     */
    planned: number;
    /**
     * @name procedure_size_all
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    procedure_size_all: number;
    /**
     * @name program
     * @type ICProgramUpdateProgram
     * @description Only if type constant is MANUALLY_RELEASE_MANAGER && firmware_type is FIRMWARE or BACKUP
     * @readonly
     * @required
     */
    program: ICProgramUpdateProgram;
    /**
     * @name state
     * @type ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED")
     * @readonly
     */
    state?: ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED");
    /**
     * @name state_canceled
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_canceled: number;
    /**
     * @name state_complete
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_complete: number;
    /**
     * @name state_faild
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_faild: number;
    /**
     * @name state_obsolete
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_obsolete: number;
    /**
     * @name state_pending
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_pending: number;
    /**
     * @name state_running
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    state_running: number;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareReleaseUpdateFilter
 * @description: Json Model for ActualizationProcedure Filter
 */
export interface IHardwareReleaseUpdateFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    project_id: string;
    /**
     * @name states
     * @type ("SUCCESSFULLY_COMPLETE"|"COMPLETE"|"COMPLETE_WITH_ERROR"|"CANCELED"|"IN_PROGRESS"|"NOT_START_YET")[]
     * @readonly
     */
    states?: ("SUCCESSFULLY_COMPLETE"|"COMPLETE"|"COMPLETE_WITH_ERROR"|"CANCELED"|"IN_PROGRESS"|"NOT_START_YET")[];
}


/**
 * @name IHardwareReleaseUpdateList
 * @description: Interface IHardwareReleaseUpdateList definition
 */
export interface IHardwareReleaseUpdateList {
    /**
     * @name content
     * @type IHardwareReleaseUpdate[]
     * @readonly
     * @required
     */
    content: IHardwareReleaseUpdate[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHardwareType
 * @description: Model of HardwareType
 */
export interface IHardwareType {
    /**
     * @name batches
     * @type IHardwareBatch[]
     * @description accessible only for persons with permissions
     * @readonly
     */
    batches?: IHardwareBatch[];
    /**
     * @name boot_loaders
     * @type IBootLoader[]
     * @description accessible only for persons with permissions
     */
    boot_loaders?: IBootLoader[];
    /**
     * @name compiler_target_name
     * @type string
     * @required
     */
    compiler_target_name: string;
    /**
     * @name connectible_to_internet
     * @type boolean
     * @required
     */
    connectible_to_internet: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name main_boot_loader
     * @type IBootLoader
     */
    main_boot_loader?: IBootLoader;
    /**
     * @name main_c_program
     * @type ICProgram
     */
    main_c_program?: ICProgram;
    /**
     * @name main_test_c_program
     * @type ICProgram
     * @description accessible only for persons with permissions
     * @readonly
     */
    main_test_c_program?: ICProgram;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name picture_link
     * @type string
     * @readonly
     */
    picture_link?: string;
    /**
     * @name processor
     * @type IProcessor
     */
    processor?: IProcessor;
    /**
     * @name producer
     * @type IProducer
     */
    producer?: IProducer;
    /**
     * @name supported_libraries
     * @type ICompilationLibrary[]
     * @readonly
     */
    supported_libraries?: ICompilationLibrary[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareTypeNew
 * @description: Json Model for new HardwareType
 */
export interface IHardwareTypeNew {
    /**
     * @name compiler_target_name
     * @type string
     * @description The description must have at least 4 characters
     * @required
     */
    compiler_target_name: string;
    /**
     * @name connectible_to_internet
     * @type boolean
     * @description If device can connect to internet
     * @required
     */
    connectible_to_internet: boolean;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name processor_id
     * @type string
     * @description Required valid processor_id
     * @format uuid
     * @required
     */
    processor_id: string;
    /**
     * @name producer_id
     * @type string
     * @description Required valid producer_id
     * @format uuid
     * @required
     */
    producer_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IHardwareUpdate
 * @description: Model of HardwareUpdate
 */
export interface IHardwareUpdate {
    /**
     * @name bootloader_detail
     * @type IBootloaderUpdateProgram
     * @description Is visible only if update is for Bootloader
     * @readonly
     */
    bootloader_detail?: IBootloaderUpdateProgram;
    /**
     * @name c_program_detail
     * @type ICProgramUpdateProgram
     * @description Is visible only if update is for Firmware or Backup
     * @readonly
     */
    c_program_detail?: ICProgramUpdateProgram;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name error
     * @type string
     * @description Only if state is critical_error or Homer record some error
     */
    error?: string;
    /**
     * @name error_code
     * @type number
     * @description Only if state is critical_error or Homer record some error
     * @format int32
     */
    error_code?: number;
    /**
     * @name finished
     * @type number
     * @description UNIX time
     * @example 1466163471
     * @format int32
     * @required
     */
    finished: number;
    /**
     * @name firmware_type
     * @type ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI")
     * @required
     */
    firmware_type: ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI");
    /**
     * @name get_file
     * @type IBlob
     * @description Is visible only if user send own binary file ( OR state for c_program_detail)
     * @readonly
     */
    get_file?: IBlob;
    /**
     * @name hardware
     * @type IHardware
     * @readonly
     * @required
     */
    hardware: IHardware;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name instance_snapshot
     * @type IInstanceSnapshot
     * @readonly
     */
    instance_snapshot?: IInstanceSnapshot;
    /**
     * @name planned
     * @type number
     * @description UNIX time
     * @example 1466163471
     * @format int32
     * @required
     */
    planned: number;
    /**
     * @name release_update
     * @type IHardwareReleaseUpdate
     * @description Only if its under Release Update
     * @readonly
     */
    release_update?: IHardwareReleaseUpdate;
    /**
     * @name state
     * @type ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED")
     * @required
     */
    state: ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED");
    /**
     * @name tracking_id
     * @type string
     * @format uuid
     * @required
     */
    tracking_id: string;
    /**
     * @name type
     * @type ("MANUALLY_BY_USER_INDIVIDUAL"|"MANUALLY_RELEASE_MANAGER"|"MANUALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE"|"AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE")
     * @required
     */
    type: ("MANUALLY_BY_USER_INDIVIDUAL"|"MANUALLY_RELEASE_MANAGER"|"MANUALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE"|"AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IHardwareUpdateProgressFilter
 * @description: Json Model for ActualizationProcedureTask Filter
 */
export interface IHardwareUpdateProgressFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name ioda_id
     * @type string
     * @description Hardware ID or part of ID (not necessary to send UUID)
     * @format uuid
     */
    ioda_id?: string;
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name tracking_id
     * @type string
     * @description Tracking ID - can be used for group of updates!
     * @format uuid
     * @required
     */
    tracking_id: string;
}


/**
 * @name IHardwareUpdateList
 * @description: Interface IHardwareUpdateList definition
 */
export interface IHardwareUpdateList {
    /**
     * @name content
     * @type IHardwareUpdate[]
     * @readonly
     * @required
     */
    content: IHardwareUpdate[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHardwareUpdateMakeRelease
 * @description: Json Model for ActualizationProcedure Make Procedure
 */
export interface IHardwareUpdateMakeRelease {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name firmware_type
     * @type ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI")
     * @readonly
     * @required
     */
    firmware_type: ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI");
    /**
     * @name hardware_group_ids
     * @type string[]
     * @readonly
     */
    hardware_group_ids?: string[];
    /**
     * @name hardware_ids
     * @type string[]
     * @readonly
     */
    hardware_ids?: string[];
    /**
     * @name hardware_type_settings
     * @type IHardwareUpdateMakeReleaseHardwareType[]
     * @readonly
     * @required
     */
    hardware_type_settings: IHardwareUpdateMakeReleaseHardwareType[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name time
     * @type number
     * @description If  value is null - its a command for immediately update
     * @format int32
     * @readonly
     */
    time?: number;
}


/**
 * @name IHardwareUpdateMakeReleaseHardwareType
 * @description: Json Model for ActualizationProcedure Make Procedure - HardwareType
 */
export interface IHardwareUpdateMakeReleaseHardwareType {
    /**
     * @name bootloader_id
     * @type string
     * @description Required if file is not set
     * @format uuid
     */
    bootloader_id?: string;
    /**
     * @name c_program_version_id
     * @type string
     * @description Required if file is not set
     * @format uuid
     */
    c_program_version_id?: string;
    /**
     * @name file
     * @type string
     * @description Required, if c_program_version_id is not set. The file is encoded in base64.
     */
    file?: string;
    /**
     * @name firmware_type
     * @type ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI")
     * @required
     */
    firmware_type: ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI");
    /**
     * @name hardware_type_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    hardware_type_id: string;
}


/**
 * @name IHardwareUpdateMakeSingleUpdate
 * @description: Json Model with List of Board ID
 */
export interface IHardwareUpdateMakeSingleUpdate {
    /**
     * @name bootloader_id
     * @type string
     * @description Required if file is not set
     * @format uuid
     */
    bootloader_id?: string;
    /**
     * @name c_program_version_id
     * @type string
     * @description Required if file is not set
     * @format uuid
     */
    c_program_version_id?: string;
    /**
     * @name file
     * @type string
     * @description Required, if c_program_version_id is not set. The file is encoded in base64.
     */
    file?: string;
    /**
     * @name firmware_type
     * @type ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI")
     * @required
     */
    firmware_type: ("FIRMWARE"|"BOOTLOADER"|"BACKUP"|"WIFI");
    /**
     * @name hardware_id
     * @type string
     * @format uuid
     * @required
     */
    hardware_id: string;
}


/**
 * @name IHardwareUpdatesFilter
 * @description: Json Model for ActualizationProcedureTask Filter
 */
export interface IHardwareUpdatesFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name full_id
     * @type string
     * @description Hardware Full ID
     */
    full_id?: string;
    /**
     * @name hardware_ids
     * @type string[]
     * @readonly
     */
    hardware_ids?: string[];
    /**
     * @name id
     * @type string
     * @description Hardware ID or part of ID (not necessary to send UUID)
     * @format uuid
     */
    id?: string;
    /**
     * @name instance_ids
     * @type string[]
     * @readonly
     */
    instance_ids?: string[];
    /**
     * @name instance_snapshot_ids
     * @type string[]
     * @readonly
     */
    instance_snapshot_ids?: string[];
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("DATE_OF_CREATE"|"DATE_OF_PLANE"|"DATE_OF_FINISH"|"STATE"|"TYPE"|"HARDWARE_NAME"|"HARDWARE_ID")
     * @description Order By Enum value
     */
    order_by?: ("DATE_OF_CREATE"|"DATE_OF_PLANE"|"DATE_OF_FINISH"|"STATE"|"TYPE"|"HARDWARE_NAME"|"HARDWARE_ID");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name release_update_ids
     * @type string[]
     * @readonly
     */
    release_update_ids?: string[];
    /**
     * @name type_of_updates
     * @type ("MANUALLY_BY_USER_INDIVIDUAL"|"MANUALLY_RELEASE_MANAGER"|"MANUALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE"|"AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE")[]
     * @readonly
     */
    type_of_updates?: ("MANUALLY_BY_USER_INDIVIDUAL"|"MANUALLY_RELEASE_MANAGER"|"MANUALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_INSTANCE"|"AUTOMATICALLY_BY_USER_ALWAYS_UP_TO_DATE"|"AUTOMATICALLY_BY_SERVER_ALWAYS_UP_TO_DATE")[];
    /**
     * @name update_states
     * @type ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED")[]
     * @readonly
     */
    update_states?: ("PENDING"|"RUNNING"|"COMPLETE"|"CANCELED"|"OBSOLETE"|"FAILED")[];
}


/**
 * @name IHardwareGroupDeviceListEditGroup
 * @description: Json Model for new Hardware Group edit group of devices or group of hardware
 */
export interface IHardwareGroupDeviceListEditGroup {
    /**
     * @name group_id
     * @type string
     * @description group_id
     * @format uuid
     * @required
     */
    group_id: string;
    /**
     * @name hardware_ids
     * @type string[]
     * @description hardware_ids
     * @required
     */
    hardware_ids: string[];
}


/**
 * @name IHardwareList
 * @description: Individual Hardware List
 */
export interface IHardwareList {
    /**
     * @name content
     * @type IHardware[]
     * @readonly
     * @required
     */
    content: IHardware[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHardwareNetworkStatesList
 * @description: Individual Hardware online states List
 */
export interface IHardwareNetworkStatesList {
    /**
     * @name content
     * @type IHardwareOnlineStates[]
     * @readonly
     * @required
     */
    content: IHardwareOnlineStates[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name reached_limit
     * @type boolean
     * @readonly
     * @required
     */
    reached_limit: boolean;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHardwareNewPassword
 * @description: Interface IHardwareNewPassword definition
 */
export interface IHardwareNewPassword {
    /**
     * @name mqtt_password
     * @type string
     * @readonly
     * @required
     */
    mqtt_password: string;
    /**
     * @name mqtt_username
     * @type string
     * @readonly
     * @required
     */
    mqtt_username: string;
}


/**
 * @name IHardwareNewSettingsResult
 * @description: Json Model with settings and firwmare and bootloader for Embedded hardware
 */
export interface IHardwareNewSettingsResult {
    /**
     * @name configuration
     * @type IHardwareConfiguration
     * @readonly
     * @required
     */
    configuration: IHardwareConfiguration;
    /**
     * @name full_id
     * @type string
     * @readonly
     */
    full_id?: string;
}


/**
 * @name IHardwareOnlineStates
 * @description: Json Model for online states
 */
export interface IHardwareOnlineStates {
    /**
     * @name data
     * @type IHardwareStateEvent[]
     * @readonly
     * @required
     */
    data: IHardwareStateEvent[];
    /**
     * @name hardware
     * @type IShortReference
     * @readonly
     * @required
     */
    hardware: IShortReference;
}


/**
 * @name IHardwareOnlineStatesFilter
 * @description: Json Model for filtering online states
 */
export interface IHardwareOnlineStatesFilter {
    /**
     * @name active
     * @type string
     * @description Boolean - required in String! "true" or "false "
     */
    active?: string;
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name end_date
     * @type number
     * @description end of time range, in unix time
     * @example 1704067200
     * @required
     */
    end_date: number;
    /**
     * @name end_time
     * @type number
     * @description Unix in second - created - end_time
     * @example 1536424319
     * @format int32
     */
    end_time?: number;
    /**
     * @name full_id
     * @type string
     * @description Hardware Full ID
     */
    full_id?: string;
    /**
     * @name hardware_groups_id
     * @type string[]
     * @description List of hardware_group.id
     */
    hardware_groups_id?: string[];
    /**
     * @name hardware_type_ids
     * @type string[]
     * @description List of hardware_type.id
     */
    hardware_type_ids?: string[];
    /**
     * @name id
     * @type string
     * @description Hardware ID or part of ID (not necessary to send UUID)
     * @format uuid
     */
    id?: string;
    /**
     * @name instance_snapshot
     * @type string
     * @description List of instance_snapshot.id
     * @format uuid
     */
    instance_snapshot?: string;
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"FULL_ID"|"GROUP_NAME"|"ID"|"DATE_OF_REGISTER"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"FULL_ID"|"GROUP_NAME"|"ID"|"DATE_OF_REGISTER"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name processors
     * @type string[]
     * @description List of processor.id
     */
    processors?: string[];
    /**
     * @name producers
     * @type string[]
     * @description List of producer.id
     */
    producers?: string[];
    /**
     * @name projects
     * @type string[]
     * @description List of project.id
     */
    projects?: string[];
    /**
     * @name start_date
     * @type number
     * @description begin of time range, in unix time
     * @example 1549223285
     * @required
     */
    start_date: number;
    /**
     * @name start_time
     * @type number
     * @description Unix in second - created - start_time
     * @example 1536424319
     * @format int32
     */
    start_time?: number;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IHardwareRegistrationHash
 * @description: Only For Admins
 */
export interface IHardwareRegistrationHash {
    /**
     * @name hash
     * @type string
     * @required
     */
    hash: string;
}


/**
 * @name IHardwareStateEvent
 * @description: Json Model for single online state
 */
export interface IHardwareStateEvent {
    /**
     * @name time
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    time: number;
    /**
     * @name value
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @readonly
     * @required
     */
    value: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
}


/**
 * @name IHardwareStaticIndividualConfigAndValues
 * @description: Object represent values that can not be changed, such as memory serial number, sim card number, hardware change decision, etc. Some values are not useful for users, but serve as database shortcuts, etc.
 */
export interface IHardwareStaticIndividualConfigAndValues {
    /**
     * @name backup
     * @type IWSMessageHardwareOverviewBoardBinariesInfo
     * @required
     */
    backup: IWSMessageHardwareOverviewBoardBinariesInfo;
    /**
     * @name decision_for_default_firmware
     * @type boolean
     * @required
     */
    decision_for_default_firmware: boolean;
    /**
     * @name firmware
     * @type IWSMessageHardwareOverviewBoardBinariesInfo
     * @required
     */
    firmware: IWSMessageHardwareOverviewBoardBinariesInfo;
    /**
     * @name iccid
     * @type string
     * @required
     */
    iccid: string;
    /**
     * @name imsi
     * @type string
     * @required
     */
    imsi: string;
}


/**
 * @name IHardwareUpdateEvent
 * @description: Json Model for Hardware Update Event
 */
export interface IHardwareUpdateEvent {
    /**
     * @name part
     * @type number
     * @format int32
     * @required
     */
    part: number;
    /**
     * @name phase
     * @type string
     * @required
     */
    phase: string;
    /**
     * @name time_stamp
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    time_stamp: number;
}


/**
 * @name IHomerServer
 * @description: Model of HomerServer
 */
export interface IHomerServer {
    /**
     * @name connection_identificator
     * @type string
     * @readonly
     */
    connection_identificator?: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name days_in_archive
     * @type number
     * @format int32
     * @required
     */
    days_in_archive: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name deployment_in_progress
     * @type boolean
     * @description Visible only when server is in deployment state
     */
    deployment_in_progress?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name grid_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    grid_port: number;
    /**
     * @name hardware_logger_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    hardware_logger_port: number;
    /**
     * @name hash_certificate
     * @type string
     * @readonly
     */
    hash_certificate?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name interactive
     * @type boolean
     * @required
     */
    interactive: boolean;
    /**
     * @name log_level
     * @type ("ERROR"|"WARN"|"INFO"|"DEBUG"|"TRACE")
     * @required
     */
    log_level: ("ERROR"|"WARN"|"INFO"|"DEBUG"|"TRACE");
    /**
     * @name logging
     * @type boolean
     * @required
     */
    logging: boolean;
    /**
     * @name mqtt_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    mqtt_port: number;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name online_state
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @required
     */
    online_state: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
    /**
     * @name rest_api_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    rest_api_port: number;
    /**
     * @name server_type
     * @type ("PUBLIC"|"PRIVATE"|"BACKUP"|"MAIN"|"TEST")
     * @required
     */
    server_type: ("PUBLIC"|"PRIVATE"|"BACKUP"|"MAIN"|"TEST");
    /**
     * @name server_url
     * @type string
     * @readonly
     * @required
     */
    server_url: string;
    /**
     * @name server_version
     * @type string
     * @readonly
     * @required
     */
    server_version: string;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name time_stamp_configuration
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    time_stamp_configuration: number;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name web_view_port
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    web_view_port: number;
}


/**
 * @name IHomerServerFilter
 * @description: Json Model for Filtering Homer Servers
 */
export interface IHomerServerFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     */
    project_id?: string;
    /**
     * @name server_types
     * @type ("PUBLIC"|"PRIVATE"|"BACKUP"|"MAIN"|"TEST")[]
     * @description Default if empty is public
     */
    server_types?: ("PUBLIC"|"PRIVATE"|"BACKUP"|"MAIN"|"TEST")[];
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IHomerServerList
 * @description: Individual Homer Server List
 */
export interface IHomerServerList {
    /**
     * @name content
     * @type IHomerServer[]
     * @readonly
     * @required
     */
    content: IHomerServer[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IHomerServerUpgrade
 * @description: Interface IHomerServerUpgrade definition
 */
export interface IHomerServerUpgrade {
    /**
     * @name image
     * @type string
     * @required
     */
    image: string;
    /**
     * @name server_id
     * @type string
     * @format uuid
     * @required
     */
    server_id: string;
}


/**
 * @name IHomerVersions
 * @description: Interface IHomerVersions definition
 */
export interface IHomerVersions {
    /**
     * @name versions
     * @type string[]
     * @required
     */
    versions: string[];
}


/**
 * @name IInstance
 * @description: Model of Instance
 */
export interface IInstance {
    /**
     * @name b_program
     * @type IBProgram
     */
    b_program?: IBProgram;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name current_snapshot
     * @type IInstanceSnapshot
     * @readonly
     */
    current_snapshot?: IInstanceSnapshot;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name instance_history
     * @type IInstanceHistoryJson
     * @readonly
     */
    instance_history?: IInstanceHistoryJson;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name online_state
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @description Value is cached with asynchronous refresh
     * @required
     */
    online_state: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
    /**
     * @name server
     * @type IHomerServer
     * @readonly
     */
    server?: IHomerServer;
    server_backup?: IHomerServer;
    /**
     * @name snapshots
     * @type IInstanceSnapshot[]
     */
    snapshots?: IInstanceSnapshot[];
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IInstanceHistoryEntryJson
 * @description: Object representing instance history single entry
 */
export interface IInstanceHistoryEntryJson {
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name time
     * @type number
     * @format int32
     * @required
     */
    time: number;
    /**
     * @name user_id
     * @type string
     * @format uuid
     * @required
     */
    user_id: string;
    /**
     * @name version_id
     * @type string
     * @required
     */
    version_id: string;
}


/**
 * @name IInstanceHistoryJson
 * @description: Object representing instance history
 */
export interface IInstanceHistoryJson {
    /**
     * @name content
     * @type IInstanceHistoryEntryJson[]
     * @required
     */
    content: IInstanceHistoryEntryJson[];
}


/**
 * @name IInstanceSnapshot
 * @description: Model of InstanceSnapshot
 */
export interface IInstanceSnapshot {
    /**
     * @name b_program
     * @type IBProgram
     * @readonly
     */
    b_program?: IBProgram;
    /**
     * @name b_program_version
     * @type IBProgramVersion
     */
    b_program_version?: IBProgramVersion;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name deployed
     * @type number
     * @example 1466163478925
     * @format int32
     * @readonly
     * @required
     */
    deployed: number;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description File Link
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name m_projects
     * @type IBProgramVersionSnapGridProject[]
     * @readonly
     */
    m_projects?: IBProgramVersionSnapGridProject[];
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name settings
     * @type ISwaggerInstanceSnapShotConfiguration
     * @readonly
     */
    settings?: ISwaggerInstanceSnapShotConfiguration;
    /**
     * @name stopped
     * @type number
     * @example 1466163478925
     * @format int32
     * @readonly
     * @required
     */
    stopped: number;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IInstanceSnapshotDeploy
 * @description: Json Model for new Version of B_Program
 */
export interface IInstanceSnapshotDeploy {
    /**
     * @name allow_updates
     * @type boolean
     */
    allow_updates?: boolean;
    /**
     * @name snapshot_id
     * @type string
     * @format uuid
     * @required
     */
    snapshot_id: string;
    /**
     * @name upload_time
     * @type number
     * @description UNIX time in millis - Date: number of milliseconds elapsed since  Thursday, 1 January 1970
     * @example 1466163478925
     * @format int32
     */
    upload_time?: number;
}


/**
 * @name IInstanceSnapshotJsonFileInterface
 * @description: Interface IInstanceSnapshotJsonFileInterface definition
 */
export interface IInstanceSnapshotJsonFileInterface {
    /**
     * @name interface_id
     * @type string
     * @format uuid
     * @required
     */
    interface_id: string;
    /**
     * @name target_id
     * @type string
     * @format uuid
     * @required
     */
    target_id: string;
    /**
     * @name type
     * @type ("group"|"hardware")
     * @required
     */
    type: ("group"|"hardware");
}


/**
 * @name IInstanceSnapshotNew
 * @description: Interface IInstanceSnapshotNew definition
 */
export interface IInstanceSnapshotNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name interfaces
     * @type IInstanceSnapshotJsonFileInterface[]
     * @required
     */
    interfaces: IInstanceSnapshotJsonFileInterface[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name snapshot
     * @type string
     * @required
     */
    snapshot: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name version_id
     * @type string
     * @format uuid
     * @required
     */
    version_id: string;
}


/**
 * @name IInstanceFilter
 * @description: Interface IInstanceFilter definition
 */
export interface IInstanceFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Description
     */
    description?: string;
    /**
     * @name instance_types
     * @type string[]
     */
    instance_types?: string[];
    /**
     * @name name
     * @type string
     * @description Name
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @format uuid
     */
    project_id?: string;
    /**
     * @name server_unique_ids
     * @type string[]
     */
    server_unique_ids?: string[];
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IInstanceList
 * @description: List of instances by Filter Query
 */
export interface IInstanceList {
    /**
     * @name content
     * @type IInstance[]
     * @readonly
     * @required
     */
    content: IInstance[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IInstanceMESHEdit
 * @description: Interface IInstanceMESHEdit definition
 */
export interface IInstanceMESHEdit {
    /**
     * @name description
     * @type string
     */
    description?: string;
}


/**
 * @name IInstanceMesh
 * @description: Interface IInstanceMesh definition
 */
export interface IInstanceMesh {
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name short_prefix
     * @type string
     * @required
     */
    short_prefix: string;
}


/**
 * @name IInstanceNew
 * @description: Interface IInstanceNew definition
 */
export interface IInstanceNew {
    /**
     * @name b_program_id
     * @type string
     * @format uuid
     * @required
     */
    b_program_id: string;
    /**
     * @name backup_server_id
     * @type string
     * @description Optional and not supported value now
     * @format uuid
     */
    backup_server_id?: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name main_server_id
     * @type string
     * @format uuid
     * @required
     */
    main_server_id: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IInstanceToken
 * @description: Interface IInstanceToken definition
 */
export interface IInstanceToken {
    /**
     * @name description
     * @type string
     */
    description?: string;
}


/**
 * @name IIntegratorClient
 * @description: Model of Client of an Integrator (Customer)
 */
export interface IIntegratorClient {
    /**
     * @name contact
     * @type IContact
     * @required
     */
    contact: IContact;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IInvitation
 * @description: Model of Invitation
 */
export interface IInvitation {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name invited_person
     * @type IPerson
     * @description Only if user is already registered in system, otherwise there is a null value
     * @readonly
     * @required
     */
    invited_person: IPerson;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name who_invite
     * @type IPerson
     * @readonly
     * @required
     */
    who_invite: IPerson;
}


/**
 * @name IInvitePerson
 * @description: Json Model for sharing project with Persons, invite to permission group etc..
 */
export interface IInvitePerson {
    /**
     * @name persons_mail
     * @type string[]
     * @required
     */
    persons_mail: string[];
}


/**
 * @name IInvoice
 * @description: Model of Invoice
 */
export interface IInvoice {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name currency
     * @type ("CZK"|"EUR"|"USD")
     * @required
     */
    currency: ("CZK"|"EUR"|"USD");
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name from
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name gw_url
     * @type string
     * @description Visible only when the invoice is not paid
     */
    gw_url?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name invoice_items
     * @type IInvoiceItem[]
     * @readonly
     * @required
     */
    invoice_items: IInvoiceItem[];
    /**
     * @name invoice_number
     * @type string
     * @readonly
     * @required
     */
    invoice_number: string;
    /**
     * @name invoice_pdf_link
     * @type string
     * @description Visible only when the invoice is available
     */
    invoice_pdf_link?: string;
    /**
     * @name method
     * @type ("INVOICE_BASED"|"CREDIT_CARD")
     * @required
     */
    method: ("INVOICE_BASED"|"CREDIT_CARD");
    /**
     * @name overdue
     * @type number
     * @description UNIX time
     * @example 1466163475
     * @format int32
     * @readonly
     * @required
     */
    overdue: number;
    /**
     * @name paid
     * @type number
     * @description UNIX time
     * @example 1466163475
     * @format int32
     * @readonly
     */
    paid?: number;
    /**
     * @name payment_status
     * @type string
     * @readonly
     * @required
     */
    payment_status: string;
    /**
     * @name proforma_pdf_link
     * @type string
     * @description Visible only when the invoice is available
     */
    proforma_pdf_link?: string;
    /**
     * @name public_html_url
     * @type string
     * @required
     */
    public_html_url: string;
    /**
     * @name require_payment
     * @type boolean
     * @description Visible only when the invoice is not paid
     * @readonly
     */
    require_payment?: boolean;
    /**
     * @name to
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name to_confirm
     * @type boolean
     * @readonly
     */
    to_confirm?: boolean;
    /**
     * @name total_price_with_vat
     * @type number
     * @format double
     */
    total_price_with_vat?: number;
    /**
     * @name total_price_without_vat
     * @type number
     * @format double
     */
    total_price_without_vat?: number;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IInvoiceItem
 * @description: Model of InvoiceItem
 */
export interface IInvoiceItem {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name quantity
     * @type number
     * @required
     */
    quantity: number;
    /**
     * @name total_price_without_vat
     * @type number
     * @format double
     * @readonly
     * @required
     */
    total_price_without_vat: number;
    /**
     * @name unit_name
     * @type string
     * @required
     */
    unit_name: string;
    /**
     * @name unit_price
     * @type number
     * @required
     */
    unit_price: number;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name vat_rate
     * @type number
     * @format int32
     * @required
     */
    vat_rate: number;
}


/**
 * @name IInvoiceFullDetails
 * @description: Json Model for new Producer
 */
export interface IInvoiceFullDetails {
    /**
     * @name invoice
     * @type IInvoice
     * @required
     */
    invoice: IInvoice;
    /**
     * @name invoice_items
     * @type IInvoiceItem[]
     * @required
     */
    invoice_items: IInvoiceItem[];
}


/**
 * @name ILibrary
 * @description: Model of Library
 */
export interface ILibrary {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type ILibraryVersion[]
     */
    versions?: ILibraryVersion[];
}


/**
 * @name ILibraryIdLibraryVersionId
 * @description: Json Model for pair of library id and library version id
 */
export interface ILibraryIdLibraryVersionId {
    /**
     * @name library_id
     * @type string
     * @format uuid
     * @required
     */
    library_id: string;
    /**
     * @name library_version_id
     * @type string
     * @format uuid
     * @required
     */
    library_version_id: string;
}


/**
 * @name ILibraryVersion
 * @description: Model of LibraryVersion
 */
export interface ILibraryVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name c_program_examples
     * @type ICProgram[]
     * @readonly
     * @required
     */
    c_program_examples: ICProgram[];
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name ILibraryCopy
 * @description: Json Model for copy Library
 */
export interface ILibraryCopy {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name library_id
     * @type string
     * @format uuid
     * @required
     */
    library_id: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ILibraryFileLoad
 * @description: Json Model for Library_File_Load
 */
export interface ILibraryFileLoad {
    /**
     * @name files
     * @type ILibraryRecord[]
     */
    files?: ILibraryRecord[];
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     * @description Contains IDs of imported Library versions
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
}


/**
 * @name ILibraryFilter
 * @description: Json Model for getting Library Filter List
 */
export interface ILibraryFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name excluded_libraries
     * @type string[]
     * @description Excludes Libraries (e.g already used in this program)
     */
    excluded_libraries?: string[];
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"ID"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"ID"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name pending_library
     * @type boolean
     * @description Designed for Administrators for publishing decisions of Community Codes - Without permission, the value is ignored.
     */
    pending_library?: boolean;
    /**
     * @name project_id
     * @type string
     * @format uuid
     */
    project_id?: string;
    /**
     * @name public_library
     * @type boolean
     * @description Show - All Public Programs which are confirmed and approved.
     */
    public_library?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name ILibraryIdList
 * @description: Json Model for getting Library Filter List
 */
export interface ILibraryIdList {
    /**
     * @name library_id
     * @type string[]
     * @required
     */
    library_id: string[];
}


/**
 * @name ILibraryList
 * @description: Individual Library List
 */
export interface ILibraryList {
    /**
     * @name content
     * @type ILibrary[]
     * @readonly
     * @required
     */
    content: ILibrary[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name ILibraryNew
 * @description: Json Model for new Library
 */
export interface ILibraryNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     */
    project_id?: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ILibraryRecord
 * @description: Json Model for Library_Record
 */
export interface ILibraryRecord {
    /**
     * @name content
     * @type string
     * @required
     */
    content: string;
    /**
     * @name file_name
     * @type string
     * @required
     */
    file_name: string;
}


/**
 * @name ILibraryVersionCompile
 * @description: Json Model for new Version of Library
 */
export interface ILibraryVersionCompile {
    /**
     * @name files
     * @type ILibraryRecord[]
     */
    files?: ILibraryRecord[];
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
    /**
     * @name library_compilation_version
     * @type string
     * @description The Library Version tag_name from HardwareType.supported_libraries
     */
    library_compilation_version?: string;
}


/**
 * @name ILibraryVersionNew
 * @description: Json Model for new Version of Library
 */
export interface ILibraryVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name files
     * @type ILibraryRecord[]
     */
    files?: ILibraryRecord[];
    /**
     * @name imported_libraries
     * @type ILibraryIdLibraryVersionId[]
     */
    imported_libraries?: ILibraryIdLibraryVersionId[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ILogicJson
 * @description: Json Model for logic_json
 */
export interface ILogicJson {
    /**
     * @name info
     * @type string
     * @required
     */
    info: string;
    /**
     * @name program
     * @type string
     * @required
     */
    program: string;
}


/**
 * @name ILoginResult
 * @description: Json Model that you will get, if login was successful
 */
export interface ILoginResult {
    /**
     * @name hmac
     * @type string
     * @readonly
     */
    hmac?: string;
    /**
     * @name person
     * @type IPerson
     * @readonly
     */
    person?: IPerson;
    /**
     * @name roles
     * @type IRole[]
     * @description List of roles, that
     * @readonly
     */
    roles?: IRole[];
}


/**
 * @name ILoginToken
 * @description: Json Model that you will get, if login was successful
 */
export interface ILoginToken {
    /**
     * @name auth_token
     * @type string
     * @description X-AUTH-TOKEN - used this token in HTML head for verifying the identities
     * @format uuid
     * @readonly
     * @required
     */
    auth_token: string;
}


/**
 * @name IMProgramSnapShot
 * @description: Interface IMProgramSnapShot definition
 */
export interface IMProgramSnapShot {
    /**
     * @name m_program_id
     * @type string
     * @format uuid
     * @required
     */
    m_program_id: string;
    /**
     * @name version_id
     * @type string
     * @format uuid
     * @required
     */
    version_id: string;
}


/**
 * @name IMProgramVersionInterface
 * @description: Json Model for Version of M_program
 */
export interface IMProgramVersionInterface {
    /**
     * @name version
     * @type IGridProgramVersion
     * @readonly
     * @required
     */
    version: IGridProgramVersion;
    /**
     * @name virtual_input_output
     * @type string
     * @readonly
     * @required
     */
    virtual_input_output: string;
}


/**
 * @name IMProgramVersionNew
 * @description: Json Model for new Version of M_program
 */
export interface IMProgramVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name m_code
     * @type string
     * @required
     */
    m_code: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name public_access
     * @type boolean
     * @description if value is true - program can be open throw the QR token (public) by everyone!
     */
    public_access?: boolean;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name virtual_input_output
     * @type string
     * @required
     */
    virtual_input_output: string;
}


/**
 * @name IMProgramInterface
 * @description: Json Model that contain values with accessible interface of virtual_input_output under M_programs (auto_incrementing == false) or head M_Program.virtual_input_output if auto_incrementing is True
 */
export interface IMProgramInterface {
    /**
     * @name accessible_versions
     * @type IMProgramVersionInterface[]
     * @required
     */
    accessible_versions: IMProgramVersionInterface[];
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
}


/**
 * @name IMProjectSnapShot
 * @description: Interface IMProjectSnapShot definition
 */
export interface IMProjectSnapShot {
    /**
     * @name m_program_snapshots
     * @type IMProgramSnapShot[]
     * @description Connected hardware
     * @required
     */
    m_program_snapshots: IMProgramSnapShot[];
    /**
     * @name m_project_id
     * @type string
     * @format uuid
     * @required
     */
    m_project_id: string;
}


/**
 * @name IMProjectInterface
 * @description: Json Model that contain values with accessible interface of virtual_input_output under M_project (auto_incrementing == false) or head M_project.M_program.virtual_input_output if auto_incrementing is True
 */
export interface IMProjectInterface {
    /**
     * @name accessible_interface
     * @type IMProgramInterface[]
     * @readonly
     * @required
     */
    accessible_interface: IMProgramInterface[];
    /**
     * @name auto_incrementing
     * @type boolean
     * @readonly
     */
    auto_incrementing?: boolean;
    /**
     * @name description
     * @type string
     * @readonly
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     * @readonly
     * @required
     */
    name: string;
}


/**
 * @name IModelMongoThingsMobileCDR
 * @description: Interface IModelMongoThingsMobileCDR definition
 */
export interface IModelMongoThingsMobileCDR {
    /**
     * @name cdr_country
     * @type string
     * @required
     */
    cdr_country: string;
    /**
     * @name cdr_date_start
     * @type number
     * @example 1536424319
     * @format int32
     * @required
     */
    cdr_date_start: number;
    /**
     * @name cdr_date_stop
     * @type number
     * @example 1536424319
     * @format int32
     * @required
     */
    cdr_date_stop: number;
    /**
     * @name cdr_imsi
     * @type number
     * @format int32
     * @required
     */
    cdr_imsi: number;
    /**
     * @name cdr_network
     * @type string
     * @required
     */
    cdr_network: string;
    /**
     * @name cdr_traffic
     * @type number
     * @format int32
     * @required
     */
    cdr_traffic: number;
    /**
     * @name created
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name id
     * @type string
     * @example 5e14a6cafbde1c0001ae483e
     * @required
     */
    id: string;
    /**
     * @name msisdn
     * @type number
     * @format int32
     * @required
     */
    msisdn: number;
    /**
     * @name updated
     * @type number
     * @description unixTime
     * @example 1536424319
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name INameAndDescProjectIdOptional
 * @description: Json Model for crating new Block Model
 */
export interface INameAndDescProjectIdOptional {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     */
    project_id?: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name INameAndDescProjectIdRequired
 * @description: Json pattern for crating new object with name, description and required project id.
 */
export interface INameAndDescProjectIdRequired {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name INameAndDescription
 * @description: Json Model with name and description
 */
export interface INameAndDescription {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name INotification
 * @description: Model of Notification
 */
export interface INotification {
    /**
     * @name body
     * @type INotificationElement[]
     * @required
     */
    body: INotificationElement[];
    /**
     * @name buttons
     * @type INotificationButton[]
     */
    buttons?: INotificationButton[];
    /**
     * @name confirmation_id
     * @type string
     * @format uuid
     * @required
     */
    confirmation_id: string;
    /**
     * @name confirmation_required
     * @type boolean
     * @required
     */
    confirmation_required: boolean;
    /**
     * @name confirmed
     * @type boolean
     * @required
     */
    confirmed: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name importance
     * @type ("LOW"|"NORMAL"|"HIGH")
     * @required
     */
    importance: ("LOW"|"NORMAL"|"HIGH");
    /**
     * @name level
     * @type ("INFO"|"SUCCESS"|"WARNING"|"ERROR")
     * @required
     */
    level: ("INFO"|"SUCCESS"|"WARNING"|"ERROR");
    /**
     * @name state
     * @type ("CREATED"|"UNCONFIRMED"|"UPDATED"|"DELETED")
     * @required
     */
    state: ("CREATED"|"UNCONFIRMED"|"UPDATED"|"DELETED");
    /**
     * @name type
     * @type ("CHAIN_START"|"CHAIN_UPDATE"|"CHAIN_END"|"INDIVIDUAL")
     * @required
     */
    type: ("CHAIN_START"|"CHAIN_UPDATE"|"CHAIN_END"|"INDIVIDUAL");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name was_read
     * @type boolean
     * @required
     */
    was_read: boolean;
}


/**
 * @name INotificationButton
 * @description: Json Model for notification buttons
 */
export interface INotificationButton {
    /**
     * @name action
     * @type ("CONFIRM_NOTIFICATION"|"ACCEPT_PROJECT_INVITATION"|"REJECT_PROJECT_INVITATION"|"ACCEPT_RESTORE_FIRMWARE"|"REJECT_RESTORE_FIRMWARE")
     * @required
     */
    action: ("CONFIRM_NOTIFICATION"|"ACCEPT_PROJECT_INVITATION"|"REJECT_PROJECT_INVITATION"|"ACCEPT_RESTORE_FIRMWARE"|"REJECT_RESTORE_FIRMWARE");
    /**
     * @name bold
     * @type boolean
     * @required
     */
    bold: boolean;
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name italic
     * @type boolean
     * @required
     */
    italic: boolean;
    /**
     * @name text
     * @type string
     * @required
     */
    text: string;
    /**
     * @name underline
     * @type boolean
     * @required
     */
    underline: boolean;
}


/**
 * @name INotificationConfirm
 * @description: Json Model for confirming notification
 */
export interface INotificationConfirm {
    /**
     * @name action
     * @type ("CONFIRM_NOTIFICATION"|"ACCEPT_PROJECT_INVITATION"|"REJECT_PROJECT_INVITATION"|"ACCEPT_RESTORE_FIRMWARE"|"REJECT_RESTORE_FIRMWARE")
     * @description Notification_action
     * @required
     */
    action: ("CONFIRM_NOTIFICATION"|"ACCEPT_PROJECT_INVITATION"|"REJECT_PROJECT_INVITATION"|"ACCEPT_RESTORE_FIRMWARE"|"REJECT_RESTORE_FIRMWARE");
}


/**
 * @name INotificationElement
 * @description: Json Model for notification body elements
 */
export interface INotificationElement {
    /**
     * @name bold
     * @type boolean
     * @required
     */
    bold: boolean;
    /**
     * @name button
     * @type boolean
     * @required
     */
    button: boolean;
    /**
     * @name color
     * @type string
     */
    color?: string;
    /**
     * @name date
     * @type number
     * @example 1536424319
     */
    date?: number;
    /**
     * @name id
     * @type string
     * @format uuid
     */
    id?: string;
    /**
     * @name italic
     * @type boolean
     * @required
     */
    italic: boolean;
    /**
     * @name name
     * @type ("ARTICLE"|"AUTHORIZATION_TOKEN"|"BOOTLOADER"|"COMPILER"|"GARFIELD"|"PERSON"|"PRODUCT"|"PROJECT"|"FIRMWARE"|"FIRMWARE_VERSION"|"LIBRARY"|"LIBRARY_VERSION"|"WIDGET"|"WIDGET_VERSION"|"GRID_PROJECT"|"GRID_PROGRAM"|"GRID_PROGRAM_VERSION"|"BLOCK"|"BLOCK_VERSION"|"BLOCKO_PROGRAM"|"BLOCKO_PROGRAM_VERSION"|"INSTANCE"|"INSTANCE_SNAPSHOT"|"HARDWARE"|"HARDWARE_GROUP"|"HARDWARE_UPDATE"|"HARDWARE_BATCH"|"HARDWARE_ENTITY"|"HARDWARE_TYPE"|"INVITATION"|"INVOICE"|"PROCESSOR"|"PRODUCER"|"NOTIFICATION"|"ROLE"|"UPDATE_PROCEDURE"|"PRODUCT_EXTENSION"|"ERROR"|"TARIFF"|"TARIFF_EXTENSION"|"PAYMENT_DETAILS"|"HOMER"|"CUSTOMER"|"EMPLOYEE"|"GSM"|"CONTACT"|"INTEGRATOR_CLIENT"|"MONGO_GATEWAY")
     */
    name?: ("ARTICLE"|"AUTHORIZATION_TOKEN"|"BOOTLOADER"|"COMPILER"|"GARFIELD"|"PERSON"|"PRODUCT"|"PROJECT"|"FIRMWARE"|"FIRMWARE_VERSION"|"LIBRARY"|"LIBRARY_VERSION"|"WIDGET"|"WIDGET_VERSION"|"GRID_PROJECT"|"GRID_PROGRAM"|"GRID_PROGRAM_VERSION"|"BLOCK"|"BLOCK_VERSION"|"BLOCKO_PROGRAM"|"BLOCKO_PROGRAM_VERSION"|"INSTANCE"|"INSTANCE_SNAPSHOT"|"HARDWARE"|"HARDWARE_GROUP"|"HARDWARE_UPDATE"|"HARDWARE_BATCH"|"HARDWARE_ENTITY"|"HARDWARE_TYPE"|"INVITATION"|"INVOICE"|"PROCESSOR"|"PRODUCER"|"NOTIFICATION"|"ROLE"|"UPDATE_PROCEDURE"|"PRODUCT_EXTENSION"|"ERROR"|"TARIFF"|"TARIFF_EXTENSION"|"PAYMENT_DETAILS"|"HOMER"|"CUSTOMER"|"EMPLOYEE"|"GSM"|"CONTACT"|"INTEGRATOR_CLIENT"|"MONGO_GATEWAY");
    /**
     * @name program_id
     * @type string
     * @format uuid
     */
    program_id?: string;
    /**
     * @name project_id
     * @type string
     * @format uuid
     */
    project_id?: string;
    /**
     * @name text
     * @type string
     */
    text?: string;
    /**
     * @name type
     * @type ("LINK"|"OBJECT"|"TEXT"|"DATE"|"NEW_LINE")
     * @required
     */
    type: ("LINK"|"OBJECT"|"TEXT"|"DATE"|"NEW_LINE");
    /**
     * @name underline
     * @type boolean
     * @required
     */
    underline: boolean;
    /**
     * @name url
     * @type string
     */
    url?: string;
}


/**
 * @name INotificationFilter
 * @description: Json Model for getting Article Filter List
 */
export interface INotificationFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
}


/**
 * @name INotificationList
 * @description: Individual Notification List
 */
export interface INotificationList {
    /**
     * @name content
     * @type INotification[]
     * @readonly
     * @required
     */
    content: INotification[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
    /**
     * @name unread_total
     * @type number
     * @description Total unread subjects
     * @format int32
     * @readonly
     * @required
     */
    unread_total: number;
}


/**
 * @name INotificationRead
 * @description: Json Model for List with ids of read notifications
 */
export interface INotificationRead {
    /**
     * @name notification_id
     * @type string[]
     * @description List of notification.id
     * @required
     */
    notification_id: string[];
}


/**
 * @name IParameterConfig
 * @description: Interface IParameterConfig definition
 */
export interface IParameterConfig {
    /**
     * @name allowed_values
     * @type string[]
     * @required
     */
    allowed_values: string[];
    /**
     * @name default_value
     * @type any
     * @required
     */
    default_value: any;
    /**
     * @name disabled
     * @type boolean
     * @required
     */
    disabled: boolean;
    /**
     * @name key
     * @type ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME")
     * @required
     */
    key: ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME");
    /**
     * @name max
     * @type number
     * @format int32
     * @required
     */
    max: number;
    /**
     * @name min
     * @type number
     * @format int32
     * @required
     */
    min: number;
    /**
     * @name type
     * @type string
     * @required
     */
    type: string;
}


/**
 * @name IPaymentDetails
 * @description: Details about product payment
 */
export interface IPaymentDetails {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name payment_method
     * @type ("INVOICE_BASED"|"CREDIT_CARD")
     * @required
     */
    payment_method: ("INVOICE_BASED"|"CREDIT_CARD");
    /**
     * @name payment_methods
     * @type ("INVOICE_BASED"|"CREDIT_CARD")[]
     */
    payment_methods?: ("INVOICE_BASED"|"CREDIT_CARD")[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IPaymentDetailsUpdate
 * @description: Json Model with Product Payment Details
 */
export interface IPaymentDetailsUpdate {
    /**
     * @name payment_method
     * @type ("INVOICE_BASED"|"CREDIT_CARD")
     * @description The way how the customer will pay for the product.
     * @required
     */
    payment_method: ("INVOICE_BASED"|"CREDIT_CARD");
}


/**
 * @name IPermission
 * @description: Model of Permission
 */
export interface IPermission {
    /**
     * @name action
     * @type ("CREATE"|"READ"|"UPDATE"|"DELETE"|"ACTIVATE"|"INVITE"|"PUBLISH"|"DEPLOY")
     * @required
     */
    action: ("CREATE"|"READ"|"UPDATE"|"DELETE"|"ACTIVATE"|"INVITE"|"PUBLISH"|"DEPLOY");
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name entity_type
     * @type ("ARTICLE"|"AUTHORIZATION_TOKEN"|"BOOTLOADER"|"COMPILER"|"GARFIELD"|"PERSON"|"PRODUCT"|"PROJECT"|"FIRMWARE"|"FIRMWARE_VERSION"|"LIBRARY"|"LIBRARY_VERSION"|"WIDGET"|"WIDGET_VERSION"|"GRID_PROJECT"|"GRID_PROGRAM"|"GRID_PROGRAM_VERSION"|"BLOCK"|"BLOCK_VERSION"|"BLOCKO_PROGRAM"|"BLOCKO_PROGRAM_VERSION"|"INSTANCE"|"INSTANCE_SNAPSHOT"|"HARDWARE"|"HARDWARE_GROUP"|"HARDWARE_UPDATE"|"HARDWARE_BATCH"|"HARDWARE_ENTITY"|"HARDWARE_TYPE"|"INVITATION"|"INVOICE"|"PROCESSOR"|"PRODUCER"|"NOTIFICATION"|"ROLE"|"UPDATE_PROCEDURE"|"PRODUCT_EXTENSION"|"ERROR"|"TARIFF"|"TARIFF_EXTENSION"|"PAYMENT_DETAILS"|"HOMER"|"CUSTOMER"|"EMPLOYEE"|"GSM"|"CONTACT"|"INTEGRATOR_CLIENT"|"MONGO_GATEWAY")
     * @required
     */
    entity_type: ("ARTICLE"|"AUTHORIZATION_TOKEN"|"BOOTLOADER"|"COMPILER"|"GARFIELD"|"PERSON"|"PRODUCT"|"PROJECT"|"FIRMWARE"|"FIRMWARE_VERSION"|"LIBRARY"|"LIBRARY_VERSION"|"WIDGET"|"WIDGET_VERSION"|"GRID_PROJECT"|"GRID_PROGRAM"|"GRID_PROGRAM_VERSION"|"BLOCK"|"BLOCK_VERSION"|"BLOCKO_PROGRAM"|"BLOCKO_PROGRAM_VERSION"|"INSTANCE"|"INSTANCE_SNAPSHOT"|"HARDWARE"|"HARDWARE_GROUP"|"HARDWARE_UPDATE"|"HARDWARE_BATCH"|"HARDWARE_ENTITY"|"HARDWARE_TYPE"|"INVITATION"|"INVOICE"|"PROCESSOR"|"PRODUCER"|"NOTIFICATION"|"ROLE"|"UPDATE_PROCEDURE"|"PRODUCT_EXTENSION"|"ERROR"|"TARIFF"|"TARIFF_EXTENSION"|"PAYMENT_DETAILS"|"HOMER"|"CUSTOMER"|"EMPLOYEE"|"GSM"|"CONTACT"|"INTEGRATOR_CLIENT"|"MONGO_GATEWAY");
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IPerson
 * @description: Interface IPerson definition
 */
export interface IPerson {
    /**
     * @name byzance_admin
     * @type boolean
     * @readonly
     */
    byzance_admin?: boolean;
    /**
     * @name country
     * @type string
     * @required
     */
    country: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
    /**
     * @name first_name
     * @type string
     * @required
     */
    first_name: string;
    /**
     * @name gender
     * @type string
     * @required
     */
    gender: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name last_name
     * @type string
     * @required
     */
    last_name: string;
    /**
     * @name nick_name
     * @type string
     * @required
     */
    nick_name: string;
    /**
     * @name picture_link
     * @type string
     * @readonly
     * @required
     */
    picture_link: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IPersonChangeProperty
 * @description: Json Model for changing Person property
 */
export interface IPersonChangeProperty {
    /**
     * @name email
     * @type string
     * @description Valid email
     */
    email?: string;
    /**
     * @name password
     * @type string
     * @description The password length must be between 8 and 60 characters
     */
    password?: string;
    /**
     * @name property
     * @type string
     * @description Required property ('password' or 'email')
     * @required
     */
    property: string;
}


/**
 * @name IPersonNew
 * @description: Json Model for creating new Person
 */
export interface IPersonNew {
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
    /**
     * @name first_name
     * @type string
     * @description The full_name is not required. Max lenght is 60 characters.
     * @required
     */
    first_name: string;
    /**
     * @name last_name
     * @type string
     * @description The full_name is not required. Max lenght is 60 characters.
     * @required
     */
    last_name: string;
    /**
     * @name nick_name
     * @type string
     * @description Length must be between 8 and 60 characters.
     * @required
     */
    nick_name: string;
    /**
     * @name password
     * @type string
     * @description The password length must be between 8 and 60 characters
     * @required
     */
    password: string;
}


/**
 * @name IPersonPasswordNew
 * @description: Json Model for recovering person password
 */
export interface IPersonPasswordNew {
    /**
     * @name email
     * @type string
     * @required
     */
    email: string;
    /**
     * @name password
     * @type string
     * @description The password length must be between 8 and 60 characters
     * @required
     */
    password: string;
    /**
     * @name password_recovery_token
     * @type string
     * @description Required password_recovery_token
     * @required
     */
    password_recovery_token: string;
}


/**
 * @name IPersonUpdate
 * @description: Json Model for update Person. (For password or email, you have to used separate API)
 */
export interface IPersonUpdate {
    /**
     * @name country
     * @type string
     * @description Where does the user come from.
     */
    country?: string;
    /**
     * @name first_name
     * @type string
     * @description Length must be between 8 and 60 characters.
     */
    first_name?: string;
    /**
     * @name gender
     * @type ("male"|"female")
     * @description Gender of the user.
     */
    gender?: ("male"|"female");
    /**
     * @name last_name
     * @type string
     * @description Length must be between 8 and 60 characters.
     */
    last_name?: string;
    /**
     * @name nick_name
     * @type string
     * @description Length must be between 8 and 60 characters.
     * @required
     */
    nick_name: string;
}


/**
 * @name IProcessor
 * @description: Model of Processor
 */
export interface IProcessor {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name processor_code
     * @type string
     * @required
     */
    processor_code: string;
    /**
     * @name speed
     * @type number
     * @format int32
     * @required
     */
    speed: number;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProcessorNew
 * @description: Json Model for new Processor
 */
export interface IProcessorNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name processor_code
     * @type string
     * @description The Processor Code must have at least 4 characters
     * @required
     */
    processor_code: string;
    /**
     * @name speed
     * @type number
     * @format int32
     * @required
     */
    speed: number;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IProducer
 * @description: Model of Producer
 */
export interface IProducer {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProduct
 * @description: Model of Product
 */
export interface IProduct {
    /**
     * @name active
     * @type boolean
     * @readonly
     * @required
     */
    active: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name integrator_client
     * @type IIntegratorClient
     * @required
     */
    integrator_client: IIntegratorClient;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name owner
     * @type ICustomer
     * @required
     */
    owner: ICustomer;
    /**
     * @name payment_details
     * @type IPaymentDetails
     * @required
     */
    payment_details: IPaymentDetails;
    /**
     * @name remaining_credit
     * @type number
     * @format double
     * @readonly
     */
    remaining_credit?: number;
    /**
     * @name subscription_id
     * @type string
     * @required
     */
    subscription_id: string;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProductEvent
 * @description: Interface IProductEvent definition
 */
export interface IProductEvent {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name detail
     * @type string
     */
    detail?: string;
    /**
     * @name event_type
     * @type ("PRODUCT_CREATED"|"PRODUCT_DELETED"|"INVOICE_CREATED"|"INVOICE_CONFIRMED"|"INVOICE_ISSUED"|"INVOICE_PAYMENT_RECEIVED"|"EXTENSION_CREATED"|"EXTENSION_ACTIVATED"|"EXTENSION_DEACTIVATED"|"EXTENSION_DELETED")
     * @required
     */
    event_type: ("PRODUCT_CREATED"|"PRODUCT_DELETED"|"INVOICE_CREATED"|"INVOICE_CONFIRMED"|"INVOICE_ISSUED"|"INVOICE_PAYMENT_RECEIVED"|"EXTENSION_CREATED"|"EXTENSION_ACTIVATED"|"EXTENSION_DEACTIVATED"|"EXTENSION_DELETED");
    /**
     * @name event_type_name
     * @type string
     * @readonly
     */
    event_type_name?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name product
     * @type IProduct
     * @required
     */
    product: IProduct;
    /**
     * @name read_permission
     * @type ("USER"|"ADMIN")
     * @required
     */
    read_permission: ("USER"|"ADMIN");
    /**
     * @name reference
     * @type string
     * @format uuid
     * @required
     */
    reference: string;
    /**
     * @name reference_name
     * @type string
     * @readonly
     */
    reference_name?: string;
    /**
     * @name reference_type
     * @type ("EXTENSION"|"INVOICE")
     * @readonly
     */
    reference_type?: ("EXTENSION"|"INVOICE");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProductExtension
 * @description: Model of ProductExtension
 */
export interface IProductExtension {
    /**
     * @name active
     * @type boolean
     * @readonly
     * @required
     */
    active: boolean;
    /**
     * @name config
     * @type string
     * @description Visible only for Administrator with Special Permission
     * @readonly
     */
    config?: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name type
     * @type ("PROJECT"|"LOG"|"DATABASE"|"REST_API"|"SUPPORT"|"INSTANCE"|"HOMER_SERVER"|"PARTICIPANT"|"IODA"|"GSM")
     * @required
     */
    type: ("PROJECT"|"LOG"|"DATABASE"|"REST_API"|"SUPPORT"|"INSTANCE"|"HOMER_SERVER"|"PARTICIPANT"|"IODA"|"GSM");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProductExtensionNew
 * @description: Json Model for creating new extension of product.
 */
export interface IProductExtensionNew {
    /**
     * @name config
     * @type string
     * @required
     */
    config: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name extension_type
     * @type string
     * @description Enumerated type of extension
     * @required
     */
    extension_type: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IProductExtensionType
 * @description: Json Model for ProductExtension_Type
 */
export interface IProductExtensionType {
    /**
     * @name description
     * @type string
     * @readonly
     * @required
     */
    description: string;
    /**
     * @name name
     * @type string
     * @readonly
     * @required
     */
    name: string;
    /**
     * @name type
     * @type string
     * @readonly
     * @required
     */
    type: string;
}


/**
 * @name IProductCredit
 * @description: Json Model for buying credit for Product
 */
export interface IProductCredit {
    /**
     * @name credit
     * @type number
     * @description Double number - amount of credit to be bought.
     * @format double
     * @required
     */
    credit: number;
}


/**
 * @name IProductNew
 * @description: Json Model if user wants to create new Product for projects
 */
export interface IProductNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name extension_ids
     * @type string[]
     * @description List of Ids of Extensions
     */
    extension_ids?: string[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name owner_id
     * @type string
     * @description If null new customer is created. Customer can be company or single person.
     * @format uuid
     */
    owner_id?: string;
    /**
     * @name owner_new_contact
     * @type IContactUpdate
     * @description Contact data for new product owner. Used if owner_id is null.
     */
    owner_new_contact?: IContactUpdate;
    /**
     * @name payment_details
     * @type IPaymentDetailsUpdate
     * @description Product payment details.
     */
    payment_details?: IPaymentDetailsUpdate;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name tariff_id
     * @type string
     * @description Id of a selected tariff.
     * @format uuid
     * @required
     */
    tariff_id: string;
}


/**
 * @name IProject
 * @description: Model of Project
 */
export interface IProject {
    /**
     * @name active
     * @type boolean
     * @readonly
     * @required
     */
    active: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name persons
     * @type IPerson[]
     * @required
     */
    persons: IPerson[];
    /**
     * @name product
     * @type IProduct
     * @required
     */
    product: IProduct;
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IProjectAddHardware
 * @description: Interface IProjectAddHardware definition
 */
export interface IProjectAddHardware {
    /**
     * @name description
     * @type string
     * @description Optional value on Begging
     */
    description?: string;
    /**
     * @name group_id
     * @type string
     * @format uuid
     */
    group_id?: string;
    /**
     * @name name
     * @type string
     * @description Optional value on Begging
     */
    name?: string;
    /**
     * @name project_id
     * @type string
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name registration_hash
     * @type string
     * @required
     */
    registration_hash: string;
}


/**
 * @name IProjectList
 * @description: Project List
 */
export interface IProjectList {
    /**
     * @name content
     * @type IProject[]
     * @readonly
     * @required
     */
    content: IProject[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IProjectNew
 * @description: Json Model for new General Project
 */
export interface IProjectNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name product_id
     * @type string
     * @description Id of Product (Tariff) where the project will be registered
     * @format uuid
     * @required
     */
    product_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name IProjectValidUniqueName
 * @description: Json Model for Validation of unique name in project
 */
export interface IProjectValidUniqueName {
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name object_type
     * @type ("Project"|"HomerServer"|"CodeServer"|"BProgram"|"BProgramVersion"|"CProgram"|"CProgramVersion"|"Library"|"LibraryVersion"|"GridProgram"|"GridProgramVersion"|"GridProject"|"Hardware"|"HardwareGroup"|"GSM"|"Role"|"Widget"|"WidgetVersion"|"Block"|"BlockVersion"|"Instance"|"Snapshot"|"Database"|"DatabaseCollection")
     * @description Type
     * @required
     */
    object_type: ("Project"|"HomerServer"|"CodeServer"|"BProgram"|"BProgramVersion"|"CProgram"|"CProgramVersion"|"Library"|"LibraryVersion"|"GridProgram"|"GridProgramVersion"|"GridProject"|"Hardware"|"HardwareGroup"|"GSM"|"Role"|"Widget"|"WidgetVersion"|"Block"|"BlockVersion"|"Instance"|"Snapshot"|"Database"|"DatabaseCollection");
    /**
     * @name parent_id
     * @type string
     * @description Required only if object_type is not Project
     * @format uuid
     */
    parent_id?: string;
}


/**
 * @name IProjectFilter
 * @description: Json Model for Filtering Boards
 */
export interface IProjectFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name description
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Hardware Alias Name - substring supported
     */
    name?: string;
    /**
     * @name order_by
     * @type ("NAME"|"CREATED"|"UPDATED")
     * @description Order By Enum value
     */
    order_by?: ("NAME"|"CREATED"|"UPDATED");
    /**
     * @name order_schema
     * @type ("ASC"|"DESC")
     * @description Order
     */
    order_schema?: ("ASC"|"DESC");
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name products
     * @type string[]
     * @description List of processor.id
     */
    products?: string[];
    /**
     * @name tags
     * @type string[]
     * @description List of tags
     */
    tags?: string[];
}


/**
 * @name IReportAdminDashboard
 * @description: Interface IReportAdminDashboard definition
 */
export interface IReportAdminDashboard {
    /**
     * @name board_registered
     * @type number
     * @format int32
     * @required
     */
    board_registered: number;
    /**
     * @name bugs_reported
     * @type number
     * @format int32
     * @required
     */
    bugs_reported: number;
    /**
     * @name compilation_server_public_created
     * @type number
     * @format int32
     * @required
     */
    compilation_server_public_created: number;
    /**
     * @name compilation_server_public_online
     * @type number
     * @format int32
     * @required
     */
    compilation_server_public_online: number;
    /**
     * @name homer_server_private_created
     * @type number
     * @format int32
     * @required
     */
    homer_server_private_created: number;
    /**
     * @name homer_server_private_online
     * @type number
     * @format int32
     * @required
     */
    homer_server_private_online: number;
    /**
     * @name homer_server_public_created
     * @type number
     * @format int32
     * @required
     */
    homer_server_public_created: number;
    /**
     * @name homer_server_public_online
     * @type number
     * @format int32
     * @required
     */
    homer_server_public_online: number;
    /**
     * @name person_registration
     * @type number
     * @format int32
     * @required
     */
    person_registration: number;
    /**
     * @name project_created
     * @type number
     * @format int32
     * @required
     */
    project_created: number;
}


/**
 * @name IResendEmail
 * @description: Json Model for change description for Permission
 */
export interface IResendEmail {
    /**
     * @name email
     * @type string
     * @description Email is optional value! If it is empty, default invoice email will be used.
     */
    email?: string;
}


/**
 * @name IResultBadRequest
 * @description: When is something wrong
 */
export interface IResultBadRequest {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("error")
     * @description state
     * @readonly
     * @required
     */
    state: ("error");
}


/**
 * @name IResultExternalServerSideError
 * @description: Unknown Error on external server
 */
export interface IResultExternalServerSideError {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Internal Server Error
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("error")
     * @description state
     * @readonly
     * @required
     */
    state: ("error");
}


/**
 * @name IResultForbidden
 * @description: Permission is needed for this action.
 */
export interface IResultForbidden {
    /**
     * @name code
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("forbidden")
     * @readonly
     * @required
     */
    state: ("forbidden");
}


/**
 * @name IResultFound
 * @description: Requested entity was not found
 */
export interface IResultFound {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("not_found")
     * @description state
     * @readonly
     * @required
     */
    state: ("not_found");
}


/**
 * @name IResultInternalServerError
 * @description: When server is in fault state
 */
export interface IResultInternalServerError {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("internal_server_error")
     * @description state
     * @readonly
     * @required
     */
    state: ("internal_server_error");
}


/**
 * @name IResultInvalidBody
 * @description: Provided body is not valid. Something is missing or some field input is not allowed.
 */
export interface IResultInvalidBody {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("invalid_body")
     * @description state
     * @readonly
     * @required
     */
    state: ("invalid_body");
}


/**
 * @name IResultNotValidated
 * @description: When account is not validated.
 */
export interface IResultNotValidated {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("error_person_account_is_not_validated")
     * @description state
     * @readonly
     * @required
     */
    state: ("error_person_account_is_not_validated");
}


/**
 * @name IResultOk
 * @description: Interface IResultOk definition
 */
export interface IResultOk {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User. Server fills the message only when it is important
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("ok")
     * @description state
     * @readonly
     * @required
     */
    state: ("ok");
}


/**
 * @name IResultServerOffline
 * @description: External servers (compilation, blocko cloud_blocko_server etc.) are offline
 */
export interface IResultServerOffline {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("server_is_offline")
     * @description state
     * @readonly
     * @required
     */
    state: ("server_is_offline");
}


/**
 * @name IRole
 * @description: Model of Role
 */
export interface IRole {
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name default_role
     * @type boolean
     * @required
     */
    default_role: boolean;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name permissions
     * @type IPermission[]
     * @required
     */
    permissions: IPermission[];
    /**
     * @name persons
     * @type IPerson[]
     * @required
     */
    persons: IPerson[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name IRoleAddPermission
 * @description: Json Model for add list of Permission to Role
 */
export interface IRoleAddPermission {
    /**
     * @name permissions
     * @type string[]
     * @description List of permission ids
     * @required
     */
    permissions: string[];
}


/**
 * @name IRoleFilter
 * @description: Json Model for getting Role Filter List
 */
export interface IRoleFilter {
    /**
     * @name count_on_page
     * @type number
     * @description number of objects per page, min value is 1, max value is 50, default is 25. It's optional
     * @format int32
     */
    count_on_page?: number;
    /**
     * @name page_number
     * @type number
     * @description page_number is Integer. 1,2,3...n. For first call, use 1 (first page of list)
     * @format int32
     * @required
     */
    page_number: number;
    /**
     * @name project_id
     * @type string
     * @description Include only if you want to get Roles of given project
     * @format uuid
     */
    project_id?: string;
}


/**
 * @name IRoleList
 * @description: Individual Role List
 */
export interface IRoleList {
    /**
     * @name content
     * @type IRole[]
     * @readonly
     * @required
     */
    content: IRole[];
    /**
     * @name from
     * @type number
     * @description First value position from all subjects. Minimum is 0.
     * @format int32
     * @readonly
     * @required
     */
    from: number;
    /**
     * @name pages
     * @type number
     * @description Numbers of pages, which you can call
     * @format int32
     * @readonly
     * @required
     */
    pages: number;
    /**
     * @name to
     * @type number
     * @description Minimum is "from" Maximum is "total"
     * @format int32
     * @readonly
     * @required
     */
    to: number;
    /**
     * @name total
     * @type number
     * @description Total subjects
     * @format int32
     * @readonly
     * @required
     */
    total: number;
}


/**
 * @name IServerRegistrationFormData
 * @description: Interface IServerRegistrationFormData definition
 */
export interface IServerRegistrationFormData {
    /**
     * @name server_sizes
     * @type IServerRegistrationFormDataServerSize[]
     * @required
     */
    server_sizes: IServerRegistrationFormDataServerSize[];
}


/**
 * @name IServerRegistrationFormDataServerRegion
 * @description: Interface IServerRegistrationFormDataServerRegion definition
 */
export interface IServerRegistrationFormDataServerRegion {
    /**
     * @name available
     * @type boolean
     * @required
     */
    available: boolean;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name slug
     * @type string
     * @required
     */
    slug: string;
}


/**
 * @name IServerRegistrationFormDataServerSize
 * @description: Interface IServerRegistrationFormDataServerSize definition
 */
export interface IServerRegistrationFormDataServerSize {
    /**
     * @name available
     * @type boolean
     * @required
     */
    available: boolean;
    /**
     * @name memory
     * @type number
     * @format int32
     * @required
     */
    memory: number;
    /**
     * @name price_hourly
     * @type number
     * @required
     */
    price_hourly: number;
    /**
     * @name price_monthly
     * @type number
     * @required
     */
    price_monthly: number;
    /**
     * @name regions
     * @type IServerRegistrationFormDataServerRegion[]
     * @required
     */
    regions: IServerRegistrationFormDataServerRegion[];
    /**
     * @name slug
     * @type string
     * @required
     */
    slug: string;
    /**
     * @name vcpus
     * @type number
     * @format int32
     * @required
     */
    vcpus: number;
}


/**
 * @name ISetting
 * @description: Interface ISetting definition
 */
export interface ISetting {
    /**
     * @name key
     * @type ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME")
     * @required
     */
    key: ("ALIAS"|"AUTOBACKUP"|"AUTOJUMP"|"BACKUP_MQTT_CONNECTION"|"BLREPORT"|"KEEPALIVE"|"LOWPAN_CREDENTIALS"|"LOWPANBR"|"NETSOURCE"|"NORMAL_MQTT_CONNECTION"|"RECONNECT"|"TIMEOFFSET"|"TIMESYNC"|"WEBPORT"|"WEBVIEW"|"WDENABLE"|"WDTIME");
    /**
     * @name pending_value
     * @type any
     * @required
     */
    pending_value: any;
    /**
     * @name type
     * @type string
     * @required
     */
    type: string;
    /**
     * @name value
     * @type any
     * @required
     */
    value: any;
}


/**
 * @name IShortReference
 * @description: Model of Reference
 */
export interface IShortReference {
    /**
     * @name author
     * @type IPerson
     */
    author?: IPerson;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @required
     */
    created: number;
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name online_state
     * @type ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER")
     * @description Only for Special Object type like Server, Instance, HW
     */
    online_state?: ("NOT_YET_FIRST_CONNECTED"|"FREEZED"|"SHUT_DOWN"|"SYNCHRONIZATION_IN_PROGRESS"|"OFFLINE"|"ONLINE"|"UNKNOWN_LOST_CONNECTION_WITH_SERVER");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @required
     */
    updated: number;
}


/**
 * @name ISingleValue
 * @description: Json Model for data point in time
 */
export interface ISingleValue {
    /**
     * @name time
     * @type number
     * @format int32
     * @readonly
     * @required
     */
    time: number;
    /**
     * @name value
     * @type number
     * @format double
     * @readonly
     * @required
     */
    value: number;
}


/**
 * @name ISwaggerBlockVersionNew
 * @description: Json Model for crating new version and also content of BlockVersion Model
 */
export interface ISwaggerBlockVersionNew {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name logic_json
     * @type string
     * @required
     */
    logic_json: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ISwaggerDatabaseNew
 * @description: Interface ISwaggerDatabaseNew definition
 */
export interface ISwaggerDatabaseNew {
    /**
     * @name collection_name
     * @type string
     * @required
     */
    collection_name: string;
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name product_id
     * @type string
     * @format uuid
     * @required
     */
    product_id: string;
}


/**
 * @name ISwaggerGridWidgetVersionGridAppSource
 * @description: Interface ISwaggerGridWidgetVersionGridAppSource definition
 */
export interface ISwaggerGridWidgetVersionGridAppSource {
    /**
     * @name id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @readonly
     * @required
     */
    link_to_download: string;
    /**
     * @name logic_json
     * @type ILogicJson
     * @readonly
     */
    logic_json?: ILogicJson;
}


/**
 * @name ISwaggerInstanceSnapShotConfiguration
 * @description: Interface ISwaggerInstanceSnapShotConfiguration definition
 */
export interface ISwaggerInstanceSnapShotConfiguration {
    /**
     * @name api_keys
     * @type ISwaggerInstanceSnapShotConfigurationApiKeys[]
     * @required
     */
    api_keys: ISwaggerInstanceSnapShotConfigurationApiKeys[];
    /**
     * @name grids_collections
     * @type ISwaggerInstanceSnapShotConfigurationFile[]
     * @required
     */
    grids_collections: ISwaggerInstanceSnapShotConfigurationFile[];
    /**
     * @name mesh_keys
     * @type ISwaggerInstanceSnapShotConfigurationApiKeys[]
     * @required
     */
    mesh_keys: ISwaggerInstanceSnapShotConfigurationApiKeys[];
}


/**
 * @name ISwaggerInstanceSnapShotConfigurationApiKeys
 * @description: Interface ISwaggerInstanceSnapShotConfigurationApiKeys definition
 */
export interface ISwaggerInstanceSnapShotConfigurationApiKeys {
    /**
     * @name blocked
     * @type number
     * @format int32
     * @required
     */
    blocked: number;
    /**
     * @name created
     * @type number
     * @format int32
     * @required
     */
    created: number;
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name token
     * @type string
     * @required
     */
    token: string;
}


/**
 * @name ISwaggerInstanceSnapShotConfigurationFile
 * @description: Interface ISwaggerInstanceSnapShotConfigurationFile definition
 */
export interface ISwaggerInstanceSnapShotConfigurationFile {
    /**
     * @name grid_programs
     * @type ISwaggerInstanceSnapShotConfigurationProgram[]
     * @required
     */
    grid_programs: ISwaggerInstanceSnapShotConfigurationProgram[];
    /**
     * @name grid_project_id
     * @type string
     * @format uuid
     * @required
     */
    grid_project_id: string;
}


/**
 * @name ISwaggerInstanceSnapShotConfigurationProgram
 * @description: Interface ISwaggerInstanceSnapShotConfigurationProgram definition
 */
export interface ISwaggerInstanceSnapShotConfigurationProgram {
    /**
     * @name connection_token
     * @type string
     * @required
     */
    connection_token: string;
    /**
     * @name connection_url
     * @type string
     * @readonly
     */
    connection_url?: string;
    /**
     * @name grid_program_id
     * @type string
     * @format uuid
     * @required
     */
    grid_program_id: string;
    /**
     * @name grid_program_version_id
     * @type string
     * @format uuid
     * @required
     */
    grid_program_version_id: string;
    /**
     * @name snapshot_settings
     * @type ("PUBLIC"|"PROJECT"|"TESTING")
     * @required
     */
    snapshot_settings: ("PUBLIC"|"PROJECT"|"TESTING");
}


/**
 * @name ISystemAccess
 * @description: Json Model for System Access>
 */
export interface ISystemAccess {
    /**
     * @name permissions
     * @type IPermission[]
     * @readonly
     * @required
     */
    permissions: IPermission[];
    /**
     * @name roles
     * @type IRole[]
     * @readonly
     * @required
     */
    roles: IRole[];
}


/**
 * @name ITMSimStatus
 * @description: Interface ITMSimStatus definition
 */
export interface ITMSimStatus {
    /**
     * @name activation_date
     * @type number
     * @example 1536424319
     * @format int32
     * @readonly
     */
    activation_date?: number;
    /**
     * @name balance
     * @type number
     * @format int32
     * @required
     */
    balance: number;
    /**
     * @name block_sim_after_expiration_date
     * @type number
     * @format int32
     */
    block_sim_after_expiration_date?: number;
    /**
     * @name block_sim_daily
     * @type boolean
     * @readonly
     */
    block_sim_daily?: boolean;
    /**
     * @name block_sim_monthly
     * @type boolean
     * @readonly
     */
    block_sim_monthly?: boolean;
    /**
     * @name block_sim_total
     * @type boolean
     * @readonly
     */
    block_sim_total?: boolean;
    /**
     * @name country
     * @type string
     * @description Last know Country
     * @readonly
     */
    country?: string;
    /**
     * @name daily_traffic
     * @type number
     * @description in bites
     * @format int32
     */
    daily_traffic?: number;
    /**
     * @name daily_traffic_threshold
     * @type number
     * @description in MB
     * @format int32
     */
    daily_traffic_threshold?: number;
    /**
     * @name days_from_activation
     * @type number
     * @format int32
     * @readonly
     */
    days_from_activation?: number;
    /**
     * @name expiration_date
     * @type number
     * @example 1536424319
     * @format int32
     */
    expiration_date?: number;
    /**
     * @name iccid
     * @type string
     * @required
     */
    iccid: string;
    /**
     * @name last_connection
     * @type number
     * @example 1536424319
     * @format int32
     */
    last_connection?: number;
    /**
     * @name month_cost
     * @type number
     * @format double
     * @readonly
     */
    month_cost?: number;
    /**
     * @name monthly_traffic
     * @type number
     * @description in bites
     * @format int32
     */
    monthly_traffic?: number;
    /**
     * @name monthly_traffic_threshold
     * @type number
     * @description in MB
     * @format int32
     */
    monthly_traffic_threshold?: number;
    /**
     * @name msisdn
     * @type number
     * @format int32
     * @required
     */
    msisdn: number;
    /**
     * @name name
     * @type string
     * @required
     */
    name: string;
    /**
     * @name plan
     * @type string
     * @required
     */
    plan: string;
    /**
     * @name status
     * @type ("{active"|"not active}")
     * @description Sim Status
     */
    status?: ("{active"|"not active}");
    /**
     * @name tag
     * @type string
     * @description Not Changeable!
     * @readonly
     */
    tag?: string;
    /**
     * @name total_traffic
     * @type number
     * @description in bites
     * @format int32
     */
    total_traffic?: number;
    /**
     * @name total_traffic_threshold
     * @type number
     * @description in MB
     * @format int32
     */
    total_traffic_threshold?: number;
    /**
     * @name type
     * @type string
     * @required
     */
    type: string;
}


/**
 * @name ITariff
 * @description: Interface ITariff definition
 */
export interface ITariff {
    /**
     * @name active
     * @type boolean
     * @required
     */
    active: boolean;
    /**
     * @name awesome_icon
     * @type string
     * @required
     */
    awesome_icon: string;
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name credit_for_beginning
     * @type number
     * @description Visible only for Administrator with Special Permission
     * @format double
     */
    credit_for_beginning?: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name extensions_included
     * @type ITariffExtension[]
     */
    extensions_included?: ITariffExtension[];
    /**
     * @name extensions_recommended
     * @type ITariffExtension[]
     */
    extensions_recommended?: ITariffExtension[];
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name identifier
     * @type string
     * @required
     */
    identifier: string;
    /**
     * @name labels
     * @type ITariffLabel[]
     * @readonly
     */
    labels?: ITariffLabel[];
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name order_position
     * @type number
     * @format int32
     * @required
     */
    order_position: number;
    /**
     * @name owner_details_required
     * @type boolean
     * @required
     */
    owner_details_required: boolean;
    /**
     * @name payment_details_required
     * @type boolean
     * @required
     */
    payment_details_required: boolean;
    /**
     * @name payment_methods
     * @type ("INVOICE_BASED"|"CREDIT_CARD")[]
     * @readonly
     */
    payment_methods?: ("INVOICE_BASED"|"CREDIT_CARD")[];
    /**
     * @name price
     * @type number
     * @format double
     * @readonly
     */
    price?: number;
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name ITariffExtension
 * @description: Model of TariffExtension
 */
export interface ITariffExtension {
    /**
     * @name active
     * @type boolean
     * @required
     */
    active: boolean;
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name config
     * @type string
     * @description Visible only for Administrator with Special Permission
     * @readonly
     */
    config?: string;
    /**
     * @name consumption
     * @type string
     * @description Visible only for Administrator with Special Permission
     */
    consumption?: string;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name order_position
     * @type number
     * @format int32
     * @required
     */
    order_position: number;
    /**
     * @name price
     * @type number
     * @format double
     * @readonly
     * @required
     */
    price: number;
    /**
     * @name type
     * @type ("PROJECT"|"LOG"|"DATABASE"|"REST_API"|"SUPPORT"|"INSTANCE"|"HOMER_SERVER"|"PARTICIPANT"|"IODA"|"GSM")
     * @required
     */
    type: ("PROJECT"|"LOG"|"DATABASE"|"REST_API"|"SUPPORT"|"INSTANCE"|"HOMER_SERVER"|"PARTICIPANT"|"IODA"|"GSM");
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
}


/**
 * @name ITariffExtensionEdit
 * @description: Json Model for updating extension of product.
 */
export interface ITariffExtensionEdit {
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name config
     * @type string
     * @description Json in String
     * @required
     */
    config: string;
    /**
     * @name consumption
     * @type string
     * @description Json in String
     * @required
     */
    consumption: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ITariffExtensionNew
 * @description: Json Model for updating extension of product.
 */
export interface ITariffExtensionNew {
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name config
     * @type string
     * @description Json in String
     * @required
     */
    config: string;
    /**
     * @name consumption
     * @type string
     * @description Json in String
     * @required
     */
    consumption: string;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name extension_type
     * @type string
     * @description Enumerated type of extension
     * @required
     */
    extension_type: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ITariffLabel
 * @description: Json Model for getting Type_Of_Block Filter List
 */
export interface ITariffLabel {
    /**
     * @name description
     * @type string
     * @required
     */
    description: string;
    /**
     * @name icon
     * @type string
     * @required
     */
    icon: string;
}


/**
 * @name ITariffNew
 * @description: Json Model for creating new tariff.
 */
export interface ITariffNew {
    /**
     * @name awesome_icon
     * @type string
     * @required
     */
    awesome_icon: string;
    /**
     * @name color
     * @type string
     * @required
     */
    color: string;
    /**
     * @name credit_for_beginning
     * @type number
     * @format double
     * @required
     */
    credit_for_beginning: number;
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name identifier
     * @type string
     * @required
     */
    identifier: string;
    /**
     * @name labels
     * @type ITariffLabel[]
     * @required
     */
    labels: ITariffLabel[];
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name owner_details_required
     * @type boolean
     * @required
     */
    owner_details_required: boolean;
    /**
     * @name payment_details_required
     * @type boolean
     * @required
     */
    payment_details_required: boolean;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
}


/**
 * @name ITerminalConnectionSummary
 * @description: Interface ITerminalConnectionSummary definition
 */
export interface ITerminalConnectionSummary {
    /**
     * @name grid_app_url
     * @type string
     * @description WS address -> replaces token in URL by verified token from the other APIs if its required by query
     * @readonly
     * @required
     */
    grid_app_url: string;
    /**
     * @name grid_program
     * @type string
     * @description Grid Program
     * @readonly
     * @required
     */
    grid_program: string;
    /**
     * @name grid_program_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    grid_program_id: string;
    /**
     * @name grid_program_version_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    grid_program_version_id: string;
    /**
     * @name grid_project_id
     * @type string
     * @format uuid
     * @readonly
     * @required
     */
    grid_project_id: string;
    /**
     * @name instance_id
     * @type string
     * @description Generated Token used for public programs, use as standard verification token
     * @format uuid
     * @readonly
     * @required
     */
    instance_id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Grid Program code in URL
     * @readonly
     * @required
     */
    link_to_download: string;
    /**
     * @name source_code_list
     * @type ISwaggerGridWidgetVersionGridAppSource[]
     * @description Generated Token used for public programs, use as standard verification token
     * @readonly
     * @required
     */
    source_code_list: ISwaggerGridWidgetVersionGridAppSource[];
}


/**
 * @name IUnauthorized
 * @description: Interface IUnauthorized definition
 */
export interface IUnauthorized {
    /**
     * @name code
     * @type number
     * @description code
     * @format int32
     * @readonly
     * @required
     */
    code: number;
    /**
     * @name message
     * @type string
     * @description Can be null! If not, you can show that to User
     * @readonly
     */
    message?: string;
    /**
     * @name state
     * @type ("unauthorized")
     * @description state
     * @readonly
     * @required
     */
    state: ("unauthorized");
}


/**
 * @name IWSMessageHardwareOverviewBoardBinariesInfo
 * @description: Interface IWSMessageHardwareOverviewBoardBinariesInfo definition
 */
export interface IWSMessageHardwareOverviewBoardBinariesInfo {
    /**
     * @name build_id
     * @type string
     * @required
     */
    build_id: string;
    /**
     * @name memsize
     * @type number
     * @format int32
     * @required
     */
    memsize: number;
    /**
     * @name size
     * @type number
     * @format int32
     * @required
     */
    size: number;
    /**
     * @name timestamp
     * @type number
     * @format int32
     * @required
     */
    timestamp: number;
    /**
     * @name usr_name
     * @type string
     * @required
     */
    usr_name: string;
    /**
     * @name usr_version
     * @type string
     * @required
     */
    usr_version: string;
    /**
     * @name version
     * @type string
     * @required
     */
    version: string;
}


/**
 * @name IWebSocketToken
 * @description: Json Model that you will get, if login was successful
 */
export interface IWebSocketToken {
    /**
     * @name websocket_token
     * @type string
     * @description Swagger_Websocket_Token - used this token for WebSocket access. The lifetime of the token is 5 seconds. It is disposable. It can not be used twice. In the event of the expiration of the life of the disabled.
     * @format uuid
     * @readonly
     * @required
     */
    websocket_token: string;
}


/**
 * @name IWidget
 * @description: Model of Widget
 */
export interface IWidget {
    /**
     * @name community_publishing_permission
     * @type boolean
     * @required
     */
    community_publishing_permission: boolean;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name producer
     * @type IProducer
     * @description can be hidden, if Widget is created by User not by Company
     * @readonly
     */
    producer?: IProducer;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @required
     */
    publish_type: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name versions
     * @type IWidgetVersion[]
     * @required
     */
    versions: IWidgetVersion[];
}


/**
 * @name IWidgetVersion
 * @description: Model of WidgetVersion
 */
export interface IWidgetVersion {
    /**
     * @name approval_state
     * @type ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED")
     * @description Only if user make request for publishing
     */
    approval_state?: ("PENDING"|"APPROVED"|"DISAPPROVED"|"EDITED");
    /**
     * @name author
     * @type IPerson
     * @description Visible only if user has permission to know it
     * @readonly
     */
    author?: IPerson;
    /**
     * @name created
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    created: number;
    /**
     * @name delete_permission
     * @type boolean
     * @description True if user can delete this object.
     * @readonly
     */
    delete_permission?: boolean;
    /**
     * @name description
     * @type string
     */
    description?: string;
    /**
     * @name id
     * @type string
     * @format uuid
     * @required
     */
    id: string;
    /**
     * @name link_to_download
     * @type string
     * @description Program Link to download
     * @readonly
     */
    link_to_download?: string;
    /**
     * @name name
     * @type string
     */
    name?: string;
    /**
     * @name publish_type
     * @type ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION")
     * @description Only for main / default program - and access only for administrators
     */
    publish_type?: ("PRIVATE"|"PUBLIC"|"DEFAULT_MAIN"|"DEFAULT_TEST"|"DEFAULT_VERSION");
    /**
     * @name tags
     * @type string[]
     */
    tags?: string[];
    /**
     * @name update_permission
     * @type boolean
     * @description True if user can update this object.
     * @readonly
     */
    update_permission?: boolean;
    /**
     * @name updated
     * @type number
     * @example 1466163478
     * @format int32
     * @readonly
     * @required
     */
    updated: number;
    /**
     * @name working_copy
     * @type boolean
     * @description Visible only for working copy versions
     */
    working_copy?: boolean;
}


/**
 * @name IWidgetCopy
 * @description: Json Model for copy Widget
 */
export interface IWidgetCopy {
    /**
     * @name description
     * @type string
     * @description description can be null or maximum length of 255 characters.
     */
    description?: string;
    /**
     * @name name
     * @type string
     * @description Length must be between 2 and 60 characters.
     * @required
     */
    name: string;
    /**
     * @name project_id
     * @type string
     * @description Provide project id if you want to create private object
     * @format uuid
     * @required
     */
    project_id: string;
    /**
     * @name tags
     * @type string[]
     * @description Tags - Optional
     */
    tags?: string[];
    /**
     * @name widget_id
     * @type string
     * @format uuid
     * @required
     */
    widget_id: string;
}




export abstract class TyrionAPI extends HomerAPI {

    protected abstract requestRestPath<T>(method:string, path:string, body:Object, success:number[], contentType:string):Promise<T>;

    /**
     * @name __login
     * @summary login
     * @operationId login
     * @tags Access, Person, APP-Api
     *
     * @description Get access Token
     *
     * @param {IEmailAndPassword} body - Contains Json with values
     *
     * @returns {ILoginToken} [code 200] Successfully logged
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Wrong Email or Password
     * @throws {IResultInternalServerError} [code 500] Server side Error
     * @throws {IResultNotValidated} [code 705] Account not validated
     */
    public __login(body:IEmailAndPassword):Promise<ILoginToken> {
        return this.requestRestPath("POST", `/login`, body, [200], 'application/json');
    }

    /**
     * @name __logout
     * @summary logout
     * @operationId logout
     * @tags Access, Person, APP-Api
     *
     * @description for logout person - that's deactivate person token
     *
     *
     * @returns {IResultOk} [code 200] Successfully logged out
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Wrong Email or Password
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public __logout():Promise<IResultOk> {
        return this.requestRestPath("POST", `/logout`, {}, [200], 'application/json');
    }

    /**
     * @name apikeyAdd
     * @summary add apikey
     * @operationId apikey
     * @tags Access, Person, APP-Api
     *
     * @description creates permanent api key
     *
     *
     * @returns {ILoginToken} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public apikeyAdd():Promise<ILoginToken> {
        return this.requestRestPath("POST", `/apikey`, {}, [200], 'application/json');
    }

    /**
     * @name articleCreate
     * @summary create Article
     * @operationId article_create
     * @tags Article
     *
     * @description create new Article
     *
     * @param {IArticleCreateUpdate} body - Contains Json with values
     *
     * @returns {IArticle} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public articleCreate(body:IArticleCreateUpdate):Promise<IArticle> {
        return this.requestRestPath("POST", `/article`, body, [201], 'application/json');
    }

    /**
     * @name articleDelete
     * @summary delete Article
     * @operationId article_delete
     * @tags Article
     *
     * @description remove Article object
     *
     * @param {string} article_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public articleDelete(article_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/article/${article_id}`, {}, [200], 'application/json');
    }

    /**
     * @name articleEdit
     * @summary edit Article
     * @operationId article_edit
     * @tags Article
     *
     * @description edit Article object
     *
     * @param {string} article_id
     * @param {IArticleCreateUpdate} body - Contains Json with values
     *
     * @returns {IArticle} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public articleEdit(article_id:string, body:IArticleCreateUpdate):Promise<IArticle> {
        return this.requestRestPath("PUT", `/article/${article_id}`, body, [200], 'application/json');
    }

    /**
     * @name articleGetListByFilter
     * @summary get Article List by Filter
     * @operationId article_getByFilter
     * @tags Article
     *
     * @description get Article List
     *
     * @param {IArticleFilter} body - Contains Json with values
     *
     * @returns {IArticleList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public articleGetListByFilter(body:IArticleFilter):Promise<IArticleList> {
        return this.requestRestPath("PUT", `/articles`, body, [200], 'application/json');
    }

    /**
     * @name bProgramClone
     * @summary clone B_Program
     * @operationId bProgram_clone
     * @tags B_Program
     *
     * @description clone B_Program for private
     *
     * @param {IBProgramCopy} body - Contains Json with values
     *
     * @returns {IBProgram} [code 201] Successfully created
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramClone(body:IBProgramCopy):Promise<IBProgram> {
        return this.requestRestPath("PUT", `/b_program/clone`, body, [201], 'application/json');
    }

    /**
     * @name bProgramCreate
     * @summary create B_Program
     * @operationId bProgram_create
     * @tags B_Program
     *
     * @description create new B_Program
     *
     * @param {string} project_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IBProgram} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramCreate(project_id:string, body:INameAndDescription):Promise<IBProgram> {
        return this.requestRestPath("POST", `/b_program/${project_id}`, body, [201], 'application/json');
    }

    /**
     * @name bProgramDelete
     * @summary delete B_Program
     * @operationId bProgram_delete
     * @tags B_Program
     *
     * @description remove B_Program object
     *
     * @param {string} b_program_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramDelete(b_program_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/b_program/${b_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bProgramEdit
     * @summary edit B_Program
     * @operationId bProgram_update
     * @tags B_Program
     *
     * @description edit basic information in B_Program object
     *
     * @param {string} b_program_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IBProgram} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramEdit(b_program_id:string, body:INameAndDescription):Promise<IBProgram> {
        return this.requestRestPath("PUT", `/b_program/${b_program_id}`, body, [200], 'application/json');
    }

    /**
     * @name bProgramGet
     * @summary get B_Program
     * @operationId bProgram_get
     * @tags B_Program
     *
     * @description get B_Program object
     *
     * @param {string} b_program_id
     *
     * @returns {IBProgram} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramGet(b_program_id:string):Promise<IBProgram> {
        return this.requestRestPath("GET", `/b_program/${b_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bProgramGetInstanceSnapshotFile
     * @summary get B_Program Instance Snapshot File
     * @operationId cloud_file_get_b_program_version
     * @tags Homer-Server-API
     *
     * @description Required secure Token changed throw websocket
     *
     * @param {string} snapshot_id
     *
     * @returns {any} [code 200]
     *
     * @throws {any} [code 303] Automatic Redirect To another URL
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramGetInstanceSnapshotFile(snapshot_id:string):Promise<any> {
        return this.requestRestPath("GET", `/cloud/homer_server/file/snapshot_programs/${snapshot_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bProgramGetListByFilter
     * @summary get B_Program List by Filter
     * @operationId bProgram_getByFilter
     * @tags B_Program
     *
     * @description get B_Program List
     *
     * @param {IBProgramFilter} body - Contains Json with values
     *
     * @returns {IBProgramList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramGetListByFilter(body:IBProgramFilter):Promise<IBProgramList> {
        return this.requestRestPath("PUT", `/b_programs`, body, [200], 'application/json');
    }

    /**
     * @name bProgramVersionCreate
     * @summary create B_Program_Version
     * @operationId bProgram_version_create
     * @tags B_Program
     *
     * @description create new version of Blocko program
     *
     * @param {string} b_program_id
     * @param {IBProgramVersionNew} body - Contains Json with values
     *
     * @returns {IBProgramVersion} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionCreate(b_program_id:string, body:IBProgramVersionNew):Promise<IBProgramVersion> {
        return this.requestRestPath("POST", `/b_program/version/${b_program_id}`, body, [200], 'application/json');
    }

    /**
     * @name bProgramVersionDelete
     * @summary delete B_Program_Version
     * @operationId bProgram_version_delete
     * @tags B_Program
     *
     * @description remove B_Program version object
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionDelete(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/b_program/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bProgramVersionEdit
     * @summary edit B_Program_Version
     * @operationId bProgram_version_update
     * @tags B_Program
     *
     * @description edit Version object
     *
     * @param {string} version_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IBProgramVersion} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionEdit(version_id:string, body:INameAndDescription):Promise<IBProgramVersion> {
        return this.requestRestPath("PUT", `/b_program/version/${version_id}`, body, [200], 'application/json');
    }

    /**
     * @name bProgramVersionEditResponsePublication
     * @summary edit B_Program_Version Response publication
     * @operationId bProgram_version_public_response
     * @tags Admin-B_Program
     *
     * @description sets Approval_state to pending
     *
     * @param {ICommunityVersionPublishResponse} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionEditResponsePublication(body:ICommunityVersionPublishResponse):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/b_program/version_for_aprove/approve_decision`, body, [200], 'application/json');
    }

    /**
     * @name bProgramVersionGet
     * @summary get B_Program_Version
     * @operationId bProgram_version_get
     * @tags B_Program
     *
     * @description get B_Program version object
     *
     * @param {string} version_id
     *
     * @returns {IBProgramVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionGet(version_id:string):Promise<IBProgramVersion> {
        return this.requestRestPath("GET", `/b_program/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bProgramVersionMakePublic
     * @summary make B_Program_Version public
     * @operationId bProgram_version_make_public
     * @tags B_Program
     *
     * @description Make B_Program public, so other users can see it and use it. Attention! Attention! Attention! A user can publish only three programs at the stage waiting for approval.
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Bad Request
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bProgramVersionMakePublic(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/b_program/version/make_public/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockClone
     * @summary clone Block
     * @operationId block_clone
     * @tags Block
     *
     * @description clone Block for private
     *
     * @param {IBlockoBlockCopy} body - Contains Json with values
     *
     * @returns {IBlock} [code 201] Successfully created
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockClone(body:IBlockoBlockCopy):Promise<IBlock> {
        return this.requestRestPath("POST", `/block/clone`, body, [201], 'application/json');
    }

    /**
     * @name blockCreate
     * @summary create Block
     * @operationId block_create
     * @tags Block
     *
     * @description creating new independent Block object for Blocko tools
     *
     * @param {INameAndDescProjectIdOptional} body - Contains Json with values
     *
     * @returns {IBlock} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something went wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockCreate(body:INameAndDescProjectIdOptional):Promise<IBlock> {
        return this.requestRestPath("POST", `/block`, body, [201], 'application/json');
    }

    /**
     * @name blockDelete
     * @summary delete Block
     * @operationId block_delete
     * @tags Block
     *
     * @description delete Block
     *
     * @param {string} block_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockDelete(block_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/block/${block_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockEdit
     * @summary edit Block
     * @operationId block_update
     * @tags Block
     *
     * @description update basic information (name, and description) of the independent Block
     *
     * @param {string} block_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IBlock} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockEdit(block_id:string, body:INameAndDescription):Promise<IBlock> {
        return this.requestRestPath("PUT", `/block/${block_id}`, body, [200], 'application/json');
    }

    /**
     * @name blockGet
     * @summary get Block
     * @operationId block_get
     * @tags Block
     *
     * @description get independent Block object
     *
     * @param {string} block_id
     *
     * @returns {IBlock} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockGet(block_id:string):Promise<IBlock> {
        return this.requestRestPath("GET", `/block/${block_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockGetListByFilter
     * @summary get Block List by Filter
     * @operationId block_getByFilter
     * @tags Block
     *
     * @description get Block List
     *
     * @param {IBlockFilter} body - Contains Json with values
     *
     * @returns {IBlockList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockGetListByFilter(body:IBlockFilter):Promise<IBlockList> {
        return this.requestRestPath("PUT", `/blocks`, body, [200], 'application/json');
    }

    /**
     * @name blockVersionCreate
     * @summary create BlockVersion
     * @operationId blocko_block_version_create
     * @tags Block
     *
     * @description new Block version
     *
     * @param {string} block_id
     * @param {ISwaggerBlockVersionNew} body - Contains Json with values
     *
     * @returns {IBlockVersion} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something went wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionCreate(block_id:string, body:ISwaggerBlockVersionNew):Promise<IBlockVersion> {
        return this.requestRestPath("POST", `/block/version/${block_id}`, body, [201], 'application/json');
    }

    /**
     * @name blockVersionDelete
     * @summary delete BlockVersion
     * @operationId blocko_block_version_delete
     * @tags Block
     *
     * @description delete Block version
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionDelete(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/block/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockVersionEdit
     * @summary edit BlockVersion
     * @operationId blocko_block_version_update
     * @tags Block
     *
     * @description You can edit only basic information of the version. If you want to update the code, you have to create a new version!
     *
     * @param {string} version_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IBlockVersion} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something went wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionEdit(version_id:string, body:INameAndDescription):Promise<IBlockVersion> {
        return this.requestRestPath("PUT", `/block/version/${version_id}`, body, [200], 'application/json');
    }

    /**
     * @name blockVersionEditResponsePublication
     * @summary edit BlockVersion Response publication
     * @operationId blocko_block_version_public_response
     * @tags Admin-Block
     *
     * @description sets Approval_state from pending
     *
     * @param {ICommunityVersionPublishResponse} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionEditResponsePublication(body:ICommunityVersionPublishResponse):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/version_for_aprove/approve_decision`, body, [200], 'application/json');
    }

    /**
     * @name blockVersionGet
     * @summary get BlockVersion
     * @operationId blocko_block_version_get
     * @tags Block
     *
     * @description get version (content) from independent Block
     *
     * @param {string} version_id
     *
     * @returns {IBlockVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionGet(version_id:string):Promise<IBlockVersion> {
        return this.requestRestPath("GET", `/block/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockVersionPublish
     * @summary publish BlockVersion
     * @operationId blocko_block_version_make_public
     * @tags Block
     *
     * @description sets Approval_state to pending
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionPublish(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/block/version/make_public/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name blockVersionSetMain
     * @summary setMain BlockVersion
     * @operationId blocko_block_version_setMain
     * @tags Admin-Block
     *
     * @description Administration Skunks only - set Version as Main - First code in program
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultFound} [code 403] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public blockVersionSetMain(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/block/version/set_main/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardChangeserver
     * @summary change_server Board
     * @operationId hardware_redirect_to_server
     * @tags Hardware
     *
     * @description Redirect Board to another server (Change Server)
     *
     * @param {string} hardware_id
     * @param {IBoardServerRedirect} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardChangeserver(hardware_id:string, body:IBoardServerRedirect):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/change_server/${hardware_id}`, body, [200], 'application/json');
    }

    /**
     * @name boardCheckRegistrationStatus
     * @summary check Board registration status
     * @operationId hardware_check
     * @tags Board
     *
     * @description Check Board state for new Registration. Types of responses in JSON state value[CAN_REGISTER, NOT_EXIST, ALREADY_REGISTERED_IN_YOUR_ACCOUNT, ALREADY_REGISTERED, PERMANENTLY_DISABLED, BROKEN_DEVICE]...
     *     PERMANENTLY_DISABLED - device was removed by Byzance.
     *    BROKEN_DEVICE - device exist - but its not possible to registered that. Damaged during manufacturing.
     *
     * @param {string} registration_hash
     * @param {string} project_id
     *
     * @returns {IEntityRegistrationStatus} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardCheckRegistrationStatus(registration_hash:string, project_id:string):Promise<IEntityRegistrationStatus> {
        return this.requestRestPath("GET", `/hardware/check/${registration_hash}/${project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardCommandExecution
     * @summary command Board execution
     * @operationId hardware_command_execution
     * @tags Board
     *
     * @description Removes picture of logged person
     *
     * @param {IBoardCommand} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardCommandExecution(body:IBoardCommand):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/command`, body, [200], 'application/json');
    }

    /**
     * @name boardCreateAutomaticGarfield
     * @summary create Board automatic Garfield
     * @operationId hardware_create_garfield
     * @tags Admin-Board
     *
     * @description This Api is using for Board automatic registration adn Testing. Hardware is not allowed to delete! Only deactivate. Classic User can only register that to own project or own to account
     *
     * @param {IBoardNewGarfield} body - Contains Json with values
     *
     * @returns {IHardwareNewSettingsResult} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardCreateAutomaticGarfield(body:IBoardNewGarfield):Promise<IHardwareNewSettingsResult> {
        return this.requestRestPath("POST", `/hardware/automatic_registration`, body, [201], 'application/json');
    }

    /**
     * @name boardDeactivate
     * @summary deactivate Board
     * @operationId hardware_deactivate
     * @tags Hardware
     *
     * @description Permanent exclusion from the system - for some reason it is not allowed to remove the Board from database
     *
     * @param {string} hardware_id
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardDeactivate(hardware_id:string):Promise<IHardware> {
        return this.requestRestPath("DELETE", `/hardware/deactivate/${hardware_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardDeletePicture
     * @summary delete Board picture
     * @operationId hardware_removePicture
     * @tags Board
     *
     * @description Removes picture of logged person
     *
     * @param {string} hardware_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardDeletePicture(hardware_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/picture/remove/${hardware_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardEditDeveloperSettings
     * @summary edit Board developer settings
     * @operationId hardware_updateDeveloperSettings
     * @tags Hardware
     *
     * @description Edit Developers parameters [developer_kit, database_synchronize]
     *
     * @param {string} hardware_id
     * @param {IBoardDeveloperSettings} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardEditDeveloperSettings(hardware_id:string, body:IBoardDeveloperSettings):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/settings/${hardware_id}`, body, [200], 'application/json');
    }

    /**
     * @name boardEditParameters
     * @summary edit Board parameters
     * @operationId hardware_updateParameters
     * @tags Hardware
     *
     * @description Edit bootloader parameters [netsource, webview, webport, ...]
     *
     * @param {IBoardDeveloperParameters} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardEditParameters(body:IBoardDeveloperParameters):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/parameters`, body, [200], 'application/json');
    }

    /**
     * @name boardEditPersonalDescription
     * @summary edit Board personal description
     * @operationId hardware_update_description
     * @tags Hardware
     *
     * @description Used for add descriptions by owners. "Persons" who registred "Board" to own "Project"
     *
     * @param {string} hardware_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardEditPersonalDescription(hardware_id:string, body:INameAndDescription):Promise<IHardware> {
        return this.requestRestPath("PUT", `/hardware/userDescription/${hardware_id}`, body, [200], 'application/json');
    }

    /**
     * @name boardGeneratemqttpassword
     * @summary generate_mqtt_password Board
     * @operationId hardware_generate_new_password
     * @tags Board
     *
     * @description Generate new connection password for Hardware
     *
     * @param {string} hardware_id
     *
     * @returns {IHardwareNewPassword} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardGeneratemqttpassword(hardware_id:string):Promise<IHardwareNewPassword> {
        return this.requestRestPath("PUT", `/hardware/mqtt_password_reset/${hardware_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardGet
     * @summary get Board
     * @operationId hardware_get
     * @tags Hardware
     *
     * @description if you want get Board object by query = hardware_id. User can get only hardware from project, whitch user owning or user need Permission key "Board_rea".
     *
     * @param {string} hardware_id
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardGet(hardware_id:string):Promise<IHardware> {
        return this.requestRestPath("GET", `/hardware/${hardware_id}`, {}, [200], 'application/json');
    }

    /**
     * @name boardGetByHash
     * @summary get Board by Hash
     * @operationId project_hardware_getByHash
     * @tags Hardware
     *
     * @param {IBoardGetByHash} body - Contains Json with values
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardGetByHash(body:IBoardGetByHash):Promise<IHardware> {
        return this.requestRestPath("PUT", `/project/hardware/get_by_hash`, body, [200], 'application/json');
    }

    /**
     * @name boardGroupUpdateDevices
     * @summary update BoardGroup Devices
     * @operationId hardwareGroup_addHardware
     * @tags BoardGroup
     *
     * @description update BoardGroup add or remove devices
     *
     * @param {IHardwareGroupDeviceListEditGroup} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardGroupUpdateDevices(body:IHardwareGroupDeviceListEditGroup):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware_group/group_hardwares`, body, [200], 'application/json');
    }

    /**
     * @name boardsGetListByFilter
     * @summary get Boards List by Filter
     * @operationId hardware_getByFilter
     * @tags Hardware
     *
     * @description Get List of hardware. According to permission - system return only hardware from project, where is user owner or all hardware if user have static Permission key
     *
     * @param {IBoardFilter} body - Contains Json with values
     *
     * @returns {IHardwareList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public boardsGetListByFilter(body:IBoardFilter):Promise<IHardwareList> {
        return this.requestRestPath("PUT", `/hardwares`, body, [200], 'application/json');
    }

    /**
     * @name bootloaderCreate
     * @summary create Bootloader
     * @operationId bootLoader_create
     * @tags HardwareType
     *
     * @description Create bootloader for HardwareType
     *
     * @param {string} hardware_type_id
     * @param {IBootLoaderCreate} body - Contains Json with values
     *
     * @returns {IBootLoader} [code 201] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderCreate(hardware_type_id:string, body:IBootLoaderCreate):Promise<IBootLoader> {
        return this.requestRestPath("POST", `/bootloader/${hardware_type_id}`, body, [201], 'application/json');
    }

    /**
     * @name bootloaderDelete
     * @summary delete Bootloader
     * @operationId bootLoader_delete
     * @tags HardwareType
     *
     * @param {string} boot_loader_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderDelete(boot_loader_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/bootloader/${boot_loader_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bootloaderEdit
     * @summary edit Bootloader
     * @operationId bootLoader_update
     * @tags HardwareType
     *
     * @description Edit bootloader for HardwareType
     *
     * @param {string} boot_loader_id
     * @param {IBootLoaderCreate} body - Contains Json with values
     *
     * @returns {IBootLoader} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderEdit(boot_loader_id:string, body:IBootLoaderCreate):Promise<IBootLoader> {
        return this.requestRestPath("PUT", `/bootloader/${boot_loader_id}`, body, [200], 'application/json');
    }

    /**
     * @name bootloaderEditSetAsMain
     * @summary edit Bootloader Set as Main
     * @operationId bootLoader_markAsMain
     * @tags Admin-Bootloader
     *
     * @description List of Hardware Id for update on latest bootloader version (system used latest bootloader for type of hardware)
     *
     * @param {string} boot_loader_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderEditSetAsMain(boot_loader_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/bootloader/mark/bootloader/${boot_loader_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bootloaderGetFileRecord
     * @summary get Bootloader FileRecord
     * @operationId fileRecord_get_bootLoader
     * @tags File, Garfield
     *
     * @param {string} bootloader_id
     *
     * @returns {any} [code 200]
     *
     * @throws {any} [code 303] Automatic Redirect To another URL
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderGetFileRecord(bootloader_id:string):Promise<any> {
        return this.requestRestPath("GET", `/file/bootloader/${bootloader_id}`, {}, [200], 'application/json');
    }

    /**
     * @name bootloaderUploadFile
     * @summary upload Bootloader File
     * @operationId bootLoader_uploadFile
     * @tags Admin-Bootloader
     *
     * @param {string} boot_loader_id
     * @param {IBase64File} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public bootloaderUploadFile(boot_loader_id:string, body:IBase64File):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/bootloader/file/${boot_loader_id}`, body, [200], 'application/json');
    }

    public cprogramVersionUpdateFileManualy(boot_loader_id:string, body:IBase64File):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/bootloader/file/${boot_loader_id}`, body, [200], 'application/json');
    }

    /**
     * @name cProgramCompile
     * @summary compile C_Program
     * @operationId compile_c_program_code
     * @tags C_Program
     *
     * @description Compile code
     *
     * @param {ICProgramVersionUpdate} body - Contains Json with values
     *
     * @returns {ICloudCompilationServerCompilationResult} [code 200] Compilation successful
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {ICompilationBuildError[]} [code 422] Compilation unsuccessful
     * @throws {IResultServerOffline} [code 477] External server is offline
     * @throws {IResultExternalServerSideError} [code 478] External server side Error
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramCompile(body:ICProgramVersionUpdate):Promise<ICloudCompilationServerCompilationResult> {
        return this.requestRestPath("POST", `/c_program/version/compile`, body, [200], 'application/json');
    }

    /**
     * @name cProgramCreate
     * @summary create C_Program
     * @operationId c_program_create
     * @tags C_Program
     *
     * @description If you want create new C_Program in project.id = {project_id}. Send required json values and cloud_compilation_server respond with new object
     *
     * @param {ICProgramNew} body - Contains Json with values
     *
     * @returns {ICProgram} [code 201] Successfully created
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramCreate(body:ICProgramNew):Promise<ICProgram> {
        return this.requestRestPath("POST", `/c_program/create`, body, [201], 'application/json');
    }

    /**
     * @name cProgramDelete
     * @summary delete C_Program
     * @operationId c_program_delete
     * @tags C_Program
     *
     * @description delete C_Program by query = c_program_id, query = version_id
     *
     * @param {string} c_program_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramDelete(c_program_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/c_program/${c_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramEdit
     * @summary edit C_Program
     * @operationId c_program_edit
     * @tags C_Program
     *
     * @description If you want edit base information about C_Program by  query = c_program_id. Send required json values and cloud_compilation_server respond with new object
     *
     * @param {string} c_program_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {ICProgram} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramEdit(c_program_id:string, body:INameAndDescription):Promise<ICProgram> {
        return this.requestRestPath("PUT", `/c_program/${c_program_id}`, body, [200], 'application/json');
    }

    /**
     * @name cProgramGet
     * @summary get C_Program
     * @operationId c_program_get
     * @tags C_Program
     *
     * @description get C_Program by query = c_program_id
     *
     * @param {string} c_program_id
     *
     * @returns {ICProgram} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramGet(c_program_id:string):Promise<ICProgram> {
        return this.requestRestPath("GET", `/c_program/get/${c_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramGetFile
     * @summary get C_Program File
     * @operationId cloud_file_get_c_program_compilation
     * @tags Homer-Server-API
     *
     * @description Required secure Token changed throw websocket
     *
     * @param {string} compilation_id
     *
     * @returns {any} [code 200]
     *
     * @throws {any} [code 303] Automatic Redirect To another URL
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramGetFile(compilation_id:string):Promise<any> {
        return this.requestRestPath("GET", `/cloud/homer_server/file/c_program/${compilation_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramGetListByFilter
     * @summary get C_Program List by Filter
     * @operationId c_program_getByFilter
     * @tags C_Program
     *
     * @description get all C_Programs that belong to logged person
     *
     * @param {ICProgramFilter} body - Contains Json with values
     *
     * @returns {ICProgramList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramGetListByFilter(body:ICProgramFilter):Promise<ICProgramList> {
        return this.requestRestPath("PUT", `/c_programs`, body, [200], 'application/json');
    }

    /**
     * @name cProgramMakeClone
     * @summary make_Clone C_Program
     * @operationId c_program_clone
     * @tags C_Program
     *
     * @description clone C_Program for private
     *
     * @param {ICProgramCopy} body - Contains Json with values
     *
     * @returns {ICProgram} [code 201] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramMakeClone(body:ICProgramCopy):Promise<ICProgram> {
        return this.requestRestPath("PUT", `/c_program/clone`, body, [201], 'application/json');
    }

    /**
     * @name cProgramVersionCreateSaveAs
     * @summary create C_Program_Version SaveAs
     * @operationId c_program_version_create
     * @tags C_Program
     *
     * @description New and database tracked version of C_Program
     *
     * @param {string} c_program_id - version_id String query
     * @param {ICProgramVersionNew} body - Contains Json with values
     *
     * @returns {ICProgramVersion} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionCreateSaveAs(c_program_id:string, body:ICProgramVersionNew):Promise<ICProgramVersion> {
        return this.requestRestPath("POST", `/c_program/version/create/${c_program_id}`, body, [201], 'application/json');
    }

    /**
     * @name cProgramVersionDelete
     * @summary delete C_Program_Version
     * @operationId c_program_version_delete
     * @tags C_Program
     *
     * @description delete Version.id = version_id in C_Program by query = c_program_id, query = version_id
     *
     * @param {string} version_id - version_id String query
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionDelete(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/c_program/version/delete/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramVersionEditInformation
     * @summary edit C_Program_Version information
     * @operationId c_program_version_edit
     * @tags C_Program
     *
     * @description For update basic (name and description) information in Version of C_Program. If you want update code. You have to create new version. And after that you can delete previous version
     *
     * @param {string} version_id - version_id String query
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {ICProgramVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionEditInformation(version_id:string, body:INameAndDescription):Promise<ICProgramVersion> {
        return this.requestRestPath("PUT", `/c_program/version/edit/${version_id}`, body, [200], 'application/json');
    }

    /**
     * @name cProgramVersionEditResponsePublication
     * @summary edit C_Program_Version Response publication
     * @operationId c_program_public_response
     * @tags Admin-C_Program
     *
     * @description sets Approval_state to pending
     *
     * @param {ICommunityVersionPublishResponse} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionEditResponsePublication(body:ICommunityVersionPublishResponse):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/c_program/version_for_aprove/approve_decision`, body, [200], 'application/json');
    }

    /**
     * @name cProgramVersionGet
     * @summary get C_Program_Version
     * @operationId c_program_version_get
     * @tags C_Program
     *
     * @description get Version of C_Program by query = version_id
     *
     * @param {string} version_id - version_id String query
     *
     * @returns {ICProgramVersion} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionGet(version_id:string):Promise<ICProgramVersion> {
        return this.requestRestPath("GET", `/c_program/version/get/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramVersionGetFileRecord
     * @summary get CProgram_Version FileRecord
     * @operationId fileRecord_get_firmware
     * @tags File, Garfield
     *
     * @param {string} version_id
     *
     * @returns {any} [code 200]
     *
     * @throws {any} [code 303] Automatic Redirect To another URL
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionGetFileRecord(version_id:string):Promise<any> {
        return this.requestRestPath("GET", `/file/cprogram_version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramVersionMakePublic
     * @summary make C_Program_Version public
     * @operationId c_program_version_make_public
     * @tags C_Program
     *
     * @description Make C_Program public, so other users can see it and use it. Attention! Attention! Attention! A user can publish only three programs at the stage waiting for approval.
     *
     * @param {string} version_id - version_id String query
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Bad Request
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionMakePublic(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/c_program/version/make_public/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name cProgramVersionWorkingcopysave
     * @summary working_copy_save C_Program_Version
     * @operationId c_program_version_working_copy
     * @tags C_Program
     *
     * @description Just override last version
     *
     * @param {string} c_program_id - version_id String query
     * @param {ICProgramVersionRefresh} body - Contains Json with values
     *
     * @returns {ICProgramVersion} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cProgramVersionWorkingcopysave(c_program_id:string, body:ICProgramVersionRefresh):Promise<ICProgramVersion> {
        return this.requestRestPath("POST", `/c_program/version/working_copy/${c_program_id}`, body, [201], 'application/json');
    }

    /**
     * @name cleanCacheServer
     * @summary server cleanCache
     * @operationId server_clearCache
     * @tags Admin
     *
     * @description Special API only For PRIVATE use
     *
     *
     * @returns {IResultOk} [code 200] Ok result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public cleanCacheServer():Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/clear_cache`, {}, [200], 'application/json');
    }

    /**
     * @name compilationLibraryClean
     * @summary clean CompilationLibrary
     * @operationId compilationLibrary_clean
     * @tags CompilationLibrary
     *
     * @param {ICompilationLibraryClean} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryClean(body:ICompilationLibraryClean):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/compilation_library/clean`, body, [200], 'application/json');
    }

    /**
     * @name compilationLibraryCreate
     * @summary create CompilationLibrary
     * @operationId compilationLibrary_create
     * @tags HardwareType
     *
     * @description Create compilation library
     *
     * @param {ICompilationLibraryCreate} body - Contains Json with values
     *
     * @returns {ICompilationLibrary} [code 201] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryCreate(body:ICompilationLibraryCreate):Promise<ICompilationLibrary> {
        return this.requestRestPath("POST", `/compilation_library`, body, [201], 'application/json');
    }

    /**
     * @name compilationLibraryDelete
     * @summary delete CompilationLibrary
     * @operationId compilationLibrary_delete
     * @tags CompilationLibrary
     *
     * @param {string} library_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryDelete(library_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/compilation_library/${library_id}`, {}, [200], 'application/json');
    }

    /**
     * @name compilationLibraryEdit
     * @summary edit CompilationLibrary
     * @operationId compilationLibrary_update
     * @tags CompilationLibrary
     *
     * @description Edit Compilation Library
     *
     * @param {string} library_id
     * @param {ICompilationLibraryCreate} body - Contains Json with values
     *
     * @returns {ICompilationLibrary} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryEdit(library_id:string, body:ICompilationLibraryCreate):Promise<ICompilationLibrary> {
        return this.requestRestPath("PUT", `/compilation_library/${library_id}`, body, [200], 'application/json');
    }

    /**
     * @name compilationLibraryGetListByFilter
     * @summary get CompilationLibrary List by Filter
     * @operationId compilationLibrary_getByFilter
     * @tags CompilationLibrary
     *
     * @param {ICompilationLibraryFilter} body - Contains Json with values
     *
     * @returns {ICompilationLibraryList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryGetListByFilter(body:ICompilationLibraryFilter):Promise<ICompilationLibraryList> {
        return this.requestRestPath("PUT", `/compilation_librarys`, body, [200], 'application/json');
    }

    /**
     * @name compilationLibraryUploadFile
     * @summary upload CompilationLibrary File
     * @operationId compilationLibrary_uploadFile
     * @tags CompilationLibrary
     *
     * @param {string} library_id
     * @param {any} file - Zip file as a part of multipart/form-data
     *
     * @returns {ICompilationLibrary} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationLibraryUploadFile(library_id:string, file:string|Blob):Promise<ICompilationLibrary> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.requestRestPath("PUT", `/compilation_library/file/${library_id}`, formData, [200], 'multipart/form-data');
    }

    /**
     * @name compilationServerCreate
     * @summary create Compilation_Server
     * @operationId compilation_server_create
     * @tags Admin-External-Server
     *
     * @description Create new Gate for Compilation_Server
     *
     * @param {ICompilationServerNew} body - Contains Json with values
     *
     * @returns {ICompilationServer} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationServerCreate(body:ICompilationServerNew):Promise<ICompilationServer> {
        return this.requestRestPath("POST", `/compilation_server`, body, [201], 'application/json');
    }

    /**
     * @name compilationServerEdit
     * @summary edit Compilation_Server
     * @operationId compilation_server_edit
     * @tags Admin-External-Server
     *
     * @description Edit basic information Compilation_Server
     *
     * @param {string} server_id
     * @param {ICompilationServerNew} body - Contains Json with values
     *
     * @returns {ICompilationServer} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationServerEdit(server_id:string, body:ICompilationServerNew):Promise<ICompilationServer> {
        return this.requestRestPath("PUT", `/compilation_server/${server_id}`, body, [200], 'application/json');
    }

    /**
     * @name compilationServerGet
     * @summary get Compilation_Server
     * @operationId compilation_server_get
     * @tags Admin-External-Server
     *
     * @description get Compilation_Servers
     *
     * @param {string} server_id
     *
     * @returns {ICompilationServer} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationServerGet(server_id:string):Promise<ICompilationServer> {
        return this.requestRestPath("GET", `/compilation_server/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name compilationServersDelete
     * @summary delete Compilation_Servers
     * @operationId compilation_server_delete
     * @tags Admin-External-Server
     *
     * @description remove Compilation_Servers
     *
     * @param {string} server_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationServersDelete(server_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/compilation_server/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name compilationServersGetList
     * @summary get Compilation_Servers List
     * @operationId compilation_server_get_all
     * @tags Admin-External-Server
     *
     * @description get Compilation_Servers
     *
     *
     * @returns {ICompilationServer[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public compilationServersGetList():Promise<ICompilationServer[]> {
        return this.requestRestPath("GET", `/compilation_server`, {}, [200], 'application/json');
    }

    /**
     * @name connectiontokenRemoveAll
     * @summary remove  Connection_token All
     * @operationId person_removeAllConnections
     * @tags Admin-Person
     *
     * @description remove all connection tokens
     *
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public connectiontokenRemoveAll(person_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/person/connections/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name contactCreateCustomer
     * @summary create contact customer
     * @operationId contact_customer_create
     * @tags Price & Invoice & Tariffs
     *
     * @description create contact in Product
     *
     * @param {string} customer_id
     * @param {IContactUpdate} body - Contains Json with values
     *
     * @returns {IContact} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public contactCreateCustomer(customer_id:string, body:IContactUpdate):Promise<IContact> {
        return this.requestRestPath("POST", `/customer/contact/${customer_id}`, body, [201], 'application/json');
    }

    /**
     * @name contactEditDetails
     * @summary edit Contact details
     * @operationId contact_update
     * @tags Price & Invoice & Tariffs
     *
     * @description edit contact
     *
     * @param {string} contact_id
     * @param {IContactUpdate} body - Contains Json with values
     *
     * @returns {IContact} [code 200] Successfully updated
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public contactEditDetails(contact_id:string, body:IContactUpdate):Promise<IContact> {
        return this.requestRestPath("PUT", `/contact/${contact_id}`, body, [200], 'application/json');
    }

    /**
     * @name creditBuyForGivenProduct
     * @summary buy Credit for given product
     * @operationId product_credit
     * @tags Price & Invoice & Tariffs
     *
     * @description creates invoice - credit will be added after payment if payment method is bank transfer or if getting money from credit card is successful
     *
     * @param {string} product_id
     * @param {IProductCredit} body - Contains Json with values
     *
     * @returns {IInvoice} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public creditBuyForGivenProduct(product_id:string, body:IProductCredit):Promise<IInvoice> {
        return this.requestRestPath("PUT", `/product/credit/${product_id}`, body, [200], 'application/json');
    }

    /**
     * @name customerCreate
     * @summary Create Customer
     * @operationId create_customer
     * @tags Price & Invoice & Tariffs
     *
     * @description Creates new Customer
     *
     * @param {IContactUpdate} body - Contains Json with values
     *
     * @returns {ICustomer} [code 201] Created successfully
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public customerCreate(body:IContactUpdate):Promise<ICustomer> {
        return this.requestRestPath("POST", `/customer`, body, [201], 'application/json');
    }

    /**
     * @name customersGetAll
     * @summary get Customers All
     * @operationId customer_get_all
     * @tags Price & Invoice & Tariffs
     *
     * @description Gets all customers by logged user.
     *
     *
     * @returns {ICustomer[]} [code 200] Created successfully
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public customersGetAll():Promise<ICustomer[]> {
        return this.requestRestPath("GET", `/customer`, {}, [200], 'application/json');
    }

    /**
     * @name databaseCollectionCreate
     * @summary create Database_Collection
     * @operationId create_collection
     * @tags Database
     *
     * @description Create collection in database
     *
     * @param {string} db_id
     * @param {string} collection_name
     *
     * @returns {IProductExtension} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databaseCollectionCreate(db_id:string, collection_name:string):Promise<IProductExtension> {
        return this.requestRestPath("PUT", `/database/${db_id}/${collection_name}`, {}, [200], 'application/json');
    }

    /**
     * @name databaseCollectionGet
     * @summary get Database_Collection
     * @operationId get_collections
     * @tags Database
     *
     * @description List all collections by database id
     *
     * @param {string} db_id
     *
     * @returns {IDatabase} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databaseCollectionGet(db_id:string):Promise<IDatabase> {
        return this.requestRestPath("GET", `/database/${db_id}`, {}, [200], 'application/json');
    }

    /**
     * @name databaseCreate
     * @summary create Database
     * @operationId create_db
     * @tags Database
     *
     * @description Create database with collection
     *
     * @param {ISwaggerDatabaseNew} body - Contains Json with values
     *
     * @returns {IDatabase} [code 201] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databaseCreate(body:ISwaggerDatabaseNew):Promise<IDatabase> {
        return this.requestRestPath("POST", `/database`, body, [201], 'application/json');
    }

    /**
     * @name databaseDelete
     * @summary delete Database
     * @operationId drop_db
     * @tags Database
     *
     * @description delete Database by id
     *
     * @param {string} db_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databaseDelete(db_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/database/${db_id}`, {}, [200], 'application/json');
    }

    /**
     * @name databaseEdit
     * @summary edit Database
     * @operationId edit_db
     * @tags Database
     *
     * @description Edit database
     *
     * @param {string} db_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databaseEdit(db_id:string, body:INameAndDescription):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/database/${db_id}`, body, [200], 'application/json');
    }

    /**
     * @name databasesGet
     * @summary get Databases
     * @operationId list_db
     * @tags Database
     *
     * @description List all databases by product_id
     *
     * @param {string} product_id
     *
     * @returns {IDatabase[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public databasesGet(product_id:string):Promise<IDatabase[]> {
        return this.requestRestPath("GET", `/database/all/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name emailAuthenticateFromRegistration
     * @summary authenticate Email from registration
     * @operationId person_emailAuthentication
     * @tags Admin-Person
     *
     * @description sends authentication email, if user did not get the first one from the registration
     *
     * @param {string} token
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public emailAuthenticateFromRegistration(token:string):Promise<IResultOk> {
        return this.requestRestPath("GET", `/person/mail_authentication/${token}`, {}, [200], 'application/json');
    }

    /**
     * @name emailSendAuthentication
     * @summary send Email authentication
     * @operationId person_authenticationSendEmail
     * @tags Person
     *
     * @description sends authentication email, if user did not get the first one from the registration
     *
     * @param {IEmailRequired} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public emailSendAuthentication(body:IEmailRequired):Promise<IResultOk> {
        return this.requestRestPath("POST", `/person/authentication_email`, body, [200], 'application/json');
    }

    /**
     * @name emailSendPasswordRecoveryEmail
     * @summary send Email password recovery email
     * @operationId person_passwordRecoverySendEmail
     * @tags Access
     *
     * @description sends email with link for changing forgotten password
     *
     * @param {IEmailRequired} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public emailSendPasswordRecoveryEmail(body:IEmailRequired):Promise<IResultOk> {
        return this.requestRestPath("POST", `/person/password/recovery_mail`, body, [200], 'application/json');
    }

    /**
     * @name employeeAdd
     * @summary add Employee
     * @operationId customer_add_employee
     * @tags Price & Invoice & Tariffs
     *
     * @description Adds employee to a company. Add them again for send new invitations
     *
     * @param {ICustomerEmployee} body - Contains Json with values
     *
     * @returns {ICustomer} [code 200] Updated successfully
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public employeeAdd(body:ICustomerEmployee):Promise<ICustomer> {
        return this.requestRestPath("POST", `/employee`, body, [200], 'application/json');
    }

    /**
     * @name employeeRemove
     * @summary remove Employee
     * @operationId customer_remove_employee
     * @tags Price & Invoice & Tariffs
     *
     * @description Removes employee from a company.
     *
     * @param {string} employee_id
     *
     * @returns {IResultOk} [code 200] Removed successfully
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public employeeRemove(employee_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/employee/${employee_id}`, {}, [200], 'application/json');
    }

    /**
     * @name entityValidation
     * @summary validation Entity
     * @operationId something_validateProperty
     * @tags Person
     *
     * @description for cyclical validation during registration, key contains 'email' or 'nick_name'. Or can be used for 'vat_number' as a key.
     *
     * @param {IEntityValidationIn} body - Contains Json with values
     *
     * @returns {IEntityValidationOut} [code 200] Result if it is possible to use
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public entityValidation(body:IEntityValidationIn):Promise<IEntityValidationOut> {
        return this.requestRestPath("POST", `/validate_entity`, body, [200], 'application/json');
    }

    /**
     * @name eventsProduct
     * @summary product events
     * @operationId product_getEvents
     * @tags Product
     *
     * @description get product history events
     *
     * @param {string} product_id
     * @param {number} page
     * @param {number} items
     *
     * @returns {IProductEvent[]} [code 200] Successfully updated
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public eventsProduct(product_id:string, page:number, items:number):Promise<IProductEvent[]> {
        return this.requestRestPath("GET", `/product/events/${product_id}/${page}/${items}`, {}, [200], 'application/json');
    }

    /**
     * @name fBGetLoginURL
     * @summary Get FB login URL
     * @operationId login_url
     * @tags Facebook
     *
     * @description Facebook Login with OAuth Authentication, this is a first API point for get URL for redirection, you have to set URL the backend server at the end of process redirects your person.
     *
     * @param {IFacebookLoginRedirect} body - Contains Json with values
     *
     * @returns {IFacebookLogin} [code 200] Ok Result
     *
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public fBGetLoginURL(body:IFacebookLoginRedirect):Promise<IFacebookLogin> {
        return this.requestRestPath("PUT", `/facebook/login/url`, body, [200], 'application/json');
    }

    /**
     * @name fBGetLoginURLForPerson
     * @summary Get FB login URL for person
     * @operationId login_url_person
     * @tags Facebook
     *
     * @description Facebook Login with OAuth Authentication for current person relation
     *
     * @param {IFacebookLoginRedirect} body - Contains Json with values
     *
     * @returns {IFacebookLogin} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public fBGetLoginURLForPerson(body:IFacebookLoginRedirect):Promise<IFacebookLogin> {
        return this.requestRestPath("PUT", `/facebook/login/url-user`, body, [200], 'application/json');
    }

    /**
     * @name gridProgramClone
     * @summary clone GridProgram
     * @operationId gridProgram_clone
     * @tags Grid
     *
     * @description clone GridProgram for private
     *
     * @param {IGridProgramCopy} body - Contains Json with values
     *
     * @returns {IGridProgram} [code 201] Successfully created
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramClone(body:IGridProgramCopy):Promise<IGridProgram> {
        return this.requestRestPath("PUT", `/grid_program/clone`, body, [201], 'application/json');
    }

    /**
     * @name gridProgramCreate
     * @summary create GridProgram
     * @operationId gridProgram_create
     * @tags Grid
     *
     * @description creating new GridProgram
     *
     * @param {string} grid_project_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IGridProgram} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramCreate(grid_project_id:string, body:INameAndDescription):Promise<IGridProgram> {
        return this.requestRestPath("POST", `/grid_program/${grid_project_id}`, body, [201], 'application/json');
    }

    /**
     * @name gridProgramDelete
     * @summary delete GridProgram
     * @operationId gridProgram_delete
     * @tags Grid
     *
     * @description remove GridProgram by query = grid_program_id
     *
     * @param {string} grid_program_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramDelete(grid_program_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/grid_program/${grid_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProgramGet
     * @summary get GridProgram
     * @operationId gridProgram_get
     * @tags Grid
     *
     * @description get GridProgram by query grid_program_id
     *
     * @param {string} grid_program_id
     *
     * @returns {IGridProgram} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramGet(grid_program_id:string):Promise<IGridProgram> {
        return this.requestRestPath("GET", `/grid_program/${grid_program_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProgramGetByToken
     * @summary getByToken GridProgram
     * @operationId gridProgram_getByQRToken
     * @tags APP-Api
     *
     * @description get Grid_Program by token
     *
     * @param {string} instance_id
     * @param {string} program_version_id
     *
     * @returns {ITerminalConnectionSummary} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Bad Request - Probably token is not valid anymore
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultServerOffline} [code 477] External Server is offline
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramGetByToken(instance_id:string, program_version_id:string):Promise<ITerminalConnectionSummary> {
        return this.requestRestPath("GET", `/grid_program/app/token/${instance_id}/${program_version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProgramUpdate
     * @summary update GridProgram
     * @operationId gridProgram_update
     * @tags Grid
     *
     * @description update GridProgram - in this case we are not support versions of grid_project
     *
     * @param {string} grid_program_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IGridProgram} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramUpdate(grid_program_id:string, body:INameAndDescription):Promise<IGridProgram> {
        return this.requestRestPath("PUT", `/grid_program/${grid_program_id}`, body, [200], 'application/json');
    }

    /**
     * @name gridProgramVersionCreate
     * @summary Create GridProgramVersion
     * @operationId gridProgram_version_create
     * @tags Grid
     *
     * @description creating new GridProgramVersion
     *
     * @param {string} grid_program_id
     * @param {IMProgramVersionNew} body - Contains Json with values
     *
     * @returns {IGridProgramVersion} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramVersionCreate(grid_program_id:string, body:IMProgramVersionNew):Promise<IGridProgramVersion> {
        return this.requestRestPath("POST", `/grid_program/version/${grid_program_id}`, body, [201], 'application/json');
    }

    /**
     * @name gridProgramVersionDelete
     * @summary delete GridProgramVersion
     * @operationId gridProgram_version_delete
     * @tags Grid
     *
     * @description remove GridProgramVersion
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramVersionDelete(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/grid_program/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProgramVersionEdit
     * @summary edit GridProgramVersion
     * @operationId gridProgram_version_update
     * @tags Grid
     *
     * @description edit GridProgramVersion by query = version_id
     *
     * @param {string} version_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IGridProgramVersion} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramVersionEdit(version_id:string, body:INameAndDescription):Promise<IGridProgramVersion> {
        return this.requestRestPath("PUT", `/grid_program/version/${version_id}`, body, [200], 'application/json');
    }

    /**
     * @name gridProgramVersionGet
     * @summary get GridProgramVersion
     * @operationId gridProgram_version_get
     * @tags Grid
     *
     * @description get GridProgramVersion by query version_id
     *
     * @param {string} version_id
     *
     * @returns {IGridProgramVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProgramVersionGet(version_id:string):Promise<IGridProgramVersion> {
        return this.requestRestPath("GET", `/grid_program/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProjectCreate
     * @summary Create GridProject
     * @operationId gridProject_create
     * @tags Grid
     *
     * @description GridProject is package for GridPrograms -> presupposition is that you need more control terminal for your IoT project. Different screens for family members, for employee etc.. But of course - you can used that for only one GridProgram
     *
     * @param {string} project_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IGridProject} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Some Json value Missing
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectCreate(project_id:string, body:INameAndDescription):Promise<IGridProject> {
        return this.requestRestPath("POST", `/grid_project/${project_id}`, body, [201], 'application/json');
    }

    /**
     * @name gridProjectDelete
     * @summary delete GridProject
     * @operationId gridProject_delete
     * @tags Grid
     *
     * @description remove M_Project by query = grid_project_id
     *
     * @param {string} grid_project_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectDelete(grid_project_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/grid_project/${grid_project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProjectEdit
     * @summary edit GridProject
     * @operationId gridProject_update
     * @tags Grid
     *
     * @description edit basic information in M_Project by query = grid_project_id
     *
     * @param {string} grid_project_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IGridProject} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectEdit(grid_project_id:string, body:INameAndDescription):Promise<IGridProject> {
        return this.requestRestPath("PUT", `/grid_project/${grid_project_id}`, body, [200], 'application/json');
    }

    /**
     * @name gridProjectGet
     * @summary get GridProject
     * @operationId gridProject_get
     * @tags Grid
     *
     * @description get GridProject by query = grid_project_id
     *
     * @param {string} grid_project_id
     *
     * @returns {IGridProject} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectGet(grid_project_id:string):Promise<IGridProject> {
        return this.requestRestPath("GET", `/grid_project/${grid_project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name gridProjectGetByFilter
     * @summary get GridProject by Filter
     * @operationId gridProject_getByFilter
     * @tags Grid
     *
     * @description get GridProject by filter parameters
     *
     * @param {IGridProjectFilter} body - Contains Json with values
     *
     * @returns {IGridProjectList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectGetByFilter(body:IGridProjectFilter):Promise<IGridProjectList> {
        return this.requestRestPath("PUT", `/grid_projects`, body, [200], 'application/json');
    }

    /**
     * @name gridProjectGetInterface
     * @summary getInterface GridProject
     * @operationId gridProject_getInterface
     * @tags Grid
     *
     * @description get accessible interface from M_Project
     *
     * @param {string} grid_project_id
     *
     * @returns {IMProjectInterface} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public gridProjectGetInterface(grid_project_id:string):Promise<IMProjectInterface> {
        return this.requestRestPath("GET", `/grid_project/interface/${grid_project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareBatchCreate
     * @summary create HardwareBatch
     * @operationId hardwareBatch_create
     * @tags HardwareType
     *
     * @description Create new Production Batch for Hardware Type
     *
     * @param {string} hardware_type_id
     * @param {IHardwareBatchNew} body - Contains Json with values
     *
     * @returns {IHardwareBatch} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareBatchCreate(hardware_type_id:string, body:IHardwareBatchNew):Promise<IHardwareBatch> {
        return this.requestRestPath("POST", `/hardware_batch/${hardware_type_id}`, body, [201], 'application/json');
    }

    /**
     * @name hardwareBatchDelete
     * @summary delete HardwareBatch
     * @operationId hardwareBatch_delete
     * @tags HardwareType
     *
     * @description if you want delete Hardware Batch object by query = hardware_type_id
     *
     * @param {string} batch_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareBatchDelete(batch_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/hardware_batch/${batch_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareBatchEdit
     * @summary edit HardwareBatch
     * @operationId hardwareBatch_edit
     * @tags HardwareType
     *
     * @description Create new Production Batch for Type Of Board
     *
     * @param {string} batch_id
     * @param {IHardwareBatchNew} body - Contains Json with values
     *
     * @returns {IHardwareBatch} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareBatchEdit(batch_id:string, body:IHardwareBatchNew):Promise<IHardwareBatch> {
        return this.requestRestPath("PUT", `/hardware_batch/${batch_id}`, body, [200], 'application/json');
    }

    /**
     * @name hardwareBatchGetConfigDefaults
     * @summary get HardwareBatch config defaults
     * @operationId hardwareBatch_getConfigDefaults
     * @tags HardwareType
     *
     *
     * @returns {IHardwareBatchConfig} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareBatchGetConfigDefaults():Promise<IHardwareBatchConfig> {
        return this.requestRestPath("GET", `/hardware_batch/defaults`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareBatchSetConfig
     * @summary set HardwareBatch config
     * @operationId hardwareBatch_setConfiguration
     * @tags HardwareType
     *
     * @param {string} batch_id
     * @param {IHardwareBatchConfigSet} body - Contains Json with values
     *
     * @returns {IHardwareBatch} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareBatchSetConfig(batch_id:string, body:IHardwareBatchConfigSet):Promise<IHardwareBatch> {
        return this.requestRestPath("PUT", `/hardware_batch/config/${batch_id}`, body, [200], 'application/json');
    }

    /**
     * @name hardwareCreateManual
     * @summary create Hardware manual
     * @operationId hardware_create_manual
     * @tags Admin-Board
     *
     * @description This Api is using only for developing mode, for registration of our Board - in future it will be used only by machine in factory or hardware themselves with "registration procedure". Hardware is not allowed to delete! Only deactivate. Classic User can only register that to own project or own to account
     *
     * @param {IBoardNewManual} body - Contains Json with values
     *
     * @returns {IHardware} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareCreateManual(body:IBoardNewManual):Promise<IHardware> {
        return this.requestRestPath("POST", `/hardware/manual_registration`, body, [201], 'application/json');
    }

    /**
     * @name hardwareGetHashAdminOnly
     * @summary get Hardware hash admin only
     * @operationId hardware_get_registration_hash
     * @tags Admin-Board
     *
     * @description This Api is using only with special Admin Permission
     *
     * @param {string} full_id
     *
     * @returns {IHardwareRegistrationHash} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGetHashAdminOnly(full_id:string):Promise<IHardwareRegistrationHash> {
        return this.requestRestPath("GET", `/hardware/hash/${full_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareGetStates
     * @summary get hardware states
     * @operationId hardware_getStates
     * @tags Hardware
     *
     * @description Online hardware states by filter
     *
     * @param {IHardwareOnlineStatesFilter} body - Contains Json with values
     *
     * @returns {IHardwareNetworkStatesList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGetStates(body:IHardwareOnlineStatesFilter):Promise<IHardwareNetworkStatesList> {
        return this.requestRestPath("PUT", `/hardware/online_states`, body, [200], 'application/json');
    }

    /**
     * @name hardwareGroupCreate
     * @summary create HardwareGroup
     * @operationId hardwareGroup_create
     * @tags HardwareGroup
     *
     * @description Create HardwareGroup
     *
     * @param {INameAndDescProjectIdRequired} body - Contains Json with values
     *
     * @returns {IHardwareGroup} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGroupCreate(body:INameAndDescProjectIdRequired):Promise<IHardwareGroup> {
        return this.requestRestPath("POST", `/hardware_group`, body, [201], 'application/json');
    }

    /**
     * @name hardwareGroupDelete
     * @summary delete HardwareGroup
     * @operationId hardwareGroup_delete
     * @tags HardwareGroup
     *
     * @description delete BoardGroup
     *
     * @param {string} group_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGroupDelete(group_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/hardware_group/${group_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareGroupEdit
     * @summary edit HardwareGroup
     * @operationId hardwareGroup_update
     * @tags HardwareGroup
     *
     * @description update HardwareGroup
     *
     * @param {string} group_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IHardwareGroup} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGroupEdit(group_id:string, body:INameAndDescription):Promise<IHardwareGroup> {
        return this.requestRestPath("PUT", `/hardware_group/${group_id}`, body, [200], 'application/json');
    }

    /**
     * @name hardwareGroupGet
     * @summary get HardwareGroup
     * @operationId hardwareGroup_get
     * @tags HardwareGroup
     *
     * @description get List of BoardGroup from Project
     *
     * @param {string} group_id
     *
     * @returns {IHardwareGroup} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGroupGet(group_id:string):Promise<IHardwareGroup> {
        return this.requestRestPath("GET", `/hardware_group/individual/${group_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareGroupGetListByFilter
     * @summary get HardwareGroup List by Filter
     * @operationId hardwareGroup_get_filter
     * @tags HardwareGroup
     *
     * @description get List of BoardGroup from Project
     *
     * @param {IHardwareGroupFilter} body - Contains Json with values
     *
     * @returns {IHardwareGroupList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareGroupGetListByFilter(body:IHardwareGroupFilter):Promise<IHardwareGroupList> {
        return this.requestRestPath("PUT", `/hardware_groups`, body, [200], 'application/json');
    }

    /**
     * @name hardwareReleaseUpdateCancel
     * @summary cancel HardwareReleaseUpdate
     * @operationId cancel_hardwareUpdateRelease
     * @tags Actualization
     *
     * @description get Actualization task by ID
     *
     * @param {string} id
     *
     * @returns {IHardwareReleaseUpdate} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareReleaseUpdateCancel(id:string):Promise<IHardwareReleaseUpdate> {
        return this.requestRestPath("PUT", `/release_update/stop/${id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareReleaseUpdateGet
     * @summary get HardwareReleaseUpdate
     * @operationId get_hardwareUpdateRelease
     * @tags Actualization
     *
     * @description get Actualization task by ID
     *
     * @param {string} id
     *
     * @returns {IHardwareReleaseUpdate} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareReleaseUpdateGet(id:string):Promise<IHardwareReleaseUpdate> {
        return this.requestRestPath("GET", `/release_update/object/${id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareReleaseUpdateGetByFilter
     * @summary get HardwareReleaseUpdate by Filter
     * @operationId get_HardwareReleaseUpdate_by_filter
     * @tags HardwareUpdate
     *
     * @description get release Update by query
     *
     * @param {IHardwareReleaseUpdateFilter} body - Contains Json with values
     *
     * @returns {IHardwareReleaseUpdateList} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareReleaseUpdateGetByFilter(body:IHardwareReleaseUpdateFilter):Promise<IHardwareReleaseUpdateList> {
        return this.requestRestPath("PUT", `/release_updates`, body, [200], 'application/json');
    }

    /**
     * @name hardwareReleaseUpdateMake
     * @summary make HardwareReleaseUpdate
     * @operationId make_hardwareUpdateRelease
     * @tags HardwareUpdate
     *
     * @description make procedure
     *
     * @param {IHardwareUpdateMakeRelease} body - Contains Json with values
     *
     * @returns {IHardwareReleaseUpdate} [code 201] Ok Created
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareReleaseUpdateMake(body:IHardwareUpdateMakeRelease):Promise<IHardwareReleaseUpdate> {
        return this.requestRestPath("PUT", `/release_update/make`, body, [201], 'application/json');
    }

    /**
     * @name hardwareTypeCreate
     * @summary create HardwareType
     * @operationId hardwareType_create
     * @tags HardwareType
     *
     * @description The HardwareType is category for IoT. Like Raspberry2, Arduino-Uno etc.
     *
     *    We using that for compilation, sorting libraries, filters and more..
     *
     * @param {IHardwareTypeNew} body - Contains Json with values
     *
     * @returns {IHardwareType} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeCreate(body:IHardwareTypeNew):Promise<IHardwareType> {
        return this.requestRestPath("POST", `/hardware_type`, body, [201], 'application/json');
    }

    /**
     * @name hardwareTypeDelete
     * @summary delete HardwareType
     * @operationId hardwareType_delete
     * @tags HardwareType
     *
     * @description if you want delete HardwareType object by query = hardware_type_id
     *
     * @param {string} hardware_type_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeDelete(hardware_type_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/hardware_type/${hardware_type_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareTypeEdit
     * @summary edit HardwareType
     * @operationId hardwareType_update
     * @tags HardwareType
     *
     * @description if you want edit base HardwareType information
     *
     * @param {string} hardware_type_id
     * @param {IHardwareTypeNew} body - Contains Json with values
     *
     * @returns {IHardwareType} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeEdit(hardware_type_id:string, body:IHardwareTypeNew):Promise<IHardwareType> {
        return this.requestRestPath("PUT", `/hardware_type/${hardware_type_id}`, body, [200], 'application/json');
    }

    /**
     * @name hardwareTypeGet
     * @summary get HardwareType
     * @operationId hardwareType_get
     * @tags HardwareType
     *
     * @description if you want get HardwareType object by query = hardware_type_id
     *
     * @param {string} hardware_type_id
     *
     * @returns {IHardwareType} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeGet(hardware_type_id:string):Promise<IHardwareType> {
        return this.requestRestPath("GET", `/hardware_type/${hardware_type_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareTypeSetcprogramversion_as_main
     * @summary set_c_program_version_as_main HardwareType
     * @operationId c_program_markScheme
     * @tags Admin-C_Program, HardwareType
     *
     * @description set C_Program version as Main for This Type of Device. Version must be from Main or Test C Program of this version
     *
     * @param {string} version_id - version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeSetcprogramversion_as_main(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/c_program/default/mark/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareTypeUploadPicture
     * @summary upload HardwareType picture
     * @operationId hardwareType_uploadPicture
     * @tags HardwareType
     *
     * @description Upload HardwareType picture
     *
     * @param {string} hardware_type_id
     * @param {IBase64File} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypeUploadPicture(hardware_type_id:string, body:IBase64File):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware_type/picture/${hardware_type_id}`, body, [200], 'application/json');
    }

    /**
     * @name hardwareTypesGetAll
     * @summary get HardwareTypes All
     * @operationId hardwareType_getAll
     * @tags HardwareType
     *
     * @description if you want get all HardwareType objects
     *
     *
     * @returns {IHardwareType[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareTypesGetAll():Promise<IHardwareType[]> {
        return this.requestRestPath("GET", `/hardware_type/all`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareUpdate
     * @summary update Hardware
     * @operationId hardware_updateFirmware
     * @tags HardwareUpdate
     *
     * @description Upload Bin File hardware or Selected Version to Hardware. Result (HTML code) will be every time 200. - Its because upload, restart, etc.. operation need more than ++30 second There is also problem / chance that Tyrion didn't find where Embedded hardware is. So you have to listening Server Sent Events (SSE) and show "future" message to the user!
     *
     * @param {IHardwareUpdateMakeSingleUpdate} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdate(body:IHardwareUpdateMakeSingleUpdate):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/hardware/update/firmware`, body, [200], 'application/json');
    }

    /**
     * @name hardwareUpdateBootloader
     * @summary update Hardware Bootloader
     * @operationId bootLoader_manualUpdate
     * @tags Bootloader
     *
     * @description List of Hardware Id for update on latest bootloader version (system used latest bootloader for type of hardware)
     *
     * @param {IBoardBootloaderUpdate} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdateBootloader(body:IBoardBootloaderUpdate):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/bootloader/update_device_list`, body, [200], 'application/json');
    }

    /**
     * @name hardwareUpdateCancel
     * @summary cancel HardwareUpdate
     * @operationId cancel_HardwareUpdate_CProgramUpdatePlan
     * @tags Actualization
     *
     * @description get Actualization task by ID
     *
     * @param {string} id
     *
     * @returns {IHardwareUpdate} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdateCancel(id:string):Promise<IHardwareUpdate> {
        return this.requestRestPath("PUT", `/update_task/stop/${id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareUpdateGet
     * @summary get HardwareUpdate
     * @operationId get_HardwareUpdate_CProgramUpdatePlan
     * @tags Actualization
     *
     * @description get Actualization task by ID
     *
     * @param {string} id
     *
     * @returns {IHardwareUpdate} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdateGet(id:string):Promise<IHardwareUpdate> {
        return this.requestRestPath("GET", `/update_task/object/${id}`, {}, [200], 'application/json');
    }

    /**
     * @name hardwareUpdateGetByFilter
     * @summary get HardwareUpdate by Filter
     * @operationId get_HardwareUpdate_CProgramUpdatePlan_by_filter
     * @tags HardwareUpdate
     *
     * @description get actualization Tasks by query
     *
     * @param {IHardwareUpdatesFilter} body - Contains Json with values
     *
     * @returns {IHardwareUpdateList} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdateGetByFilter(body:IHardwareUpdatesFilter):Promise<IHardwareUpdateList> {
        return this.requestRestPath("PUT", `/update_tasks`, body, [200], 'application/json');
    }

    /**
     * @name hardwareUpdateProgressGet
     * @summary get HardwareUpdateProgress
     * @operationId get_HardwareUpdateProgress
     * @tags Actualization
     *
     * @description get Actualization progress logs from Homer by Filer. Attention - trackingId can be same for group of updates. So - you have to specify also UUID of Hardware
     *
     * @param {IHardwareUpdateProgressFilter} body - Contains Json with values
     *
     * @returns {IHardwareUpdateEvent[]} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUpdateProgressGet(body:IHardwareUpdateProgressFilter):Promise<IHardwareUpdateEvent[]> {
        return this.requestRestPath("PUT", `/update_task/progress`, body, [200], 'application/json');
    }

    /**
     * @name hardwareUploadPicture
     * @summary uploadPicture Hardware
     * @operationId hardware_uploadPicture
     * @tags Hardware
     *
     * @description Upload Board file
     *
     * @param {string} hardware_id
     * @param {IBase64File} body - Contains Json with values
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public hardwareUploadPicture(hardware_id:string, body:IBase64File):Promise<IHardware> {
        return this.requestRestPath("PUT", `/hardware/picture/upload/${hardware_id}`, body, [200], 'application/json');
    }

    /**
     * @name homerServerCreateAutomatically
     * @summary create Homer_Server Automatically
     * @operationId homer_server_create_automatically
     * @tags External-Server
     *
     * @description Create new Homer_Server - private or public
     *
     * @param {ICloudHomerServerNewAuto} body - Contains Json with values
     *
     * @returns {IHomerServer} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerCreateAutomatically(body:ICloudHomerServerNewAuto):Promise<IHomerServer> {
        return this.requestRestPath("POST", `/homer_server_automaticaly`, body, [201], 'application/json');
    }

    /**
     * @name homerServerCreateManually
     * @summary create Homer_Server Manually
     * @operationId homer_server_create_manually
     * @tags External-Server
     *
     * @description Create new Homer_Server - private or public
     *
     * @param {ICloudHomerServerNewManually} body - Contains Json with values
     *
     * @returns {IHomerServer} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerCreateManually(body:ICloudHomerServerNewManually):Promise<IHomerServer> {
        return this.requestRestPath("POST", `/homer_server_manualy`, body, [201], 'application/json');
    }

    /**
     * @name homerServerDelete
     * @summary delete Homer_Server
     * @operationId homer_server_delete
     * @tags External-Server
     *
     * @description remove Compilation_Servers
     *
     * @param {string} server_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerDelete(server_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/homer_server/server/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerEdit
     * @summary edit Homer_Server
     * @operationId homer_server_edit
     * @tags External-Server
     *
     * @description Edit basic information Compilation_Server
     *
     * @param {string} server_id
     * @param {ICloudHomerServerNewManually} body - Contains Json with values
     *
     * @returns {IHomerServer} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerEdit(server_id:string, body:ICloudHomerServerNewManually):Promise<IHomerServer> {
        return this.requestRestPath("PUT", `/homer_server/server/edit/${server_id}`, body, [200], 'application/json');
    }

    /**
     * @name homerServerEditSetBackupServer
     * @summary edit Homer_Server Set Backup Server
     * @operationId homer_server_set_backup_server
     * @tags Admin-External-Server
     *
     * @description Edit basic information Compilation_Server
     *
     * @param {string} server_id
     *
     * @returns {IHomerServer} [code 200] Updated successfully
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerEditSetBackupServer(server_id:string):Promise<IHomerServer> {
        return this.requestRestPath("PUT", `/homer_server/set_backup/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerEditSetMainServer
     * @summary edit Homer_Server Set Main Server
     * @operationId homer_server_set_main_server
     * @tags Admin-External-Server
     *
     * @description Edit basic information Compilation_Server
     *
     * @param {string} server_id
     *
     * @returns {IHomerServer} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerEditSetMainServer(server_id:string):Promise<IHomerServer> {
        return this.requestRestPath("PUT", `/homer_server/set_main/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerGet
     * @summary get Homer_Server
     * @operationId homer_server_get
     * @tags Admin-External-Server
     *
     * @description get all Homer Servers
     *
     * @param {string} server_id
     *
     * @returns {IHomerServer} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerGet(server_id:string):Promise<IHomerServer> {
        return this.requestRestPath("GET", `/homer_server/server/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerGetAvailableVersions
     * @summary get Homer_Server Available versions
     * @operationId homer_getAvailableVersions
     * @tags External-Server
     *
     * @description Get available versions for Homer upgrade
     *
     *
     * @returns {IHomerVersions} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerGetAvailableVersions():Promise<IHomerVersions> {
        return this.requestRestPath("GET", `/homer_server_available_versions`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerGetRegistrationComponents
     * @summary get Homer_Server Registration Components
     * @operationId get_registration_data
     * @tags External-Server
     *
     * @description Get All data for User registration form in Portal
     *
     *
     * @returns {IServerRegistrationFormData} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerGetRegistrationComponents():Promise<IServerRegistrationFormData> {
        return this.requestRestPath("GET", `/homer_server_registration_data`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerRestart
     * @summary restart Homer_Server
     * @operationId homer_server_restart
     * @tags External-Server
     *
     * @description Restart Virtual Homer_Server machine if its supported
     *
     * @param {string} server_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerRestart(server_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/homer_server/action/restart/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerShutdown
     * @summary shut_down Homer_Server
     * @operationId homer_server_power_down
     * @tags External-Server
     *
     * @description Shut Down Virtual Homer_Server if its supported
     *
     * @param {string} server_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerShutdown(server_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/homer_server/action/shutdown/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerStart
     * @summary start Homer_Server
     * @operationId homer_server_power_on
     * @tags External-Server
     *
     * @description Start Virtual Homer_Server machine if its supported
     *
     * @param {string} server_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerStart(server_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/homer_server/action/start/${server_id}`, {}, [200], 'application/json');
    }

    /**
     * @name homerServerUpgrade
     * @summary upgrade Homer_Server
     * @operationId homer_upgrade
     * @tags External-Server
     *
     * @description Upgrade Homer_Server
     *
     * @param {IHomerServerUpgrade} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServerUpgrade(body:IHomerServerUpgrade):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/homer_server/upgrade`, body, [200], 'application/json');
    }

    /**
     * @name homerServersGetListByFilter
     * @summary get Homer_Servers List by Filter
     * @operationId homer_server_by_filter
     * @tags Admin-External-Server
     *
     * @description get all Homer Servers
     *
     * @param {IHomerServerFilter} body - Contains Json with values
     *
     * @returns {IHomerServerList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public homerServersGetListByFilter(body:IHomerServerFilter):Promise<IHomerServerList> {
        return this.requestRestPath("PUT", `/homer_servers`, body, [200], 'application/json');
    }

    /**
     * @name instanceAddApiKey
     * @summary add Instance Api Key
     * @operationId instance_create_api_key
     * @tags Instance
     *
     * @description add new Api key for selected instance
     *
     * @param {string} instance_id
     * @param {IInstanceToken} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 201] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceAddApiKey(instance_id:string, body:IInstanceToken):Promise<IInstanceSnapshot> {
        return this.requestRestPath("POST", `/instance/api_key/${instance_id}`, body, [201], 'application/json');
    }

    /**
     * @name instanceAddMeshNetworkKey
     * @summary add Instance Mesh Network Key
     * @operationId instance_create_mesh_network_key
     * @tags Instance
     *
     * @description add new Mesh Network key for selected instance
     *
     * @param {string} instance_id
     * @param {IInstanceMesh} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 201] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceAddMeshNetworkKey(instance_id:string, body:IInstanceMesh):Promise<IInstanceSnapshot> {
        return this.requestRestPath("POST", `/instance/mesh_network_key/${instance_id}`, body, [201], 'application/json');
    }

    /**
     * @name instanceCreate
     * @summary create Instance
     * @operationId instance_create
     * @tags Instance
     *
     * @description Create new Instance under Project
     *
     * @param {IInstanceNew} body - Contains Json with values
     *
     * @returns {IInstance} [code 201] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceCreate(body:IInstanceNew):Promise<IInstance> {
        return this.requestRestPath("POST", `/instance`, body, [201], 'application/json');
    }

    /**
     * @name instanceEdit
     * @summary edit Instance
     * @operationId instance_update
     * @tags Instance
     *
     * @description Edit Basic information on Instance
     *
     * @param {string} instance_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IInstance} [code 200] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceEdit(instance_id:string, body:INameAndDescription):Promise<IInstance> {
        return this.requestRestPath("PUT", `/instance/${instance_id}`, body, [200], 'application/json');
    }

    /**
     * @name instanceGet
     * @summary get Instance
     * @operationId instance_get
     * @tags Instance
     *
     * @param {string} instance_id
     *
     * @returns {IInstance} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceGet(instance_id:string):Promise<IInstance> {
        return this.requestRestPath("GET", `/instance/${instance_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceGetByFilter
     * @summary get Instance by Filter
     * @operationId get_b_program_instance_by_filter
     * @tags Instance
     *
     * @description Get List of Instances. According to permission - system return only Instance from project, where is user owner or all Instances if user have static Permission key
     *
     * @param {IInstanceFilter} body - Contains Json with values
     *
     * @returns {IInstanceList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceGetByFilter(body:IInstanceFilter):Promise<IInstanceList> {
        return this.requestRestPath("PUT", `/instances`, body, [200], 'application/json');
    }

    /**
     * @name instanceRemove
     * @summary remove Instance
     * @operationId instance_delete
     * @tags Instance
     *
     * @param {string} instance_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceRemove(instance_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/instance/${instance_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceRemoveApiKey
     * @summary remove Instance Api Key
     * @operationId instance_remove_api_key
     * @tags Instance
     *
     * @description remove Api key for selected instance
     *
     * @param {string} instance_id
     * @param {string} token
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceRemoveApiKey(instance_id:string, token:string):Promise<IInstanceSnapshot> {
        return this.requestRestPath("DELETE", `/instance/api_key/${instance_id}/${token}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceRemoveMeshNetworkKey
     * @summary remove Instance Mesh Network Key
     * @operationId instance_remove_mesh_network_key
     * @tags Instance
     *
     * @description remove Mesh Network key for selected instance
     *
     * @param {string} instance_id
     * @param {string} token
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceRemoveMeshNetworkKey(instance_id:string, token:string):Promise<IInstanceSnapshot> {
        return this.requestRestPath("DELETE", `/instance/mesh_network_key/${instance_id}/${token}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceShutdown
     * @summary shutdown Instance
     * @operationId instance_shutdown
     * @tags Instance
     *
     * @description stop instance of blocko and remove from cloud.
     *
     * @param {string} instance_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceShutdown(instance_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/instance/shutdown/${instance_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceSnapshotCreate
     * @summary create InstanceSnapshot
     * @operationId instanceSnapshot_create
     * @tags Instance
     *
     * @description Create new Instance Snapshot
     *
     * @param {string} instance_id
     * @param {IInstanceSnapshotNew} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 201] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotCreate(instance_id:string, body:IInstanceSnapshotNew):Promise<IInstanceSnapshot> {
        return this.requestRestPath("POST", `/instance/snapshot/${instance_id}`, body, [201], 'application/json');
    }

    /**
     * @name instanceSnapshotDelete
     * @summary delete InstanceSnapshot
     * @operationId instanceSnapshot_delete
     * @tags Instance
     *
     * @description delete Instance
     *
     * @param {string} snapshot_id
     *
     * @returns {IResultOk} [code 200] Successfully deleted
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotDelete(snapshot_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/instance/snapshot/${snapshot_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceSnapshotDeploy
     * @summary deploy InstanceSnapshot
     * @operationId instanceSnapshot_deploy
     * @tags Instance
     *
     * @description deploy instance of blocko to cloud.
     *
     * @param {IInstanceSnapshotDeploy} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Successfully uploaded
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotDeploy(body:IInstanceSnapshotDeploy):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/instance/snapshot/deploy`, body, [200], 'application/json');
    }

    /**
     * @name instanceSnapshotEdit
     * @summary edit InstanceSnapshot
     * @operationId instanceSnapshot_update
     * @tags Instance
     *
     * @param {string} snapshot_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 200] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotEdit(snapshot_id:string, body:INameAndDescription):Promise<IInstanceSnapshot> {
        return this.requestRestPath("PUT", `/instance/snapshot/update/${snapshot_id}`, body, [200], 'application/json');
    }

    /**
     * @name instanceSnapshotExportForHomer
     * @summary export InstanceSnapshot for Homer
     * @operationId instance_export_snapshot_from_offline_homer
     * @tags Instance
     *
     * @description Export Snapshot Config for manual synchronized Homer in private network without access to Main Server
     *
     * @param {string} snapshot_id
     *
     * @returns {IResultOk} [code 200] Result with undefined JSON Structure
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotExportForHomer(snapshot_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/instance/snapshot/export/${snapshot_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceSnapshotGet
     * @summary get InstanceSnapshot
     * @operationId instanceSnapshot_get
     * @tags Instance
     *
     * @description Get InstanceSpapshot from Instance
     *
     * @param {string} snapshot_id
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceSnapshotGet(snapshot_id:string):Promise<IInstanceSnapshot> {
        return this.requestRestPath("GET", `/instance/snapshot/get/${snapshot_id}`, {}, [200], 'application/json');
    }

    /**
     * @name instanceUpdateApiKey
     * @summary update Instance Api Key
     * @operationId instance_edit_api_key
     * @tags Instance
     *
     * @description update Api key for selected instance
     *
     * @param {string} instance_id
     * @param {string} token
     * @param {IInstanceToken} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceUpdateApiKey(instance_id:string, token:string, body:IInstanceToken):Promise<IInstanceSnapshot> {
        return this.requestRestPath("PUT", `/instance/api_key/${instance_id}/${token}`, body, [200], 'application/json');
    }

    /**
     * @name instanceUpdateGridSettings
     * @summary update Instance Grid Settings
     * @operationId instance_change_settings_grid_App
     * @tags Instance
     *
     * @description Update Grid Settings on Instance
     *
     * @param {string} snapshot_id
     * @param {ISwaggerInstanceSnapShotConfiguration} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceUpdateGridSettings(snapshot_id:string, body:ISwaggerInstanceSnapShotConfiguration):Promise<IInstanceSnapshot> {
        return this.requestRestPath("PUT", `/instance/grid_app/${snapshot_id}`, body, [200], 'application/json');
    }

    /**
     * @name instanceUpdateMeshNetworkKey
     * @summary update Instance Mesh Network Key
     * @operationId instance_edit_mesh_network_key
     * @tags Instance
     *
     * @description update Mesh Network key for selected instance
     *
     * @param {string} instance_id
     * @param {string} token
     * @param {IInstanceMESHEdit} body - Contains Json with values
     *
     * @returns {IInstanceSnapshot} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public instanceUpdateMeshNetworkKey(instance_id:string, token:string, body:IInstanceMESHEdit):Promise<IInstanceSnapshot> {
        return this.requestRestPath("PUT", `/instance/mesh_netwhaork_key/${instance_id}/${token}`, body, [200], 'application/json');
    }

    /**
     * @name invoiceConfirm
     * @summary confirm Invoice
     * @operationId invoice_confirm
     * @tags Admin-Invoice
     *
     * @description confirm Invoice above product monthly limit only with permission
     *
     * @param {string} invoice_id
     *
     * @returns {IInvoice} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceConfirm(invoice_id:string):Promise<IInvoice> {
        return this.requestRestPath("GET", `/invoice/confirm/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceDelete
     * @summary delete Invoice
     * @operationId invoice_delete
     * @tags Admin-Invoice
     *
     * @description remove Invoice only with permission
     *
     * @param {string} invoice_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceDelete(invoice_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/invoice/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceEditSetAsPaid
     * @summary edit Invoice Set As Paid
     * @operationId invoice_set_as_paid
     * @tags Admin-Invoice
     *
     * @description remove Invoice only with permission
     *
     * @param {string} invoice_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceEditSetAsPaid(invoice_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/invoice/set_pay/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceGet
     * @summary get Invoice
     * @operationId invoice_get
     * @tags Price & Invoice & Tariffs
     *
     * @description get summary information from invoice, invoice items only for user with update permission
     *
     * @param {string} invoice_id
     *
     * @returns {IInvoiceFullDetails} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceGet(invoice_id:string):Promise<IInvoiceFullDetails> {
        return this.requestRestPath("GET", `/invoice/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceGetPDFFile
     * @summary get Invoice PDF file
     * @operationId invoice_getPdf
     * @tags Price & Invoice & Tariffs
     *
     * @description get PDF invoice file
     *
     * @param {string} kind
     * @param {string} invoice_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceGetPDFFile(kind:string, invoice_id:string):Promise<IResultOk> {
        return this.requestRestPath("GET", `/invoice/pdf/${kind}/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceResend
     * @summary resend Invoice
     * @operationId invoice_resend
     * @tags Price & Invoice & Tariffs
     *
     * @description resend Invoice to specific email
     *
     * @param {string} invoice_id
     * @param {IResendEmail} body - Contains Json with values - values in Json is not requierd
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceResend(invoice_id:string, body:IResendEmail):Promise<IResultOk> {
        return this.requestRestPath("POST", `/invoice/resend/${invoice_id}`, body, [200], 'application/json');
    }

    /**
     * @name invoiceSendReimbursement
     * @summary send Invoice reimbursement
     * @operationId invoice_reimbursement
     * @tags Price & Invoice & Tariffs
     *
     * @description reimbursement of an unpaid invoice - with settings from creating product before
     *
     * @param {string} invoice_id
     *
     * @returns {IInvoice} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceSendReimbursement(invoice_id:string):Promise<IInvoice> {
        return this.requestRestPath("POST", `/invoice/reimbursement/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceSendReminder
     * @summary send Invoice Reminder
     * @operationId invoice_reminder
     * @tags Admin-Invoice
     *
     * @description get PDF invoice file
     *
     * @param {string} invoice_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceSendReminder(invoice_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/invoice/send_remainder/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name invoiceSynchronizeWithFakturoid
     * @summary Synchronize Invoice with Fakturoid
     * @operationId invoice_synchronize_fakturoid
     * @tags Admin
     *
     * @description remove Invoice only with permission
     *
     * @param {string} invoice_id
     *
     * @returns {IInvoice} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public invoiceSynchronizeWithFakturoid(invoice_id:string):Promise<IInvoice> {
        return this.requestRestPath("GET", `/invoice/synchronize_fakturoid/${invoice_id}`, {}, [200], 'application/json');
    }

    /**
     * @name librariesGetById
     * @summary get Libraries by Id
     * @operationId library_list_by_ids
     * @tags Library
     *
     * @description get Libraries by id list
     *
     * @param {ILibraryIdList} body - Contains Json with values
     *
     * @returns {ILibrary[]} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public librariesGetById(body:ILibraryIdList):Promise<ILibrary[]> {
        return this.requestRestPath("PUT", `/library/id_list`, body, [200], 'application/json');
    }

    /**
     * @name libraryCompile
     * @summary compile Library
     * @operationId compile_library
     * @tags Library
     *
     * @description Compile Library
     *
     * @param {ILibraryVersionCompile} body - Contains Json with values
     *
     * @returns {ICloudCompilationServerCompilationResult} [code 200] Compilation successful
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {ICompilationBuildError[]} [code 422] Compilation unsuccessful
     * @throws {IResultServerOffline} [code 477] External server is offline
     * @throws {IResultExternalServerSideError} [code 478] External server side Error
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryCompile(body:ILibraryVersionCompile):Promise<ICloudCompilationServerCompilationResult> {
        return this.requestRestPath("POST", `/library/compile_version`, body, [200], 'application/json');
    }

    /**
     * @name libraryCreate
     * @summary create Library
     * @operationId library_create
     * @tags Library
     *
     * @description Create Library for C programs
     *
     * @param {ILibraryNew} body - Contains Json with values
     *
     * @returns {ILibrary} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryCreate(body:ILibraryNew):Promise<ILibrary> {
        return this.requestRestPath("POST", `/library`, body, [201], 'application/json');
    }

    /**
     * @name libraryDelete
     * @summary delete Library
     * @operationId library_delete
     * @tags Library
     *
     * @description For remove Library
     *
     * @param {string} library_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryDelete(library_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/library/${library_id}`, {}, [200], 'application/json');
    }

    /**
     * @name libraryEdit
     * @summary edit Library
     * @operationId library_edit
     * @tags Library
     *
     * @description Edit Library name and description
     *
     * @param {string} library_id
     * @param {ILibraryNew} body - Contains Json with values
     *
     * @returns {ILibrary} [code 200] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryEdit(library_id:string, body:ILibraryNew):Promise<ILibrary> {
        return this.requestRestPath("PUT", `/library/${library_id}`, body, [200], 'application/json');
    }

    /**
     * @name libraryGet
     * @summary get Library
     * @operationId library_get
     * @tags Library
     *
     * @description if you want to get Library.
     *
     * @param {string} library_id
     *
     * @returns {ILibrary} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryGet(library_id:string):Promise<ILibrary> {
        return this.requestRestPath("GET", `/library/${library_id}`, {}, [200], 'application/json');
    }

    /**
     * @name libraryGetListByFilter
     * @summary get Library List by Filter
     * @operationId library_getByFilter
     * @tags Library
     *
     * @description if you want to get Libraries filtered by specific parameters. For private Libraries under project set project_id, for all public use empty JSON
     *
     * @param {ILibraryFilter} body - Contains Json with values
     *
     * @returns {ILibraryList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryGetListByFilter(body:ILibraryFilter):Promise<ILibraryList> {
        return this.requestRestPath("PUT", `/librarys`, body, [200], 'application/json');
    }

    /**
     * @name libraryMakeClone
     * @summary make_Clone Library
     * @operationId library_clone
     * @tags Library
     *
     * @description clone Library for private
     *
     * @param {ILibraryCopy} body - Contains Json with values
     *
     * @returns {ICProgram} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryMakeClone(body:ILibraryCopy):Promise<ICProgram> {
        return this.requestRestPath("PUT", `/library/clone`, body, [200], 'application/json');
    }

    /**
     * @name libraryVersionCreate
     * @summary create Library_Version
     * @operationId library_version_create
     * @tags Library
     *
     * @description If you want add new code to Library
     *
     * @param {string} library_id
     * @param {ILibraryVersionNew} body - Contains Json with values
     *
     * @returns {ILibraryVersion} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Some Json value Missing
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionCreate(library_id:string, body:ILibraryVersionNew):Promise<ILibraryVersion> {
        return this.requestRestPath("POST", `/library/version/${library_id}`, body, [201], 'application/json');
    }

    /**
     * @name libraryVersionDelete
     * @summary delete Library_Version
     * @operationId library_version_delete
     * @tags Library
     *
     * @description delete Library by query = version_id
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionDelete(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/library/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name libraryVersionEdit
     * @summary edit Library_Version
     * @operationId library_version_edit
     * @tags Library
     *
     * @description For update basic (name and description) information in Version of Library. If you want update code. You have to create new version. And after that you can delete previous version
     *
     * @param {string} version_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {ILibraryVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionEdit(version_id:string, body:INameAndDescription):Promise<ILibraryVersion> {
        return this.requestRestPath("PUT", `/library/version/${version_id}`, body, [200], 'application/json');
    }

    /**
     * @name libraryVersionEditResponsePublication
     * @summary edit Library_Version Response publication
     * @operationId library_public_response
     * @tags Admin-Library
     *
     * @description sets Approval_state to pending
     *
     * @param {ICommunityVersionPublishResponse} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionEditResponsePublication(body:ICommunityVersionPublishResponse):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/library/version_for_aprove/approve_decision`, body, [200], 'application/json');
    }

    /**
     * @name libraryVersionGet
     * @summary get Library_Version
     * @operationId library_version_get
     * @tags Library
     *
     * @description get Version of Library by query = version_id
     *
     * @param {string} version_id
     *
     * @returns {ILibraryVersion} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionGet(version_id:string):Promise<ILibraryVersion> {
        return this.requestRestPath("GET", `/library/version/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name libraryVersionMakePublic
     * @summary make Library_Version public
     * @operationId library_version_make_public
     * @tags Library
     *
     * @description Make Library public, so other users can see it and use it. Attention! Attention! Attention! A user can publish only three programs at the stage waiting for approval.
     *
     * @param {string} version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Bad Request
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionMakePublic(version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/library/version/make_public/${version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name libraryVersionWorkingcopysave
     * @summary working_copy_save Library_Version
     * @operationId library_version_working_copy
     * @tags Library
     *
     * @description Just override last version
     *
     * @param {string} library_id - version_id String query
     * @param {ILibraryFileLoad} body - Contains Json with values
     *
     * @returns {ILibraryVersion} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public libraryVersionWorkingcopysave(library_id:string, body:ILibraryFileLoad):Promise<ILibraryVersion> {
        return this.requestRestPath("POST", `/library/version/working_copy/${library_id}`, body, [201], 'application/json');
    }

    /**
     * @name notificationConfirm
     * @summary confirm Notification
     * @operationId notification_confirm
     * @tags Notifications
     *
     * @description Confirms notification
     *
     * @param {string} notification_id
     * @param {INotificationConfirm} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationConfirm(notification_id:string, body:INotificationConfirm):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/notification/confirm/${notification_id}`, body, [200], 'application/json');
    }

    /**
     * @name notificationDelete
     * @summary delete Notification
     * @operationId notification_delete
     * @tags Notifications
     *
     * @description remove notification by id
     *
     * @param {string} notification_id
     *
     * @returns {IResultOk} [code 200] Delete Successful
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationDelete(notification_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/notification/${notification_id}`, {}, [200], 'application/json');
    }

    /**
     * @name notificationGetLatest
     * @summary get Notification latest
     * @operationId notification_getByFilter
     * @tags Notifications
     *
     * @description Get list of latest user notifications. Server return maximum 25 latest objects.
     *
     *     For get another page (next 25 notifications) call this api with "page_number" path parameter.
     *
     *     May missing or you can insert Integer values from page[1,2...,n] in JsonNotification body cannot by documented through swagger. Visit docu.skunks.cz
     *
     * @param {INotificationFilter} body - Contains Json with values
     *
     * @returns {INotificationList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationGetLatest(body:INotificationFilter):Promise<INotificationList> {
        return this.requestRestPath("PUT", `/notifications`, body, [200], 'application/json');
    }

    /**
     * @name notificationGetUnread
     * @summary get Notification unread
     * @operationId notification_getUnreadByFilter
     * @tags Notifications
     *
     * @description Get list of latest unread user notifications. Server return maximum 25 latest objects.
     *
     *     For get another page (next 25 notifications) call this api with "page_number" path parameter.
     *
     *     May missing or you can insert Integer values from page[1,2...,n] in JsonNotification body cannot by documented through swagger. Visit wiki.skunks.cz
     *
     * @param {INotificationFilter} body - Contains Json with values
     *
     * @returns {INotificationList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationGetUnread(body:INotificationFilter):Promise<INotificationList> {
        return this.requestRestPath("GET", `/notification/unread_list`, body, [200], 'application/json');
    }

    /**
     * @name notificationsGetUnconfirmed
     * @summary get Notifications unconfirmed
     * @operationId notifications_getUnconfirmed
     * @tags Notifications
     *
     * @description This API should by called right after user logs in. Sends notifications which require confirmation via websocket.
     *
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationsGetUnconfirmed():Promise<IResultOk> {
        return this.requestRestPath("GET", `/notification/unconfirmed`, {}, [200], 'application/json');
    }

    /**
     * @name notificationsMarkAsRead
     * @summary mark Notifications as read
     * @operationId notification_read
     * @tags Notifications
     *
     * @description Mark notifications as read. Send list with ids
     *
     * @param {INotificationRead} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Successfully marked as read
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public notificationsMarkAsRead(body:INotificationRead):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/notification/read`, body, [200], 'application/json');
    }

    /**
     * @name onTerminateDemand
     * @summary terminate on demand
     * @operationId product_terminateOnDemand
     * @tags Price & Invoice & Tariffs
     *
     * @description cancel automatic payments in Product
     *
     * @param {string} product_id
     *
     * @returns {IResultOk} [code 200] Successfully updated
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public onTerminateDemand(product_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/product/terminate_ondemand/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name paymentEditDetails
     * @summary edit Payment Details
     * @operationId payment_details_update
     * @tags Price & Invoice & Tariffs
     *
     * @description edit payment details
     *
     * @param {string} payment_details_id
     * @param {IPaymentDetailsUpdate} body - Contains Json with values
     *
     * @returns {IPaymentDetails} [code 200] Successfully updated
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public paymentEditDetails(payment_details_id:string, body:IPaymentDetailsUpdate):Promise<IPaymentDetails> {
        return this.requestRestPath("PUT", `/payment_details/${payment_details_id}`, body, [200], 'application/json');
    }

    /**
     * @name permissionsGetAll
     * @summary get Permissions All
     * @operationId permission_get_all
     * @tags Admin-Permission
     *
     * @description Get all user Permission. You need permission for that or have right system Roles
     *
     *
     * @returns {IPermission[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public permissionsGetAll():Promise<IPermission[]> {
        return this.requestRestPath("GET", `/permission/all`, {}, [200], 'application/json');
    }

    /**
     * @name personActivate
     * @summary activate Person
     * @operationId person_activate
     * @tags Admin-Person
     *
     * @description activate Person by id
     *
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personActivate(person_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/activate/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personCreate
     * @summary create Person
     * @operationId person_create
     * @tags Person
     *
     * @description create new Person with unique email and nick_name
     *
     * @param {IPersonNew} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personCreate(body:IPersonNew):Promise<IResultOk> {
        return this.requestRestPath("POST", `/person`, body, [200], 'application/json');
    }

    /**
     * @name personDeactivate
     * @summary deactivate Person
     * @operationId person_deactivate
     * @tags Admin-Person
     *
     * @description deactivate Person by id
     *
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personDeactivate(person_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/deactivate/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personDelete
     * @summary delete Person
     * @operationId person_delete
     * @tags Admin-Person
     *
     * @description delete Person by id
     *
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personDelete(person_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/person/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personDeleteLoggedConnections
     * @summary delete Person logged connections
     * @operationId remove_Person_Connection
     * @tags Person
     *
     * @description You know where the user is logged in. And you can log out this connection. (Terminate token)
     *
     * @param {string} connection_id
     *
     * @returns {IResultOk} [code 200] OK result
     *
     * @throws {IResultFound} [code 404] Not Found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personDeleteLoggedConnections(connection_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/person/connection/${connection_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personDeletePicture
     * @summary delete Person picture
     * @operationId person_removePicture
     * @tags Person
     *
     * @description Removes picture of logged person
     *
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personDeletePicture():Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/picture/remove`, {}, [200], 'application/json');
    }

    /**
     * @name personEdit
     * @summary edit Person
     * @operationId person_update
     * @tags Person
     *
     * @description Edit person basic information
     *
     * @param {string} person_id
     * @param {IPersonUpdate} body - Contains Json with values
     *
     * @returns {IPerson} [code 200] Successfully updated
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personEdit(person_id:string, body:IPersonUpdate):Promise<IPerson> {
        return this.requestRestPath("PUT", `/person/${person_id}`, body, [200], 'application/json');
    }

    /**
     * @name personEditProperty
     * @summary edit Person property
     * @operationId person_changeLoginProperty
     * @tags Person
     *
     * @description Request password or email change. API does not change password or email, only sends email for authorization of the change and holds values in different object.JSON value 'property' contains only 'password' or 'email'
     *
     * @param {IPersonChangeProperty} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personEditProperty(body:IPersonChangeProperty):Promise<IResultOk> {
        return this.requestRestPath("POST", `/person/change_property`, body, [200], 'application/json');
    }

    /**
     * @name personEmailApprovingPasswordOrChange
     * @summary email Person approving password or email change
     * @operationId person_authorizePropertyChange
     * @tags Admin-Person
     *
     * @param {string} token
     * @param {IPersonChangeProperty} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personEmailApprovingPasswordOrChange(token:string, body:IPersonChangeProperty):Promise<IResultOk> {
        return this.requestRestPath("GET", `/person/authorize_change/${token}`, body, [200], 'application/json');
    }

    /**
     * @name personGet
     * @summary get Person
     * @operationId person_get
     * @tags Person
     *
     * @description get Person by id
     *
     * @param {string} person_id
     *
     * @returns {IPerson} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personGet(person_id:string):Promise<IPerson> {
        return this.requestRestPath("GET", `/person/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personGetByToken
     * @summary get Person by token
     * @operationId person_get_by_token
     * @tags Access, Person, Social-GitHub, Social-Facebook
     *
     * @description If you want login to system with social networks - you can used facebook, github or twitter api just ask for token, server responds with object where is token and redirection link. Redirect user to this link and after returning to success page that you filled in ask for token, ask again to this api and server respond with Person Object and with Roles and Permissions lists
     *
     *
     * @returns {ILoginResult} [code 200] Successfully logged
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Wrong Email or Password
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personGetByToken():Promise<ILoginResult> {
        return this.requestRestPath("GET", `/login/person`, {}, [200], 'application/json');
    }

    /**
     * @name personGetLoggedConnections
     * @summary get Person logged connections
     * @operationId person_getAllConnections
     * @tags Person
     *
     * @description get all connections, where user is logged
     *
     *
     * @returns {IAuthorizationToken[]} [code 200] OK result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not Found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personGetLoggedConnections():Promise<IAuthorizationToken[]> {
        return this.requestRestPath("GET", `/person/connection/all`, {}, [200], 'application/json');
    }

    /**
     * @name personGetRolesAndPermissions
     * @summary get Person Roles and Permissions
     * @operationId system_access_get_everything
     * @tags Admin-Role, Admin-Permission, Person
     *
     * @description This api return List of Roles and List of Permission
     *
     * @param {string} person_id
     *
     * @returns {ISystemAccess} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personGetRolesAndPermissions(person_id:string):Promise<ISystemAccess> {
        return this.requestRestPath("GET", `/secure/person/system_acces/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name personRestartPassword
     * @summary restart Person password
     * @operationId person_passwordRecovery
     * @tags Access
     *
     * @description changes password if password_recovery_token is not older than 24 hours, deletes all FloatingPersonTokens
     *
     * @param {IPersonPasswordNew} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personRestartPassword(body:IPersonPasswordNew):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/password/recovery`, body, [200], 'application/json');
    }

    /**
     * @name personUploadPicture
     * @summary upload Person picture
     * @operationId person_uploadPicture
     * @tags Person
     *
     * @description Uploads personal photo. Picture must be smaller than 800 KB and its dimensions must be between 50 and 400 pixels. If user already has a picture, it will be replaced by the new one. API requires base64 Content-Type, name of the property is 'file'.
     *
     * @param {IBase64File} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personUploadPicture(body:IBase64File):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/picture/upload`, body, [200], 'application/json');
    }

    /**
     * @name personValidEmail
     * @summary valid Person email
     * @operationId person_validEmail
     * @tags Admin-Person
     *
     * @description valid Person email by id
     *
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public personValidEmail(person_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/person/valid_email/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name processorCreate
     * @summary create Processor
     * @operationId processor_create
     * @tags Admin-Processor
     *
     * @description If you want create new Processor. Send required json values and server respond with new object
     *
     * @param {IProcessorNew} body - Contains Json with values
     *
     * @returns {IProcessor} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public processorCreate(body:IProcessorNew):Promise<IProcessor> {
        return this.requestRestPath("POST", `/processor`, body, [201], 'application/json');
    }

    /**
     * @name processorDelete
     * @summary delete Processor
     * @operationId processor_delete
     * @tags Admin-Processor
     *
     * @description If you want delete Processor by query processor_id.
     *
     * @param {string} processor_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public processorDelete(processor_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/processor/${processor_id}`, {}, [200], 'application/json');
    }

    /**
     * @name processorEdit
     * @summary edit Processor
     * @operationId processor_edit
     * @tags Processor
     *
     * @description If you want update Processor.id by query = processor_id . Send required json values and server respond with update object
     *
     * @param {string} processor_id
     * @param {IProcessorNew} body - Contains Json with values
     *
     * @returns {IProcessor} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public processorEdit(processor_id:string, body:IProcessorNew):Promise<IProcessor> {
        return this.requestRestPath("PUT", `/processor/${processor_id}`, body, [200], 'application/json');
    }

    /**
     * @name processorGet
     * @summary get Processor
     * @operationId processor_get
     * @tags Processor
     *
     * @description If you get Processor by query processor_id.
     *
     * @param {string} processor_id
     *
     * @returns {IProcessor} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public processorGet(processor_id:string):Promise<IProcessor> {
        return this.requestRestPath("GET", `/processor/${processor_id}`, {}, [200], 'application/json');
    }

    /**
     * @name processorGetAll
     * @summary get Processor All
     * @operationId processor_getAll
     * @tags Processor
     *
     * @description Get list of all Processor by query
     *
     *
     * @returns {IProcessor[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public processorGetAll():Promise<IProcessor[]> {
        return this.requestRestPath("GET", `/processor`, {}, [200], 'application/json');
    }

    /**
     * @name producerCreate
     * @summary create Producer
     * @operationId producer_create
     * @tags Admin-Producer
     *
     * @description if you want create new Producer. Its company owned physical hardware and we used that for filtering
     *
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IProducer} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public producerCreate(body:INameAndDescription):Promise<IProducer> {
        return this.requestRestPath("POST", `/producer`, body, [201], 'application/json');
    }

    /**
     * @name producerDelete
     * @summary delete Producer
     * @operationId producer_delete
     * @tags Admin-Producer
     *
     * @description if you want delete Producer
     *
     * @param {string} producer_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public producerDelete(producer_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/producer/${producer_id}`, {}, [200], 'application/json');
    }

    /**
     * @name producerEdit
     * @summary edit Producer
     * @operationId producer_update
     * @tags Admin-Producer
     *
     * @description if you want edit information about Producer. Its company owned physical hardware and we used that for filtering
     *
     * @param {string} producer_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IProducer} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public producerEdit(producer_id:string, body:INameAndDescription):Promise<IProducer> {
        return this.requestRestPath("PUT", `/producer/${producer_id}`, body, [200], 'application/json');
    }

    /**
     * @name producerGet
     * @summary get Producer
     * @operationId producer_get
     * @tags Producer
     *
     * @description if you want get Producer. Its company owned physical hardware and we used that for filtering
     *
     * @param {string} producer_id
     *
     * @returns {IProducer} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public producerGet(producer_id:string):Promise<IProducer> {
        return this.requestRestPath("GET", `/producer/${producer_id}`, {}, [200], 'application/json');
    }

    /**
     * @name producersGetAll
     * @summary get Producers All
     * @operationId producer_getAll
     * @tags Producer
     *
     * @description if you want get list of Producers. Its list of companies owned physical hardware and we used that for filtering
     *
     *
     * @returns {IProducer[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public producersGetAll():Promise<IProducer[]> {
        return this.requestRestPath("GET", `/producer/all`, {}, [200], 'application/json');
    }

    /**
     * @name productActivate
     * @summary activate Product
     * @operationId product_activate
     * @tags Price & Invoice & Tariffs
     *
     * @description Activate product Tariff and deactivate all staff around that
     *
     * @param {string} product_id
     *
     * @returns {IProduct} [code 200] Activating was successful
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productActivate(product_id:string):Promise<IProduct> {
        return this.requestRestPath("PUT", `/product/activate/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productCreate
     * @summary create Product
     * @operationId product_create
     * @tags Price & Invoice & Tariffs
     *
     * @description It is the base object. Peak of Pyramid :). This Api is used for its creation. You can get two kind of response:
     *
     *    First(201):  System create new Object - Product
     *
     *    Second(200): The product requires payment - The server creates the object, but returns an Invoice
     *
     *    If the user choose credit card payment, the invoice will contain gw_url, which is a link to the payment gate, you can redirect him there. If bank transfer is chosen, server will return an Invoice, but the user will pay it independently via his bank account.
     *
     * @param {IProductNew} body - Contains Json with values
     *
     * @returns {IProduct} [code 201] Created successfully
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productCreate(body:IProductNew):Promise<IProduct> {
        return this.requestRestPath("POST", `/product`, body, [201], 'application/json');
    }

    /**
     * @name productDeactivate
     * @summary deactivate Product
     * @operationId product_deactivate
     * @tags Price & Invoice & Tariffs
     *
     * @description deactivate product Tariff and deactivate all stuff under it
     *
     * @param {string} product_id
     *
     * @returns {IProduct} [code 200] Deactivating was successful
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productDeactivate(product_id:string):Promise<IProduct> {
        return this.requestRestPath("PUT", `/product/deactivate/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productDeleteTariff
     * @summary delete Product Tariff
     * @operationId product_delete
     * @tags Admin
     *
     * @description get PDF invoice file
     *
     * @param {string} product_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productDeleteTariff(product_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/product/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productEditDetails
     * @summary edit Product details
     * @operationId product_update
     * @tags Price & Invoice & Tariffs
     *
     * @description edit basic details of Product
     *
     * @param {string} product_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IProduct} [code 200] Successfully updated
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productEditDetails(product_id:string, body:INameAndDescription):Promise<IProduct> {
        return this.requestRestPath("PUT", `/product/general/${product_id}`, body, [200], 'application/json');
    }

    /**
     * @name productExtensionActivate
     * @summary activate Product_Extension
     * @operationId productExtension_activate
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} extension_id
     *
     * @returns {IProductExtension} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionActivate(extension_id:string):Promise<IProductExtension> {
        return this.requestRestPath("PUT", `/product_extension/activate/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productExtensionCreate
     * @summary create Product_Extension
     * @operationId productExtension_create
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} product_id
     * @param {IProductExtensionNew} body - Contains Json with values
     *
     * @returns {IProductExtension} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionCreate(product_id:string, body:IProductExtensionNew):Promise<IProductExtension> {
        return this.requestRestPath("POST", `/product_extension/${product_id}`, body, [201], 'application/json');
    }

    /**
     * @name productExtensionDeactivate
     * @summary deactivate Product_Extension
     * @operationId productExtension_deactivate
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} extension_id
     *
     * @returns {IProductExtension} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionDeactivate(extension_id:string):Promise<IProductExtension> {
        return this.requestRestPath("PUT", `/product_extension/deactivate/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productExtensionDelete
     * @summary delete Product_Extension
     * @operationId productExtension_delete
     * @tags Admin-Extension
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionDelete(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/product_extension/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productExtensionGet
     * @summary get Product_Extension
     * @operationId productExtension_get
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} extension_id
     *
     * @returns {IProductExtension} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionGet(extension_id:string):Promise<IProductExtension> {
        return this.requestRestPath("GET", `/product_extension/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productExtensionGetListProduct
     * @summary get Product_Extension List Product
     * @operationId productExtension_getAllProduct
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     * @param {string} product_id
     *
     * @returns {IProductExtension[]} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionGetListProduct(product_id:string):Promise<IProductExtension[]> {
        return this.requestRestPath("GET", `/product/extensions/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productExtensionGetListUserOwn
     * @summary get Product_Extension List user Own
     * @operationId productExtension_getAll
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     *
     * @returns {IProductExtension[]} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productExtensionGetListUserOwn():Promise<IProductExtension[]> {
        return this.requestRestPath("GET", `/product_extension`, {}, [200], 'application/json');
    }

    /**
     * @name productGet
     * @summary get Product
     * @operationId product_get
     * @tags Price & Invoice & Tariffs
     *
     * @param {string} product_id
     *
     * @returns {IProduct} [code 200] List of users Products
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productGet(product_id:string):Promise<IProduct> {
        return this.requestRestPath("GET", `/product/${product_id}`, {}, [200], 'application/json');
    }

    /**
     * @name productGetExtensionFinancialEvents
     * @summary get Product Extension Financial Events
     * @operationId productExtensionFinancialEvents_get
     * @tags Price & Invoice & Extension
     *
     * @param {IExtensionFinancialEventSearch} body - Contains Json with values
     *
     * @returns {IExtensionFinancialEvent[]} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public productGetExtensionFinancialEvents(body:IExtensionFinancialEventSearch):Promise<IExtensionFinancialEvent[]> {
        return this.requestRestPath("POST", `/financial_events`, body, [200], 'application/json');
    }

    /**
     * @name productGetInvoices
     * @summary get Product invoices
     * @operationId product_getInvoices
     * @tags Price & Invoice & Tariffs
     *
     * @description get all invoices for product
     *
     * @param {string} product_id
     *
     * @returns {IInvoice[]} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productGetInvoices(product_id:string):Promise<IInvoice[]> {
        return this.requestRestPath("GET", `/product/invoices/${product_id}/`, {}, [200], 'application/json');
    }

    /**
     * @name productsGetUserCanUsed
     * @summary get Products user can used
     * @operationId product_getActive
     * @tags Price & Invoice & Tariffs
     *
     * @description get all the products that the user can use when creating new projects
     *
     *
     * @returns {IApplicableProduct[]} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productsGetUserCanUsed():Promise<IApplicableProduct[]> {
        return this.requestRestPath("GET", `/product/list/user_applicable`, {}, [200], 'application/json');
    }

    /**
     * @name productsGetUserOwnList
     * @summary get Products user Own List
     * @operationId product_getAll
     * @tags Price & Invoice & Tariffs
     *
     *
     * @returns {IProduct[]} [code 200] List of users Products
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public productsGetUserOwnList():Promise<IProduct[]> {
        return this.requestRestPath("GET", `/product/all`, {}, [200], 'application/json');
    }

    /**
     * @name projectActiveHW
     * @summary activeHW Project
     * @operationId project_activateHardware
     * @tags Project
     *
     * @description freze HW from Project
     *
     * @param {string} registration_id
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectActiveHW(registration_id:string):Promise<IHardware> {
        return this.requestRestPath("PUT", `/project/hardware/activate/${registration_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectAddHW
     * @summary addHW Project
     * @operationId project_addHardware
     * @tags Project
     *
     * @description add new HW to Project, creates HardwareRegistration
     *
     * @param {IProjectAddHardware} body - Contains Json with values
     *
     * @returns {IHardware} [code 201] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectAddHW(body:IProjectAddHardware):Promise<IHardware> {
        return this.requestRestPath("PUT", `/project/hardware/add`, body, [201], 'application/json');
    }

    /**
     * @name projectCreate
     * @summary create Project
     * @operationId project_create
     * @tags Project
     *
     * @description create new Project
     *
     * @param {IProjectNew} body - Contains Json with values
     *
     * @returns {IProject} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectCreate(body:IProjectNew):Promise<IProject> {
        return this.requestRestPath("POST", `/project`, body, [201], 'application/json');
    }

    /**
     * @name projectDeactiveHW
     * @summary deactiveHW Project
     * @operationId project_deactivateHardware
     * @tags Project
     *
     * @description freeze HW from Project
     *
     * @param {string} registration_id
     *
     * @returns {IHardware} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectDeactiveHW(registration_id:string):Promise<IHardware> {
        return this.requestRestPath("PUT", `/project/hardware/deactive/${registration_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectDelete
     * @summary delete Project
     * @operationId project_delete
     * @tags Project
     *
     * @description delete Projects by project_id
     *
     * @param {string} project_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectDelete(project_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/project/${project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectEdit
     * @summary edit Project
     * @operationId project_update
     * @tags Project
     *
     * @description edit ne Project
     *
     * @param {string} project_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IProject} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectEdit(project_id:string, body:INameAndDescription):Promise<IProject> {
        return this.requestRestPath("PUT", `/project/${project_id}`, body, [200], 'application/json');
    }

    /**
     * @name projectGet
     * @summary get Project
     * @operationId project_get
     * @tags Project
     *
     * @description get Projects by project_id
     *
     * @param {string} project_id
     *
     * @returns {IProject} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectGet(project_id:string):Promise<IProject> {
        return this.requestRestPath("GET", `/project/${project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectGetInvitation
     * @summary get Project Invitation
     * @operationId project_get_invitation
     * @tags Project
     *
     * @description get Projects Invitations of new Users by project_id
     *
     * @param {string} project_id
     *
     * @returns {IInvitation[]} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectGetInvitation(project_id:string):Promise<IInvitation[]> {
        return this.requestRestPath("GET", `/project/invitations/${project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectRemoveHW
     * @summary removeHW Project
     * @operationId project_removeHardware
     * @tags Project
     *
     * @description removes HW from Project
     *
     * @param {string} registration_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectRemoveHW(registration_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/project/hardware/remove/${registration_id}`, {}, [200], 'application/json');
    }

    /**
     * @name projectShare
     * @summary share Project
     * @operationId project_invite
     * @tags Project
     *
     * @description sends Invitation to all users in list: List<persons_mail>
     *
     * @param {string} project_id
     * @param {IInvitePerson} body - Contains Json with values
     *
     * @returns {IInvitation[]} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectShare(project_id:string, body:IInvitePerson):Promise<IInvitation[]> {
        return this.requestRestPath("PUT", `/project/invite/${project_id}`, body, [200], 'application/json');
    }

    /**
     * @name projectUnshare
     * @summary unshare Project
     * @operationId project_removeParticipant
     * @tags Project
     *
     * @description unshare Project with all users in list: List<person_id>
     *
     * @param {string} project_id
     * @param {IInvitePerson} body - Contains Json with values
     *
     * @returns {IProject} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectUnshare(project_id:string, body:IInvitePerson):Promise<IProject> {
        return this.requestRestPath("PUT", `/project/remove_participant/${project_id}`, body, [200], 'application/json');
    }

    /**
     * @name projectValidObjectUniqueName
     * @summary valid Project Object Unique Name
     * @operationId project_valid_name
     * @tags Project
     *
     * @description Valid unique name in Projects objects
     *
     * @param {IProjectValidUniqueName} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Object with this name is already register
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectValidObjectUniqueName(body:IProjectValidUniqueName):Promise<IResultOk> {
        return this.requestRestPath("POST", `/project_valid_name`, body, [200], 'application/json');
    }

    /**
     * @name projectsGetListByFilter
     * @summary get Projects List by Filter
     * @operationId project_getByFilter
     * @tags Project
     *
     * @description get filter Projects List by logged Person
     *
     * @param {IProjectFilter} body - Contains Json with values
     *
     * @returns {IProjectList} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public projectsGetListByFilter(body:IProjectFilter):Promise<IProjectList> {
        return this.requestRestPath("PUT", `/projects`, body, [200], 'application/json');
    }

    /**
     * @name reportAdminDashboardGet
     * @summary get Report_Admin_Dashboard
     * @operationId report_admin_dashboard
     * @tags Admin-Report
     *
     * @description Special API only For PRIVATE use
     *
     *
     * @returns {IReportAdminDashboard} [code 200] Ok result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public reportAdminDashboardGet():Promise<IReportAdminDashboard> {
        return this.requestRestPath("GET", `/admin/report/dashboard`, {}, [200], 'application/json');
    }

    /**
     * @name roleAddPermissions
     * @summary add Role Permissions
     * @operationId role_addPermission
     * @tags Role
     *
     * @description If you want add system permissions to Role. You need permission for that or have right system Roles
     *
     * @param {string} role_id
     * @param {IRoleAddPermission} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleAddPermissions(role_id:string, body:IRoleAddPermission):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/role/permission/${role_id}`, body, [200], 'application/json');
    }

    /**
     * @name roleAddPerson
     * @summary add Role Person
     * @operationId role_addPerson
     * @tags Role, Admin-Person
     *
     * @description If you set Role to Person. You need permission for that or have right system Roles
     *
     * @param {string} role_id
     * @param {IInvitePerson} body - Contains Json with values
     *
     * @returns {IRole} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleAddPerson(role_id:string, body:IInvitePerson):Promise<IRole> {
        return this.requestRestPath("PUT", `/role/person/${role_id}`, body, [200], 'application/json');
    }

    /**
     * @name roleCreate
     * @summary create Role
     * @operationId role_create
     * @tags Role
     *
     * @description If you want create new Role in system. You need permission for that or have right system Roles
     *
     * @param {INameAndDescProjectIdOptional} body - Contains Json with values
     *
     * @returns {IRole} [code 201] Successfully created
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleCreate(body:INameAndDescProjectIdOptional):Promise<IRole> {
        return this.requestRestPath("POST", `/role`, body, [201], 'application/json');
    }

    /**
     * @name roleDelete
     * @summary delete Role
     * @operationId role_delete
     * @tags Role
     *
     * @description If you want delete  Role from system. You need permission for that or have right system Roles
     *
     * @param {string} role_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleDelete(role_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/role/${role_id}`, {}, [200], 'application/json');
    }

    /**
     * @name roleEdit
     * @summary edit Role
     * @operationId role_update
     * @tags Role
     *
     * @description edit description
     *
     * @param {string} role_id
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IRole} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleEdit(role_id:string, body:INameAndDescription):Promise<IRole> {
        return this.requestRestPath("PUT", `/role/${role_id}`, body, [200], 'application/json');
    }

    /**
     * @name roleGet
     * @summary get Role
     * @operationId role_get
     * @tags Role
     *
     * @description get description
     *
     * @param {string} role_id
     *
     * @returns {IRole} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleGet(role_id:string):Promise<IRole> {
        return this.requestRestPath("GET", `/role/${role_id}`, {}, [200], 'application/json');
    }

    /**
     * @name roleGetAll
     * @summary get Role All
     * @operationId role_get_all
     * @tags Role
     *
     * @description If you set Role to Person. You need permission for that or have right system Roles
     *
     *
     * @returns {IRole[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleGetAll():Promise<IRole[]> {
        return this.requestRestPath("GET", `/role`, {}, [200], 'application/json');
    }

    /**
     * @name roleGetListByFilter
     * @summary get Role List by Filter
     * @operationId role_getByFilter
     * @tags Role
     *
     * @description get Role List
     *
     * @param {IRoleFilter} body - Contains Json with values
     *
     * @returns {IRoleList} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleGetListByFilter(body:IRoleFilter):Promise<IRoleList> {
        return this.requestRestPath("PUT", `/role/list`, body, [200], 'application/json');
    }

    /**
     * @name roleRemovePermission
     * @summary remove Role Permission
     * @operationId role_removePermission
     * @tags Role
     *
     * @description If you want remove system permissions from Role. You need permission for that or have right system Roles
     *
     * @param {string} role_id
     * @param {string} permission_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleRemovePermission(role_id:string, permission_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/role/permission/${role_id}/${permission_id}`, {}, [200], 'application/json');
    }

    /**
     * @name roleRemovePerson
     * @summary remove Role Person
     * @operationId role_removePerson
     * @tags Role, Admin-Person
     *
     * @description If you set Role to Person. You need permission for that or have right system Roles
     *
     * @param {string} role_id
     * @param {string} person_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public roleRemovePerson(role_id:string, person_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/role/person/${role_id}/${person_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simActivate
     * @summary activate Sim
     * @operationId gsm_activate
     * @tags GSM
     *
     * @param {string} sim_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simActivate(sim_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/gsm/active/${sim_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simCheckRegistrationStatus
     * @summary check Sim registration status
     * @operationId gsm_check
     * @tags GSM
     *
     * @description Check SIM state for new Registration. Types of responses in JSON state value[CAN_REGISTER, NOT_EXIST, ALREADY_REGISTERED_IN_YOUR_ACCOUNT, ALREADY_REGISTERED, PERMANENTLY_DISABLED, BROKEN_DEVICE]...
     *     PERMANENTLY_DISABLED - sim was removed by Byzance.
     *    BROKEN_DEVICE - modul exist - but its not possible to registered that. Damaged during manufacturing.
     *
     * @param {string} registration_hash
     * @param {string} project_id
     *
     * @returns {IEntityRegistrationStatus} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simCheckRegistrationStatus(registration_hash:string, project_id:string):Promise<IEntityRegistrationStatus> {
        return this.requestRestPath("GET", `/gsm/check/${registration_hash}/${project_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simDeactivate
     * @summary deactivate Sim
     * @operationId gsm_deactivate
     * @tags GSM
     *
     * @param {string} sim_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simDeactivate(sim_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/gsm/deactive/${sim_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simDelete
     * @summary delete Sim
     * @operationId gsm_delete
     * @tags GSM-admin
     *
     * @description delete sim by id
     *
     * @param {string} sim_id
     *
     * @returns {IResultOk} [code 200] OK Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simDelete(sim_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/admin/gsm/${sim_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simGet
     * @summary get Sim
     * @operationId gsm_get
     * @tags GSM
     *
     * @description get Sim by id, not by msinumber
     *
     * @param {string} sim_id
     *
     * @returns {IGSM} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simGet(sim_id:string):Promise<IGSM> {
        return this.requestRestPath("GET", `/gsm/${sim_id}`, {}, [200], 'application/json');
    }

    /**
     * @name simGetCrdRecords
     * @summary get Sim crd records
     * @operationId get_cdr_records
     * @tags GSM
     *
     * @param {IGSMCRDFilter} body - Contains Json with values
     *
     * @returns {IGSMCDRList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simGetCrdRecords(body:IGSMCRDFilter):Promise<IGSMCDRList> {
        return this.requestRestPath("PUT", `/gsm/cdrs`, body, [200], 'application/json');
    }

    /**
     * @name simGetDataUsage
     * @summary get Sim data usage
     * @operationId data_consumption_by_gsm
     * @tags GSM
     *
     * @param {IDataConsumptionFilter} body - Contains Json with values
     *
     * @returns {IDataByMsisdn[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simGetDataUsage(body:IDataConsumptionFilter):Promise<IDataByMsisdn[]> {
        return this.requestRestPath("PUT", `/gsm/data_consumption`, body, [200], 'application/json');
    }

    /**
     * @name simGetListByFilter
     * @summary get Sim List by filter
     * @operationId gsm_getByFilter
     * @tags GSM
     *
     * @param {IGSMFilter} body - Contains Json with values
     *
     * @returns {IGSMList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simGetListByFilter(body:IGSMFilter):Promise<IGSMList> {
        return this.requestRestPath("PUT", `/gsms`, body, [200], 'application/json');
    }

    /**
     * @name simRegister
     * @summary register Sim
     * @operationId gsm_register
     * @tags GSM
     *
     * @description register SIM to project
     *
     * @param {IGSMRegister} body - Contains Json with values
     *
     * @returns {IGSM} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simRegister(body:IGSMRegister):Promise<IGSM> {
        return this.requestRestPath("POST", `/gsm`, body, [200], 'application/json');
    }

    /**
     * @name simUnregister
     * @summary unregister Sim
     * @operationId gsm_unregister
     * @tags GSM
     *
     * @description unregister SIM from project
     *
     * @param {string} sim_id
     * @param {IGSMRegister} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simUnregister(sim_id:string, body:IGSMRegister):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/gsm/gsm_project/${sim_id}`, body, [200], 'application/json');
    }

    /**
     * @name simUpdate
     * @summary update Sim
     * @operationId gsm_update
     * @tags GSM
     *
     * @param {string} sim_id
     * @param {IGSMUpdate} body - Contains Json with values
     *
     * @returns {IGSM} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public simUpdate(sim_id:string, body:IGSMUpdate):Promise<IGSM> {
        return this.requestRestPath("PUT", `/gsm/update/${sim_id}`, body, [200], 'application/json');
    }

    /**
     * @name tariffActivate
     * @summary activate Tariff
     * @operationId tariff_activate
     * @tags Admin-Tariff
     *
     * @description activate Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {ITariff} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffActivate(tariff_id:string):Promise<ITariff> {
        return this.requestRestPath("PUT", `/admin/tariff/active/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffAddExtensionIncluded
     * @summary add Tariff Extension included
     * @operationId tariff_add_extension_included
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     * @param {string} ext_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffAddExtensionIncluded(tariff_id:string, ext_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff/extensions/included/${tariff_id}/${ext_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffAddExtensionRecommended
     * @summary add Tariff Extension recommended
     * @operationId tariff_add_extension_recommended
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     * @param {string} ext_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffAddExtensionRecommended(tariff_id:string, ext_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff/extensions/recommended/${tariff_id}/${ext_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffCreate
     * @summary create Tariff
     * @operationId tariff_create
     * @tags Admin-Tariff
     *
     * @description create new Tariff
     *
     * @param {ITariffNew} body - Contains Json with values
     *
     * @returns {ITariff} [code 201] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffCreate(body:ITariffNew):Promise<ITariff> {
        return this.requestRestPath("POST", `/admin/tariff`, body, [201], 'application/json');
    }

    /**
     * @name tariffDeactivate
     * @summary deactivate Tariff
     * @operationId tariff_deactivate
     * @tags Admin-Tariff
     *
     * @description deactivate Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffDeactivate(tariff_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff/deactive/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffDelete
     * @summary delete Tariff
     * @operationId tariff_delete
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffDelete(tariff_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/admin/tariff/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffEdit
     * @summary edit Tariff
     * @operationId tariff_edit
     * @tags Admin-Tariff
     *
     * @description create new Tariff
     *
     * @param {string} tariff_id
     * @param {ITariffNew} body - Contains Json with values
     *
     * @returns {ITariff} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffEdit(tariff_id:string, body:ITariffNew):Promise<ITariff> {
        return this.requestRestPath("PUT", `/admin/tariff/edit/${tariff_id}`, body, [200], 'application/json');
    }

    /**
     * @name tariffExtensionActivate
     * @summary activate Tariff_Extension
     * @operationId tariffExtension_activate
     * @tags Admin-Extension
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionActivate(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff_extension/activate/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffExtensionCreate
     * @summary create Tariff_Extension
     * @operationId tariffExtension_create
     * @tags Admin-Extension
     *
     * @param {ITariffExtensionNew} body - Contains Json with values
     *
     * @returns {ITariffExtension} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public tariffExtensionCreate(body:ITariffExtensionNew):Promise<ITariffExtension> {
        return this.requestRestPath("POST", `/admin/tariff_extension`, body, [201], 'application/json');
    }

    /**
     * @name tariffExtensionDeactivate
     * @summary deactivate Tariff_Extension
     * @operationId tariffExtension_deactivate
     * @tags Admin-Extension
     *
     * @description deactivate Tariff Extension
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionDeactivate(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff_extension/deactivate/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffExtensionDelete
     * @summary delete Tariff_Extension
     * @operationId tariffExtension_delete
     * @tags Admin-Extension
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionDelete(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/admin/tariff_extension/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffExtensionEdit
     * @summary edit Tariff_Extension
     * @operationId tariffExtension_update
     * @tags Admin-Extension
     *
     * @description create new Tariff
     *
     * @param {string} extension_id
     * @param {ITariffExtensionEdit} body - Contains Json with values
     *
     * @returns {ITariffExtension} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionEdit(extension_id:string, body:ITariffExtensionEdit):Promise<ITariffExtension> {
        return this.requestRestPath("PUT", `/admin/tariff_extension/${extension_id}`, body, [200], 'application/json');
    }

    /**
     * @name tariffExtensionGet
     * @summary Get Tariff_Extension
     * @operationId tariffExtension_get
     * @tags Price & Invoice & Tariffs
     *
     * @param {string} extension_id
     *
     * @returns {ITariffExtension} [code 200] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Not found object
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public tariffExtensionGet(extension_id:string):Promise<ITariffExtension> {
        return this.requestRestPath("GET", `/admin/tariff_extension/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffExtensionOrderDown
     * @summary order TariffExtension Down
     * @operationId tariffExtension_down
     * @tags Admin-Extension
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionOrderDown(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff_extension/down/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffExtensionOrderUp
     * @summary order TariffExtension Up
     * @operationId tariffExtension_up
     * @tags Admin-Extension
     *
     * @param {string} extension_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffExtensionOrderUp(extension_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff_extension/up/${extension_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffGet
     * @summary get Tariff
     * @operationId tariff_get
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {ITariff} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffGet(tariff_id:string):Promise<ITariff> {
        return this.requestRestPath("GET", `/admin/tariff/get/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffGetAllTypes
     * @summary get Tariff All types
     * @operationId tariff_getAllTypes
     * @tags Price & Invoice & Tariffs
     *
     * @description Extension is used to somehow(based on configuration and type) extend product capabilities. (e.g. how many projects can user have)
     *
     *
     * @returns {IProductExtensionType[]} [code 200] OK Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side error
     */
    public tariffGetAllTypes():Promise<IProductExtensionType[]> {
        return this.requestRestPath("GET", `/admin/tariff/types`, {}, [200], 'application/json');
    }

    /**
     * @name tariffGetExtensionsAll
     * @summary get Tariff Extensions all
     * @operationId tariffExtension_getAll
     * @tags Price & Invoice & Tariffs
     *
     * @description get all Tariffs - required for every else action in system. For example: Project is created under the Product which is under some Tariff
     *
     *
     * @returns {ITariffExtension[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffGetExtensionsAll():Promise<ITariffExtension[]> {
        return this.requestRestPath("GET", `/admin/tariff_extension`, {}, [200], 'application/json');
    }

    /**
     * @name tariffOrderDown
     * @summary order Tariff Down
     * @operationId tariff_down
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffOrderDown(tariff_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff/down/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffOrderUp
     * @summary order Tariff Up
     * @operationId tariff_up
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffOrderUp(tariff_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/tariff/up/${tariff_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffRemoveExtension
     * @summary remove Tariff Extension
     * @operationId tariff_remove_extension
     * @tags Admin-Tariff
     *
     * @param {string} tariff_id
     * @param {string} ext_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Something is wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffRemoveExtension(tariff_id:string, ext_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/admin/tariff/extensions/${tariff_id}/${ext_id}`, {}, [200], 'application/json');
    }

    /**
     * @name tariffsGetAll
     * @summary get Tariffs all
     * @operationId tariff_getAll
     * @tags Price & Invoice & Tariffs
     *
     * @description get all Tariffs - required for every else action in system. For example: Project is created under the Product which is under some Tariff
     *
     *
     * @returns {ITariff[]} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public tariffsGetAll():Promise<ITariff[]> {
        return this.requestRestPath("GET", `/tariff`, {}, [200], 'application/json');
    }

    /**
     * @name websocketGetAccessToken
     * @summary get Websocket Access Token
     * @operationId get_Websocket_token
     * @tags Access, WebSocket
     *
     * @description For connection to websocket, you have to connect with temporary unique token. This Api return Tokenwith a maximum lifetime of 5 seconds. After the token is deactivated. After logging in, or the connectionlost is token deactivated also.
     *
     *
     * @returns {IWebSocketToken} [code 200] Token successfully generated
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public websocketGetAccessToken():Promise<IWebSocketToken> {
        return this.requestRestPath("GET", `/websocket/access_token`, {}, [200], 'application/json');
    }

    /**
     * @name widgetCreate
     * @summary create Widget
     * @operationId widget_create
     * @tags Widget
     *
     * @description creating new independent Widget object for Grid tools
     *
     * @param {INameAndDescProjectIdOptional} body - Contains Json with values
     *
     * @returns {IWidget} [code 201] Successfully created
     *
     * @throws {IResultBadRequest} [code 400] Something went wrong
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetCreate(body:INameAndDescProjectIdOptional):Promise<IWidget> {
        return this.requestRestPath("POST", `/widget`, body, [201], 'application/json');
    }

    /**
     * @name widgetDelete
     * @summary delete Widget
     * @operationId widget_delete
     * @tags Widget
     *
     * @description delete Widget
     *
     * @param {string} grid_widget_id - widget_id String path
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetDelete(grid_widget_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/widget/${grid_widget_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetEdit
     * @summary edit Widget
     * @operationId widget_update
     * @tags Widget
     *
     * @description update basic information (name, and description) of the independent GridWidget
     *
     * @param {string} grid_widget_id - widget_id String path
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IWidget} [code 200] Ok Result
     *
     * @throws {IResultInvalidBody} [code 400] Invalid body
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetEdit(grid_widget_id:string, body:INameAndDescription):Promise<IWidget> {
        return this.requestRestPath("PUT", `/widget/${grid_widget_id}`, body, [200], 'application/json');
    }

    /**
     * @name widgetGet
     * @summary get Widget
     * @operationId widget_get
     * @tags Widget
     *
     * @description get independent Widget object
     *
     * @param {string} grid_widget_id - widget_id String path
     *
     * @returns {IWidget} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetGet(grid_widget_id:string):Promise<IWidget> {
        return this.requestRestPath("GET", `/widget/${grid_widget_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetGetListByFilter
     * @summary get Widget List by Filter
     * @operationId widget_getByFilter
     * @tags Grid-Widget
     *
     * @description get GridWidget List
     *
     * @param {IGridWidgetFilter} body - Contains Json with values
     *
     * @returns {IGridWidgetList} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetGetListByFilter(body:IGridWidgetFilter):Promise<IGridWidgetList> {
        return this.requestRestPath("PUT", `/widgets`, body, [200], 'application/json');
    }

    /**
     * @name widgetMakeClone
     * @summary make_Clone Widget
     * @operationId widget_clone
     * @tags Grid_Widget
     *
     * @description clone Grid_Widget for private
     *
     * @param {IWidgetCopy} body - Contains Json with values
     *
     * @returns {IWidget} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetMakeClone(body:IWidgetCopy):Promise<IWidget> {
        return this.requestRestPath("POST", `/widget/make_clone`, body, [200], 'application/json');
    }

    /**
     * @name widgetVersionCreate
     * @summary create Widget_Version
     * @operationId widget_version_create
     * @tags Grid-Widget
     *
     * @description new GridWidget version
     *
     * @param {string} grid_widget_id - widget_id String path
     * @param {IGridWidgetVersionNew} body - Contains Json with values
     *
     * @returns {IWidgetVersion} [code 201] Successfully created
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionCreate(grid_widget_id:string, body:IGridWidgetVersionNew):Promise<IWidgetVersion> {
        return this.requestRestPath("POST", `/widget/version/${grid_widget_id}`, body, [201], 'application/json');
    }

    /**
     * @name widgetVersionDelete
     * @summary delete Widget_Version
     * @operationId widget_version_delete
     * @tags Grid-Widget
     *
     * @description delete GridWidget version
     *
     * @param {string} grid_widget_version_id - version_id String path
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionDelete(grid_widget_version_id:string):Promise<IResultOk> {
        return this.requestRestPath("DELETE", `/widget/version/${grid_widget_version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetVersionEdit
     * @summary edit Widget_Version
     * @operationId widget_version_edit
     * @tags Grid-Widget
     *
     * @description You can edit only basic information of the version. If you want to update the code, you have to create a new version!
     *
     * @param {string} grid_widget_version_id - version_id String path
     * @param {INameAndDescription} body - Contains Json with values
     *
     * @returns {IWidgetVersion} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionEdit(grid_widget_version_id:string, body:INameAndDescription):Promise<IWidgetVersion> {
        return this.requestRestPath("PUT", `/widget/version/${grid_widget_version_id}`, body, [200], 'application/json');
    }

    /**
     * @name widgetVersionEditResponsePublication
     * @summary edit Widget_Version Response publication
     * @operationId widget_version_public_response
     * @tags Admin-Grid-Widget
     *
     * @description sets Approval_state to pending
     *
     * @param {ICommunityVersionPublishResponse} body - Contains Json with values
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionEditResponsePublication(body:ICommunityVersionPublishResponse):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/widget/approval`, body, [200], 'application/json');
    }

    /**
     * @name widgetVersionGet
     * @summary get Widget_Version
     * @operationId widget_version_get
     * @tags Grid-Widget
     *
     * @description get version (content) from independent GridWidget
     *
     * @param {string} grid_widget_version_id - version_id String path
     *
     * @returns {IWidgetVersion} [code 200] Ok Result
     *
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionGet(grid_widget_version_id:string):Promise<IWidgetVersion> {
        return this.requestRestPath("GET", `/widget/version/${grid_widget_version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetVersionMakePublic
     * @summary make Widget_Version public
     * @operationId widget_version_make_public
     * @tags C_Program
     *
     * @description Make C_Program public, so other users can see it and use it. Attention! Attention! Attention! A user can publish only three programs at the stage waiting for approval.
     *
     * @param {string} grid_widget_version_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultBadRequest} [code 400] Bad Request
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultFound} [code 404] Object not found
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionMakePublic(grid_widget_version_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/widget/version/make_public/${grid_widget_version_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetVersionSetAsMain
     * @summary set_As_Main Widget_Version
     * @operationId widget_version_setMain
     * @tags Admin-Grid-Widget
     *
     * @param {string} grid_widget_id
     *
     * @returns {IResultOk} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionSetAsMain(grid_widget_id:string):Promise<IResultOk> {
        return this.requestRestPath("PUT", `/admin/widget/version/set_main/${grid_widget_id}`, {}, [200], 'application/json');
    }

    /**
     * @name widgetVersionsGet
     * @summary get Widget_Versions
     * @operationId widget_version_getAll
     * @tags Grid-Widget
     *
     * @description get all versions (content) from independent GridWidget
     *
     * @param {string} grid_widget_id - widget_id String path
     * @param {IGridWidgetVersionNew} body - Contains Json with values
     *
     * @returns {IWidgetVersion[]} [code 200] Ok Result
     *
     * @throws {IResultFound} [code 400] Object not found
     * @throws {IUnauthorized} [code 401] Unauthorized request
     * @throws {IResultForbidden} [code 403] Need required permission
     * @throws {IResultInternalServerError} [code 500] Server side Error
     */
    public widgetVersionsGet(grid_widget_id:string, body:IGridWidgetVersionNew):Promise<IWidgetVersion[]> {
        return this.requestRestPath("GET", `/widget/version/all/${grid_widget_id}`, body, [200], 'application/json');
    }

}
