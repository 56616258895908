/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */
import { Component, Input, Output, OnInit, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { NotificationService } from '../services/NotificationService';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormSelectComponentOption } from './FormSelectComponent';
import { AbstractControl, FormBuilder } from '@angular/forms';

@Component({
    selector: 'bk-filter-page-component',
    /* tslint:disable */
    template: `
        <div *ngIf="!onDashboard">
            <br>
            <br>
        </div>

        <div class="row" *ngIf="totalPages > 1 || to > 25">
            <div [class.col]="!onDashboard"
                 [class.col-lg-3]="!onDashboard"
                 [class.text-left]="!onDashboard"
                 [class.text-center]="onDashboard">
                <div class="dataTables_info" style="margin-left: 30px; margin-top: 10px;" role="status"
                     aria-live="polite">Showing {{from == 0 ? 1 : from }} to {{to}} of {{total}} entries
                </div>
            </div>
            <div class="col col-lg-6 text-center pagination-centered">
                <div class="dataTables_paginate paging_bootstrap_number" *ngIf="showPagesSquares">
                    <ul class="pagination" style="visibility: visible;">

                        <li *ngIf="totalPages > 10" [class.disabled]="selectedPage <= 1">
                            <a title="First"
                               (click)="onPageClick(1)">
                                <i class="fa fa-angle-double-left"></i></a>
                        </li>

                        <li (click)="onPageClick(selectedPage)"
                            class="prev" [class.disabled]="selectedPage <= 1"><a title="Prev">
                            <i class="fa fa-angle-left"></i></a></li>

                        <li *ngFor="let page of shownPageList"
                            class="mt-element-ribbon bg-grey-steel"
                            [class.active]="selectedPage == page">
                            <a (click)="onPageClick(page)" [class.page-filter-links]="selectedPage == page">{{page}}</a>
                        </li>

                        <li class="next" [class.disabled]="(totalPages) <= selectedPage"><a title="Next" (click)="onPageClick(selectedPage+1)"><i
                            class="fa fa-angle-right"></i></a></li>

                        <li *ngIf="totalPages > 10" [class.disabled]="(totalPages) <= selectedPage">
                            <a title="Last"
                               (click)="onPageClick(totalPages)">
                               <i class="fa fa-angle-double-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col col-lg-2">
                <bk-form-select
                    *ngIf="control"
                    [control]="control"
                    [options]="optionListForSelector"
                    [selectedValue]="defaultIndex"
                    (valueChanged)="refreshPage(1)">
                </bk-form-select>
            </div>
            <div class="col col-lg-1"></div>
        </div>

    `
    /* tslint:enable */
})
export class FilterPagerComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    from: number = 1;

    @Input()
    to: number = 25;

    @Input()
    total: number = 25;

    @Input()
    totalPages: number = 0;

    // Show page pagination
    @Input()
    showPagesSquares: boolean = true;

    // Just cosmetic parameter
    @Input()
    onDashboard: boolean = false;

    // Control with page on counter - optional
    @Input()
    control: AbstractControl = null;

    // Optional List Selector
    @Input()
    optionListForSelector: FormSelectComponentOption[] = [{value: '25', label: '25'},  {value: '50', label: '50'},  {value: '100', label: '100'}];

    @Input()
    defaultIndex?: number = 25;

    @Input()
    refreshPage: (page: number) => void;

    @Input()
    set externalPageRefresh(page: {newValue: number}) {
        if (page && page.newValue) {
            this.selectedPage = page.newValue;
        }
    }

    @Output()
    onSelect: EventEmitter<number> = new EventEmitter<number>();

    selectedPage: number = 1;

    // Visible pages in shifting page
    shownPageList: number[] = [];
    queryParamsSubscription: Subscription;


    constructor(public notificationService: NotificationService, protected activatedRoute: ActivatedRoute, protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.queryParamsSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            if (params.has('page')) {
                try {
                    this.selectedPage = parseInt(params.get('page'), 10);
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }

    ngOnChanges(): void {
        if (this.selectedPage ) {
            this.shiftingPages(this.selectedPage);
        }
    }

    ngOnDestroy(): void {
        this.queryParamsSubscription.unsubscribe();
    }

    onPageClick(index: number) {
        if ((this.totalPages + 1) <= index || index < 1) {
            return;
        }
        this.onSelect.emit(index);
        this.selectedPage = index;

        if (this.refreshPage) {
            this.refreshPage(index);
        }

        // Activate page shifting if there are more then 10 pages
        if (this.totalPages > 10) {
            this.shiftingPages(this.selectedPage);
        }
    }

    shiftingPages(position: number) {
        // Reset shown pages
        this.shownPageList = [];

        const lastShift  = Math.max(5 - position, 0);
        const lastPage = Math.min(position + lastShift + 5, this.totalPages);
        const firstPage = Math.max(lastPage - 9, 1);

        for (let i = firstPage; i <= lastPage; i++) {
            this.shownPageList.push(i);
        }
    }

}
