import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { ModalModel } from '../services/ModalService';
import { TranslationService } from '../services/TranslationService';
import { IInstanceSnapshot } from '../backend/TyrionAPI';


export class ModalsInstanceStartModel extends ModalModel {

    public clickedButton: string = null;

    constructor(
        public allowUpdates: boolean = false,
        public snapshot?: IInstanceSnapshot
    ) {
        super();
    }
}

@Component({
    selector: 'bk-modals-instance-start',
    template: require('./instance-start.html')
})
export class ModalsInstanceStartComponent implements OnInit {

    @Input()
    modalModel: ModalsInstanceStartModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    constructor(private translationService: TranslationService) {

    }

    ngOnInit(): void {

    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onYesClick(): void {
        this.modalClose.emit(true);
    }

    onNoClick(): void {
        this.modalClose.emit(false);
    }

    onCheck(): void {
        this.modalModel.allowUpdates = !this.modalModel.allowUpdates;
    }

    allowChanged(allow: boolean) {
        this.modalModel.allowUpdates = allow;
    }

    translate(key: string, ...args: any[]): string {
        return this.translationService.translate(key, this, null, args);
    }
}
