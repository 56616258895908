/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Input, Output, EventEmitter, Component, OnInit, Injector } from '@angular/core';
import { ModalModel } from '../services/ModalService';
import { _BaseMainComponent } from '../views/_BaseMainComponent';
import { IParameterConfig } from '../backend/TyrionAPI';
import { FormControl, Validators } from '@angular/forms';

export interface ParameterControl {
    key: string;
    type: string;
    disabled: FormControl;
    default_value: FormControl;
    min: FormControl;
    max: FormControl;
    allowed_values: FormControl;
}

export class ModalsHardwareBatchDefaultsModel extends ModalModel {

    public modalLarge = true;
    public modalWide = true;
    public parameters: Array<IParameterConfig>;

    constructor() {
        super();
    }
}

@Component({
    selector: 'bk-modals-hardware-batch-defaults',
    template: require('./hardware-batch-defaults.html')
})
export class ModalsHardwareBatchDefaultsComponent extends _BaseMainComponent implements OnInit {

    @Input()
    modalModel: ModalsHardwareBatchDefaultsModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    parameters: Array<ParameterControl> = null;

    constructor(injector: Injector) {
        super(injector);
    };

    ngOnInit(): void {
        if (this.modalModel.parameters && this.modalModel.parameters.length) {
            this.parameters = this.convert(this.modalModel.parameters);
        }
        if (!this.parameters || !this.parameters.length) {
            this.tyrionBackendService.hardwareBatchGetConfigDefaults()
                .then(defaults => {
                    this.parameters = this.convert(defaults.parameters)
                })
                .catch();
        }
    }

    convert(parameters: Array<IParameterConfig>): Array<ParameterControl> {
        return parameters.map(p => {
            return {
                key: p.key,
                type: p.type,
                disabled: this.formBuilder.control(p.disabled, [Validators.required]),
                default_value: this.formBuilder.control(p.default_value),
                min: this.formBuilder.control(this.falsyValueToNull(p.min), [Validators.pattern(/[0-9]*/)]),
                max: this.formBuilder.control(this.falsyValueToNull(p.max), [Validators.pattern(/[0-9]*/)]),
                allowed_values: this.formBuilder.control(this.falsyValueToNull(p.allowed_values ? p.allowed_values.toString() : null)),
            }
        })
    }

    falsyValueToNull(value) {
        return value ? value : null;
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onSaveClick(): void {
        this.modalModel.parameters = this.parameters.map(p => {
            let default_value;
            switch (p.type) {
                case 'boolean': {
                    default_value = !!p.default_value.value;
                    break;
                }
                case 'number': {
                    default_value = parseInt(p.default_value.value, 10);
                    break;
                }
                case 'string': {
                    default_value = p.default_value.value;
                    break;
                }
            }
            return {
                key: <any>p.key,
                type: p.type,
                disabled: p.disabled.value,
                default_value,
                min: p.min.value,
                max: p.max.value,
                allowed_values: p.allowed_values.value ? p.allowed_values.value.trim().split(',').map(v => v.trim()) : null
            }
        });
        this.modalClose.emit(true);
    }
}
