import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidatorErrorsService } from '../services/ValidatorErrorsService';

@Component({
    selector: 'bk-time-picker',
    template: require('./TimePickerComponent.html')
})
export class TimePickerComponent {

    @Input()
    set control(control: AbstractControl) {
        this.timeControl = control;
        const value: string = control.value;
        if (value.length > 0) {
            const slitted = value.split(':');
            this.hour = slitted[0];
            this.minute = slitted[1];
        }
    }

    @Input()
    set defualtTime(defaultTime: TimeHourMinute) {
        if (defaultTime !== null) {
            this.hour = this.addZero(defaultTime.hour);
            this.minute = this.addZero(defaultTime.minute);
        }
    }
    currentdate = new Date();

    @Input()
    label: string = '';

    @Input()
    labelEnabled: boolean = true;

    @Input()
    placeholder: string = this.currentdate.getHours() + ':' + (this.currentdate.getMinutes());

    @Input()
    readonly: boolean = false;

    @Input()
    waitForTouch: boolean = true;

    @Input()
    minuteStep: number = 1;

    @Output()
    dateTimeChanged: EventEmitter<TimeHourMinute> = new EventEmitter();

    @Output()
    onEnterEvent: EventEmitter<any> = new EventEmitter();

    timeControl: AbstractControl;

    open: boolean = false;

    minute: string = '' + (this.currentdate.getMinutes() + 2);

    hour: string = '' + this.currentdate.getHours();

    @ViewChild('timePicker') timePicker: ElementRef;

    // Close table with clocks if it's clicked outside time picker component
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.timePicker.nativeElement.contains(event.target)) {
            this.open = false;
        }
    }

    changeOpen() {
        this.open = !this.open;
    }


    OnOutsideClick(event: any) {
        if (event.value && this.open) {
            this.open = !this.open;
        }
    }

    addZero(num: number): string {
        return ('0' + num).slice(-2);
    }

    updateControl() {
        this.timeControl.setValue(this.hour + ':' + this.minute);
        this.dateTimeChanged.emit({
            hour: Number(this.hour),
            minute: Number(this.minute)
        });
    }

    incrementHour() {
        let h = Number(this.hour);
        h++;
        if (h > 23) {
            h = 0;
        }

        this.hour = this.addZero(h);
        this.updateControl();
    }
    incrementMinute() {

        let m = Number(this.minute);
        m += this.minuteStep;

        if (m > 59) {
            m = m - 60;
            this.incrementHour();

        }
        this.minute = this.addZero(m);
        this.updateControl();


    }
    decrementHour() {
        let h = Number(this.hour);
        h--;
        if (h < 0) {
            h = 23;
        }

        this.hour = this.addZero(h);
        this.updateControl();


    }
    decrementMinute() {
        let m = Number(this.minute);
        m--;
        if (m < 0) {
            m = 59;
            this.decrementHour();
            this.updateControl();

        }

        this.minute = this.addZero(m);

    }

    onChanged() {

    }
    constructor(public validatorErrorsService: ValidatorErrorsService) {

    }

    onKeyDownEvent(event) {
        if (event.keyCode === 13) {
            this.onEnterEvent.emit(event);
        }
    }

}

export class TimeHourMinute {
    hour: number;
    minute: number;
}
