import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { _BaseMainComponent } from '../views/_BaseMainComponent';

@Component({
    selector: 'bk-dashboard-element',
    template: require('./DashboardElementComponent.html'),
})

export class DashboardElementComponent  extends _BaseMainComponent {

    @Input()
    section: ('hardware' | 'instance' | 'server' | 'code' | 'blocko' | 'grid');

    @Input()
    header_label: string;

    @Input()
    header_icon: string;

    @Input()
    filter_item: any;

    @Input()
    filter_item_content: any[] = null;

    @Input()
    table_name: string;

    @Input()
    main_message_link: string;

    @Input()
    main_message_comment_link: string;

    @Output()
    navigateTo: EventEmitter<string> = new EventEmitter<string>();

    constructor(injector: Injector) {
        super(injector);
    }

    onHeaderClick() {
        this.navigateTo.emit(this.section);
    }
}
