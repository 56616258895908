/**
 * Created by Tomas Kupcek on 25.01.2017.
 */

import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IProducer, IHardwareType } from '../backend/TyrionAPI';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { IError } from '../services/_backend_class/Responses';

@Component({
    selector: 'bk-view-producers',
    template: require('./producers-producer.html')
})
export class ProducersProducerComponent extends _BaseMainComponent implements OnInit, OnDestroy {

    routeParamsSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    producerId: string;
    producer: IProducer = null;

    devices: IHardwareType[] = null;

    currentParamsService: CurrentParamsService; // exposed for template - filled by _BaseMainComponent

    constructor(injector: Injector) {
        super(injector);
    };

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.producerId = params['producer'];
            this.refresh();
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    refresh(): void {
        this.blockUI();
        this.tyrionBackendService.producerGet(this.producerId)
            .then((producer) => {
                this.producer = producer;
                return this.tyrionBackendService.hardwareTypesGetAll();
            })
            .then((devices) => {
                for (let i in devices) {
                    if (devices[i].producer.id !== this.producerId) {
                        devices.splice(parseInt(i, 10));
                    }
                }
                this.devices = devices;
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
        this.unblockUI();
    }
}
