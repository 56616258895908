import { AfterViewInit, Component, Input, OnDestroy, OnInit, Injector } from '@angular/core';
const uuidv1 = require('uuid/v1');
// @ts-ignore
import * as am4core from '@amcharts/amcharts4/core';
// @ts-ignore
import * as am4charts from '@amcharts/amcharts4/charts';
// @ts-ignore
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
import { _BaseMainComponent } from '../views/_BaseMainComponent';
import { UnixTimeFormatPipe } from '../pipes/UnixTimeFormatPipe';


export interface DataValuesGraph {
    _id: number;
    data: DataValuesSingleDate[];
}

export interface DataValuesSingleDate {
    time: number;
    value: number;
}


export interface DataUsageWithFilter {
    dataToShow: DataValuesGraph[];
    startDate: number;
    endDate: number;
    granularity: am4core.ITimeInterval;
}

@Component({
    selector: 'bk-gsm-usage-chart',
    template: require('./DataUsageChartComponent.html')
})
export class DataUsageChartComponent extends _BaseMainComponent implements OnInit, AfterViewInit, OnDestroy {


    @Input() loading$: boolean;
    @Input() showInPortlet: boolean = true;
    @Input() title: string = 'AmChartComponent_Title';
    @Input() showTitle: boolean = true;
    @Input() showHeadTools: boolean = true;
    @Input() title_tooltip: string = null;

    @Input() parserValue?: (val: number) => string;

    @Input() interval?: number;
    @Input() toolTipInGraph?: boolean = true;
    @Input() highlightFrom?: number;
    @Input() highlightTo?: number;
    @Input() set dataToShow(dataToShow: DataUsageWithFilter) {
        // console.log('dataToShow:');
        // console.log(dataToShow);
        if (this.chart) {
            this.setupSeries(dataToShow);
        } else if (dataToShow) {
            this.savedData = dataToShow;
        }
    }

    /* Service variables  */
    chartId: string = uuidv1();
    series: am4charts.Series[];
    name: string;
    logo: HTMLImageElement;
    dateAxis: am4charts.DateAxis;
    /* Operation variables */

    savedData: DataUsageWithFilter;

    colors: string[] = ['#ff0e1b', '#1fb2ff', '#ffbf09', '#57ff31', '#27ebff', '#fff815', '#737111', '#ff04fe', '#beff5b'];
    colorPointer: number = 0;

    private chart: am4charts.XYChart;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit () {
    }

    // events
    chartClicked (e: any): void {
    }

    chartHovered (e: any): void {
    }


    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            const chart = am4core.create(this.chartId, am4charts.XYChart);
            chart.paddingRight = 20;
            chart.exporting.timeoutDelay = 10000;


            this.dateAxis = new am4charts.DateAxis();
            this.dateAxis.title.text = this.translationService.translate('label_date_axis', this);
            chart.xAxes.push(this.dateAxis);


            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = this.translationService.translate('label_value_axis', this);
            valueAxis.numberFormatter.numberFormat = '#.0b';


            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.minWidth = 35;
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = this.dateAxis;
            chart.legend = new am4charts.Legend();

            // this.scrollbarX = new am4charts.XYChartScrollbar();
            // chart.scrollbarX = this.scrollbarX;

            this.chart = chart;
            if (this.savedData) {
                this.setupSeries(this.savedData);
            }
        });
    }

    setupSeries(data: DataUsageWithFilter) {

        this.colorPointer = 0;
        this.savedData = data;
        if (!data) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.series.clear();

                this.chart.data = [];
                this.dateAxis.baseInterval = data.granularity;

                this.dateAxis.min = data.startDate;
                this.dateAxis.max = data.endDate;

                const series = data.dataToShow.map((dataValue: DataValuesGraph) => {

                    const serie = new am4charts.ColumnSeries();
                    serie.name = 'MSI Number: ' + dataValue._id;
                    serie.stacked = true;
                    serie.dataFields.dateX = 'time';
                    serie.dataFields.valueY = '' + dataValue._id;
                    serie.fill =  am4core.color(this.colors[this.colorPointer]);
                    serie.stroke =  am4core.color(this.colors[this.colorPointer]);
                    this.incrementcolorPointer();
                    if (this.toolTipInGraph) {
                        serie.tooltipHTML = '{' + dataValue._id + '}' +
                            '<br>' +
                            '<small>{time}</small>';
                    }

                    dataValue.data.forEach(x => {
                        let index = this.chart.data.findIndex(t => t.time.getTime() === this.getTime(x.time).getTime());
                        if (index > -1) {
                            this.chart.data[index][dataValue._id] = x.value;
                        } else {
                            let dataObject = {time: this.getTime(x.time)};
                            dataObject[dataValue._id] = x.value;
                            this.chart.data.push(dataObject);
                        }
                    });



                    return serie;
                });
                this.chart.series.pushAll(series);
                // this.scrollbarX.series.pushAll(series);
                this.chart.validateData();
            }
        });
    }

    private getTime(time: number): Date {
        if (time < 1000000000000) {
            return new Date(time * 1000);
        } else {
            return new Date(time);
        }
    }

    private getReadableTime(time: number) {
        if (time < 1000000000000) {
            return new UnixTimeFormatPipe().transform(time, 'LLLL');
        }
        return new UnixTimeFormatPipe().transform(time / 1000, 'LLLL');
    }

    private getRoundValue(x: number) {
        if (this.parserValue) {
            return this.parserValue(x);
        } else {
            return x.toString();
        }
    }


    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }


    incrementcolorPointer() {
        this.colorPointer++;
        if (this.colorPointer >= this.colors.length) {
            this.colorPointer = 0;
        }
    }
}
