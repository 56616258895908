/**
 * Created by Alexandr Tylš on 08.03.18.
 */
/*
 * © 2015-2016 Becki Authors. See the AUTHORS file found in the top-level
 * directory of this distribution.
 */

import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalModel } from '../services/ModalService';
import { FormSelectComponentOption } from '../components/FormSelectComponent';

export class ModalsSelectModel extends ModalModel {
    selected: string = null;
    constructor(
        public options: Array<FormSelectComponentOption> = []
    ) {
        super();
    }
}

@Component({
    selector: 'bk-modals-select',
    template: require('./select.html')
})
export class ModalsSelectComponent implements OnInit {

    @Input()
    modalModel: ModalsSelectModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    errorMessage: string = null;

    form: FormGroup;

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            value: ['', [Validators.required]]
        });
    }

    constructor(private formBuilder: FormBuilder) {
    }

    onValueChanged(value: string) {
        this.modalModel.selected = value;
    }

    onSubmitClick(): void {
        if (!this.modalModel.selected) {
            this.errorMessage = 'There is no value selected.'; // this.translationService.translate('label_no_version_selected', this);
        } else {
            this.modalClose.emit(true);
        }
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
