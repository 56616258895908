/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Component, Injector, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { IApplicableProduct, IArticle, IArticleList, IInstanceList, IProject, IProjectFilter, IProjectList } from '../backend/TyrionAPI';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlashMessageSuccess } from '../services/NotificationService';
import { ModalsArticleModel } from '../modals/article';
import { ModalsRemovalModel } from '../modals/removal';
import { ModalsProjectPropertiesModel } from '../modals/project-properties';
import { TyrionBackendService } from '../services/BackendService';
import { IError } from '../services/_backend_class/Responses';

@Component({
    selector: 'bk-view-dashboard',
    template: require('./dashboard.html')
})
export class DashboardComponent extends _BaseMainComponent implements OnInit, OnDestroy, AfterViewInit {

    tab: string = 'general';
    projects: IProjectList = null;
    instanceFilter: IInstanceList = null;

    ngUnsubscribe = new Subject<void>();

    constructor(injector: Injector, public backendService: TyrionBackendService) {
        super(injector);
    };

    ngOnInit(): void {

        this.tyrionBackendService.objectUpdateTyrionEcho.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
            if (status.model === 'ProjectsRefreshAfterInvite') {
                this.projectRefresh();
            }
        });

        this.projectRefresh();
        this.instanceRefresh();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onToggleTab(tab: string) {
        this.tab = tab;
    }

    projectRefresh(): void {
        this.tyrionBackendService.projectsGetListByFilter(<IProjectFilter>{
            count_on_page: 15,
            order_by: 'NAME',
            order_schema: 'ASC',
            page_number: 0
        })
            .then((projects: IProjectList) => {
                this.projects = projects;
            });
    }

    instanceRefresh(): void {
        this.tyrionBackendService.instanceGetByFilter(<IProjectFilter>{
            count_on_page: 15,
            order_by: 'NAME',
            order_schema: 'ASC',
            page_number: 0
        })
            .then((instanceFilter: IInstanceList) => {
                this.instanceFilter = instanceFilter;
            });
    }

    onDropDownEmitter(action: string, project: IProject): void {
        if (action === 'add_hardware') {
            this.navigate(this.onScanHardwareClick(project.id));
        }
    }
}




