import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { _BaseMainComponent } from '../views/_BaseMainComponent';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { IHardwareUpdateEvent } from '../backend/TyrionAPI';
const uuidv1 = require('uuid/v1');

@Component({
    selector: 'bk-hardware-update-chart',
    template: `
        <div>
            <ng-container>

                <br>

                <div class="m-widget3" [hidden]="savedData && savedData.length === 0">
                    <div id="{{chartId}}" style="width: 100%; height: 500px"></div>
                </div>

                <bk-nothing-to-show
                    [condition_loading]="!updateId"
                    [condition_empty]="savedData && savedData.length === 0"
                    [main_message_link]="'label_no_update_data'|bkTranslate:this"
                    [show_button]="false"
                    [main_message_comment_link]="''">
                </bk-nothing-to-show>


                <div class="text-center">
                    <bk-drob-down-button
                        [btns]="[
                            {
                                condition: true,
                                btn_label_for_person: ('label_refresh_chart'|bkTranslate:this),
                                btn_tag: 'refresh_chart',
                                icon: 'fa-refresh',
                                colorType: 'UPDATE',
                                permission: true,
                                onClick: refreshData.bind(this)
                            }
                    ]">
                    </bk-drob-down-button>
                </div>
            </ng-container>
        </div>

     `
})
export class HardwareUpdateChartComponent extends _BaseMainComponent implements OnInit, AfterViewInit, OnDestroy {


    @Input() loading$: boolean;
    @Input() showInPortlet: boolean = true;
    @Input() title: string = 'AmChartComponent_Title';
    @Input() showTitle: boolean = true;
    @Input() showHeadTools: boolean = true;
    @Input() title_tooltip: string = null;

    @Input() parserValue?: (val: number) => string;

    @Input() startDate?: number;
    @Input() endDate?: number;
    @Input() interval?: number;
    @Input() toolTipInGraph?: boolean = true;
    @Input() highlightFrom?: number;
    @Input() highlightTo?: number;

    @Input() hardwareId: string;
    @Input() trackingId: string;
    @Input() updateId: string;


    chartId: string = uuidv1();

    /* Service variables  */
    series: am4charts.Series[];
    scrollbarX: am4charts.XYChartScrollbar;
    name: string;
    logo: HTMLImageElement;
    /* Operation variables */

    savedData: IHardwareUpdateEvent[];   // TODO toto by mělo být pole polí s HardwareShort..

    colors = {
        PHASE_CREATING_ACTION:  '#57ff31',
        PHASE_WAITING: '#1fb2ff',
        PHASE_FLASH_ERASING: '#ffbf09',
        PHASE_FLASH_ERASED: '#ce9003',
        PHASE_PING_TEST: '#916502',
        PHASE_UPLOAD_START:  '#3ca133',
        PHASE_UPLOADING:  '#1fb2ff',
        PHASE_UPLOAD_DONE:  '#3ca133',
        PHASE_UPDATE_STARTED:  '#ff04fe',
        PHASE_RESTARTING:  '#beff5b',
        PHASE_CONNECTED_AFTER_RESTART:  '#1fb2ff',
        PHASE_WAITING_IN_LOWPAN_QUEUE:  '#1fb2ff',
        PHASE_UPDATE_DONE: '#57ff31',

        PHASE_CONNECTED_AFTER_RESTART_BACKUP_RESTORE: '#ff0e1b',
        PHASE_UPDATE_FAIL: '#ff0e1b',
        PHASE_UPDATE_CANCEL: '#ff0e1b',
        PHASE_HARDWARE_OFFLINE: '#ff0e1b',
    };

    private chart: am4charts.XYChart;
    private valueAxis: am4charts.ValueAxis;
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit () {
    }

    // events
    chartClicked (e: any): void {
    }

    chartHovered (e: any): void {
    }

    refreshData = (): void => {
        this.tyrionBackendService.hardwareUpdateProgressGet(
            {
                tracking_id: this.trackingId,
                ioda_id: this.hardwareId,
                page_number: 1
            }
        ).then((x: IHardwareUpdateEvent[]) => {
            this.savedData = x;
            this.setupSeries(x);
            this.unblockUI();
        }).catch(e => {
            this.fmError(e)
        });

    }


    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            const chart = am4core.create(this.chartId, am4charts.XYChart);
            chart.paddingRight = 20;
            chart.exporting.timeoutDelay = 10000;


            const dAxis = new am4charts.CategoryAxis();
            dAxis.title.text = this.translationService.translate('label_date_axis', this);
            chart.xAxes.push(dAxis);
            dAxis.dataFields.category = 'timestamp';

            this.valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            this.valueAxis.title.text = this.translationService.translate('label_value_axis', this);


            this.valueAxis.tooltip.disabled = true;
            this.valueAxis.renderer.minWidth = 35;
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dAxis;
            chart.legend = new am4charts.Legend();
            // dAxis.tooltipDateFormat = 'yyyy-MM-dd HH:mm:ss.SSS';


            this.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX = this.scrollbarX;

            this.chart = chart;

            this.refreshData();
        });
    }

    setupSeries(data: IHardwareUpdateEvent[]) {

        this.savedData = data;
        if (!data) {
            return;
        }


        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.series.clear();

                let mappedData = [];

                if (data.length > 0) {
                    this.valueAxis.max = data[data.length - 1].part + 10;
                    let startingTime = data[0].time_stamp;
                    let previousTime = data[0].time_stamp;
                    let lastPhase = '';
                    for (let i = 0; i < data.length; i++) {
                        let dataPoint = data[i];
                        mappedData.push({
                            part: dataPoint.part,
                            timestamp: dataPoint.time_stamp - startingTime,
                            delta: dataPoint.time_stamp - previousTime,
                            phase: dataPoint.phase,
                            bulletColor: this.colors[dataPoint.phase],
                            bulletHidden: dataPoint.phase === lastPhase
                        });
                        lastPhase = dataPoint.phase;
                        previousTime = dataPoint.time_stamp;
                    }
                }
                this.chart.data = mappedData;
                const serie = new am4charts.LineSeries();

                // TODO pomot něco takového serie.name = hardware.name ? hardware.name : hardware.id;
                serie.name = 'Progress';
                serie.dataFields.categoryX = 'timestamp';
                serie.dataFields.valueY = 'part';
                serie.tooltipHTML = 'part: {part}  <br> phase: {phase} <br> time: {timestamp}ms <br> delta: {delta}ms ';

                let bullet = serie.bullets.push(new am4charts.CircleBullet());
                bullet.circle.radius = 5;
                bullet.propertyFields.disabled = 'bulletHidden';
                bullet.propertyFields.fill = 'bulletColor';
                bullet.circle.dy = -3;
                this.chart.series.push(serie);
                // this.scrollbarX.series.pushAll(series);
                this.chart.validateData();
            }
        });
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }

}
