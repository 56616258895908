/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Input, Output, EventEmitter, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TyrionBackendService } from '../services/BackendService';
import { ModalModel } from '../services/ModalService';
import { IHardwareType } from '../backend/TyrionAPI';
import { MultiSelectComponent } from '../components/MultiSelectComponent';
import { NotificationService } from '../services/NotificationService';
import { FormSelectComponentOption } from '../components/FormSelectComponent';


export class ModalsCreateCompilationLibraryModel extends ModalModel {
    constructor(
        public description: string = '',
        public name: string = '',
        public changing_note: string = '',
        public version_identifier: string = '',
        public hardware_type_ids: Array<string> = []
    ) {
        super();
    }
}

@Component({
    selector: 'bk-modals-create-compilation-library',
    template: require('./create-compilation-library.html')
})
export class ModalsCreateCompilationLibraryComponent implements OnInit {

    @Input()
    modalModel: ModalsCreateCompilationLibraryModel;

    @Output()
    modalClose = new EventEmitter<boolean>();

    @ViewChild(MultiSelectComponent)
    multiSelect: MultiSelectComponent;

    form: FormGroup;

    availableTypes: Array<FormSelectComponentOption> = [];

    constructor(private backendService: TyrionBackendService, private formBuilder: FormBuilder, private notificationService: NotificationService) {

        this.form = this.formBuilder.group({
            'description': ['', [Validators.required, Validators.minLength(4)]],
            'name': ['', [Validators.required, Validators.minLength(4)]],
            'changing_note': ['', [Validators.required, Validators.minLength(4)]],
            'version_identifier': ['', [Validators.required]],
        });
    }

    ngOnInit() {

        (<FormControl>(this.form.controls['description'])).setValue(this.modalModel.description);
        (<FormControl>(this.form.controls['name'])).setValue(this.modalModel.name);
        (<FormControl>(this.form.controls['changing_note'])).setValue(this.modalModel.changing_note);
        (<FormControl>(this.form.controls['version_identifier'])).setValue(this.modalModel.version_identifier);

        this.backendService.hardwareTypesGetAll()
            .then((types: Array<IHardwareType>) => {
                this.availableTypes = types.map(type => {
                    let item = {
                        label: type.name,
                        value: type.id
                    };
                    if (this.modalModel.hardware_type_ids.indexOf(type.id) > -1) {
                        this.multiSelect.selectedItems.push(item)
                    }
                    return item;
                });
            })
            .catch(reason => {
                this.notificationService.fmError(reason);
            })

    }

    onSubmitClick(): void {
        this.modalModel.description = this.form.controls['description'].value;
        this.modalModel.name = this.form.controls['name'].value;
        this.modalModel.changing_note = this.form.controls['changing_note'].value;
        this.modalModel.version_identifier = this.form.controls['version_identifier'].value;
        this.modalModel.hardware_type_ids = this.multiSelect.selectedItems.map(i => i.value.toString());
        this.modalClose.emit(true);
    }

    onCloseClick(): void {
        this.modalClose.emit(false);
    }

    onCancelClick(): void {
        this.modalClose.emit(false);
    }
}
