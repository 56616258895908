import { TyrionApiBackend } from '../../backend/BeckiBackend';
import { WebSocketClientTyrion } from './WebSocketClientTyrion';
import { WebSocketClientHardware } from './WebSocketClientHardware';
import { WebSocketClientBlocko } from './WebSocketClientBlocko';

export class WebsocketService {

    protected tyrion: WebSocketClientTyrion;
    protected hardware: WebSocketClientHardware[] = [];
    protected instances: WebSocketClientBlocko[] = [];

    protected backend: TyrionApiBackend = null;

    public constructor(backend: TyrionApiBackend) {
        this.backend = backend;
    }

    /**
     * Return Tyrion Connection
     */
    public getTyrionConnection(): WebSocketClientTyrion {
        return this.tyrion;
    }

    /**
     * Return Tyrion Connection
     */
    public openTyrionConnection(url: string): void {
        if (!this.tyrion) {
            this.tyrion = new WebSocketClientTyrion(this.backend, url);
        }
        setImmediate(() => this.tyrion.connect());
    }

    public closeTyrionConnection(): void {
        if (typeof this.tyrion !== 'undefined') {
            this.tyrion.disconnect();
        }
    }

    // WebSocket Messages From Homer For HArdware Logger:
    public connectDeviceTerminalWebSocket(server_url: string, port: string, callback: (socket: WebSocketClientHardware, error: any) => void) {

        if (server_url === null) {
            callback(null, 'Missing server_url or port');
            return;
        }

        let url: string = port != null ?
            `${this.backend.wsProtocol}://${server_url}:${port}/?token=${TyrionApiBackend.getToken()}&authority_url=${encodeURIComponent(TyrionApiBackend.host)}` :
            `${this.backend.wsProtocol}://${server_url}/?token=${TyrionApiBackend.getToken()}&authority_url=${encodeURIComponent(TyrionApiBackend.host)}`;

        for (let hwSocket in this.hardware) {
            if (this.hardware.hasOwnProperty(hwSocket) && this.hardware[hwSocket].matchUrl(url)) {
                if (!this.hardware[hwSocket].isOpen()) {
                    this.hardware[hwSocket].connect();
                }
                callback(this.hardware[hwSocket], null);
                return;
            }
        }

        let socket: WebSocketClientHardware = new WebSocketClientHardware(url);
        this.hardware.push(socket);
        callback(socket, null);
    }

    // WebSocket Messages From Homer For Blocko Liveview:
    public connectBlockoInstanceWebSocket(server_url: string, port: string, instanceId: string, callback: (socket: WebSocketClientBlocko, error: any) => void) {

        let url: string = port != null ?
            `${this.backend.wsProtocol}://${server_url}:${port}/?token=${TyrionApiBackend.getToken()}&instance_id=${instanceId}&authority_url=${encodeURIComponent(TyrionApiBackend.host)}` :
            `${this.backend.wsProtocol}://${server_url}:${port}/?token=${TyrionApiBackend.getToken()}&instance_id=${instanceId}&authority_url=${encodeURIComponent(TyrionApiBackend.host)}`;


        /* Probably causes weird states
        for (let instanceSocket in this.instances) {
            if (this.instances.hasOwnProperty(instanceSocket) && this.instances[instanceSocket].matchUrl(url)) {
                if (!this.instances[instanceSocket].isOpen()) {
                    this.instances[instanceSocket].connect();
                }
                callback(this.instances[instanceSocket], null);
                return;
            }
        }*/

        let socket: WebSocketClientBlocko = new WebSocketClientBlocko(url, instanceId);
        // this.instances.push(socket);
        callback(socket, null);
    }
}
