/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { FlashMessageSuccess } from '../services/NotificationService';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalsRemovalModel } from '../modals/removal';
import { IGridWidgetList, IWidget } from '../backend/TyrionAPI';
import { ModalsWidgetsWidgetPropertiesModel } from '../modals/widgets-widget-properties';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { ModalsWidgetsWidgetCopyModel } from '../modals/widgets-widget-copy';
import { IError } from '../services/_backend_class/Responses';
import { FormGroup, Validators } from '@angular/forms';
import { BeckiValidators } from '../helpers/BeckiValidators';

@Component({
    selector: 'bk-view-projects-project-widgets-widgets',
    template: require('./projects-project-widgets.html'),
})
export class ProjectsProjectWidgetsComponent extends _BaseMainComponent implements OnInit, OnDestroy {

    projectId: string;

    routeParamsSubscription: Subscription;
    ngUnsubscribe = new Subject<void>();

    widgetList: IGridWidgetList = null;
    widgetPublicList: IGridWidgetList = null;
    widgetListNotApproved: IGridWidgetList = null;

    currentParamsService: CurrentParamsService; // exposed for template - filled by BaseMainComponent

    tab: string = 'my_widgets';

    pagePendingWidgets: number = 0;
    pagePrivateWidgets: number = 0;
    pagePublicWidgets: number = 0;

    formFilterPrivateWidgets: FormGroup;
    formFilterPublicWidgets: FormGroup;

    constructor(injector: Injector) {
        super(injector);

        this.formFilterPrivateWidgets = this.formBuilder.group({
            'name': ['', [Validators.maxLength(60), BeckiValidators.forbiddenSymbol(/[^\x20-\x7E]/g)]],
            'description': ['', [Validators.maxLength(60)]],
            'tags': [[], []],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });

        this.formFilterPublicWidgets = this.formBuilder.group({
            'name': ['', [Validators.maxLength(60), BeckiValidators.forbiddenSymbol(/[^\x20-\x7E]/g)]],
            'description': ['', [Validators.maxLength(60)]],
            'tags': [[], []],
            'orderBy': ['NAME', []],
            'order_schema': ['ASC', []],
        });
    };

    ngOnInit(): void {
        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.projectId = params['project'];

            if (this.projectId) {
                this.onShowProgramPrivateWidgetFilter();
            } else {
                this.tab = 'public_widgets';
                this.onShowProgramPublicWidgetFilter();
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onToggleTab(tab: string) {
        this.tab = tab;

        if (tab === 'my_widgets' && this.widgetList == null) {
            this.onShowProgramPrivateWidgetFilter();
        }

        if (tab === 'public_widgets' && this.widgetPublicList == null) {
            this.onShowProgramPublicWidgetFilter();
        }

        if (tab === 'admin_widgets' && this.widgetListNotApproved == null) {
            this.onShowProgramPendingWidgetFilter();
        }
    }

    onPortletClick(action: string): void {
        if (action === 'add_widget') {
            this.onWidgetAddClick();
        }
    }

    onMakeClone(widget: IWidget): void {
        let model = new ModalsWidgetsWidgetCopyModel(this.projectId, widget);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.widgetMakeClone({
                    widget_id: widget.id,
                    project_id: this.projectId,
                    name: model.widget.name,
                    description: model.widget.description,
                    tags: model.widget.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_copy_success')));
                        this.unblockUI();
                        this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                        this.tab = 'my_widgets';
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                        this.onShowProgramPublicWidgetFilter(this.pagePublicWidgets);
                        this.unblockUI();
                    });
            }
        });
    }

    onWidgetAddClick = () => {

        let model = new ModalsWidgetsWidgetPropertiesModel(this.projectId);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.widgetCreate({
                    project_id: this.projectId,
                    name: model.widget.name,
                    description: model.widget.description,
                    tags: model.widget.tags
                })
                    .then(widget => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_widget_add_success')));
                        this.unblockUI();
                        this.navigate(this.onWidgetClick(widget.id));
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                    });
            }
        });

    }

    onWidgetEditClick(widget: IWidget): void {
        let model = new ModalsWidgetsWidgetPropertiesModel(this.projectId, widget);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.widgetEdit(widget.id, {
                    name: model.widget.name,
                    description: model.widget.description,
                    tags: model.widget.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_widget_edit_success')));
                        if (widget.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                        } else {
                            this.onShowProgramPublicWidgetFilter(this.pagePublicWidgets);
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (widget.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                        } else {
                            this.onShowProgramPublicWidgetFilter(this.pagePublicWidgets);
                        }
                    });
            }
        });

    }

    onWidgetDeleteClick(widget: IWidget): void {

        this.modalService.showModal(new ModalsRemovalModel(widget.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.widgetDelete(widget.id)
                    .then(() => {

                        if (widget.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateWidgetFilter((this.widgetList.content.length === 1 && this.widgetList.pages > 1) ? this.pagePrivateWidgets - 1 : this.pagePrivateWidgets);
                        } else {
                            this.onShowProgramPublicWidgetFilter((this.widgetPublicList.content.length === 1 && this.widgetPublicList.pages > 1) ? this.pagePublicWidgets - 1 : this.pagePublicWidgets);
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (widget.publish_type === 'PRIVATE') {
                            this.onShowProgramPrivateWidgetFilter(this.pagePrivateWidgets);
                        } else {
                            this.onShowProgramPublicWidgetFilter(this.pagePublicWidgets);
                        }
                    });
            }
        });

    }


    onShowProgramPrivateWidgetFilter = (page?: number): void => {

        if (page) {
            this.pagePrivateWidgets = page;
            this.blockUI();
        }

        if (!this.formFilterPrivateWidgets.valid && this.formFilterPrivateWidgets.dirty) {
            return;
        }

        this.tyrionBackendService.widgetGetListByFilter( {
            project_id: this.projectId,
            name: this.formFilterPrivateWidgets.controls['name'].value,
            description: this.formFilterPrivateWidgets.controls['description'].value,
            tags: this.formFilterPrivateWidgets.controls['tags'].value,
            order_by: this.formFilterPrivateWidgets.controls['orderBy'].value,
            order_schema: this.formFilterPrivateWidgets.controls['order_schema'].value,
            page_number: this.pagePrivateWidgets
        })
            .then((list) => {
                this.widgetList = list;
                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onShowProgramPublicWidgetFilter = (page?: number): void => {
        if (page) {
            this.pagePublicWidgets = page;
            this.blockUI();
        }

        if (!this.formFilterPublicWidgets.valid && this.formFilterPublicWidgets.dirty) {
            return;
        }

        this.tyrionBackendService.widgetGetListByFilter({
            public_programs: true,
            name: this.formFilterPublicWidgets.controls['name'].value,
            description: this.formFilterPublicWidgets.controls['description'].value,
            tags: this.formFilterPublicWidgets.controls['tags'].value,
            order_by: this.formFilterPublicWidgets.controls['orderBy'].value,
            order_schema: this.formFilterPublicWidgets.controls['order_schema'].value,
            page_number: this.pagePublicWidgets
        })
            .then((list) => {
                this.widgetPublicList = list;
                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onShowProgramPendingWidgetFilter(page?: number): void {
        if (page) {
            this.pagePendingWidgets = page;
        }
        this.blockUI();
        this.tyrionBackendService.widgetGetListByFilter( {
            pending_widgets: true,       // For public its required
            page_number: this.pagePendingWidgets,
        })
            .then((list) => {
                this.widgetListNotApproved = list;
                this.unblockUI();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });
    }

    onDropDownEmitter(action: string, object: any): void {

        if (action === 'clone_widget') {
            this.onMakeClone(object);
        }

        if (action === 'edit_widget') {
            this.onWidgetEditClick(object);
        }

        if (action === 'remove_widget') {
            this.onWidgetDeleteClick(object);
        }

        if (action === 'make_decision') {
            this.navigate(this.onWidgetAdminClick(object.id));
        }
    }
}
