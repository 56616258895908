/**
 * © 2016 Becki Authors. See the AUTHORS file found in the top-level directory
 * of this distribution.
 */

import { Component, OnInit, Injector, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { _BaseMainComponent } from './_BaseMainComponent';
import { FlashMessageSuccess } from '../services/NotificationService';
import { Subject, Subscription } from 'rxjs';
import { CodeFile, CodeIDEComponent } from '../components/CodeIDEComponent';
import { ModalsConfirmModel } from '../modals/confirm';
import { ModalsVersionDialogModel } from '../modals/version-dialog';
import {
    ICProgram,
    ILibraryRecord,
    ICProgramVersion,
    IHardwareType,
    IHardware,
    ICProgramVersionRefresh,
    ILibraryVersion,
    ILibrary,
    ICloudCompilationServerCompilationResult,
    ICompilationBuildError, IBinaryDetails, ICompilationLibrary
} from '../backend/TyrionAPI';
import { CurrentParamsService } from '../services/CurrentParamsService';
import { NullSafe } from '../helpers/NullSafe';
import { ModalsSelectHardwareModel } from '../modals/select-hardware';
import { getAllInputOutputs } from '../helpers/CodeInterfaceHelpers';
import { ModalsCodeAddLibraryModel } from '../modals/code-add-library';
import { ModalsCodeLibraryVersionModel } from '../modals/code-library-version';
import { ModalsRemovalModel } from '../modals/removal';
import { ModalsCodePropertiesModel } from '../modals/code-properties';
import { ModalsSetAsMainModel } from '../modals/set-as-main';
import { ModalsPublicShareRequestModel } from '../modals/public-share-request';
import { ModalsPublicShareResponseModel } from '../modals/public-share-response';
import { ExitConfirmationService } from '../services/ExitConfirmationService';
import { FormSelectComponentOption } from '../components/FormSelectComponent';
import { CodeCompileError, IError } from '../services/_backend_class/Responses';
import { takeUntil } from 'rxjs/operators';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ModalsFileUploadModel } from '../modals/file-upload';

@Component({
    selector: 'bk-view-projects-project-code-code',
    template: require('./projects-project-code-code.html')
})
export class ProjectsProjectCodeCodeComponent extends _BaseMainComponent implements OnInit, OnDestroy, AfterViewInit {

    project_Id: string;
    codeId: string;

    routeParamsSubscription: Subscription;

    codeProgram: ICProgram = null;
    buildMacro: CodeFile = null;
    codeProgramVersions: ICProgramVersion[] = null;

    selectedProgramVersion: ICProgramVersion = null;
    selectedCodeFiles: CodeFile[] = null;
    importedLibraries: {version: ILibraryVersion, library: ILibrary}[] = [];
    buildErrors: ICompilationBuildError[] = null;
    binaryDetails: IBinaryDetails = null;
    buildError: string = '';

    buildInProgress: boolean = false;

    currentParamsService: CurrentParamsService; // exposed for template - filled by BaseMainComponent
    hardwareType: IHardwareType = null;

    selected_hardware: IHardware[] = [];
    projectSubscription: Subscription;

    @ViewChild('CodeIDEComponent')
    codeIDE: CodeIDEComponent;

    tab: string = 'ide';
    tab_under_ide: string = 'error_list';

    // List of all Version for compilation (for this type_of_board)
    libraryCompilationVersionOptions: FormSelectComponentOption[] = null;
    selectedLibrary: string;
    selectedVersionFreshest: boolean = true;
    selectedVersionSuported: boolean = true;

    protected afterLoadSelectedVersionId: string = null;

    fileChangeTimeout: any = null;

    ngUnsubscribe = new Subject<void>();

    protected exitConfirmationService: ExitConfirmationService = null;

    constructor(injector: Injector) {
        super(injector);

        this.exitConfirmationService = injector.get(ExitConfirmationService);
        this.exitConfirmationService.setConfirmationEnabled(false);

    };

    ngOnInit(): void {
        let main = new CodeFile('main.cpp', '');
        main.fixedPath = true;
        this.selectedCodeFiles = [main];

        this.refreshInterface();

        this.routeParamsSubscription = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.codeId = params['code'];
            this.project_Id = params['project'];
            this.refresh();

            if (this.project_Id != null && params['version']) {
                this.router.navigate(['/projects', this.project_Id, 'code', this.codeId], { replaceUrl: true });
                this.selectVersionByVersionId(params['version']);
            }
        });

        this.tyrionBackendService.objectUpdateTyrionEcho.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
            if (status.model === 'CProgram' && this.codeId === status.model_id) {
                this.refresh();
            } else if (status.model === 'CProgramVersion' && this.codeProgram.versions && this.codeProgramVersions.find(v => v.id === status.model_id)) {
                this.reloadVersions();
            }
        });

    }

    onPortletClick(action: string): void {
        if (action === 'edit_program') {
            this.onEditClick();
        }

        if (action === 'remove_program') {
            this.onRemoveClick();
        }

        if (action === 'make_clone') {
            this.onMakeClone();
        }
    }

    onToggleTab(tab: string) {
        this.tab = tab;
    }

    onToggleIDETab(tab: string) {
        if (this.tab_under_ide === tab) {
            this.tab_under_ide = ''; // Hide tab
        } else {
            this.tab_under_ide = tab;
        }
    }


    ngAfterViewInit(): void {
        this.refreshInterface();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        if (this.project_Id != null && this.projectSubscription) {
            this.projectSubscription.unsubscribe();
        }
    }

    /**
     * Little bit complicated Boolean,
     * But its only for Main Default C_PRograms for Type Of Boards and only for not already Mark && only
     * succesfully compiled
     * @param version
     * @returns {boolean}
     */
    markupableAsMain(version: ICProgramVersion): boolean {
        return !version
            && !this.project_Id
            && !this.hardwareType
            && (this.hardwareType.main_test_c_program != null || this.hardwareType.main_c_program == null)
            && (this.hardwareType.main_test_c_program.id === this.codeProgram.id || this.hardwareType.main_c_program.id === this.codeProgram.id)
            && version.status === 'SUCCESS'
            && !version.main_mark;
    }

    onCProgramPublishResult(version: ICProgramVersion): void {
        let model = new ModalsPublicShareResponseModel(
            version.name,
            version.description,
            this.codeProgram.name,
            this.codeProgram.description
        );
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramVersionEditResponsePublication({
                    version_id: version.id,
                    version_name: model.version_name,
                    version_description: model.version_description,
                    decision: model.decision,
                    reason: model.reason,
                    program_description: model.program_description,
                    program_name: model.program_name
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));
                        this.navigate(['/admin/c-program']);
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRemoveClick(): void {
        this.modalService.showModal(new ModalsRemovalModel(this.codeProgram.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramDelete(this.codeProgram.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_remove')));
                        if (this.project_Id != null) {
                            this.refresh();
                        }
                        this.router.navigate(['/projects/' + this.project_Id + '/code']);
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onEditClick(): void {
        let model = new ModalsCodePropertiesModel(null, this.project_Id, this.codeProgram);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramEdit(this.codeProgram.id, {
                    name: model.program.name,
                    description: model.program.description,
                    tags: model.program.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onRemoveVersionClick(version: ICProgramVersion): void {
        this.modalService.showModal(new ModalsRemovalModel(version.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramVersionDelete(version.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_version_remove')));
                        if (this.codeProgram.versions && this.codeProgram.versions.length === 1) {
                            // if last version was removed
                            this.router.navigate(['projects', this.project_Id, 'code']);
                        } else {
                            if (this.project_Id != null) {
                                this.refresh();
                            }
                            this.refresh();
                        }
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        if (this.project_Id != null) {
                            this.refresh();
                        }
                        this.refresh();
                    });
            }
        });
    }

    onEditVersionClick(version: ICProgramVersion): void {
        let model = new ModalsVersionDialogModel(this.codeProgram.id, 'CProgramVersion', version);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramVersionEditInformation(version.id, {
                    name: model.object.name,
                    description: model.object.description,
                    tags: model.object.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_version_change', model.object.name)));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    selectVersionByVersionId(versionId: string) {
        if (this.codeProgramVersions && !(this.codeProgramVersions.length === 0)) {
            let version = null;
            if (versionId) {
                version = this.codeProgramVersions.find((bpv) => bpv.id === versionId);
            }

            if (version) {
                this.selectProgramVersion(version);
            }
        } else {
            this.afterLoadSelectedVersionId = versionId;
        }
    }

    refresh(): void {

        this.blockUI();
        this.tyrionBackendService.cProgramGet(this.codeId).then((codeProgram) => {

            this.codeProgram = codeProgram;

            this.codeProgramVersions = this.codeProgram.versions || [];

            let version = null;
            if (this.afterLoadSelectedVersionId) {
                version = this.codeProgramVersions.find((bpv) => bpv.id === this.afterLoadSelectedVersionId);
            }

            if (version) {
                this.selectProgramVersion(version);
            } else if (this.codeProgramVersions.length > 0) {
                this.selectProgramVersion(this.codeProgramVersions[0]);
            }

            this.unblockUI();

            this.tyrionBackendService.hardwareTypeGet(this.codeProgram.hardware_type.id)
                .then((response) => {
                    this.hardwareType = response;
                    this.onMakeListOfCompilationVersion();
                });
        }).catch((reason: IError) => {
            this.unblockUI();
            this.fmError(reason);
        });
    }

    onMakeListOfCompilationVersion() {
        if (this.hardwareType && this.hardwareType.supported_libraries) {
            this.libraryCompilationVersionOptions = this.hardwareType.supported_libraries.map((pv) => {
                return {
                    label: pv.version_identifier,
                    value: pv.version_identifier
                };
            });
            this.selectedVersionFreshest = this.hardwareType.supported_libraries.length  > 0 && this.hardwareType.supported_libraries[0].version_identifier === this.selectedLibrary;
            this.selectedVersionSuported = this.hardwareType.supported_libraries.findIndex(x => x.version_identifier === this.selectedLibrary) !== -1;
        }
    }

    onAddLibraryClick() {
        let m = new ModalsCodeAddLibraryModel(this.project_Id, this.importedLibraries.map(x => x.library.id));
        let mm: ModalsCodeLibraryVersionModel = null;
        this.modalService.showModal(m)
            .then((success) => {
                if (success) {
                    mm = new ModalsCodeLibraryVersionModel(m.library.id);
                    return this.modalService.showModal(mm);
                }
                return null;
            })
            .then((success) => {
                if (success && mm && mm.libraryVersion) {
                    this.importedLibraries.push({library: mm.library, version: mm.libraryVersion})
                }
            });
    }

    onChangeLibraryVersionClick(library: ILibrary) {
        let modalModel = new ModalsCodeLibraryVersionModel(library.id);
        this.modalService.showModal(modalModel)
            .then((success) => {
                if (success && modalModel.libraryVersion) {
                    this.importedLibraries = this.importedLibraries.filter(x => x.library.id !== library.id);
                    this.importedLibraries.push({library: library, version: modalModel.libraryVersion});
                }
            });
    }

    onFileContentChange() {
        if (this.fileChangeTimeout) {
            clearTimeout(this.fileChangeTimeout);
        }
        this.fileChangeTimeout = setTimeout(() => this.onFileContentChangeDebounced(), 500);
        this.exitConfirmationService.setConfirmationEnabled(true);

    }

    onFileContentChangeDebounced() {
        this.refreshInterface();
    }

    onMakeClone(): void {
        let model = new ModalsCodePropertiesModel(null, this.project_Id, this.codeProgram, true);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramMakeClone({
                    c_program_id: this.codeProgram.id,
                    project_id: this.project_Id,
                    name: model.program.name,
                    description: model.program.description,
                    tags: model.program.tags
                })
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_update')));

                        this.unblockUI();
                        if (this.project_Id != null) {
                            this.navigate(['/projects', this.project_Id, 'code']);
                        }

                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.unblockUI();
                    });
            }
        });
    }

    refreshInterface() {

        if (!this.codeProgram) {
            return;
        }

        let main = '';

        let userFiles: { [file: string]: string } = {};

        this.selectedCodeFiles.forEach((file) => {
            if (file.objectFullPath === 'main.cpp') {
                main = file.content;
            } else if (!file.library) {
                userFiles[file.objectFullPath] = file.content;
            }
        });

        let ios = getAllInputOutputs(main, userFiles);


        this.codeIDE.refreshInterface(ios);

    }

    reloadVersions(): void {
        if (this.codeId) {
            this.tyrionBackendService.cProgramGet(this.codeId)
                .then((codeProgram) => {
                    this.codeProgram = codeProgram;
                    this.codeProgramVersions = this.codeProgram.versions || [];
                });
        }
    }

    onCommunityPublicVersionClick(programVersion: ICProgramVersion) {
        this.modalService.showModal(new ModalsPublicShareRequestModel(this.codeProgram.name, programVersion.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cProgramVersionMakePublic(programVersion.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_code_was_publisher')));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    selectProgramVersion(programVersion: ICProgramVersion) {
        if (!this.codeProgramVersions) {
            return;
        }
        if (this.codeProgramVersions.indexOf(programVersion) === -1) {
            return;
        }
        if (programVersion.link_to_download == null || programVersion.link_to_download.length === 0 ) {
            return;
        }

        this.buildError = ''
        this.buildErrors = null;
        this.binaryDetails = null;

        this.selectedProgramVersion = programVersion;
        this.selectedLibrary = programVersion.compilation_version;
        this.onMakeListOfCompilationVersion();
        if (programVersion.link_to_download) {
            this.fileDownloaderService.download(programVersion.link_to_download)
                .then((data) => {
                    const program: ICProgramVersionRefresh = JSON.parse(data);
                    let macros = new CodeFile('build_macro');
                    if (!program.macro) {
                        program.macro = '';
                    }

                    macros.content = program.macro;
                    this.buildMacro = macros;

                    let codeFiles: CodeFile[] = [];
                    if (Array.isArray(program.files)) {
                        codeFiles = (program.files).map((uf) => {
                            return new CodeFile(uf.file_name, uf.content);
                        });
                    }
                    this.tyrionBackendService.librariesGetById({library_id: program.imported_libraries.map((x) => x.library_id)}).then(
                        (libraryList) => {
                            this.importedLibraries = program.imported_libraries.map((importedLib) => {
                                const library = libraryList.find((x) => x.id === importedLib.library_id);
                                const version = library.versions.find((x) => x.id === importedLib.library_version_id );
                                return {
                                    library: library,
                                    version: version
                                }
                            })
                        }
                    );

                    let main = new CodeFile('main.cpp', program.main);
                    main.fixedPath = true;
                    codeFiles.push(main);

                    if (Array.isArray(program.imported_libraries)) {
                        /*program.imported_libraries.forEach((uf) => {

                            let cf = new CodeFile(this.translate('codefile_library_version_dont_have_readme', uf.library.name, uf.library_version.name,
                                uf.library.name, uf.library_version.name));
                            cf.fixedPath = true;
                            cf.library = true;
                            cf.readonly = true;

                            cf.libraryId = uf.library.id;
                            cf.libraryName = uf.library.name;
                            cf.libraryVersionId = uf.library_version.id;

                            this.tyrionBackendService.libraryVersionGet(uf.library_version.id)
                                .then((lv) => {
                                    if (lv && lv.files) {
                                        lv.files.forEach((f) => {
                                            if (f.file_name.toLowerCase() === 'readme.md') {
                                                cf.content = cf.contentOriginal = f.content;
                                            }
                                        });
                                    }
                                });

                            codeFiles.push(cf);
                        });*/
                    }

                    this.selectedCodeFiles = codeFiles;

                    this.refreshInterface();

                    if (programVersion.build_errors) {
                        this.buildError = programVersion.build_errors.system_error;
                        if (programVersion.build_errors.build_errors.length) {
                            this.buildErrors = programVersion.build_errors.build_errors;
                            this.handleErrors();
                        }
                    }

                    if (programVersion.binary_details) {
                        this.binaryDetails = programVersion.binary_details;
                    }

                    this.tab = 'ide';
                })
                .catch((reason) => {
                    this.fmError(reason);
                });
        }
    }

    onProgramVersionClick(programVersion: ICProgramVersion) {

        if (this.selectedProgramVersion) {

            let changedFiles: string[] = this.changesInSelectedVersion();

            if (changedFiles.length) {

                let text;
                if (this.selectedProgramVersion.id === programVersion.id) {
                    text = this.translate('text_unsaved_change_reload', this.selectedProgramVersion.name);
                } else {
                    text = this.translate('text_unsaved_change_switch', this.selectedProgramVersion.name, programVersion.name);
                }

                let confirm = new ModalsConfirmModel(
                    text,
                    this.translate('text_changed_files') + changedFiles.join('<br>')
                );

                this.modalService.showModal(confirm).then((yes) => {
                    if (yes) {
                        this.selectProgramVersion(programVersion);
                    }
                });

            } else {
                if (this.selectedProgramVersion.id !== programVersion.id) {
                    this.selectProgramVersion(programVersion);
                }
            }

        } else {
            this.selectProgramVersion(programVersion);
        }
    }

    changesInSelectedVersion(): string[] {
        let changedFiles: string[] = [];
        if (Array.isArray(this.selectedCodeFiles)) {
            this.selectedCodeFiles.forEach((file) => {
                if (file.changes) {
                    changedFiles.push('/' + file.objectFullPath);
                }
            });
        }
        return changedFiles;
    }

    isSelected(version: ICProgramVersion): boolean {
        return NullSafe(() => this.selectedProgramVersion.id) === version.id;
    }

    saveAsCode() {
        let model = new ModalsVersionDialogModel(this.codeProgram.id, 'CProgramVersion', {name: this.nextVersion(this.codeProgramVersions)});
        this.modalService.showModal(model).then((success) => {
            if (success) {
                let main = '';

                let userFiles: ILibraryRecord[] = [];

                this.selectedCodeFiles.forEach((file) => {
                    if (file.objectFullPath === 'main.cpp') {
                        main = file.content;
                    } else {
                        // else if (file.library) {
                        // libs.push(file.libraryVersionId);
                        userFiles.push({
                            file_name: file.objectFullPath,
                            content: file.content
                        });
                    }
                });

                this.blockUI();
                this.tyrionBackendService.cProgramVersionCreateSaveAs(this.codeId, {
                    imported_libraries: this.importedLibraries.map((lv) => { return {library_id: lv.library.id, library_version_id: lv.version.id}} ),
                    name: model.object.name,
                    description: model.object.description,
                    main: main,
                    files: userFiles,
                    tags: model.object.tags,
                    library_compilation_version: this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value,
                    macro: this.buildMacro ? this.buildMacro.content : ''
                })
                    .then(() => {
                        this.fmSuccess(this.translate('flash_code_version_save', model.object.name));
                        this.exitConfirmationService.setConfirmationEnabled(false);

                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.unblockUI();
                    });
            }
        });
    }

    saveCode() {
        let main = '';
        let userFiles: ILibraryRecord[] = [];
        let libs: string[] = [];

        this.selectedCodeFiles.forEach((file) => {
            if (file.objectFullPath === 'main.cpp') {
                main = file.content;
            } else if (file.library) {
                libs.push(file.libraryVersionId);
            } else {
                userFiles.push({
                    file_name: file.objectFullPath,
                    content: file.content
                });
            }
        });

        this.blockUI();
        this.tyrionBackendService.cProgramVersionWorkingcopysave(this.codeId, {
            imported_libraries: this.importedLibraries.map((lv) => { return {library_id: lv.library.id, library_version_id: lv.version.id}} ),
            main: main,
            files: userFiles,
            library_compilation_version: this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value,
            macro: this.buildMacro ? this.buildMacro.content : ''
           // name: 'Working Cpy'
        })
            .then(() => {
                this.exitConfirmationService.setConfirmationEnabled(false);
                this.refresh();

                this.unblockUI();
                // this.refresh();
            })
            .catch((reason: IError) => {
                this.fmError(reason);
                this.unblockUI();
            });

    }

    onSaveClick() {
        if (!this.exitConfirmationService.getConfirmationEnabled()) {
            let con = new ModalsConfirmModel(this.translate('modal_label_save_same_code'), this.translate('modal_text_no_change'), false, this.translate('btn_yes'), this.translate('btn_no'), null);

            this.modalService.showModal(con).then((success) => {
                if (!success) {
                    return;
                } else {
                    this.saveCode();
                }
            });
        } else {
            this.saveCode();
        }
    }

    onBuildClick() {
        if (!this.selectedCodeFiles) {
            return;
        }

        let main = '';

        let userFiles: ILibraryRecord[] = [];

        this.buildError = ''
        this.buildErrors = null;
        this.selectedCodeFiles.forEach((file) => {
            file.annotations = [];

            if (file.objectFullPath === 'main.cpp') {
                main = file.content;
            } else {
                userFiles.push({
                    file_name: file.objectFullPath,
                    content: file.content
                });
            }
        });

        if (this.hardwareType.supported_libraries.map(x => x.version_identifier).indexOf(this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value) == null) {
            this.fmErrorFromString('Library version is not supported');
        } else {
            this.buildInProgress = true;
            this.tyrionBackendService.cProgramCompile({
                imported_libraries: this.importedLibraries.map((lv) => { return { library_id: lv.library.id, library_version_id: lv.version.id } }),
                main: main,
                files: userFiles,
                macro: this.buildMacro ? this.buildMacro.content : null,
                hardware_type_id: this.codeProgram.hardware_type.id,
                library_compilation_version: this.codeIDE.formLibrarySelector.controls['compilation_version_library_tag'].value,
                immediately_hardware_update: this.codeIDE.upload_after_build,
                hardware_ids: this.codeIDE.selectedHardware.map(x => x.id)
            })
                .then((result: ICloudCompilationServerCompilationResult) => {
                    this.buildInProgress = false;
                    this.binaryDetails = result.binary_details;
                    if (result.build_errors && result.build_errors.length) {
                        this.buildErrors = result.build_errors;
                        this.handleErrors();
                        this.fmWarning(this.translate('flash_code_version_build_warnings'));
                    } else {
                        this.fmSuccess(this.translate('flash_code_version_build_success'));
                    }
                })
                .catch((reason: IError) => {
                    this.buildInProgress = false;
                    if (reason instanceof CodeCompileError) {
                        this.buildErrors = reason.errors;
                        this.handleErrors();
                        this.fmWarning(this.translate('flash_code_version_build_failed'));
                    } else if (reason.code === 478) {
                        this.buildError = reason.message;
                        this.fmWarning(this.translate('flash_code_version_build_failed'));
                    } else {
                        this.fmError(reason);
                    }
                });
        }
    }

    handleErrors() {
        let filesAnnotations: { [filename: string]: any[] } = {};
        this.buildErrors.forEach((bError) => {
            let filename = bError.file_name.substr(1);
            if (!filesAnnotations[filename]) {
                filesAnnotations[filename] = [];
            }
            filesAnnotations[filename].push({
                row: bError.line - 1,
                column: 1,
                text: bError.text,
                type: bError.type
            });
        });

        this.selectedCodeFiles.forEach((f) => {
            if (filesAnnotations[f.objectFullPath]) {
                f.annotations = filesAnnotations[f.objectFullPath];
            }
        });
    }

    onCProgramDefaultSetMainClick(version: ICProgramVersion) {
        this.modalService.showModal(new ModalsSetAsMainModel(this.translate('label_default_c_program_setting'), version.name)).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.hardwareTypeSetcprogramversion_as_main(version.id)
                    .then(() => {
                        this.addFlashMessage(new FlashMessageSuccess(this.translate('flash_successfully_set_as_default')));
                        this.refresh(); // also unblockUI
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh(); // also unblockUI
                    });
            }
        });
    }

    onAddDeveloperHardwareClick() {
        let m = new ModalsSelectHardwareModel(this.project_Id, this.hardwareType, true, false, true);
        this.modalService.showModal(m).then(() => {
            this.selected_hardware.push.apply(this.selected_hardware, m.selected_hardware);
        });
    }

    onRemoveDeveloperHardwareClick(hardware: IHardware) {
        for (let i =  this.selected_hardware.length - 1; i >= 0; i--) {
            if (this.selected_hardware[i].id === hardware.id) {
                this.selected_hardware.splice(i, 1);
            }
        }
    }

    onRemoveLibraryClicked(library: {version: ILibraryVersion, library: ILibrary}) {
        this.importedLibraries.splice(this.importedLibraries.indexOf(library), 1);
    }

    onVersionUploadFile(version: ICProgramVersion): void {
        let model = new ModalsFileUploadModel(this.translate('label_version'), this.translate('label_version_comment'), ['.bin']);
        this.modalService.showModal(model).then((success) => {
            if (success) {
                this.blockUI();
                this.tyrionBackendService.cprogramVersionUpdateFileManualy(version.id, model.raw)
                    .then(() => {
                        this.fmSuccess(this.translate('flash_file_uploaded'));
                        this.refresh();
                    })
                    .catch((reason: IError) => {
                        this.fmError(reason);
                        this.refresh();
                    });
            }
        });
    }

    onDropDownEmitter(action: string, version: ICProgramVersion): void {

        if (action === 'version_properties') {
            this.onEditVersionClick(version);
        }

        if (action === 'upload_file') {
            this.onVersionUploadFile(version);
        }

        if (action === 'version_properties_community') {
            this.onCommunityPublicVersionClick(version);
        }

        if (action === 'version_publish') {
            this.onCProgramPublishResult(version);
        }

        if (action === 'set_as_main') {
            this.onCProgramDefaultSetMainClick(version);
        }

        if (action === 'remove_version') {
            this.onRemoveVersionClick(version);
        }
    }

    createZip() {
        const zip = new JSZip();
        this.selectedCodeFiles.forEach(file => {
            zip.file(file.objectFullPath, file.content)
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
            // see FileSaver.js
            saveAs(content, this.codeProgram.name + '_' + this.selectedProgramVersion.name + '.zip');
        });
    }
}
